@import 'src/index';

$button-height: 60px;
.single-scan-component {
  flex-direction: column;

  .type-buttons {
    .type-button {
      height: $button-height;
      width: max-content;
      border: 2px solid transparent;
      cursor: pointer;
      font-weight: 500;
      font-size: $font-size-medium;
      color: $platinum;
      line-height: 40px;
      padding: 0 $padding-medium;

      @media only screen and (max-width: $screen-size-sm) {
        padding: 0 $padding-normal;
      }
    }

    .type-button.active {
      color: $ocean;
      border: 2px solid $ocean-light;
      border-bottom-width: 0;
    }
  }

  .search-container {
    flex: 1;

    .search-bar {
      .input-wrap {
        display: flex;
        align-items: center;
        flex-grow: 1 !important;
        min-width: 50%;
        position: relative;
        img {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
        .search-text-field {
          height: $button-height;
          flex: 1;
          border: 2px solid $ocean-light;
          font-size: $font-size-large;
          padding: 0 $padding-medium;
          display: flex;
          align-items: center;
        }
        .search-text-field.small {
          height: 45px;
          border: 2px solid $ocean;
          border-right: 0;
          outline: 0;
        }

        .search-text-field.small.disabled-border {
          border: 1px solid $dark-gray-1;
        }
      }
      .search-bar-button {
        cursor: pointer;
        height: $button-height;
        min-width: $button-height;
        background-color: $ocean;
      }

      .search-bar-button.small {
        height: 45px;
      }

      .disabled {
        background-color: $theme-typo-submit-btn !important;
        cursor: default !important;
        :hover {
          cursor: default !important;
        }
      }

      .submit-button {
        @media only screen and (min-width: $screen-size-lg) {
          min-width: 110px;
        }

        .scan-image {
          width: 24px;
        }
      }

      .settings-button {
        background-color: #cccc;
      }
    }

    .settings-container {
      padding: $padding-medium;
      transition: height 0.3s, padding 0.3s;

      .setting-field {
        .settings-label {
          min-width: 140px;
          padding-right: $padding-medium;
        }

        .user-agent-dropdown-container {
          flex: 1;

          .user-agent-dropdown {
            max-width: 100%;
            background-color: $white;

            .dropdown {
              width: 100%;
              max-width: 100%;

              button {
                width: 100%;
                max-width: 55vw;
                text-align: right;
                color: $platinum;
                font-size: $font-size-large;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-word;

                @media only screen and (min-width: $screen-size-xlg) {
                  max-width: 760px;
                }
                @media only screen and (max-width: $screen-size-lg) {
                  max-width: calc(100vw - 96px);
                }
              }

              .dropdown-menu {
                overflow: scroll;

                .dropdown-item {
                  max-width: 55vw;
                  @media only screen and (min-width: $screen-size-xlg) {
                    max-width: 760px;
                  }
                  @media only screen and (max-width: $screen-size-lg) {
                    max-width: calc(100vw - 96px);
                  }
                }
              }
            }

            .drop-down-option:hover {
              text-decoration: underline;
            }
          }
        }

        .user-agent-input-container {
          flex: 1;

          .user-agent-input {
            height: 40px;
            border: none;
            padding-left: $padding-normal;
            color: $dark-gray-7;
          }
        }

        .help-text {
          font-size: $font-size-normal;
          color: $platinum;
          padding-top: $padding-small;
        }

        .visibility-switch-container {
          .custom-control-input:checked ~ .custom-control-label::before {
            border-color: $ocean;
            background-color: $ocean;
          }

          .custom-control-label {
            color: $dark-gray-7;
            font-size: $font-size-large;
          }
        }
      }
    }

    .settings-container.height-0 {
      padding: 0 $padding-medium;
      height: 0;
      overflow: hidden;
    }
  }
  .search-container.disabled-wrap {
    opacity: 0.5;
    input {
      background-color: $pre-text-background-color;
      border: 2px solid $helper-text-border-color !important;
    }
    .submit-button {
      background-color: $dark-gray-1 !important;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: auto !important;
}

.invert-color {
  filter: invert(100%);
}

.br {
  &-txt {
    &-blue {
      color: blue;
    }
  }
}
.try-check-phish-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  @media only screen and (max-width: $screen-size-lg) {
    height: 30px;
  }
}

.try-check-phish-scan {
  font-size: $font-size-medium;
}

@media only screen and (min-width: $screen-size-lg) {
  .single-scan-component.horizontal {
    max-width: 1200px;
    margin: auto;
    padding: $padding-large 0;
    flex-direction: row;

    .type-buttons {
      .type-button {
        border: 2px solid transparent;
        border-right-width: 0;
        color: $platinum;
      }

      .type-button.active {
        border: 2px solid $white;
        border-right-width: 0;
        color: $ocean;
      }

      .type-button:first-child {
        border: 2px solid $white;
        border-top-left-radius: $button-height/2;
        border-bottom-left-radius: $button-height/2;
        border-right-width: 0;
      }

      .type-button:nth-child(2) {
        border: 2px solid $white;
      }
    }

    .search-container {
      flex: 1;

      .search-bar {
        .search-text-field {
          border-color: $white;
        }
      }

      .settings-container {
        padding-right: 0;

        .setting-field {
          .user-agent-dropdown-container {
            .user-agent-dropdown {
              .dropdown {
                button {
                  max-width: 40vw;
                }
              }
            }
          }
        }
      }
    }
  }
}

.scan-section {
  padding: $padding-medium;

  .single-scan-component {
    .type-buttons {
      .type-button {
        border-bottom-width: 0;
      }
    }

    .type-button {
      border: 2px solid transparent;
      border-right-width: 0;
      color: $platinum;
    }

    .type-button.active {
      border: 2px solid $white;
      border-bottom-width: 0;
    }

    .search-container {
      .search-bar {
        .search-text-field {
          border-color: $white;
        }
      }
    }
  }
}

$video-game-width: 800px;
.scan-in-progress-page {
  .scan-in-progress-content {
    max-width: $video-game-width + 2 * $padding-medium;
    margin: auto;

    .scan-url {
      font-weight: 500;
      font-size: $font-size-xxlarge;
      line-height: 130%;
      flex-wrap: wrap;
      word-break: break-word;
      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .video-game {
      width: 100%;
      max-width: $video-game-width;
      margin: 0 auto;
    }
  }
}

.scan-bar-container {
  .scan-bar-inner {
    max-width: $screen-size-md;
    margin: 0 auto;
  }
}

.pre-text-wrapper {
  height: 45px;
  background-color: $pre-text-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  color: $typosquat-scan-sub-text;
  opacity: 0.8;
  border: 2px solid $helper-text-border-color;
  border-right-width: 0px;
  width: 120px;
}
