@import 'src/index';

.web-footer-container {
  background-color: $platinum;
  min-height: min-content;
  margin-top: 1rem;

  .web-footer-middle {
    font-weight: bolder;
  }

  .web-footer-bottom {
    .policy-right {
      border-right: $border5;
    }
  }
  .footer-item {
    width: 33%;
    .social-icon {
      margin: 0 0.5rem;
    }
  }
  .align-right {
    display: flex;
    justify-content: flex-end;
  }
}

.web-footer-container a:hover {
  text-decoration: underline !important;
}
