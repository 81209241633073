@import 'src/index.scss';

.custom-color-palette-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  .color-palette {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    border: $theme-border;
    margin-right: $padding-xxsmall;
    &:hover {
      cursor: pointer;
    }
  }
  .selected,
  .color-palette:hover {
    border: 2px solid $theme-widget-legend-header-color;
  }
}
