@import 'src/index';

.single-takedown-form {
  background-color: $theme-widget-bg-color;
  width: 100%;
  border: $theme-border;
  margin-bottom: $padding-normal;
  margin-top: $padding-normal;
  padding: $padding-normal $padding-medium $padding-medium $padding-medium;
  font-size: $font-size-medium;
  font-weight: 400;
  color: $theme-modal-overlay-close-btn;
  .first-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .instruction-label {
      flex: 1;
    }
  }
}

.MuiButton-root.margin-left--8 {
  margin-left: -8px;
}
