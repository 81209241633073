@import 'src/index';

.critical-alerts-container {
  padding: $padding-small;
  padding-left: 0;
  .critical-alerts {
    height: 100%;
    padding: $padding-small;
    min-width: 300px;
    border-radius: 10px;
    padding: $padding-medium;
    color: $white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    .critical-header {
      font-size: 20px;
      font-weight: 500;
    }
    .critical-count {
      text-align: center;
      font-size: 64px;
      font-weight: 500;
    }
    .critical-content-header {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 500;
    }
    .critical-content {
      margin-bottom: 0;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
    }
    img {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .alert-red {
    background: linear-gradient(295deg, #ff7629 11.27%, #fd4649 86.81%);
  }
  .alert-blue {
    background: linear-gradient(211.85deg, #3de8ff -5.9%, #286bf4 100%);
  }
}
