@import '../../../index.scss';
@import '../../Common/statusLabels.scss';

.all-email-submissions {
  color: $theme-text-color;
  padding: 24px;
  &.py--end-0 {
    padding-block-end: 0;
  }
  .all-email-submissions-header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .all-email-submissions-content {
    display: flex;
  }

  .all-email-loader {
    height: 80vh;
  }

  .inbox-container {
    height: 85vh;
    overflow-y: hidden;
    min-height: calc(100vh - 160px);
    margin-right: 16px;
    background-color: $theme-background-color;
    border: var(--theme-border);
    width: clamp(288px, 330px, 390px);
    flex: 1 0;
    border: 1px solid #dde7ee;

    .dark & {
      border: 1px solid #555e68;
    }
    .inbox-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      padding: 0 16px;
      border-bottom: var(--theme-border);

      .dark & {
        background-color: #303236;
      }
      .inbox-header-title {
        font-size: 13px;
        font-weight: 400;

        .inbox-header-count {
          font-weight: 600;
        }
      }
      .inbox-header-filter {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        font-size: 14px;
        .filter-by-select {
          margin-left: 8px;
          height: 28px;
        }
        .MuiChip-avatar {
          &.inbox-header-filter-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin-left: 12px;
            margin-right: -2px;
          }
          width: auto;
        }
        .MuiChip-label {
          padding-inline: 6px 11px;
        }
      }
    }
    .inbox-content {
      position: relative;
      height: calc(100% - 84px);
      overflow-y: auto;
      .inbox-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 64px;
        padding: 0 16px;
        border-bottom: var(--theme-border);
        cursor: pointer;
        &:hover {
          background-color: $theme-hover-bg-color;
        }
        &.selected {
          background-color: $disabled-btn-bg;
          transition: background-color 0.3s ease;
        }
        .inbox-item-selected-indicator {
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: var(--theme-disposition-malicious);
          &.disposition_malicious {
            background-color: var(--theme-disposition-malicious);
          }
          &.disposition_suspicious {
            background-color: var(--theme-disposition-suspicious);
          }
          &.disposition_clean {
            background-color: var(--theme-disposition-clean);
          }
        }
        .inbox-item-header-title {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 2px;
        }
        .inbox-item-header-date-container {
          justify-content: space-between;
          font-size: 14px;
          .inbox-item-header-status {
            font-weight: 500;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #b8b8b8;
        border-radius: 30px;
        .dark & {
          background: #6b6b6b;
        }
      }
    }
  }

  .inbox-item-details {
    padding-top: 0;
    .emailDetails-analysis-container {
      height: 85vh;
      margin-top: 0;
      position: relative;
      border: 1px solid #dde7ee;
      .dark & {
        border: 1px solid #555e68;
      }
      .section-wrap {
        margin-top: 0;
        overflow-y: scroll;
        height: calc(100% - 140px);
        background: $abuseMailBox-bg-color;
        box-shadow: none;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: #b8b8b8;
          border-radius: 30px;
          .dark & {
            background: #6b6b6b;
          }
        }
      }
    }
  }
  .all-email-submissions-section-details-wrap {
    padding: 24px;
    margin: 0 24px;
    min-height: 40px;
    display: flex;
    border-top: 1px solid #dde7ee;

    .dark & {
      border-top: 1px solid #555e68;
    }

    .stack-item {
      margin-right: 10px;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      .section-header {
        font-weight: bold;
      }
      .source-data .value {
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .disposition-row {
      width: 350px;
      margin-right: 20px;
      margin-block-end: 10px;
    }

    .not-available-text {
      font-size: 14px;
      color: #171a1c;
      .dark & {
        color: #fff;
      }
    }
  }
  .custom-copy-button {
    .copy-text-icon {
      font-size: 16px;
    }
  }
  .disposition-wrap {
    display: flex;
    align-items: center;

    > img {
      margin-inline-end: 2px;
    }
  }
  .disposition-insights-block-wrap {
    .abuse-mailbox-url-insights-link {
      margin-bottom: 0;

      .remove-link {
        color: #000;
        .dark & {
          color: #fff;
        }
      }
      &:hover {
        color: #2360bc;
        text-decoration: none;
      }
      .dark & {
        color: #599bff;
        &:hover {
          color: #b2d0fe;
          text-decoration: none;
        }
      }
    }
    .clip-url {
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      width: 120px;
    }
    .url-wrap {
      display: flex;
      font-size: 14px;
      padding: 0;
      align-items: center;
      .source-data {
        display: flex;
        min-width: 150px;
        display: flex;
        justify-content: flex-start;
        margin-left: 15px;
        border-left: 1px solid #dde7ee;
        padding-inline-start: 15px;

        .dark & {
          border-left: 1px solid #4d535a;
        }
      }
    }
  }
  .sender-domain-details {
    .sender-domain-link {
      display: flex;
      justify-content: flex-start;
    }
  }
  .intent-details {
    .intent-data {
      box-sizing: border-box;
      .row {
        display: flex;
        margin-inline: 0;
      }
    }
  }

  .all-email-submissions-section-details-wrap.screenshot-section-wrap {
    .screenshot-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
    }
    .stack-item.screenshot {
      .screenshot-header {
        font-size: 14px;
        .label {
          color: $abuseMailBox-allEMailSubmission-subheader-text;
          font-size: 14px;
        }
        .download-icon {
          fill: $abuseMailBox-allEmailSubmissions-noDataView-icons-color;
          margin-bottom: 1px;
        }
      }
      .img-fluid {
        img {
          width: 280px;
          height: 150px;
        }
      }
    }
  }
}

.all-email-submissions-header {
  font-size: 24px;
  font-weight: 600;
  .tab-wrapper {
    border-bottom: 1px solid #dde7ee;
    .dark & {
      border-bottom: 1px solid #555e68;
    }
  }
}
