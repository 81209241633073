@import 'src/index';

.table-wrap {
  position: relative;
  .typosquat-blurr-table-wrap {
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    padding: 1rem 3rem;
    margin-bottom: 5px;
    .banner {
      display: flex;
      margin: 1rem auto;
      color: $theme-text-color;
      align-items: center;
      background-color: $theme-widget-bg-color;
      border: 1px solid #ebf5ff59;
      box-shadow: 0px 0px 60px 0px #18181a4d;
      height: 150px;

      .lock-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
      }
      .content-wrap {
        padding: 0 1rem;
        font-size: 12px !important;
        font-weight: 300 !important;
        flex-grow: 1;
        height: auto;
        .heading {
          font-size: 20px;
          font-weight: bold;
          padding: 0.5rem 0;
        }
        .sub-heading {
          padding-bottom: 1rem;
          color: $theme-subtext-color-typosquat-popup;
          .highlight-text {
            font-size: 16px;
            color: $theme-text-color;
          }
        }
      }
      .upgrade-wrap {
        .upgrade-btn {
          width: 150px;
          margin: 0 1rem;
        }
      }
    }
  }
}
