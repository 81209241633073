@import 'src/index';

.full-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-right {
  font-size: $font-size-normal;
  height: 20px;
}

.annotation-component {
  position: relative;

  .image-place-holder {
    object-fit: contain;
  }

  .mask {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.annotation-modal {
  .modal-dialog {
    min-width: 80vw;
    max-width: 80vw;

    .annotation-modal-content {
      position: relative;
    }
  }
}
