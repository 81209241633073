@import 'src/index';

.copy-button-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  .copy-text {
    padding-left: $padding-xsmall;
    color: $theme-hollow-btn-border;
    font-size: $font-size-normal;
  }
  &:hover {
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    &.copy-text-icon {
      fill: #327ef0 !important;

      &:is(:hover, :active) {
        fill: #2360bc !important;
      }

      .dark &.copy-text-icon {
        fill: #599bff !important;

        &:is(:hover, :active) {
          fill: #b2d0fe !important;
        }
      }
    }
  }
}
