@import 'src/index';

.absuse-mail-box-targeted-malicious-container {
  padding: $padding-medium-web;
  .page-title-component.no-padding {
    .page-title {
      padding-left: 0 !important;
    }
  }
}
