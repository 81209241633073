@import 'src/index';

.widget-collapsed {
  .details {
    max-width: 250px !important;
  }
}

.details {
  max-width: 400px !important;
  min-width: 200px !important;
  padding: 0 10px;
  .heading {
    text-align: left;
    color: $theme-text-color;
    margin-bottom: 1rem;
    font-size: 14px;
    min-height: 20%;
  }
  .data-set-wrap {
    display: flex;
    flex-flow: column nowrap;
    width: 300px;
  }
  .data-set {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    .value {
      padding: 0 5px;
      border-radius: 3px;
      margin: 0 5px;
      display: flex;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      background-color: $theme-darkweb-dataset-background;
      color: $theme-darkweb-dataset-color;
      border: 1px solid $theme-darkweb-dataset-border;
      width: 40px;
    }
    .label {
      color: $theme-text-color;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 135px;
    }
  }
}

.risk-tooltips {
  .tooltip-inner {
    max-width: 320px;
    .tooltip-message {
      display: flex;
      width: auto;
      padding: 2px;
      word-break: break-word;
      white-space: pre-line;
    }
  }
}
