@import 'src/index';

.takedown-status-label {
  color: $theme-text-color;
  font-size: $font-size-medium;
}
.takedown-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--theme-disposition-clean-color);
}
