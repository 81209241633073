@import 'src/index';

.dark-web-insights-page {
  position: relative;
  height: 100%;

  .raw-data-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content-container {
      flex: 1;
      height: calc(100vh - #{$top-nav-bar-height} - 54px) !important;
      background-color: $theme-color !important;
      border-width: 0 !important;
      padding: $padding-medium;
      .content-body {
        background-color: var(--theme-code-background) !important;
        color: var(--theme-page-text) !important;
        border: $theme-border;
        padding-top: $padding-medium !important;
        overflow-y: scroll;
      }
    }
  }
  .recent-findings-container {
    margin-top: $padding-medium;
  }
}

.dark-web-status-tooltip {
  padding: 0;
  box-shadow: none;

  .tooltip-inner {
    padding: 0;
  }
}
.mark-as-status {
  background-color: $theme-background-color;
  color: $theme-widget-sub-label-color;
}

.content-info-section {
  .content-info {
    position: relative;
    .content-container {
      flex: 2;
      border-bottom-width: 0 !important;
    }
    .info-container {
      flex: 1;
    }
    .content-body {
      background-color: var(--theme-code-background) !important;
      color: var(--theme-page-text);
      padding-top: $padding-medium !important;
      border-top: $theme-border;
      height: 500px;
      overflow: scroll;
    }
    .content-blocker {
      position: absolute;
      top: 64px;
      left: 0;
      height: calc(100% - 64px);
      width: 100%;
      backdrop-filter: blur(4px);
      border: $theme-border;

      .blocker-content {
        background-color: var(--theme-widget-bg-color);
        box-shadow: 5px 6px 7px rgba(5, 3, 16, 0.33);
        border-radius: 3px;
        padding: $padding-large $padding-xxlarge;

        > div + div {
          padding-top: $padding-small;
        }
      }
    }
  }
  .content-footer {
    height: 44px;
  }
}

.dark-web-threat-actor-insights-page {
  .first-two-sections {
    gap: $padding-medium;

    .separator {
      margin-bottom: $padding-medium;
    }
  }
  .actor-chatters {
    margin: $padding-medium 0;

    .tool-bar-container {
      background-color: var(--theme-widget-bg-color) !important;
    }

    .actor-chatters-post-col {
      max-width: 50vw;
      .content {
        color: $theme-secondary-text-color-light;
      }
      .show-all-content {
        max-height: 170px;
        overflow-y: scroll;
      }
      .chevron-up {
        transform: rotate(180deg);
      }
    }
  }
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.risk-level {
  text-transform: capitalize;
  gap: $padding-xsmall;
  font-size: $font-size-medium;

  &[data-risk-level-value='high'] {
    color: var(--theme-risk-level-high-color);
    .indicator {
      background-color: var(--theme-risk-level-high-color);
    }
  }
  &[data-risk-level-value='medium'] {
    color: var(--theme-risk-level-medium-color);
    .indicator {
      background-color: var(--theme-risk-level-medium-color);
    }
  }
  &[data-risk-level-value='low'] {
    color: var(--theme-risk-level-low-color);
    .indicator {
      background-color: var(--theme-risk-level-low-color);
    }
  }
  .risk-count {
    color: $theme-table-body-text-color;
  }
}
.risk-level + .risk-level {
  margin-left: $padding-normal;
}

.dark-web-status-column {
}

.dark-web-status-content {
  gap: $padding-small;
  width: fit-content;
  cursor: pointer;
}
.dark-web-status-column-dropdown {
  background-color: var(--theme-page-background);
  .dark-web-status {
    padding: $padding-small $padding-normal;
    min-width: 160px;
    &:hover {
      background-color: $theme-dropdown-hover-bg-color;
    }
  }
  box-shadow: 5px 6px 7px rgba(5, 3, 16, 0.33);
}
.dark-web-status {
  text-transform: capitalize;
  gap: $padding-small;
  color: $theme-text-color;
  background-color: transparent !important;
  cursor: pointer;
  .indicator {
    &[data-status-value='active'] {
      background-color: var(--theme-risk-level-active-color);
    }
    &[data-status-value='mitigated'] {
      background-color: var(--theme-risk-level-mitigated-color);
    }
    &[data-status-value='ignored'] {
      background-color: var(--theme-risk-level-ignored-color);
    }
  }
  .check-wrapper {
    flex: 1;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    background-color: transparent !important;
  }
}

.dark-web-info-icon {
  display: flex;
  align-items: center;
  .svg-icon-wrapper {
    width: 12px;
    height: 12px;
  }
}
.platform-container {
  display: flex;
  flex-direction: row;
  .mx-1 {
    display: flex;
    align-items: center;
    padding-bottom: 1.5px;
  }
}
.platform-text {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
