@import 'src/index';

.category-wrap {
  width: fit-content;
  padding: 1px 0.5rem;
  color: white;
  border-radius: 12px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}
