@import 'src/index';

$image-width: 80vw;

.image-component {
  position: relative;

  img {
    cursor: pointer;
  }

  .image-modal {
    .modal-dialog {
      min-width: $image-width;
      max-width: $image-width;

      .full-screen-image {
        max-height: calc(100vh - 60px - #{$top-nav-bar-height});
        object-fit: contain;
      }
    }
  }
}
