@import 'src/index';

.landing-page {
  display: flex;
  flex-direction: column;
  padding: $padding-medium;
  color: $theme-text-color;

  .page-content {
    flex: 1;
    background-color: $theme-background-color;
    padding: $padding-medium;

    .form-block {
      padding: $padding-small $padding-small $padding-small 0;
    }

    button.dropdown-toggle {
      border-bottom: $theme-border;
      min-width: 200px;
    }

    .reset {
      color: $theme-a-tag-color;
      padding: 0 $padding-medium;
      cursor: pointer;
    }
  }
}
