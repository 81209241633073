body,
.light {
  --theme-color: #fafafb;
  --theme-color2: #fafafb;
  --theme-page-background: #ffffff;
  --theme-page-text: #333333;
  --theme-code-background: #fcfcfc;
  --theme-border: 1px solid #e9ecf0;
  --theme-hover-bg-color: #d3e1fa;
  --theme-hover-button-color: #dee1e6;
  --theme-hover-text-color: #333333;
  --theme-widget-bg-color: #ffffff;
  --theme-widget-bg-color-2: #e9ecf0;
  --theme-widget-border-color: 1px solid #e9ecf0;
  --theme-widget-legend-header-color: #64717e;
  --theme-filter-button-bg: rgba(213, 217, 221, 0.32);
  --theme-dropdown-box-shadow: 0px 4px 8px rgba(41, 42, 51, 0.24);
  --theme-a-tag-color: #2168e4;
  --theme-links-hover: rgba(33, 104, 228, 0.2);
  --theme-dropdown-button-color: #eef0f1;
  --theme-active-filter-bg-color: #d4e9fa;
  --theme-button-hover-bg-color: #ffffff;
  --theme-monitored-header-bg: #949494;
  --theme-priority-1-bg-color: #a83c8f;
  --theme-priority-2-bg-color: #c85e92;
  --theme-priority-3-bg-color: #ed8595;
  --theme-priority-4-bg-color: #ef96a4;
  --theme-priority-5-bg-color: #f2a7b3;
  --theme-hollow-button-border: #327ef0;
  --theme-takedown-button-background: #000000;
  --theme-takedown-button-color: #ffffff;
  --theme-modal-overlay-close-btn: #64717e;
  --theme-disposition-clean-color: #337f86;
  --theme-row-select-bg-color: #d3e1f9;
  --theme-new-row-added-bg-color: #fbf2e3;
  --theme-social-card-content-border: 1px solid #f4f4f4;
  --theme-widget-sub-label-color: #333333;
  --theme-form-control-color: #ffffff;
  --theme-dropdown-hover-bg-color: #dbe7fb;
  --theme-border-2: 1px solid #d5d9dd;
  --theme-border-3: 1px solid #0000003b;

  --theme-secondary-text-color: #333333;
  --theme-secondary-text-color-light: #6f6f6f;
  --theme-circle-badge-bg-color: #d5d9dd;
  --icon-blue-shade-color: #2168e4;
  --icon-black-white-color: #333333;
  --theme-tooltip-bg-color: #ffffff;
  --theme-table-bottom-border: 1px solid #e9ecf0;
  --theme-risk-bg-color-critical: #ce3b39;
  --theme-risk-bg-color-high: #ff7b5f;
  --theme-risk-bg-color-moderate: #ffa25f;
  --theme-risk-bg-color-low: #ffd3b2;
  --theme-risk-bg-color-very-low: #d5d9dd;
  --theme-table-body-text-color: #333333;
  --theme-user-icon-hover-bg-color: #74c5f4;
  --theme-dashed-border: 1px dashed #6f6f6f;
  --theme-slider-color: #939393;
  --theme-slider-total-price-bg-color: #ffffff;
  --theme-slider-bg-color: #e9ecf0;
  --theme-slider-text-color: #64717e;
  --theme-slider-text-disabled-color: #64717e;
  --theme-checkbox-color: #fafafb;
  --theme-checkbox-disabled-color: #d5d9dd;
  --theme-dropdown-text-disabled-color: #d5d9dd;
  --theme-modal-overlay-bg-color: #ffffffb5;
  --theme-risk-level-high-color: #d73726;
  --theme-risk-level-medium-color: #fc7b20;
  --theme-risk-level-low-color: #ffce1f;
  --theme-risk-level-active-color: #f43f3f;
  --theme-risk-level-mitigated-color: #72e3fe;
  --theme-risk-level-ignored-color: #68686a;
  --theme-active-hover-bg-color: #f8f8f8;
  --theme-em-tag-bg-color: rgba(238, 174, 128, 0.77);
  --theme-b-tag-bg-color: rgba(243, 234, 61, 0.77);
  --theme-add-tag-bg-color: #f0f3f5;
  --theme-warning-color: #e82e0c;
  --theme-help-active-hover: #64717e;
  --theme-grouped-badge: #e9ecf0;
  --theme-grouped-badge-text-color: #64717e;
  --theme-column-title-color: #b8b8b8;
  --theme-group-findings-bottom-border: #dee1e5;
  --theme-group-findings-button-background-off: #f0f1f2;
  --theme-group-findings-button-circle-off: #64717e;
  --theme-group-findings-button-background-on: #d3e1fa;
  --theme-group-findings-button-circle-on: #2168e4;
  --theme-grouped-expanded-bg-color: #f6faff;
  --theme-dashboard-label-monitoring: #d2d2d3;
  --theme-source-feed-bar-bg-color: #f1effa;
  --unregistered-text: #333;
  --theme-user-session-alert-color: #f83e3e;
  --theme-user-session-warning-color: #2f82fd;
  --theme-upgrade-subtext-color: #333333;
  --theme-upgrade-subtext-border: transparent;
  --new-label-bg-color: #4285f4;
  --theme-starter-contact-border: #40afff;
  --disabled-btn-bg: #f2f2f2;
  --theme-tooltip-bg-color-seconday: #2168e4;
  --theme-subtext-color-typosquat-popup: #333333;
  --theme-light-bg-color: #d5d9dd;
  --theme-disposition-clean: #0cb281;
  --theme-disposition-suspicious: #dcb201;
  --theme-disposition-malicious: #fc5449;
  --theme-disposition-phish: #f95574;
  --theme-disposition-likely-phish: #ff9141;
  --theme-disposition-others: #899aad;
  --theme-disposition-malware: #fa9926;
  --theme-disposition-spam: #f57f17;
  --theme-agGridDateRangeFilter-rdrCalendarWrapper-background-color: #ffff;
  --theme-agGridDateRangeFilter-rdrInRange-color: rgb(211, 225, 250);

  // Typosquat
  --theme-disabled-drawer-item: #59545463;
  --theme-welcome: linear-gradient(180deg, #e9ecf0 0%, #f2edf4 33.64%, #c7d7df 93.23%);
  --theme-welcome-btn: #a8b3c1;
  --theme-welcome-border: 1px solid #e9ecf0;
  --theme-code-block-color: lightgray;
  --theme-progress-page-row: #fdfdfd;
  --theme-progress-page-block: #eef0f1;
  --theme-upgrade-button-bg: linear-gradient(119.36deg, #40afff 8.94%, #5954fe 92.98%);
  --theme-upgrade-button-border: #2c6cf6;
  --theme-upgrade-button-color: #ffffff;
  --theme-upgrade-popup-bg: var(--theme-page-background);
  --typosquat-scan-sub-text: #a5a3a3;
  --theme-starter-pack-bg: #e6f2ff;
  --helper-text-border-color: #bebebe;
  --theme-contact-us-link: #1b60d8;
  --theme-typo-submit-btn: #89a8ee;
  --pre-text-background-color: #f0f0f0;
  --theme-typo-dashboard-upgrade-button-bg: #2c6cf6;

  --typosquat-continue-btn-bg: #2168e4;
  --typosquat-continue-btn-hover: #124eb6;
  --typosquat-export-first-btn-border: #b3b3b3;
  --typosquat-export-first-btn-border-hover: #2168e4;

  //explore demo popup
  --theme-explore-demo-bg: #dfefff;
  --theme-explore-demo-border: #bac3d7;
  --theme-explore-demo-text-color: #0a2540;
  --theme-explore-demo-btn-bg: #3a71e7;
  --theme-explore-demo-btn-color: #ffffff;
  --theme-explore-subtext-color: #64717e;
  --theme-contact-us-button: #2168e4;

  //darkweb
  --theme-darkweb-subtext: #4d4d4d;
  --theme-darkweb-bg-color2: #ffffff;
  --theme-darkweb-border-storke: #e9ecf0;
  --theme-widget-background: #ffffff;
  --theme-widget-donut-box-shadow: rgba(0, 0, 0, 0.15);
  --theme-darkweb-dataset-background: #b82e2550;
  --theme-darkweb-dataset-color: #b82e25;
  --theme-darkweb-dataset-border: #b82e25;

  --theme-livescan-bg: #fff5f5;
  --theme-livescan-color: #963838;
  --theme-livescan-highlight: #ff3535;
  --theme-livescan-border: #ffbbbb;
  // Darkweb V2
  --theme-darkweb-finding-group-header-bg-color: #fff1f1;

  //checkphish-v2
  --theme-cp-slider-bg-color: #2463f6;
  --theme-cp-slider-text-color: #ffffff;
  --theme-cp-slider-active-bg-color: #ffffff;
  --theme-cp-slider-active-text-color: #2463f6;

  --theme-active-nav-bg: #efefef;
  --theme-active-nav-border-color: #33333333;
  --theme-new-label-color: #00a980;

  --theme-sub-item-text-color: #333333;
  --theme-nav-item-text-color: #333333;
  --theme-active-nav-item-text-color: #333333;

  //logo-detection
  --theme-logo-detection-border: #33333359;
  --theme-logo-link-color: #2463f6;
  --theme-logo-input-bg: #f7f7f7;
  --theme-subtext-color: #333333cc;

  //new pricing

  //free place-content: ;
  --theme-plan-free-text-color: #27a95b;
  --theme-plan-free-bg-color: #edfbe6;
  --theme-plan-free-border-colors: #aae2a1;

  //starter plan
  --theme-plan-starter-text-color: #07a8a8;
  --theme-plan-starter-bg-color: #e8faf9;
  --theme-plan-starter-border-colors: #93e7e2;

  //pro plan
  --theme-plan-pro-text-color: #327ef0;
  --theme-plan-pro-bg-color: #eef6fe;
  --theme-plan-pro-border-colors: #b2d0fe;

  //premium plan
  --theme-plan-premium-text-color: #7067e1;
  --theme-plan-premium-bg-color: #f3f1ff;
  --theme-plan-premium-border-colors: #bfbafa;

  --theme-pricing-subtext: #555e68;
  --theme-subtext-light: #9fa6ad;

  //professional-api-plan
  --theme-plan-professional-text-color: #c05fc2;
  --theme-plan-professional-bg-color: #fdf3ff;
  --theme-plan-professional-border-colors: #e9b7ea;

  //action-btns
  --action-btn-text: #555e68;
  --action-btn-border: #cdd7e1;
  --action-btn-background: transparent;
  --action-btn-disabled-text: #9fa6ad;
  --action-btn-disabled-border: #cdd7e1;
  --action-btn-disabled-background: #32383e;
  --action-btn-active-text: #ffffff;
  --action-btn-active-border: #2360bc;
  --action-btn-active-background: #2360bc;

  --action-btn2-active-text: #ffffff;
  --action-btn2-active-border: #171a1c;
  --action-btn2-active-background: #171a1c;

  --action-btn-background-color: #171a1c;
  --action-btn-text-color: #fff;

  --action-go-back-btn-background-color: #dde7ee;
  --action-go-back-btn-text-color: #171a1c;
  --action-go-back-btn-border-color: #636b744d;

  --go-back-link: #2463f6;

  --plan-chip-border: #dde7ee;
  --plan-chip-background: transparent;

  //compare plans table
  --pricing-toggle-bg: #f0f4f8;
  --cell-border: #dde7ee;

  //toggle
  --toggle-btn-bg: #dde7ee;
  --toggle-btn-active: #ffffff;

  --action-btn-current-plan-disabled--background: #f0f4f8;
  --action-btn-current-plan-disabled--text-color: #cdd7e1;

  .svg-icon-wrapper {
    &.black {
      --icon-color: #333333;
      --icon-hover-color: #333333;
      --icon-active-color: #2168e4;
    }

    &.grey {
      --icon-color: #64717e;
      --icon-hover-color: #2168e4;
      --icon-active-color: #2168e4;
    }

    &.blue {
      --icon-color: #2168e4;
      --icon-hover-color: #2168e4;
      --icon-active-color: #2168e4;
    }

    &.red {
      --icon-color: #ff3535;
    }
  }

  //new dashboard
  //risk colors
  --risk-priority1: #fc4949;
  --risk-priority2: #ff7d75;
  --risk-priority3: #fa9926;
  --risk-priority4: #f9cd30;
  --risk-priority5: #b2d0fe;

  //delta changes - whats gone
  --delta-new-typosquat: #dc93de;
  --delta-a-record-not-found: #aae2a1;
  --delta-mx-not-found: #6acd74;
  --delta-ns-not-found: #27a95b;
  --delta-red-bg: #fff1f1;

  //delta changes - whats new
  --delta-disposition: #ffb45c;
  --delta-new-ns-found: #30a2e2;
  --delta-record-found: #dc93de;
  --delta-mx-found: #968df8;
  --delta-ip-fount: #59baf1;
  --delta-new-record: #57d1c9;

  --delta-phish: #fc4949;
  --delta-clean: #07a8a8;

  --cross-color: #000;
  --card-background-color: #fff;
  --card-text-color: #171a1c;
  --card-description-color: #555e68;

  --theme-switch-thumb-active-color: #278ec8;
  --theme-switch-thumb-inActive-color: #6b7177;
  --theme-switch-track-backgroundColor: #e1e1e1;
  --theme-ag-selected-row-color: #eff6fe;

  --theme-delta-scroll-track-background: #fafafa;
  --theme-delta-scroll-thumb-background: #c1c1c1;
  --theme-light-dark-lock-color: brightness(0) saturate(100%);
  --mui-input-root-color: #000000de;
  --mui-select-box-border-color: #1c1c1c33;
  --mui-select-arrow-color: #0000008a;

  --mui-ul-background-color: #fff;
  --mui-li-item-color: #000;
  --card-wrapper-border: #dde7ee;
  --card-subtext: #87919c;
  --card-subheading: #555e68;
  --delta-green: #e8fcdc;
  --delta-red: #fff1f1;

  --risk-legend-low-text-color: #2360bc;
  --risk-legend-high-text-color: #c83a3a;

  --widget-card-description: #808080;
  --border-trend-down-green: #aae2a1;
  --border-trend-up-red: #ffb3ae;

  --badge-trend-up-red-color: #fc4949;
  --badge-trend-down-green-color: #27a95b;

  --badge-trend-up-red-background-color: #fff1f1;
  --badge-trend-down-green-background-color: #e8fcdc;

  --next-scan-text-color: #555e68;
  --next-scan-date-color: #171a1c;

  --phish-pill-bg-color: #fc4949;
  --clean-pill-bg-color: #07a8a8;
  --scam-pill-bg-color: #e18eaa;

  // --checkphish-integration
  --disabled-card-bg: #e9ecf0;
  --disabled-card-highlight-text: #555e68;
  --theme-ag-table-header-cp: #666666;

  --theme-look-alike-subtext: #929aa2;
  --theme-look-alike-label: #555e68;

  --abuseMailBox-takedownTimeAnalytics-bar: #dde7ee;
  --abuseMailBox-takedownTimeAnalytics-bar-0: #636b744d;
  --abuseMailBox-dashboard-noDataView-background: #e8f4fc;
  --abuseMailBox-dashboard-reported-malicious-threat-header-color: #64717e;
  --abuseMailBox-dashboard-noDataView-border: #dde7ee;
  --abuseMailBox-allEmailSubmissions-noDataView-subText: #00000099;
  --abuseMailBox-allEmailSubmissions-noDataView-icons-color: #327ef0;

  --name-text-color: #333;

  --am-all-email-sub-subheader-text: #7e868e;
  --amb-url-link: #327ef0;
  --amb-bg-color: #fff;
}

body,
.dark {
  --theme-color: #18181a;
  --theme-color2: #18181a;
  --theme-page-background: #26292d;
  --theme-page-text: #ffffff;
  --theme-code-background: #111217;
  --theme-border: 1px solid #2b3239;
  --theme-hover-bg-color: #3177c9;
  --theme-hover-text-color: #fff;
  --theme-hover-button-color: #3d4352;
  --theme-widget-bg-color: #1c1f22;
  --theme-widget-bg-color-2: #22262b;
  --theme-widget-border-color: 1px solid #2b3239;
  --theme-widget-legend-header-color: #bcbcbc;
  --theme-filter-button-bg: #3f3f47;
  --theme-dropdown-box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.24);
  --theme-a-tag-color: #3177c9;
  --theme-links-hover: #3177c9;
  --theme-dropdown-button-color: #1c1f22;
  --theme-active-filter-bg-color: #28486d;
  --theme-button-hover-bg-color: #386193;
  --theme-monitored-header-bg: #3f3f47;
  --theme-priority-1-bg-color: #95357f;
  --theme-priority-2-bg-color: #b85485;
  --theme-priority-3-bg-color: #bd5c6b;
  --theme-priority-4-bg-color: #ef96a4;
  --theme-priority-5-bg-color: #f2a7b3;
  --theme-hollow-button-border: #327ef0;
  --theme-takedown-button-background: #ffffff;
  --theme-takedown-button-color: #000000;
  --theme-modal-overlay-close-btn: #a8b3c1;
  --theme-disposition-clean-color: #22c197;
  --theme-row-select-bg-color: rgba(78, 89, 128, 0.32);
  --theme-new-row-added-bg-color: rgba(77, 92, 96, 0.31);
  --theme-social-card-content-border: 1px solid #4d5563;
  --theme-widget-sub-label-color: #bcbcbc;
  --theme-form-control-color: #383c41;
  --theme-dropdown-hover-bg-color: #4e5980;
  --theme-border-2: 1px solid #4d5563;
  --theme-border-3: 1px solid #ffffff99;
  --theme-secondary-text-color: #a8b3c1;
  --theme-secondary-text-color-light: #a8b3c1;
  --theme-circle-badge-bg-color: #383c41;
  --icon-blue-shade-color: #63abfe;
  --icon-black-white-color: #ffffff;
  --theme-tooltip-bg-color: #3d4352;
  --theme-tooltip-bg-color-seconday: #ffffff;
  --theme-table-bottom-border: 1px solid #393b40;
  --theme-risk-bg-color-critical: #ab2828;
  --theme-risk-bg-color-high: #974619;
  --theme-risk-bg-color-moderate: #946826;
  --theme-risk-bg-color-low: #746051;
  --theme-risk-bg-color-very-low: #545454;
  --theme-table-body-text-color: #dbdae2;
  --theme-user-icon-hover-bg-color: #28486d;
  --theme-dashed-border: 1px dashed #4d5563;
  --theme-slider-color: #3f3f47;
  --theme-slider-total-price-bg-color: #383c41;
  --theme-slider-bg-color: #383c41;
  --theme-slider-text-color: #ffffff;
  --theme-slider-text-disabled-color: #64717e;
  --theme-checkbox-color: #3f3f47;
  --theme-checkbox-disabled-color: #64717e;
  --theme-dropdown-text-disabled-color: #68686a;
  --theme-modal-overlay-bg-color: #10101080;
  --theme-risk-level-high-color: #ff4141;
  --theme-risk-level-medium-color: #ff8c21;
  --theme-risk-level-low-color: #dab042;
  --theme-risk-level-active-color: #f43f3f;
  --theme-risk-level-mitigated-color: #72e3fe;
  --theme-risk-level-ignored-color: #68686a;
  --theme-active-hover-bg-color: #3a3a3a52;
  --theme-em-tag-bg-color: rgba(217, 119, 119, 0.42);
  --theme-b-tag-bg-color: rgba(243, 234, 61, 0.77);
  --theme-add-tag-bg-color: #3d4352;
  --theme-warning-color: #ee4526;
  --theme-help-active-hover: #a8b3c1;
  --theme-grouped-badge: #5051519e;
  --theme-grouped-badge-text-color: #dbdae2;
  --theme-column-title-color: #a8b3c1;
  --theme-group-findings-bottom-border: #4d5563;
  --theme-group-findings-button-background-off: #383c41;
  --theme-group-findings-button-circle-off: #c0c0c0;
  --theme-group-findings-button-background-on: #3177c9;
  --theme-group-findings-button-circle-on: #ffffff;
  --theme-grouped-expanded-bg-color: #4252654d;
  --theme-dashboard-label-monitoring: #3f3f47;
  --theme-source-feed-bar-bg-color: #313741;
  --unregistered-text: #fff;
  --theme-user-session-alert-color: #f33f3f;
  --theme-user-session-warning-color: #317ced;
  --new-label-bg-color: #2e71e5;
  --theme-upgrade-subtext-color: #ffffff;
  --theme-upgrade-subtext-border: #ffffff;
  --theme-starter-contact-border: #317ced;
  --disabled-btn-bg: #4d4d4d;
  --theme-agGridDateRangeFilter-rdrInRange-color: rgb(40, 72, 109);
  --theme-agGridDateRangeFilter-rdrCalendarWrapper-background-color: #1c1f22;
  --theme-subtext-color-typosquat-popup: #bcbcbc;
  --theme-light-bg-color: #4d5563;
  //explore demo popup
  --theme-explore-demo-bg: #2c5eb5;
  --theme-explore-demo-border: #2c5eb5;
  --theme-explore-demo-text-color: #ffffff;
  --theme-explore-demo-btn-bg: #ffffff;
  --theme-explore-subtext-color: #a8b3c1;
  --theme-explore-demo-btn-color: #2c5eb5;
  --theme-contact-us-button: #3177c9;

  // Typosquat
  --theme-disabled-drawer-item: #59545463;
  --theme-welcome: linear-gradient(180deg, #64717e 0%, #555456 33.64%, #161e22 93.23%);
  --theme-welcome-btn: #a8b3c1;
  --theme-welcome-border: 1px solid #4d5563;
  --theme-code-block-color: gray;
  --theme-progress-page-row: #131618;
  --theme-progress-page-block: #1c1c1f;
  --theme-upgrade-button-bg: #e3fcff;
  --theme-upgrade-button-border: #e3fcff;
  --theme-upgrade-button-color: #2c6cf6;
  --theme-upgrade-popup-bg: transparent;
  --typosquat-scan-sub-text: #ffffff;
  --theme-starter-pack-bg: #1a1a1a;
  --theme-contact-us-link: #63abfe;
  --theme-typo-submit-btn: #7b98f0;
  --theme-typo-dashboard-upgrade-button-bg: #2e71e5;

  --typosquat-continue-btn-bg: #197df2;
  --typosquat-continue-btn-hover: #4e95e9;
  --typosquat-export-first-btn-border: #647385;
  --typosquat-export-first-btn-border-hover: #ffffff;

  --theme-disposition-clean: #029781;
  --theme-disposition-suspicious: #a49300;
  --theme-disposition-malicious: #fc5449;
  --theme-disposition-phish: #f95574;
  --theme-disposition-likely-phish: #ff9141;
  --theme-disposition-others: #899aad;
  --helper-text-border-color: transparent;
  --pre-text-background-color: #bebebe80;

  --theme-livescan-bg: #fff5f5;
  --theme-livescan-color: #963838;
  --theme-livescan-highlight: #ff3535;
  --theme-livescan-border: #ffbbbb;

  //darkweb
  --theme-darkweb-subtext: #c3bfbf;
  --theme-darkweb-bg-color2: #27292d;
  --theme-darkweb-border-storke: #4d5563;
  --theme-widget-background: #18181a;
  --theme-widget-donut-box-shadow: #000000;
  --theme-darkweb-dataset-background: #b82e2550;
  --theme-darkweb-dataset-color: #fa4135;
  --theme-darkweb-dataset-border: #fa4135;
  // Darkweb V2
  --theme-darkweb-finding-group-header-bg-color: #333;

  //checkphish-v2
  --theme-cp-slider-bg-color: #444e5d;
  --theme-cp-slider-text-color: #ffffff;
  --theme-cp-slider-active-bg-color: #ffffff;
  --theme-cp-slider-active-text-color: #26292d;

  //nav-item
  --theme-new-label-color: #61ffd9;
  --theme-active-nav-bg: #444e5d;
  --theme-sub-item-text-color: #ebf5ff;
  --theme-nav-item-text-color: #ffffff;
  --theme-active-nav-item-text-color: #ffffff;
  --theme-active-nav-border-color: #444e5d;

  //logo-detection

  --theme-logo-detection-border: #ebf5ff40;
  --theme-logo-link-color: #63abfe;
  --theme-logo-input-bg: #26292d;
  --theme-subtext-color: #ffffff;

  //new-pricing
  //new pricing

  //free place-content: ;
  --theme-plan-free-text-color: #45ff90;
  --theme-plan-free-bg-color: #45ff9033;
  --theme-plan-free-border-colors: #45ff90;

  //starter plan
  --theme-plan-starter-text-color: #00ffe0;
  --theme-plan-starter-bg-color: #00ffe033;
  --theme-plan-starter-border-colors: #00ffe0;

  //pro plan
  --theme-plan-pro-text-color: #09e1ff;
  --theme-plan-pro-bg-color: #09e1ff33;
  --theme-plan-pro-border-colors: #09e1ff33;

  //premium plan
  --theme-plan-premium-text-color: #e392ff;
  --theme-plan-premium-bg-color: #e392ff33;
  --theme-plan-premium-border-colors: #e392ff;

  --theme-plan-professional-text-color: #ff9bfb;
  --theme-plan-professional-bg-color: #ff9bfb33;
  --theme-plan-professional-border-colors: #ff9bfb;

  --theme-pricing-subtext: #dde7ee;

  --theme-subtext-light: #9fa6ad;

  --action-btn-text: #dde7ee;
  --action-btn-border: #555e68;
  --action-btn-background: transparent;
  --action-btn-disabled-text: #c6d3e0;
  --action-btn-disabled-border: #636b74;
  --action-btn-disabled-background: #f0f4f8;
  --action-btn-active-text: #ffffff;
  --action-btn-active-border: #327ef0;
  --action-btn-active-background: #327ef0;
  --action-btn-background-color: #f0f4f8;
  --action-btn-text-color: #171a1c;
  --action-go-back-btn-background-color: #32383e;
  --action-go-back-btn-text-color: #f0f4f8;
  --action-go-back-btn-border-color: #555e68;

  --action-btn2-active-text: #171a1c;
  --action-btn2-active-border: #ffffff;
  --action-btn2-active-background: #ffffff;
  --go-back-link: #ffffff;
  --plan-chip-border: #555e6899;
  --plan-chip-background: transparent;

  --cell-border: #555e68;

  --toggle-btn-bg: #555e68;
  --toggle-btn-active: #ffffff;

  --cross-color: #fff;
  --card-background-color: #171a1c;
  --card-text-color: #f0f4f8;
  --card-description-color: #9fa6ad;
  .svg-icon-wrapper {
    &.black {
      --icon-color: #ffffff;
      --icon-hover-color: #ffffff;
      --icon-active-color: #63abfe;
    }

    &.grey {
      --icon-color: #a8b3c1;
      --icon-hover-color: #63abfe;
      --icon-active-color: #63abfe;
    }

    &.blue {
      --icon-color: #63abfe;
      --icon-hover-color: #63abfe;
      --icon-active-color: #63abfe;
    }

    &.red {
      --icon-color: #e34444;
    }

    &.white {
      --icon-color: white;
      --icon-hover-color: white;
      --icon-active-color: #63abfe;
    }
  }

  --action-btn-current-plan-disabled--background: #555e68;
  --action-btn-current-plan-disabled--text-color: #c6d3e0;

  --theme-switch-thumb-active-color: #278ec8;
  --theme-switch-thumb-inActive-color: #6b7177;
  --theme-switch-track-backgroundColor: #43464b;
  --theme-ag-selected-row-color: #1d3a53;

  --theme-delta-scroll-track-background: #2c2c2c;
  --theme-delta-scroll-thumb-background: #6b6b6b;
  --mui-input-root-color: #fff;
  --mui-select-box-border-color: #fff;
  --mui-select-arrow-color: #fff;

  --mui-ul-background-color: #707b86;
  --mui-li-item-color: #fff;

  //new dashboard
  //risk colors
  --risk-priority1: #fc4949;
  --risk-priority2: #ff7d75;
  --risk-priority3: #fa9926;
  --risk-priority4: #f9cd30;
  --risk-priority5: #b2d0fe;

  //delta changes
  --delta-new-typosquat: #fc4949;
  --delta-a-record-not-found: #aae2a1;
  --delta-mx-not-found: #6acd74;
  --delta-ns-not-found: #27a95b;

  //delta changes whats new
  --delta-disposition: #fa9926;
  --delta-ns-found: #30a2e2;
  --delta-record-found: #c05fc2;
  --delta-ip-found: #59baf1;
  --delta-mx-found: #7067e1;

  --delta-phish: #fc4949;
  --delta-clean: #07a8a8;
  --card-wrapper-border: #ebf5ff26;
  --card-subtext: #87919c;
  --card-subheading: #dde7ee;
  --delta-green: #0a470a99;
  --delta-red: #7d121299;
  --delta-red-bg: var(--delta-new-typosquat);

  --risk-legend-high-text-color: #fc4949;
  --risk-legend-low-text-color: #599bff;

  --widget-card-description: #c6d3e0;
  --border-trend-down-green: #6acd74;
  --border-trend-up-red: #ff7d75;

  --badge-trend-up-red-color: #ff7d75;
  --badge-trend-down-green-color: #27a95b;

  --badge-trend-up-red-background-color: #7d121299;
  --badge-trend-down-green-background-color: #0a470a99;

  --next-scan-text-color: #c6d3e0;
  --next-scan-date-color: #f0f4f8;

  --phish-pill-bg-color: #c83a3a;
  --clean-pill-bg-color: #068181;
  --scam-pill-bg-color: #068181;

  --disabled-card-bg: #333333;
  --disabled-card-highlight-text: #ffffff;
  --theme-ag-table-header-cp: #dde7ee;

  --theme-look-alike-subtext: #c6d3e0;
  --theme-look-alike-label: #c6d3e0;

  --abuseMailBox-takedownTimeAnalytics-bar: #636b7429;
  --abuseMailBox-takedownTimeAnalytics-bar-0: #555e68;
  --abuseMailBox-dashboard-noDataView-background: #12467b99;
  --abuseMailBox-dashboard-noDataView-border: #555e68;
  --abuseMailBox-allEmailSubmissions-noDataView-subText: #ffffffb2;
  --abuseMailBox-dashboard-reported-malicious-threat-header-color: #64717e;
  --name-text-color: #fff;
  --abuseMailBox-allEmailSubmissions-noDataView-icons-color: #327ef0;

  --am-all-email-sub-subheader-text: #c6d3e0;
  --amb-url-link: #599bff;
  --amb-bg-color: #1c1f22;
}
