@import 'src/index';

.dashboard-summary-widget-container {
  border: $theme-widget-border-color;
  margin: $padding-medium;
  padding: 0 $padding-medium;
  background-color: $theme-widget-bg-color;
  color: $theme-text-color;
}

.dashboard-summary-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 108px;

  .summary-widget-item:last-child {
    .summary-widget-item-inner {
      border-right: 0px;
    }
  }

  .summary-widget-item {
    padding: 0;
    flex: 1 1 0;
    color: inherit;

    .summary-widget-item-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      border-right: $theme-border;
      height: 75%;
      min-height: 90px;
      margin: $padding-medium 0;
      padding: 0 $padding-normal;
      width: 100%;
      color: inherit;

      .summary-widget-upper-wrapper {
        width: 100%;
        display: flex;
        height: inherit;
        flex-direction: row;
        justify-content: space-between;

        .summary-detail-top-button {
          font-weight: 600;
          font-size: $font-size-medium;
          line-height: 21px;
          color: $theme-hollow-btn-border;
        }
        .summary-detail-top-button:hover {
          cursor: pointer;
        }
      }
      .summary-widget-lower-wrapper {
        width: 100%;
        display: flex;
        height: inherit;
        flex-direction: row;
        justify-content: space-between;
      }
      .summary-widget-bottom-text {
        display: flex;
        font-size: $font-size-normal;
        line-height: 130%;
        color: $dark-gray-1;
        justify-content: end;
        align-items: end;
        text-align: end;
        min-width: 50%;
      }
      .summary-widget-item-label {
        color: $theme-widget-legend-header-color;
        font-weight: 500;
        font-size: $font-size-normal;
        line-height: $font-size-large;
      }

      .summary-widget-item-value {
        font-weight: 600;
        line-height: $font-size-xxxlarge;
        font-size: $font-size-xxxlarge;

        svg {
          margin-left: $padding-small;
        }
      }
    }
  }

  .typosquatting-score-learn-more {
    width: 128px;
    padding: $padding-xxsmall $padding-small;
    font-weight: 400;
    font-size: 10px;
    cursor: pointer;
    background-color: var(--theme-slider-bg-color);
  }
}
