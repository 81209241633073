@import 'src/index';

.account-container {
  position: absolute;
  width: 230px;
  height: auto;
  max-width: 230px;
  margin: 0 5px 0px 0px;
  background-color: $theme-background-color;
  overflow: hidden;
  border: $theme-border;
  top: 55px;
  right: 15px;
  float: right;
  .account-drawer {
    .account-sub-item-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      color: $theme-text-color;
      line-height: 21px;
      border-bottom: $theme-border;
      .account-header {
        font-weight: 600;
        font-size: $font-size-large;
        padding: $padding-small $padding-normal;
        cursor: default;
      }
      .account-sub-item {
        font-size: 13px;
        padding: $padding-small $padding-normal;
      }
      .account-sub-item:hover {
        background-color: $theme-hover-bg-color;
        color: $theme-hover-text-color;
        cursor: pointer;
      }
    }
    .account-sub-item-wrapper:last-child {
      border-bottom: none;
    }
  }
}
