@import 'src/index';
@import 'src/Theme';

* {
  box-sizing: border-box;
}

//import local font family
@font-face {
  font-family: 'Fakt';
  font-style: normal;
  font-weight: 400;
  src: local('Fakt'), url(../assets/font/DESKTOP/Fakt/Fakt-Normal.otf) format('otf'),
    url(../assets/font/DESKTOP/Fakt/Fakt-Normal.ttf) format('truetype'),
    url(../assets/font/WEB/Fakt/Fakt-Normal.woff) format('woff'),
    url(../assets/font/WEB/Fakt/Fakt-Normal.woff2) format('woff2');
}

@font-face {
  font-family: 'Fakt';
  font-style: normal;
  font-weight: 500;
  src: local('Fakt'), url(../assets/font/DESKTOP/Fakt/Fakt-Medium.otf) format('otf'),
    url(../assets/font/DESKTOP/Fakt/Fakt-Medium.ttf) format('truetype'),
    url(../assets/font/WEB/Fakt/Fakt-Medium.woff) format('woff'),
    url(../assets/font/WEB/Fakt/Fakt-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Fakt';
  font-style: normal;
  font-weight: 600;
  src: local('Fakt'), url(../assets/font/DESKTOP/Fakt/Fakt-SemiBold.otf) format('otf'),
    url(../assets/font/DESKTOP/Fakt/Fakt-SemiBold.ttf) format('truetype'),
    url(../assets/font/WEB/Fakt/Fakt-SemiBold.woff) format('woff'),
    url(../assets/font/WEB/Fakt/Fakt-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Fakt';
  font-style: italic;
  font-weight: 400;
  src: local('Fakt'), url(../assets/font/DESKTOP/Fakt/Fakt-NormalItalic.otf) format('otf'),
    url(../assets/font/DESKTOP/Fakt/Fakt-NormalItalic.ttf) format('truetype'),
    url(../assets/font/WEB/Fakt/Fakt-NormalItalic.woff) format('woff'),
    url(../assets/font/WEB/Fakt/Fakt-NormalItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Fakt';
  font-style: italic;
  font-weight: 500;
  src: local('Fakt'), url(../assets/font/DESKTOP/Fakt/Fakt-MediumItalic.otf) format('otf'),
    url(../assets/font/DESKTOP/Fakt/Fakt-MediumItalic.ttf) format('truetype'),
    url(../assets/font/WEB/Fakt/Fakt-MediumItalic.woff) format('woff'),
    url(../assets/font/WEB/Fakt/Fakt-MediumItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Fakt';
  font-style: italic;
  font-weight: 600;
  src: local('Fakt'), url(../assets/font/DESKTOP/Fakt/Fakt-SemiBoldItalic.otf) format('otf'),
    url(../assets/font/DESKTOP/Fakt/Fakt-SemiBoldItalic.ttf) format('truetype'),
    url(../assets/font/WEB/Fakt/Fakt-SemiBoldItalic.woff) format('woff'),
    url(../assets/font/WEB/Fakt/Fakt-SemiBoldItalic.woff2) format('woff2');
}

body {
  background: #ffffff !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fakt';
  color: $dark-gray-5;
}

em {
  background: var(--theme-em-tag-bg-color);
}

b em {
  background: var(--theme-b-tag-bg-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
}

body,
html,
.App,
#root {
  width: 100%;
  height: 100%;
  font-size: $font-size-large;
  background-color: $theme-background-color;

  .card {
    border: $theme-border;
    width: 100%;
    height: auto;
    background-color: $theme-widget-bg-color;
    color: $theme-text-color;

    .card-header {
      border-bottom: none;
      background-color: $theme-widget-bg-color;
      padding: $padding-medium;
      font-weight: 500;
      font-size: $font-size-large;
      line-height: $font-size-large;
      border-radius: unset;
    }

    .card-body,
    .card-footer {
      background-color: $theme-widget-bg-color;
      padding: 0 $padding-medium $padding-medium $padding-medium;
    }

    > .row {
      background-color: $white;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.App {
  background-color: $theme-color;
}

input {
  color: $dark-gray-5;
}

a {
  color: $theme-hollow-btn-border;
}

span {
  font-weight: normal;
}

.icon-disabled {
  cursor: not-allowed !important;
  .svg-icon-wrapper {
    cursor: not-allowed !important;
  }
  .svg-icon-wrapper.fill {
    path {
      fill: var(--icon-color);
    }
    rect {
      fill: var(--icon-color);
    }
    circle {
      fill: var(--icon-color);
    }
    &:hover {
      path {
        fill: var(--icon-color);
      }
      rect {
        fill: var(--icon-color);
      }
      circle {
        fill: var(--icon-color);
      }
    }
    &:active {
      path {
        fill: var(--icon-color);
      }
      rect {
        fill: var(--icon-color);
      }
      circle {
        fill: var(--icon-color);
      }
    }
  }

  .svg-icon-wrapper.stroke {
    path {
      stroke: var(--icon-color);
    }
    rect {
      stroke: var(--icon-color);
    }
    circle {
      stroke: var(--icon-color);
    }
    &:hover {
      path {
        stroke: var(--icon-color);
      }
      rect {
        stroke: var(--icon-color);
      }
      circle {
        stroke: var(--icon-color);
      }
    }
    &:active {
      path {
        stroke: var(--icon-color);
      }
      rect {
        stroke: var(--icon-color);
      }
      circle {
        stroke: var(--icon-color);
      }
    }
  }
}

.App .auth-wrapper main.makeStyles-content-10 {
  margin-top: 3rem;
}

.display-none {
  display: none !important;
}

.box-shadow {
  box-shadow: $box-shadow;
  border-radius: 3px;
}

@media only screen and (max-width: $screen-size-xlg) {
  .hide-below-xlg {
    display: none !important;
  }
}

@media only screen and (max-width: $screen-size-lg) {
  .hide-below-lg,
  .web-only {
    display: none !important;
  }
}

@media only screen and (max-width: $screen-size-md) {
  .hide-below-md {
    display: none !important;
  }
}

@media only screen and (max-width: $screen-size-sm) {
  .hide-below-sm {
    display: none !important;
  }
}

@media only screen and (min-width: $screen-size-lg) {
  .mobile-only {
    display: none !important;
  }
}

@mixin long-text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
}

.long-text-ellipsis-1 {
  @include long-text-ellipsis;
  -webkit-line-clamp: 1;
}

.long-text-ellipsis-2 {
  @include long-text-ellipsis;
  -webkit-line-clamp: 2;
}

.long-text-ellipsis-3 {
  @include long-text-ellipsis;
  -webkit-line-clamp: 3;
}
.custom-text-dark {
  color: $dark-gray-7 !important;
}

.custom-text-light {
  color: $light-gray-3 !important;
}

.active-link {
  color: #007bff;
}

//override bootstrap modal css
.modal-dialog {
  margin-top: $padding-xxxxlarge;
  .modal-content {
    border: none;
    border-radius: 0;
    background-color: $theme-background-color;
    color: $theme-text-color;
    .modal-header {
      border-bottom: none;
      padding: $padding-medium;

      .modal-title {
        font-weight: 500;
        font-size: $font-size-large;
      }
      button.close {
        color: $theme-modal-overlay-close-btn;
      }
    }

    .modal-body {
      font-size: $font-size-normal;
      font-weight: normal;
      padding: $padding-medium;
      padding-top: 0;

      label {
        font-weight: 600;
      }
    }

    .modal-footer {
      border-top: none;
      padding: 0 $padding-medium $padding-normal $padding-medium;

      * {
        margin: 0;
      }

      button + button {
        margin-left: $padding-normal;
      }
    }
  }
}

img.image-icon.disabled {
  cursor: default !important;
  opacity: 0.5;
}

input.form-control {
  font-size: $font-size-medium;
}

//override bootstrap form-control css
.form-control {
  font-size: $font-size-medium;
  color: $theme-text-color;
  border-radius: 0;
  border: $theme-border;
  background-color: $theme-widget-bg-color;
}

.form-control:focus {
  color: $theme-text-color;
  background-color: $theme-widget-bg-color;
  border: 1px solid $theme-hollow-btn-border;
  box-shadow: none;
}

//override bootstrap table css
.table {
  margin-bottom: 0;

  td,
  th {
    border-top: none;
  }
  tbody + tbody {
    border-top: none;
  }
}

//override bootstrap dropdown-menu css
.dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: $theme-dropdown-box-shadow;
}

//override bootstrap button css

.btn-primary,
.btn-outline-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:focus {
  color: $white;
  background-color: $theme-a-tag-color;
  border-color: $theme-a-tag-color;
  font-weight: 600;
  font-size: $font-size-medium;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: $theme-text-color;
  background-color: $theme-form-control-color;
  border-color: $theme-dropdown-button-color;
}
.btn-primary:hover:not(:disabled) {
  color: $theme-text-color !important;
  background-color: $theme-button-hover-bg-color;
  border-color: $theme-a-tag-color;
}

.btn.disabled,
.btn:disabled {
  cursor: default;
}

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: $secondary;
  color: $white;
  font-weight: 600;
  font-size: $font-size-medium;
}

.btn-outline-secondary,
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  border-color: $theme-modal-overlay-close-btn;
  color: $theme-modal-overlay-close-btn;
  font-weight: 600;
  font-size: $font-size-medium;
}

//override bootstrap card css
.card {
  border: none;
  border-radius: 0;
}

.card-between {
  margin-bottom: $padding-medium;
}

.card-next-to {
  margin-right: $padding-medium;
}

.card-img {
  border-radius: 0;
}

//override bootstrap tooltip css
.tooltip.show {
  opacity: 1;
}

.tooltip {
  background-color: $theme-tooltip-bg-color;
  box-shadow: $box-shadow;

  .arrow {
    opacity: 0;
  }

  div {
    opacity: 1;
    background-color: $theme-tooltip-bg-color;
    color: $theme-text-color;
    font-size: $font-size-normal;
    font-weight: normal;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator {
  height: 1px;
  border-bottom: $theme-border-2;
}

.cursor-pointer {
  cursor: pointer;
}

.spinner.text-primary {
  color: $primary !important;
}

.text-dark-grey-2 {
  color: $dark-gray-2 !important;
}

.text-medium {
  font-size: $font-size-medium;
}

.text-link {
  color: $theme-hollow-btn-border !important;
  cursor: pointer;
}

.theme-text-color {
  color: $theme-text-color;
}

.theme-text-secondary-color {
  color: $theme-secondary-text-color;
}

//override bootstrap col css
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: $padding-medium / 2;
  padding-right: $padding-medium / 2;
}

.opacity-0 {
  opacity: 0;
}

.text-button {
  font-size: $font-size-medium;
  font-weight: 400;
  color: $dark-ocean;
  cursor: pointer;
  margin-inline-end: 10px;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.svg-icon-wrapper {
  cursor: pointer;
}

.svg-icon-wrapper.fill {
  path {
    fill: var(--icon-color);
  }
  rect {
    fill: var(--icon-color);
  }
  circle {
    fill: var(--icon-color);
  }
  &:hover {
    path {
      fill: var(--icon-hover-color);
    }
    rect {
      fill: var(--icon-hover-color);
    }
    circle {
      fill: var(--icon-hover-color);
    }
  }
  &:active {
    path {
      fill: var(--icon-active-color);
    }
    rect {
      fill: var(--icon-active-color);
    }
    circle {
      fill: var(--icon-active-color);
    }
  }
}

.svg-icon-wrapper.stroke {
  path {
    stroke: var(--icon-color);
  }
  rect {
    stroke: var(--icon-color);
  }
  circle {
    stroke: var(--icon-color);
  }
  &:hover {
    path {
      stroke: var(--icon-hover-color);
    }
    rect {
      stroke: var(--icon-hover-color);
    }
    circle {
      stroke: var(--icon-hover-color);
    }
  }
  &:active {
    path {
      stroke: var(--icon-active-color);
    }
    rect {
      stroke: var(--icon-active-color);
    }
    circle {
      stroke: var(--icon-active-color);
    }
  }
}

.nav-tabs {
  .nav-link {
    color: $theme-secondary-text-color-light;
    &:hover {
      border: $theme-border-2;
      border-bottom-color: transparent;
    }
  }
  border-bottom: $theme-border-2;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $theme-text-color;
  background-color: $theme-background-color;
  border: $theme-border-2;
  border-bottom-color: transparent;
}

hr {
  border: $theme-border;
}

.max-width-content {
  .modal-content {
    width: fit-content;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.h-screen {
  height: 100vh;
}

.my-end--8 {
  margin-block: 8px;
}
