@import 'src/index';

.pricing-info-wrap {
  display: flex;

  color: $theme-text-color;
  height: 60px;
  margin-block: auto;
  align-items: center;
  .align-content {
    display: flex;
    align-items: baseline;
  }
  .amount-wrapper {
    display: flex;
    align-items: center;
    .amount-wrap {
      .price-wrap {
        display: flex;
        align-items: baseline;
        padding: 0 !important;
        height: 35px;
        h3 {
          margin-right: 3px;
          color: $theme-text-color;
        }
        span {
          margin-left: 3px;
          font-size: 14px;
        }
      }
      .duration {
        color: $theme-pricing-subtext-light;
        margin-right: 3px;
        font-size: 12px;
        text-transform: capitalize;
      }
      .border-left {
        border-left: 1px solid gray;
      }
    }
  }
}

.amount-padding {
  padding: 0 10px;
  margin-left: 10px;
}
