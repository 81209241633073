@import 'src/index';

.takedown-modal {
  &-desc {
    margin-bottom: $padding-medium;
  }

  &-text {
    font-size: $font-size-medium;
    line-height: 21px;
    margin-bottom: $padding-small;
  }
  &-desc-wrapper {
    font-size: $font-size-medium;
    font-weight: 500;
    height: auto;
    max-height: 200px;
    overflow: scroll;
  }

  &-contact {
    font-size: $font-size-medium;
    &-label {
      font-weight: 500;
      line-height: 16px;
      margin-bottom: $padding-small;
      color: $theme-modal-overlay-close-btn;
    }

    &-email {
      font-weight: 400;
      line-height: 21px;
    }
  }

  &-form {
    margin-top: $padding-medium;
    &-label {
      font-size: $font-size-normal;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: $padding-small;
      color: $theme-modal-overlay-close-btn;
      padding-right: $padding-small;
    }
  }
  &-form:focus {
    border: $theme-border;
  }
  &-error-desc {
    font-weight: 400;
    font-size: $font-size-medium;
    line-height: 21px;
    color: $theme-warning-color;
  }
  .takedown-exclude-btn {
    font-weight: 600;
    font-size: $font-size-medium;
    line-height: 21px;
    color: $theme-hollow-btn-border;
  }
  .takedown-exclude-btn:hover {
    cursor: pointer;
    color: $theme-hollow-btn-border;
  }
}
