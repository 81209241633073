$screen-size-sm: 576px;
$screen-size-md: 768px;
$screen-size-lg: 992px;
$screen-size-xlg: 1200px;

$dropdown-width: 300px;
$screen-threshold: $screen-size-xlg;

$theme-color: black;
$dark-gray-7: #111111;
$dark-gray-6: #222222;
$dark-gray-5: #333333;
$dark-gray-4: #4f4e56;
$dark-gray-3: #64717e;
$dark-gray-2: #9d9da0;
$dark-gray-1: #949494;
$light-gray-4: #d5d9dd;
$light-gray-3: #e9ecf0;
$light-gray-2: #f3f5f7;
$light-gray-1: #fafafb;
$red: #cf1d05;
$coral: #f95574;
$yellow: yellow;
$white: white;
$ocean: #4479f3;
$dark-ocean: #2168e4;
$ocean-medium: #8daff4;
$ocean-light: #d3e1f9;
$blue: #0074b9;
$blue2: #0176d3;
$light-blue-5: #007ea7;
$light-blue-4: #48accd;
$light-blue-3: #74c5f4;
$light-blue-2: #a7dfff;
$light-blue-1: #d5eefc;
$light-blue-0: #e8f2f6;

$green: green;
$green-success: #00775a;
$platinum: #899aad;
$orange: #ffa25f;
$light-orange: #ffb885;
$sun: #f2ab2c;
$sun-light: #ffe8ac;
$blue_green: #3cb2bc;
$blue_green2: #337f86;
$seaweed: #05c1af;
$seaweed-light: #aef7ec;
$coral: #f95574;
$coral-light: #ffcac0;

$status-live: #ee4526;
$status-paused: $orange;
$status-in-progress: #01dffe;
$status-down: #72e3fe;
$status-ignored: #70be62;

$primary: $light-blue-5;
$secondary: $dark-gray-3;

$font-size-small: 10px;
$font-size-normal: 12px;
$font-size-medium: 14px;
$font-size-large: 16px;
$font-size-xlarge: 20px;
$font-size-xxlarge: 24px;
$font-size-xxxlarge: 32px;

$padding-xxxsmall: 2px;
$padding-xxsmall: 4px;
$padding-xsmall: 6px;
$padding-small: 8px;
$padding-normal: 16px;
$padding-medium-half: 12px;
$padding-medium: 24px;
$padding-large: 36px;
$padding-xlarge: 48px;
$padding-xxlarge: 60px;
$padding-xxxlarge: 72px;
$padding-xxxxlarge: 84px;

$top-nav-bar-height: 56px;

$box-shadow: 0px 0px 4px rgba(41, 42, 51, 0.32);
$box-shadow2: 0px 4px 8px rgba(41, 42, 51, 0.24);
$border: 1px solid $light-gray-3;
$border2: 1px solid $light-gray-4;
$border5: 1px solid $dark-gray-5;
$border-dashed: 1px dashed $light-gray-4;
$border-blue: 1px solid $light-blue-3;
$border-blue2: 1px solid $light-blue-5;
$button: rgba(213, 217, 221, 0.32);
$button-active: rgba(33, 104, 228, 0.16);
$theme-logo-detection-border: var(--theme-logo-detection-border);
$theme-logo-link-color: var(--theme-logo-link-color);
$theme-logo-input-bg: var(--theme-logo-input-bg);
$theme-subtext-color: var(--theme-subtext-color);

// define padding for web only
// ------------------------------------------------------
:root {
  --padding-xxxsmall: 2px;
  --padding-xxsmall: 4px;
  --padding-xsmall: 6px;
  --padding-small: 8px;
  --padding-normal: 16px;
  --padding-medium: 24px;
  --padding-medium2: 0px;
  --padding-large: 36px;
  --padding-xlarge: 48px;
  --padding-xxlarge: 60px;
}

// define the padding size for small device
@media screen and (max-width: $screen-size-sm) {
  :root {
    --padding-xxxsmall: 0px;
    --padding-xxsmall: 0px;
    --padding-xsmall: 0px;
    --padding-small: 0px;
    --padding-normal: 0px;
    --padding-medium: 0px;
    --padding-medium2: 24px;
    --padding-large: 0px;
    --padding-xlarge: 0px;
    --padding-xxlarge: 0px;
  }
}

$padding-xxxsmall-web: var(--padding-xxxsmall);
$padding-xxsmall-web: var(--padding-xxsmall);
$padding-xsmall-web: var(--padding-xsmall);
$padding-small-web: var(--padding-small);
$padding-normal-web: var(--padding-normal);
$padding-medium-web: var(--padding-medium);
$padding-medium-mobile: var(--padding-medium2);
$padding-large-web: var(--padding-large);
$padding-xlarge-web: var(--padding-xlarge);
$padding-xxlarge-web: var(--padding-xxlarge);
// ------------------------------------------------------

$theme-background-color: var(--theme-page-background);
$theme-text-color: var(--theme-page-text);
$theme-color: var(--theme-color);
$theme-color-2: var(--theme-color-2);
$theme-border: var(--theme-border);
$theme-hover-bg-color: var(--theme-hover-bg-color);
$theme-hover-text-color: var(--theme-hover-text-color);
$theme-hover-button-color: var(--theme-hover-button-color);
$theme-widget-bg-color: var(--theme-widget-bg-color);
$theme-widget-bg-color-2: var(--theme-widget-bg-color-2);
$theme-widget-border-color: var(--theme-widget-border-color);
$theme-widget-legend-header-color: var(--theme-widget-legend-header-color);
$theme-filter-button-bg: var(--theme-filter-button-bg);
$theme-dropdown-box-shadow: var(--theme-dropdown-box-shadow);
$theme-a-tag-color: var(--theme-a-tag-color);
$theme-links-hover: var(--theme-links-hover);
$theme-dropdown-button-color: var(--theme-dropdown-button-color);
$theme-active-filter-bg-color: var(--theme-active-filter-bg-color);
$theme-button-hover-bg-color: var(--theme-button-hover-bg-color);
$theme-monitored-header-bg: var(--theme-monitored-header-bg);
$theme-hollow-btn-border: var(--theme-hollow-button-border);
$theme-modal-overlay-close-btn: var(--theme-modal-overlay-close-btn);
$theme-disposition-clean-color: var(--theme-disposition-clean-color);
$theme-row-select-bg-color: var(--theme-row-select-bg-color);
$theme-new-row-added-bg-color: var(--theme-new-row-added-bg-color);
$theme-social-card-content-border: var(--theme-social-card-content-border);
$theme-widget-sub-label-color: var(--theme-widget-sub-label-color);
$theme-form-control-color: var(--theme-form-control-color);
$theme-dropdown-hover-bg-color: var(--theme-dropdown-hover-bg-color);
$theme-border-2: var(--theme-border-2);
$theme-border-3: var(--theme-border-3);
$theme-secondary-text-color: var(--theme-secondary-text-color);
$theme-secondary-text-color-light: var(--theme-secondary-text-color-light);
$theme-circle-badge-bg-color: var(--theme-circle-badge-bg-color);
$theme-tooltip-bg-color: var(--theme-tooltip-bg-color);
$theme-table-bottom-border: var(--theme-table-bottom-border);
$theme-risk-bg-color-critical: var(--theme-risk-bg-color-critical);
$theme-risk-bg-color-high: var(--theme-risk-bg-color-high);
$theme-risk-bg-color-moderate: var(--theme-risk-bg-color-moderate);
$theme-risk-bg-color-low: var(--theme-risk-bg-color-low);
$theme-risk-bg-color-very-low: var(--theme-risk-bg-color-very-low);
$theme-table-body-text-color: var(--theme-table-body-text-color);
$theme-user-icon-hover-bg-color: var(--theme-user-icon-hover-bg-color);
$theme-dashed-border: var(--theme-dashed-border);
$theme-modal-overlay-bg-color: var(--theme-modal-overlay-bg-color);
$theme-active-hover-bg-color: var(--theme-active-hover-bg-color);
$theme-add-tag-bg-color: var(--theme-add-tag-bg-color);
$theme-warning-color: var(--theme-warning-color);
$theme-active-hover-button-color: var(--theme-help-active-hover);
$theme-grouped-badge: var(--theme-grouped-badge);
$theme-grouped-badge-text-color: var(--theme-grouped-badge-text-color);
$theme-column-title-color: var(--theme-column-title-color);
$theme-column-border-bottom-color: var(--theme-group-findings-bottom-border);
$theme-group-findings-button-background-off: var(--theme-group-findings-button-background-off);
$theme-group-findings-button-circle-off: var(--theme-group-findings-button-circle-off);
$theme-group-findings-button-background-on: var(--theme-group-findings-button-background-on);
$theme-group-findings-button-circle-on: var(--theme-group-findings-button-circle-on);
$theme-takedown-button-background: var(--theme-takedown-button-background);
$theme-takedown-button-color: var(--theme-takedown-button-color);
$theme-grouped-expanded-bg-color: var(--theme-grouped-expanded-bg-color);
$theme-dashboard-label-monitoring: var(--theme-dashboard-label-monitoring);
$theme-upgrade-subtext-color: var(--theme-upgrade-subtext-color);
$theme-upgrade-subtext-border: var(--theme-upgrade-subtext-border);
$theme-starter-contact-border: var(--theme-starter-contact-border);

$theme-source-feed-bar-bg-color: var(--theme-source-feed-bar-bg-color);
$unregistered-text: var(--unregistered-text);

$theme-user-session-alert-color: var(--theme-user-session-alert-color);
$theme-user-session-warning-color: var(--theme-user-session-warning-color);
$new-label-bg-color: var(--new-label-bg-color);
$disabled-btn-bg: var(--disabled-btn-bg);

$theme-switch-thumb-active-color: var(--theme-switch-thumb-active-color);
$theme-switch-thumb-inActive-color: var(--theme-switch-thumb-inActive-color);
$theme-switch-track-backgroundColor: var(--theme-switch-track-backgroundColor);

$theme-disposition-clean: var(--theme-disposition-clean);
$theme-disposition-suspicious: var(--theme-disposition-suspicious);
$theme-disposition-phish: var(--theme-disposition-phish);
$theme-disposition-likely-phish: var(--theme-disposition-likely-phish);
$theme-disposition-others: var(--theme-disposition-others);
$theme-tooltip-bg-color-seconday: var(--theme-tooltip-bg-color-seconday);
$theme-agGridDateRangeFilter-rdrInRange-color: var(--theme-agGridDateRangeFilter-rdrInRange-color);
$theme-agGridDateRangeFilter-rdrCalendarWrapper-background-color: var(
  --theme-agGridDateRangeFilter-rdrCalendarWrapper-background-color
);

$theme-agGridDateRangeFilter-rdrMonthAndYearWrapper-select-color: #849095;

// Typosquat
$theme-disabled-drawer-item: var(--theme-disabled-drawer-item);
$theme-welcome-background: var(--theme-welcome);
$theme-welcome-btn: var(--theme-welcome-btn);
$theme-code-block-color: var(--theme-code-block-color);
$theme-progress-page-row: var(--theme-progress-page-row);
$theme-progress-page-block: var(--theme-progress-page-block);
$theme-upgrade-button-bg: var(--theme-upgrade-button-bg);
$theme-upgrade-button-border: var(--theme-upgrade-button-border);
$theme-upgrade-popup-bg: var(--theme-upgrade-popup-bg);
$theme-upgrade-button-color: var(--theme-upgrade-button-color);
$theme-starter-pack-bg: var(--theme-starter-pack-bg);
$typosquat-scan-sub-text: var(--typosquat-scan-sub-text);
$helper-text-border-color: var(--helper-text-border-color);
$theme-livescan-bg: var(--theme-livescan-bg);
$theme-typo-submit-btn: var(--theme-typo-submit-btn);
$theme-contact-us-link: var(--theme-contact-us-link);
$theme-livescan-color: var(--theme-livescan-color);
$theme-livescan-highlight: var(--theme-livescan-highlight);
$theme-livescan-border: var(--theme-livescan-border);
$typosquat-continue-btn-bg: var(--typosquat-continue-btn-bg);
$typosquat-continue-btn-hover: var(--typosquat-continue-btn-hover);
$typosquat-export-first-btn-border: var(--typosquat-export-first-btn-border);
$typosquat-export-first-btn-border-hover: var(--typosquat-export-first-btn-border-hover);
$pre-text-background-color: var(--pre-text-background-color);
$theme-typo-dashboard-upgrade-button-bg: var(--theme-typo-dashboard-upgrade-button-bg);

//darkweb
$theme-darkweb-subtext: var(--theme-darkweb-subtext);
$theme-darkweb-gb-color2: var(--theme-darkweb-bg-color2);
$theme-darkweb-border-storke: var(--theme-darkweb-border-storke);
$theme-widget-background: var(--theme-widget-background);
$theme-widget-donut-box-shadow: var(--theme-widget-donut-box-shadow);
$theme-darkweb-dataset-background: var(--theme-darkweb-dataset-background);
$theme-darkweb-dataset-color: var(--theme-darkweb-dataset-color);
$theme-darkweb-dataset-border: var(--theme-darkweb-dataset-border);
$theme-darkweb-finding-group-header-bg-color: var(--theme-darkweb-finding-group-header-bg-color);
$theme-subtext-color-typosquat-popup: var(--theme-subtext-color-typosquat-popup);

//explore-demo
$theme-explore-demo-bg: var(--theme-explore-demo-bg);
$theme-explore-demo-border: var(--theme-explore-demo-border);
$theme-explore-demo-text-color: var(--theme-explore-demo-text-color);
$theme-explore-demo-btn-bg: var(--theme-explore-demo-btn-bg);
$theme-explore-demo-btn-color: var(--theme-explore-demo-btn-color);
$theme-contact-us-button-bg: var(--theme-contact-us-button);
$theme-explore-subtext-color: var(--theme-explore-subtext-color);

//checkphish-v2
$theme-cp-slider-bg-color: var(--theme-cp-slider-bg-color);
$theme-cp-slider-text-color: var(--theme-cp-slider-text-color);
$theme-cp-slider-active-gb-color: var(--theme-cp-slider-active-bg-color);
$theme-cp-slider-active-text-color: var(--theme-cp-slider-active-text-color);

$theme-active-nav-bg: var(--theme-active-nav-bg);
$theme-new-label-color: var(--theme-new-label-color);
$theme-active-nav-border-color: var(--theme-active-nav-border-color);

$theme-sub-item-text-color: var(--theme-sub-item-text-color);
$theme-nav-item-text-color: var(--theme-nav-item-text-color);
$theme-active-nav-item-text-color: var(--theme-active-nav-item-text-color);
$theme-light-bg-color: var(--theme-light-bg-color);

//new pricing policy
$theme-plan-free-text-color: var(--theme-plan-free-text-color);
$theme-plan-free-bg-color: var(--theme-plan-free-bg-color);
$theme-plan-free-border-colors: var(--theme-plan-free-border-colors);

$theme-plan-starter-text-color: var(--theme-plan-starter-text-color);
$theme-plan-starter-bg-color: var(--theme-plan-starter-bg-color);
$theme-plan-starter-border-colors: var(--theme-plan-starter-border-colors);

$theme-plan-pro-text-color: var(--theme-plan-pro-text-color);
$theme-plan-pro-bg-color: var(--theme-plan-pro-bg-color);
$theme-plan-pro-border-colors: var(--theme-plan-pro-border-colors);

$theme-plan-premium-text-color: var(--theme-plan-premium-text-color);
$theme-plan-premium-bg-color: var(--theme-plan-premium-bg-color);
$theme-plan-premium-border-colors: var(--theme-plan-premium-border-colors);

$theme-plan-professional-text-color: var(--theme-plan-professional-text-color);
$theme-plan-professional-bg-color: var(--theme-plan-professional-bg-color);
$theme-plan-professional-border-colors: var(--theme-plan-professional-border-colors);

$theme-pricing-subtext: var(--theme-pricing-subtext);
$theme-pricing-subtext-light: var(--theme-subtext-light);

$action-btn-text: var(--action-btn-text);
$action-btn-border: var(--action-btn-border);
$action-btn-background: var(--action-btn-background);
$action-btn-disabled-text: var(--action-btn-disabled-text);
$action-btn-disabled-border: var(--action-btn-disabled-border);
$action-btn-disabled-background: var(--action-btn-disabled-background);
$action-btn-active-text: var(--action-btn-active-text);
$action-btn-active-border: var(--action-btn-active-border);
$action-btn-active-background: var(--action-btn-active-background);
$cell-border: var(--cell-border);

$action-btn-background: var(--action-btn-background);

$plan-chip-border: var(--plan-chip-border);
$plan-chip-background: var(--plan-chip-background);

$toggle-btn-bg: var(--toggle-btn-bg);
$toggle-btn-active: var(--toggle-btn-active);
$go-back-link: var(--go-back-link);

$card-cross-color: var(--cross-color);
$card-background-color: var(--card-background-color);
$card-text-color: var(--card-text-color);
$card-descriptipn-text-color: var(--card-description-color);
$cta-btn-background-color: var(--action-btn-background-color);
$cta-btn-text-color: var(--action-btn-text-color);
$go-back-btn-background-color: var(--action-go-back-btn-background-color);
$go-back-btn-color: var(--action-go-back-btn-text-color);
$go-back-btn-border-color: var(--action-go-back-btn-border-color);

$theme-light-dark-lock-color: var(--theme-light-dark-lock-color);
$mui-input-root-color: var(--mui-input-root-color);
$mui-select-box-border-color: var(--mui-select-box-border-color);
$mui-select-arrow-color: var(--mui-select-arrow-color);
$mui-ul-background-color: var(--mui-ul-background-color);
$mui-li-item-color: var(--mui-li-item-color);

$action-btn-current-plan-disabled--background: var(--action-btn-current-plan-disabled--background);
$action-btn-current-plan-disabled--text-color: var(--action-btn-current-plan-disabled--text-color);

//new dashboard
//risk colors
$risk-priority1: var(--risk-priority1);
$risk-priority2: var(--risk-priority2);
$risk-priority3: var(--risk-priority3);
$risk-priority4: var(--risk-priority4);
$risk-priority5: var(--risk-priority5);

//delta changes
$delta-new-typosquat: var(--delta-new-typosquat);
$delta-a-record-not-found: var(--delta-a-record-not-found);
$delta-mx-not-found: var(--delta-mx-not-found);
$delta-ns-not-found: var(--delta-ns-not-found);

$delta-disposition: var(--delta-disposition);
$delta-mx-found: var(--delta-mx-found);
$delta-record-found: var(--delta-record-found);
$delta-ip-found: var(--delta-ip-found);
$delta-ns-found: var(--delta-ns-found);

$delta-phish: var(--delta-phish);
$delta-clean: var(--delta-clean);
$card-wrapper-border: var(--card-wrapper-border);
$card-subtext: var(--card-subtext);
$card-subheading: var(--card-subheading);
$delta-green: var(--delta-green);
$delta-red: var(--delta-red);
$delta-red-bg: var(--delta-red-bg);

$risk-legend-high-text-color: var(--risk-legend-high-text-color);
$risk-legend-low-text-color: var(--risk-legend-low-text-color);

$widget-card-description: var(--widget-card-description);

$trend-green: var(--border-trend-down-green);
$trend-red: var(--border-trend-up-red);

$trend-red-color: var(--badge-trend-up-red-color);
$trend-green-color: var(--badge-trend-down-green-color);

$trend-red-bg-color: var(--badge-trend-up-red-background-color);
$trend-green-bg-color: var(--badge-trend-down-green-background-color);

$next-scan-text-color: var(--next-scan-text-color);
$next-scan-date-color: var(--next-scan-date-color);

$disposition-clean-pill-bg-color: var(--clean-pill-bg-color);
$disposition-phish-pill-bg-color: var(--phish-pill-bg-color);
$disposition-scam-pill-bg-color: var(--scam-pill-bg-color);
$disabled-card-bg: var(--disabled-card-bg);
$disabled-card-highlight-text: var(--disabled-card-highlight-text);
$theme-ag-table-header-cp: var(--theme-ag-table-header-cp);

$theme-look-alike-subtext: var(--theme-look-alike-subtext);
$theme-look-alike-label: var(--theme-look-alike-label);

$name-text-color: var(--name-text-color);

$abuseMailBox-dashboard-reported-malicious-threat-header-color: var(
  --abuseMailBox-dashboard-reported-malicious-threat-header-color
);
$abuseMailBox-allEmailSubmissions-noDataView-icons-color: var(
  --abuseMailBox-allEmailSubmissions-noDataView-icons-color
);

$abuseMailBox-allEMailSubmission-subheader-text: var(--am-all-email-sub-subheader-text);
$abuseMailBox-url-link: var(--amb-url-link);
$abuseMailBox-bg-color: var(--amb-bg-color);
