@import 'src/index';

.profile-container {
  display: flex;
  flex-direction: column;
  padding: $padding-medium;
  max-width: 600px;
  color: $theme-text-color;

  .top-container {
    align-items: center;
    padding-bottom: $padding-medium;

    .user-big-avatar {
      border-radius: 50%;
      height: $top-nav-bar-height;
      width: $top-nav-bar-height;
      line-height: $top-nav-bar-height;
      text-align: center;
      background-color: $primary;
      margin-right: $padding-normal;
      color: $white;
      font-size: $font-size-xxlarge;
      font-weight: 500;
    }

    .user-full-name {
      font-weight: 500;
      font-size: $font-size-large;
    }

    .Link {
      padding: $padding-normal $padding-normal 0 $padding-normal;
      width: max-content;
    }
  }
  .section-container {
    width: 100%;
    border-bottom: $theme-border-2;
    margin-bottom: $padding-medium;
    margin-left: 0;
    margin-right: 0;

    div {
      padding-left: 0;
      padding-right: 0;
    }
    .label-and-value-component {
      a {
        color: $theme-hollow-btn-border;
      }
    }

    .btn-toggle-primary,
    .btn-toggle-primary:focus {
      font-weight: 600;
      font-size: $font-size-medium;
      color: $theme-text-color;
      background-color: $theme-form-control-color;
      border-color: $theme-dropdown-button-color;
    }
    .btn-toggle-primary:not(:disabled):not(.disabled).active,
    .btn-toggle-primary:not(:disabled):not(.disabled):active {
      color: $white;
      background-color: $theme-hollow-btn-border;
      border-color: $theme-hollow-btn-border;
    }
    .btn-toggle-primary:hover:not(:disabled) {
      color: $theme-text-color;
      background-color: $theme-hover-bg-color;
      border-color: $theme-hover-bg-color;
    }
    .btn-toggle-primary:not(:disabled).active:hover {
      color: $white !important;
    }
  }
  .primary-email-container {
    .primary-email-header {
      font-weight: 600;
      line-height: normal;
    }
    .primary-tag {
      display: flex;
      width: 70px;
      padding: 3px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 1px;
      border: 1px solid #b2cdfc;
      color: #2168e4;
      background: #d0e1ff;
      font-family: Fakt;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .primary-email-container.isPrimaryEmailWorkEmail-true {
    margin-bottom: $padding-medium;
    margin-top: $padding-medium;
  }
  .work-email-container {
    .work-email-header {
      font-weight: 600;
      line-height: normal;
    }
    margin-bottom: $padding-medium;
    .no-work-email-view-container {
      width: 100%;
      margin: 0;
      .message-wrapper {
        height: 57px;
        flex-shrink: 0;
        border: 1px solid #ffc7c7;
        background: #fff5f5;
        padding: 12px 13px;
        .message {
          color: #5b3b3b;
          font-family: Fakt;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          .darker {
            color: #e33231;
            font-family: Fakt;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
          }
          .capitalise {
            text-transform: capitalize;
          }
        }
      }
    }
    .has-work-email-view-container {
      margin: 0;
      .work-email-text {
        max-width: fit-content;
        margin-right: 5px;
      }
      .verification-text {
        .content {
          color: #038e4b;
          font-family: Fakt;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-top: 5px;
          margin-left: 5px;
        }
      }
      .verification-error {
        .error-content {
          color: #ff3232;
          font-family: Fakt;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.2px;
          padding-top: 5px;
        }
      }
      .resend-email {
        color: #435469;
        border-color: #bdcfe6;
        margin-left: 20px;
      }
    }
  }
}

.change-password-page {
  padding: $padding-medium;
  color: $theme-text-color;
  .section-title {
    font-weight: 500;
    font-size: $font-size-large;
    line-height: $font-size-xlarge;
  }

  .change-password-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 440px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-control:focus {
    border-color: $theme-hollow-btn-border;
    box-shadow: none;
  }

  .form-control:invalid {
    border-color: $red;
    box-shadow: none;
  }

  .help-block.hidden {
    opacity: 0;
  }

  .help-block {
    font-size: $font-size-normal;
    padding-top: $padding-small;
  }

  .nav-text {
    font-size: $font-size-normal;
    padding-top: $padding-small;
  }

  .error-text {
    font-size: small;
    color: $red;
  }

  .submit-button {
    margin-top: $padding-medium;
  }
}

.internal-button {
  display: flex;
  color: $theme-color;
  justify-content: flex-start;
  font-size: $font-size-medium;
  padding-top: $padding-small;
  padding-bottom: $padding-small;

  &:hover {
    color: $theme-text-color;
  }
}
.theme-switch-container {
  width: 100%;
  height: auto;
  padding-left: $padding-large !important;
}

.work-email-err-msg-logo {
  img {
    position: relative;
    width: 100%;
  }
}

.plans-wrapper {
  margin: 1rem 0;
  .plan-item {
    padding: 1rem 0;
    .plan-name {
      font-size: 14px;
      color: $theme-secondary-text-color;
    }
    .plan-details {
    }
    .plan-price {
    }
  }
}
