@import 'src/index';

.attach-file-container {
  padding: $padding-normal 0;
  border-top: $border;
  border-bottom: $border;
  margin-bottom: $padding-normal;

  .attach-file-text {
    padding-left: $padding-normal;
    font-family: Fakt;
  }
}
