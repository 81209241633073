@import 'src/index';

.data-leak-container {
  display: flex;
  flex-direction: column;
  height: auto;
  // overflow: hidden;
  padding: $padding-medium 14px 0px 14px;

  .data-leak-header-wrapper {
    display: flex;
    padding: 8px $padding-xxxsmall;
    flex-direction: column;
    .data-leak-header {
      color: $theme-text-color;
      font-size: $font-size-large;
      font-weight: 600;
    }
    .data-leak-value {
      word-break: break-word;
      white-space: pre-line;
      color: $theme-secondary-text-color;
      font-size: $font-size-medium;
      font-weight: 500;
      margin: $padding-xsmall 0px;
    }
    .details-additional-text {
      color: $theme-text-color;
      font-size: $font-size-normal;
      font-weight: 400;
      line-height: 17px;
    }
  }
  .data-leak-description {
    height: 88vh;
    max-height: 88vh;
    overflow: auto;
    .data-leak-details {
      border-left: $theme-border-2;
    }
  }
  .close-icon-wrapper {
    position: absolute;
    right: 20px;
  }
}
