@import 'src/index';

.information-container {
  .title {
    color: $theme-secondary-text-color;
    font-size: $font-size-normal;
  }
  font-size: $font-size-medium;
  margin-bottom: 15px;
}
