@import 'src/index';

.caption-banner-wrap {
  padding: 5px;
  font-size: 14px;
  margin: 10px auto;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  a {
    text-decoration: none;
    color: inherit;
  }
  img {
    margin-left: 5px;
  }
}
