@import 'src/index';

.social-threat-detection-container {
  display: flex;
  width: calc(100% - 2 * #{$padding-medium});
  min-height: max-content;
  background-color: $theme-widget-bg-color;
  border: $theme-border;
  margin: 0px $padding-medium 0px $padding-medium;
  flex-direction: column;
  .social-threat-header {
    width: 100%;
    padding: $padding-medium $padding-medium 0px $padding-medium;
    .social-threat-title {
      width: 100%;
      height: 32px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1 auto;
      .title-container {
        height: 24px;
        display: flex;
        flex-direction: row;
        width: 50%;
        margin: auto 0;
        align-items: center;
        .title-wrapper {
          width: 100%;
          margin-left: 10px;
          font-weight: 500;
          font-size: $font-size-large;
          line-height: 19px;
          color: $theme-text-color;
        }
        .icon {
          .svg-icon-wrapper:hover {
            path {
              stroke: var(--icon-color);
            }
          }
        }
      }
    }

    .tool-bar-container {
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      min-height: 4rem;
      padding: 0 $padding-medium;
      width: 100%;

      .tools-container.expanded {
        overflow: unset;
      }

      .tools-container {
        align-items: center;
        flex: 1;
        max-width: 85%;
        .dropdown-multi-select-component {
          width: 170px;
          .dropdown-toggle {
            color: $theme-secondary-text-color-light !important;
          }
          .dropdown-menu {
            background-color: $theme-widget-bg-color;
          }

          .options-container {
            max-height: unset;
            .dropdown-item {
              color: $theme-text-color;
            }
            .dropdown-item:hover {
              background-color: $theme-dropdown-hover-bg-color;
            }
          }
        }
      }
    }
    .social-threat-actions {
      display: flex;
      width: 100%;
      height: 22px;
      flex-direction: row;
      margin-top: 18px;
      .action-circle {
        width: 13px;
        height: 13px;
        background: $theme-monitored-header-bg;
        border-radius: 50%;
        margin: auto 0;
      }
      .action-label {
        margin-left: 8px;
        font-size: $font-size-normal;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $theme-secondary-text-color;
      }
    }
  }

  .social-threat-content-container {
    margin: 0 10px;
    overflow: scroll hidden;
    padding-bottom: 20px;
  }
  .social-threat-content-wrapper {
    width: 100%;
    height: 208px;
    padding: $padding-small 0 20px;
    display: grid;
    grid-template-columns: 2fr 7px 1fr 7px 0.5fr;
    grid-column-gap: 1px;
    min-width: 1330px;

    .social-detect-wrapper {
      display: flex;
      flex-direction: column;
      .social-detect-label {
        width: 100%;
        height: 32px;
        background: $theme-monitored-header-bg;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-weight: 600;
        font-size: $font-size-medium;
        line-height: 21px;
        min-width: max-content;
      }
      .social-detect-label:hover {
        cursor: pointer;
        background: $theme-hover-bg-color;
        color: $theme-hover-text-color;
      }
      .social-content-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: $padding-small;
      }
    }

    .vertical-dotted-wrapper {
      width: 100%;
      height: auto;
      margin-left: 2px;
      .vertical-dotted-line {
        width: 0px;
        height: 200px;
        border: 1px dashed #d3d3d3;
      }
    }

    .card-content-wrapper {
      display: flex;
      flex-direction: column;
      height: 165px;
      width: 100%;
      margin-right: 10px;
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $theme-widget-bg-color;
        border: $theme-social-card-content-border;
        .card-content-value {
          margin-top: 30px;
          font-weight: 600;
          font-size: 32px;
          line-height: 130%;
          color: $theme-text-color;
        }
        .card-content-label {
          width: 100%;
          height: 32px;
          margin-top: 2px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: $theme-widget-sub-label-color;
          text-transform: uppercase;
          padding: 0 4px;
          text-align: center;
        }
        .card-content-bottomText {
          margin: 16px 0px 11px 0px;
          font-weight: 600;
          font-size: 11px;
          height: 15px;
          color: $theme-hollow-btn-border;
        }
      }
    }
    .card-content-wrapper:last-child {
      margin-right: 0px;
    }
    .card-content-wrapper:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
.social-threat-icon-tooltip {
  background: $dark-gray-5;
  .tooltip-inner {
    max-width: 235px;
    height: 60px;
    text-align: left;
    padding: $padding-small;
    background: $dark-gray-5;
    color: $white;
    font-size: $font-size-normal;
  }
}
