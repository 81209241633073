@import 'src/index';

.dark-web-dashboard-container {
  height: max-content;
  .spinner {
    margin: 0 auto;
    height: 50px;
    width: 50px;
  }
  .widgets-wrapper {
    display: flex;
    flex-flow: row wrap;
    padding: 1rem;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
  }
}
