@import 'src/index';

.bubble-chart-section {
  width: 80%;
  display: flex;
  flex-direction: column;
  position: relative;
  svg {
    g {
      circle {
        stroke-width: 15 !important;
        &:hover {
          cursor: pointer;
          filter: brightness(var(--circle-brightness));
        }
      }
    }
  }

  --circle-brightness: 1.1;
  svg > g > .node:hover {
    &.low-stroke {
      --circle-brightness: 1.2;
    }
    &.medium-stroke {
      --circle-brightness: 1.3;
    }
    &.high-stroke {
      --circle-brightness: 1.5;
    }
    circle {
      filter: brightness(var(--circle-brightness));
    }
  }

  .high-stroke {
    stroke: var(--theme-risk-level-high-color);
  }
  .medium-stroke {
    stroke: var(--theme-risk-level-medium-color);
  }
  .low-stroke {
    stroke: var(--theme-risk-level-low-color);
  }

  .text-count {
    font-weight: 600;
    font-size: 40px;
  }
  .text-label {
    font-weight: 400;
    font-size: $font-size-medium;
  }
  .text-count:hover {
    cursor: pointer;
  }
  .text-label:hover {
    cursor: pointer;
  }

  .bottom-category-count-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    .categories-by-risk {
      .risk-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: $padding-small;
        align-items: center;
        margin-left: $padding-medium;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
        .badge {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: inline-block;
        }
        .risk-label {
          padding-left: $padding-xsmall;
          font-size: $font-size-normal;
          line-height: 16px;
          color: $theme-secondary-text-color;
        }
        .risk-count {
          padding-left: $padding-xsmall;
          font-weight: 600;
          font-size: $font-size-normal;
          line-height: 18px;
          color: $theme-text-color;
        }
      }
    }
    .categories-by-status {
      margin-right: 70px;
      width: 10%;
      .status-count-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .status-label {
          display: block;
          width: 100%;
          font-size: $font-size-normal;
          line-height: 16px;
          color: var(--theme-secondary-text-color);
          text-align: right;
          padding-right: $padding-small;
        }
        .status-count {
          font-weight: 600;
          font-size: $font-size-large;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-decoration-line: underline;
          color: $theme-hollow-btn-border;
        }
        .status-count:hover {
          cursor: pointer;
        }
      }
    }
  }
  .no-data-placeholder-container {
    padding: 0;
    .title {
      font-size: $font-size-large;
      color: $theme-text-color;
      font-weight: unset;
    }
    .text {
      font-size: $font-size-normal;
      color: $theme-text-color;
    }
  }
}
