@import 'src/index';

.sub-menu-tab-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: $theme-border;
  height: $top-nav-bar-height;
  .sub-menu-tab-wrapper {
    display: flex;
    background-color: $theme-color;
    align-items: end;
    padding-left: 46px;
    .tab-wrapper {
      width: auto;
      height: inherit;
      display: flex;
      align-items: center;
      margin-left: 32px;
      margin-right: 32px;
      .tab-label {
        color: $theme-secondary-text-color;
        text-transform: uppercase;
        &.active {
          color: $theme-hollow-btn-border;
        }
        &.tab-disabled {
          color: grey;
        }
      }
      &.active {
        color: $theme-hollow-btn-border;
      }
      &.tab-disabled {
        color: grey;
      }
      &.tab-disabled:hover {
        color: grey;
        cursor: not-allowed;
        border-bottom: 2px solid grey;
      }
    }
    .tab-wrapper:hover {
      cursor: pointer;
    }
  }
}
