@import 'src/index';

@mixin search-findings-title {
  max-width: 75%;
  word-break: break-all;
  color: $theme-hollow-btn-border;
  font-size: $font-size-medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  &:hover {
    color: $theme-hollow-btn-border;
    text-decoration: underline;
    cursor: pointer;
  }
}

.global-search-findings-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  .global-search-findings-container {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 0px $padding-normal 6px $padding-normal;
    margin: $padding-xxsmall 0px;
    .module-header {
      display: flex;
      height: auto;
      margin-bottom: $padding-small;
      img {
        width: 25px;
        height: 25px;
        margin-right: $padding-small;
      }
      .module-label {
        display: flex;
        align-items: center;
        font-size: $font-size-medium;
        color: $theme-text-color;
      }
    }
    .finding-table-label {
      line-height: 20px;
      font-size: $font-size-normal;
      color: #78909c;
    }
    .findings-detail {
      padding-bottom: 6px;
      .finding-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 2px 0px;
        .finding-title {
          @include search-findings-title;
        }
        .title-with-no-insights {
          @include search-findings-title;
          color: $theme-text-color;
          &:hover {
            color: $theme-text-color;
            text-decoration: none;
            cursor: default;
          }
        }
      }
    }
    .findings-more-description {
      display: flex;
      justify-content: space-between;
      font-size: $font-size-normal;
      color: $theme-text-color;
      margin: $padding-small 0px;
      .see-all {
        color: $theme-hollow-btn-border;
        cursor: pointer;
      }
    }
  }
}
