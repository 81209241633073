.dark-web-dashboard-container {
  display: flex;
  flex-direction: column;
  height: auto;
  .active-findings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
    overflow-x: auto;
    margin-top: 33px;
    margin-left: 25px;
  }
  .social-media-protection-description {
    max-width: 500px;
  }
  .social-media-protection-image-container {
    .social-media-protection-image {
      width: 80%;
    }
  }
}
