@import 'src/index';

.nav-item {
  display: flex;
  align-items: center;
}

.width-0 {
  width: 0px !important;
  max-width: 0px !important;
}

.width-60 {
  width: 60px !important;
  max-width: 60px !important;

  &.side-bar-web-2nd {
    padding-block-start: 16px;
  }

  & > .second-level-header {
    display: none !important;
  }

  & :is(.navHeader, .sidebar-label, .expand-icon) {
    display: none;
  }

  .justify-items-center {
    justify-content: center;
  }
}

.company-name {
  font-weight: bold;
  font-size: $font-size-xlarge;
  color: $dark-gray-5;
}

.user-avatar {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background-color: $primary;
  color: $white;
}

.user-avatar:hover {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}

.side-bar-web-component {
  display: flex;
  flex-direction: row;
  height: 100vh;

  .side-bar-web-level {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .side-bar-web-1st {
    align-items: center;
    justify-content: space-between;
    width: 60px;
    padding: $padding-medium 0;
    background-color: $dark-gray-5;

    .company-icon-container {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: white;
      margin-bottom: $padding-medium;
    }

    .top-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      > img:first-child {
        margin-bottom: $padding-medium;
      }

      .first-level-nav-item {
        padding: $padding-small;
        margin-bottom: $padding-normal;
      }
      .first-level-nav-item.social-media > img {
        width: 26px;
      }

      .first-level-nav-item.selected {
        background-color: $dark-gray-4;
      }
    }

    .bottom-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .user-avatar {
        margin-top: $padding-normal;
      }
    }

    .app-version {
      color: $white;
      font-size: $font-size-small;
      margin-top: $padding-normal;
    }
  }

  .side-bar-web-2nd {
    align-items: flex-start;
    width: 210px;
    max-width: 210px;
    padding: $padding-medium 0;
    background-color: $white;
    transition: width 0.5s ease, max-width 0.5s ease;
    overflow: hidden;
    border-right: $border;

    .second-level-header {
      overflow: hidden;
      width: 100%;
      padding: 0 $padding-normal $padding-normal $padding-normal;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;

      img {
        cursor: pointer;
      }
    }

    .second-level-body {
      font-size: $font-size-medium;
      width: 100%;

      .second-level-title {
        padding: $padding-normal;
        white-space: nowrap;
        font-weight: 500;
        font-size: $font-size-large;
        color: $dark-gray-5;
      }

      .sub-nav-container {
        padding: 0 $padding-normal;

        a {
          text-overflow: ellipsis;

          .sub-nav-inner {
            padding: $padding-small 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              max-height: 30px;
              line-height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: $dark-gray-5;
            }
          }
        }
      }

      .sub-nav-container:hover {
        background-color: $light-blue-0;
      }

      .sub-nav-container.selected {
        background-color: $light-blue-1;

        span {
          font-weight: 600;
        }
      }

      .sub-nav-container.disabled:hover {
        background-color: $light-gray-1;
      }

      .sub-nav-container.disabled {
        a {
          text-decoration: none;
          cursor: default;
          .sub-nav-inner {
            span {
              color: $light-gray-4;
              text-decoration: none !important;
            }
          }
        }
      }

      .sub-nav-container {
        .logout-button {
          align-items: center;
          display: flex;
          border-top: $border;
          padding-top: $padding-small;
          padding-bottom: $padding-small;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          img {
            padding-right: $padding-small;
          }
        }

        // .internal-button {
        //   display: flex;
        //   color: $white;
        //   justify-content: flex-start;
        //   font-size: $font-size-medium;
        //   padding-top: $padding-small;
        //   padding-bottom: $padding-small;

        //   &:hover {
        //     color: $dark-gray-5;
        //   }
        // }
      }

      .new-dashboard-button {
        span {
          font-weight: 600;
          font-size: $font-size-medium;
          color: $dark-gray-3;
        }
      }

      .beta-icon {
        padding-left: $padding-small;
        padding-right: 0 !important;
      }
    }
  }
}

$navbarWidth: 360px;
$animation-duration: 0.3s;
.side-bar-mobile-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 288px;
  height: 100vh;
  z-index: 3;

  .nav-bar-background.is-active {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .nav-bar-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color $animation-duration;
    opacity: 0.5;
    z-index: 2;
  }

  .side-bar-level.is-active {
    left: 0;
  }

  .side-bar-level {
    position: absolute;
    top: 0;
    left: -$navbarWidth;
    width: $navbarWidth;
    max-width: 70vw;
    height: 100vh;
    background-color: $dark-gray-5;
    box-shadow: 4px 0 8px rgba(41, 42, 51, 0.24);
    transition: left $animation-duration;
    z-index: 3;

    .side-bar-header {
      padding: $padding-normal;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        padding-left: $padding-normal;
        color: $white;
        font-size: $font-size-xlarge;
        font-weight: 500;
      }
    }

    .side-bar-body {
      display: flex;
      flex-direction: column;

      .new-label-text-mobile {
        background-color: white;
        width: fit-content;
        max-width: 40px;
        display: flex;
        justify-content: center;
        font-weight: bold;
        border-radius: 2px;
        padding: 0 0.2rem;
      }

      .side-bar-item-container.selected {
        background-color: rgba(255, 255, 255, 0.15);
      }

      .side-bar-item-container:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }

      .side-bar-item-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: $padding-xsmall;
        padding: $padding-small;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img:first-child {
          margin-right: $padding-normal;
          max-width: 26px;
        }

        span {
          color: white;
          font-size: $font-size-medium;
          flex: 1;
        }
      }
    }
  }

  .first-level {
    .first-level-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .company {
        display: flex;
        flex-direction: row;
        align-items: center;

        .company-icon-container {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: white;
        }

        span {
          font-weight: 600;
        }
      }

      > img {
        cursor: pointer;
      }
    }

    .first-level-body {
    }
  }

  .second-level {
    z-index: 4;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    .second-level-header {
      img {
        cursor: pointer;
      }
    }
  }
}
