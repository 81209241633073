@import '../../../index.scss';

.all-email-submissions-no-data-container {
  width: 100%;
  height: 1234px;
  background-color: $theme-background-color;
  border: var(--theme-border);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  gap: 15px;

  .all-email-submissions-no-data-text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    .all-email-submissions-no-data-main-text {
      font-size: 24px;
      font-weight: 500;
      line-height: 20px;
    }

    .all-email-submissions-no-data-sub-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--abuseMailBox-allEmailSubmissions-noDataView-subText);
    }
  }
}
