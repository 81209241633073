@import 'src/index';

$widget-item-inner-height: 380px;
$dashboard-chart-title-height: 34px;
$widget-title-and-content-height: $widget-item-inner-height - $padding-medium - $padding-medium -
  2px;
$widget-content-height: $widget-item-inner-height - $padding-medium - $padding-medium -
  $dashboard-chart-title-height - 2px;
//2 px for the border

.card + .card {
  margin-top: $padding-medium;
}

/*react grid layout css*/
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: $red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.widgets-picker-modal {
  .modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-modal-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .dashboard-modal-header-main {
      font-size: $font-size-xxlarge;
      padding-right: $padding-normal;
    }

    .dashboard-modal-header-selection-count {
      font-weight: 500;
      font-size: $font-size-large;
    }
  }

  .dashboard-modal-header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    button + button,
    button + span.button {
      margin-left: $padding-medium;
    }
  }

  .widgets-picker-modal-body {
    max-height: 88vh;
    overflow: scroll;
    padding-left: $padding-medium-half !important;
    padding-right: $padding-medium-half !important;
    padding-bottom: 0 !important;

    .widgets-picker-contents {
      display: flex;
      flex-wrap: wrap;

      .widget-picker-candidate {
        flex: 0 50%;
        padding-left: $padding-medium-half;
        padding-right: $padding-medium-half;
        padding-bottom: $padding-medium;

        .widget-picker-candidate-inner {
          width: 100%;
          border: $border;

          img {
            max-width: 100%;
            object-fit: contain;
          }

          .candidate-info {
            border-top: $border;
            padding: $padding-medium;

            .candidate-title {
              font-weight: 500;
              font-size: $font-size-large;
            }

            .candidate-description {
              font-weight: normal;
              font-size: $font-size-medium;
            }

            button {
              width: 100%;
              margin-top: $padding-medium;
            }

            button.widget-add {
              color: $light-blue-5;
              border-color: $light-blue-5;
            }

            button.widget-add:hover {
              background-color: $light-blue-5;
              color: $white;
            }

            button.widget-selected {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              background-color: $blue_green;
              border-color: $blue_green;

              img {
                margin-right: $padding-small;
              }
            }

            button.widget-selected:hover {
              background-color: $blue_green;
            }
          }
        }
      }
    }
  }
}

.widget-section-container {
  padding: $padding-medium;
  padding-top: $padding-large;
}

.widget-item-component {
  height: 100%;
  background-color: $theme-color;
}

.widget-item-inner {
  position: relative;
  height: 100%;
  padding: $padding-medium;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: $theme-widget-border-color;
  background-color: $theme-widget-bg-color;
  color: $theme-text-color;

  .scan-result-item-title {
    font-size: 24px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
  }

  .scan-result-item-time-range {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
  }

  .scan-result-item-value {
    color: #0277bd;
  }
}

.widget-item-inner.add-new-widget-component {
  border: $border-dashed;
  background-color: $light-gray-1;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.dashboard-page-title {
  padding: $padding-medium-web !important;

  .dropdown-date-range-picker-component {
    padding-top: $padding-medium;
    padding-right: $padding-medium-web;
  }

  @media only screen and (max-width: $screen-size-sm) {
    .dropdown-date-range-picker-component {
      padding-top: $padding-normal;
    }
  }

  .dashboard-action-dropdown {
    padding-top: $padding-medium;
    padding-right: $padding-medium;

    .dropdown {
      border: $border2;
      background-color: $white;
      height: 40px;

      button {
        padding: $padding-small;
      }
    }
  }

  .beta-icon {
    padding: 0.25rem 1.5rem;
  }
}

.dashboard-page-editing-header {
  height: 80px;
  min-height: 80px;
  width: 100%;
  background-color: $dark-gray-3;
  padding: 0 $padding-medium;
  justify-content: space-between;

  input {
    background-color: $dark-gray-3;
    color: $white;
    padding-left: $padding-medium-half;
    border: 1px solid $white;
    width: 480px;
    height: 40px;
    font-size: $font-size-xxlarge;
    font-weight: 500;
    box-shadow: none;
  }

  input:focus {
    outline-color: $light-blue-3;
    border-color: $light-blue-3;
  }

  .dashboard-page-editing-header-buttons {
    .add-section-button {
      color: $white;
      padding-right: $padding-normal;
      cursor: pointer;

      img {
        padding-right: $padding-small;
      }
    }

    .btn-outline-light {
      color: $white;
    }

    button {
      font-weight: 500;
      height: 40px;
      font-size: $font-size-medium;
      color: $dark-gray-5;
    }

    button + button {
      margin-left: $padding-normal;
    }
  }
}

.hide-button-container.table-hide-button {
  position: inherit;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.hide-button-container {
  position: absolute;
  top: $padding-xxsmall;
  right: $padding-normal;

  .hide-widget-button {
    cursor: pointer;
  }
}

.replace-button-container {
  right: $padding-small;
  background-color: $white;
  padding-bottom: $padding-xxsmall;
  padding-left: $padding-small;
}

.dashboard-page {
  .zero-section-help-text {
    background-color: $blue_green2;
    padding: 0 $padding-normal;
    margin-bottom: $padding-medium;
    width: 100%;
    height: 48px;
    line-height: 48px;
    color: $white;
    font-size: $font-size-medium;
  }

  .add-section-component {
    height: 350px;
  }

  .card.widgets-container {
    background-color: transparent !important;
    border: none !important;
    height: 280px;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: $screen-size-md) {
      flex-wrap: wrap;
    }

    .widget-item-component {
      flex: 1;
    }

    .widget-item-component + .widget-item-component {
      @media only screen and (max-width: $screen-size-md) {
        margin-top: $padding-medium;
      }
      @media only screen and (min-width: $screen-size-md + 1) {
        margin-left: $padding-medium;
      }
    }

    .widget-item-component {
      @media only screen and (max-width: $screen-size-md) {
        min-width: calc(100vw - 48px);
      }
    }
  }

  > div.card.dashboard-table-container {
    border-left-width: 1.1px !important;
    border-right-width: 1.1px !important;
    border: none !important;

    .table-component {
      .tool-bar-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .table-component {
    .dashboard-table-loading-wrapper.is-loading {
      min-height: 722px;
    }

    .table-source-url-column {
      min-width: 0;

      a,
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $theme-hollow-btn-border;
      }
      .tooltip-inner {
        background: $theme-tooltip-bg-color;
        color: $theme-text-color;
      }
    }
    .table-ip-column {
      min-width: 0;

      a,
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $theme-hollow-btn-border;
      }
    }

    // remove this after ag grid is enabled for all tables
    @media only screen and (max-width: $screen-size-sm) {
      .table-source-url-column {
        max-width: 9rem !important;
      }
    }

    // handle special case
    @media only screen and (min-width: $screen-size-sm) and (max-width: 1200px) {
      .table-source-url-column {
        .tooltip-inner {
          background: $theme-tooltip-bg-color;
          color: $theme-text-color;
        }
      }
      .hide-below-lg,
      .web-only {
        display: none !important;
      }
    }

    .table-source-url-tooltip {
      background: $theme-tooltip-bg-color;
      color: $theme-text-color;
      .tooltip-inner {
        max-width: 40rem;
        background: $theme-tooltip-bg-color;
        color: $theme-text-color;
      }
    }

    @media only screen and (max-width: $screen-size-sm) {
      .tools-container > .dropdown-component .dropdown-menu {
        left: 0 !important;
      }
    }
  }
}

.table-icon {
  display: flex;
  white-space: nowrap;
  .svg-icon-wrapper {
    margin-left: $padding-xsmall;
    circle {
      fill: $theme-circle-badge-bg-color;
    }
    path {
      stroke: $theme-modal-overlay-close-btn;
    }
  }
  .svg-icon-wrapper:hover {
    cursor: pointer;
    circle {
      fill: $theme-user-icon-hover-bg-color;
    }
    path {
      stroke: $theme-modal-overlay-close-btn;
    }
  }
}

.table-icon-tooltip {
  .tooltip-inner {
    max-width: 20rem;
    text-align: left;
    padding: $padding-normal;
    background: $theme-tooltip-bg-color;
    color: $theme-text-color;
    .card-header {
      padding: 0 0 $padding-small;
      margin: 0 0 $padding-xxsmall;
      font-size: $font-size-medium;
      font-weight: 500;
      line-height: 21px;
      background: $theme-tooltip-bg-color;
      color: $theme-text-color;
      .dispute-header {
        font-weight: 400;
        padding: 0 0 $padding-small;
        background: $theme-tooltip-bg-color;
        color: $theme-text-color;
      }
    }

    .card-body {
      padding: 0;
      background: $theme-tooltip-bg-color;
      color: $theme-text-color;
    }

    .card-text {
      padding: $padding-xxsmall 0 0;
      margin: 0;
      font-size: $font-size-normal;
      line-height: $font-size-large;
    }
    .table-icon-tooltip-comments {
      color: $theme-secondary-text-color !important;
    }
  }

  &-reporter {
    font-weight: 500;
  }

  &-comments {
    color: $dark-gray-3;
  }
}

@media only screen and (min-width: $screen-size-xlg) {
  .dashboard-tile-component {
    width: calc(50% - #{$padding-medium-web} / 2) !important;

    .major-content {
      border-top: $theme-border-2;
      border-bottom: $theme-border-2;
    }
  }
}

.dashboard-tile-component {
  margin-bottom: $padding-medium;
  padding: $padding-medium;
  border: $theme-border;
  width: 100%;
  height: 100%;
  color: $theme-text-color;
  background-color: $theme-widget-bg-color;

  @media only screen and (max-width: $screen-size-lg) {
    .major-content {
      padding-bottom: 0 !important;

      .screenshot {
        padding-bottom: $padding-medium;
      }
    }
  }

  .rescan-started {
    font-size: $font-size-medium;
    line-height: 21px;
  }

  .rescan-button-wrapper {
    display: inline-flex;
    font-size: $font-size-medium;
    line-height: 21px;
    color: $theme-hollow-btn-border;
  }

  .rescan-button-wrapper:hover {
    cursor: pointer;
  }
  .scan-location-wrapper {
    margin: $padding-normal 0;
    .scan-description-wrapper {
      font-size: $font-size-normal;
      line-height: 19px;
      .scan-value-wrapper {
        font-weight: bold;
      }
    }
  }

  .major-content {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: $padding-medium 0;

    .screenshot {
      padding-right: $padding-medium-web;
    }

    .col-sm-6,
    > div {
      padding-left: 0;
      padding-right: 0;
    }

    .major-info-content {
      justify-content: space-between;
      height: 100%;
      .label-and-value-component {
        a {
          color: $theme-hollow-btn-border;
        }
      }
    }
  }

  .secondary-content {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: $padding-medium;

    > div {
      padding-left: 0;
      padding-right: 0;
    }

    .label-and-value-component {
      div {
        font-size: $font-size-medium;
      }
      .tags-column-container {
        width: 100%;
        .tags-column-wrapper {
          &:hover {
            cursor: pointer;
          }
          .add-tag-button {
            width: 90px;
          }
          .pills-label-wrapper {
            flex-basis: 15%;
          }
        }
      }
    }
  }
}

.monitoring-container {
  border: none !important;
}

.monitoring-widget-item-inner {
  padding: 0;
}

.dashboard-monitoring-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .monitoring-widget-title {
    font-size: 14px;
    flex: 1;
    padding: 0 $padding-medium;
  }

  .monitoring-widget-company {
    height: $font-size-xxxlarge;
    padding: $padding-xxxsmall $padding-small;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .monitoring-widget-item:first-child {
    background: linear-gradient(180deg, $blue_green2 0%, $blue_green 100%);
    border-right: 2px solid $white;

    .monitoring-widget-item-inner {
      border-right: none;
    }
  }

  .monitoring-widget-item {
    background: linear-gradient(180deg, $light-blue-5 0%, $light-blue-4 100%);
    padding: 0;

    .monitoring-widget-item-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      height: 75%;
      min-height: 60px;
      margin: $padding-medium 0;
      padding: 0 $padding-medium;
      width: 100%;
      color: $white;

      .monitoring-widget-item-label {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-normal;
        line-height: $font-size-large;
      }

      .monitoring-widget-item-value {
        font-weight: 600;
        line-height: $font-size-xxxlarge;
        font-size: $font-size-xxlarge;
      }
    }
  }

  .monitoring-widget-item:last-child {
    .monitoring-widget-item-inner {
      border-right: 0;
    }
  }

  @media only screen and (max-width: $screen-size-sm) {
    .monitoring-widget-item:first-child {
      max-width: 100%;
      flex: none;
      border-right: none;
      border-bottom: 2px solid $white;
    }
    .monitoring-widget-item:nth-child(2) {
      background: linear-gradient(180deg, $light-blue-5 0%, $light-blue-4 300%);
    }
    .monitoring-widget-item:nth-child(3),
    .monitoring-widget-item:nth-child(4) {
      background: linear-gradient(180deg, $light-blue-5 -100%, $light-blue-4 200%);
    }
    .monitoring-widget-item:nth-child(5),
    .monitoring-widget-item:nth-child(6) {
      background: linear-gradient(180deg, $light-blue-5 -200%, $light-blue-4 100%);
    }

    .monitoring-widget-item:nth-child(2) {
      .monitoring-widget-item-inner {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: $padding-medium;
        margin-right: $padding-medium;
        max-width: calc(100% - 2 * #{$padding-medium});
        padding: $padding-medium 0;
        padding-top: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        .monitoring-widget-company {
          margin-left: $padding-normal;
        }
      }
    }
    .monitoring-widget-item:nth-child(even) {
      .monitoring-widget-item-inner {
        border-right: 0;
      }
    }
  }
}

.dashboard-chart-title {
  font-size: $font-size-large;
  font-weight: 500;
  min-height: $dashboard-chart-title-height;
  width: max-content;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.card.no-border-or-background {
  border-width: 0 !important;
  background-color: $light-gray-1 !important;
}

.dashboard-widget {
  display: flex;
  flex-direction: column;
  height: $widget-title-and-content-height;

  .apexcharts-canvas {
    .apexcharts-zoom-icon.apexcharts-selected {
      display: none;
    }

    .apexcharts-reset-icon {
      opacity: 0;
      cursor: default;
    }
  }

  .apexcharts-legend-series {
    display: flex !important;
    flex-direction: row;
    align-items: center;
  }

  .apexcharts-tooltip.apexcharts-active {
    padding: $padding-normal;
    border-radius: 0;
    box-shadow: 0 4px 8px rgba(51, 51, 51, 0.24);

    .apexcharts-tooltip-title {
      padding: 0;
      border-bottom: 0;
      background-color: inherit;
      font-weight: normal;
      font-size: $font-size-medium !important;
    }

    .apexcharts-tooltip-series-group {
      align-items: center !important;
      padding: 0;

      .apexcharts-tooltip-y-group {
        display: flex;
        align-items: center;
      }
    }
  }

  .apexcharts-xaxistooltip {
    background-color: $white;
    border: none;
  }

  .apexcharts-xaxistooltip::before {
    display: none;
  }

  .donut-chart-container {
    width: 100%;
    max-width: 600px;
    align-self: center;
  }
}

.dashboard-bar-chart,
.dashboard-hosting-widget,
.dashboard-ip-widget {
  .hosting-widget-tooltip-container,
  .ip-widget-tooltip-container {
    .apexcharts-tooltip-title {
      font-size: $font-size-medium !important;
    }

    .live,
    .down {
      font-size: $font-size-normal !important;
      padding: $padding-xsmall 0;

      b {
        padding-left: $padding-xsmall;
      }
    }

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: $padding-small;
    }
  }

  .apexcharts-bar-series.apexcharts-plot-series {
    .apexcharts-series {
      path:hover {
        opacity: 0.9;
      }
    }
  }
}

.dashboard-region-widget {
  position: relative;

  .general-count {
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $theme-background-color;
    padding: $padding-small;
    font-size: $font-size-normal;
    font-weight: 400;
    color: $theme-text-color;
    b {
      font-size: $font-size-large;
    }
  }
}

.dashboard-map-chart {
  .loading-wrapper {
    min-height: $widget-content-height;
  }
  .leaflet-container {
    background-color: rgba(255, 0, 0, 0);
  }
  .custom-div-icon {
    width: auto !important;
    min-width: 42px !important;
    .marker-pin {
      background: linear-gradient(90.47deg, #ee8695 0.24%, #a73b8f 99.73%);
      border-radius: 12px;
      color: $white;
      text-align: center;
      //styleName: Subheader;
      font-size: $font-size-large;
      font-weight: 500;
      padding: 0 0.3rem !important;
    }
  }
}

.dashboard-map-chart,
.dashboard-countries-widget {
  .dashboard-map-container {
    position: relative;
    height: $widget-content-height;

    .dashboard-map {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;

      .leaflet-popup-close-button {
        display: none;
      }

      .leaflet-popup-content-wrapper {
        border-radius: 0;
      }

      .leaflet-popup-tip-container {
        display: none;
      }
    }
  }

  .height-0 {
    height: 0 !important;
  }
}

.dashboard-donut-chart,
.dashboard-tld-widget {
  .apexcharts-legend.apexcharts-align-center.position-right {
    .apexcharts-legend-series {
      padding-bottom: $padding-small;
    }
  }

  .apexcharts-tooltip.apexcharts-active {
    background-color: transparent;
    padding: 0;
    border: $theme-border;

    .apexcharts-tooltip-text {
      background-color: $white;
      color: $dark-gray-3;
      padding: $padding-small;
    }
  }
}

.no-data-placeholder-container {
  flex: 1;
  width: 100%;
  height: $widget-content-height;
  min-height: $widget-content-height;
  padding: 0 $padding-xxlarge;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: $font-size-xxlarge;
    font-weight: 500;
    padding: $padding-normal;
  }

  .text {
    font-size: $font-size-medium;
    font-weight: normal;
    text-align: center;
  }
}

@media only screen and (max-width: $screen-size-xlg) {
  .no-data-placeholder-container {
    padding: $padding-small 0;
  }
}

.dashboard-add-section-tile {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $padding-medium;

  .dashboard-add-section-tile-image {
    width: 385px;
    height: 100px;
  }

  .dashboard-add-section-tile-image.border {
    border: $border;
  }

  .dashboard-add-section-info {
    flex: 1;
    padding: 0 $padding-medium;

    .dashboard-add-section-title {
      padding-bottom: $padding-small;
      font-size: $font-size-large;
      font-weight: 500;
    }

    .dashboard-add-section-description {
      font-size: $font-size-medium;
      font-weight: normal;
    }
  }
}

.scanned-by-block {
  margin-top: 24px;
}
@media only screen and (min-width: $screen-size-xlg) {
  .dashboard-tile-equal-width {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}
