@import 'src/index';

.images-slide-component {
  display: flex;
  flex-direction: column;
  width: 100%;

  .images-controller {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border: $border;
    padding: $padding-small $padding-medium;
    margin: $padding-normal 0 0;

    img {
      cursor: pointer;
    }

    img.disabled {
      cursor: default;
    }

    div {
      padding: 0 $padding-small;
    }
  }

  .all-images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: $padding-normal;

    img {
      width: 9vw;
      margin-right: $padding-normal;
      cursor: pointer;
      border: 1px solid transparent;
    }

    img.image-selected {
      border: 1px solid $light-blue-2;
    }
  }
}

@media only screen and (max-width: $screen-size-lg) {
  .images-slide-component {
    .all-images {
      display: none;
    }
  }
}
