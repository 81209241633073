@import 'src/index';

.side-bar-wrapper {
  padding: 0 10px;
  .navigation-wrap {
    border: 2px solid $go-back-link;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-weight: 500;
    a {
      color: $go-back-link !important;
      text-decoration: none;
      opacity: 0.9;
    }
  }
  .app-type-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    .checkphish-toggle-selector {
      width: 100%;
      border-radius: 5px;
      background-color: $theme-cp-slider-bg-color;
      border: 1px solid $theme-cp-slider-bg-color;
      .toggle-switch-item {
        border: 2px solid $theme-cp-slider-bg-color;
        width: 120px;
        font-size: 14px;
        padding: 5px;
        background-color: $theme-cp-slider-bg-color;
        color: $theme-cp-slider-text-color;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
      }
      .toggle-switch-item.selected {
        background-color: $theme-cp-slider-active-gb-color !important;
        color: $theme-cp-slider-active-text-color !important;
      }
    }
  }
  .side-bar-items-wrap {
    padding: 1rem 0;
  }
}
