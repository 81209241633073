@import 'src/index';

$component-size: 12px;
.redirect-to-malicious-link-loading-backdrop {
  background-color: $light-gray-1;
}

.redirect-to-malicious-link-loading-content {
  display: flex;
  align-items: center;
  background-color: transparent;

  .insight-spinner-container {
    background-color: transparent;
  }
}
