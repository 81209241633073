@import 'src/index';
$dropdown-width: 235px;

@mixin thumb {
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0 0 0 2px white;
}

.MuiButton-root.margin-inline-end {
  margin-inline-end: 8px;
}

.bulk-scan-page {
  color: $theme-text-color;
  background-color: $theme-color;
  padding-top: $padding-medium;

  .page-title-component {
    background-color: $theme-color;
  }

  .title-description {
    font-size: $font-size-medium;
    font-style: normal;
    font-weight: 400;
    padding-left: $padding-medium;
  }

  .bulk-scan-page-content {
    padding: $padding-medium $padding-medium-web 0 $padding-medium-web;

    .nav-link.active {
      color: var(--theme-hollow-button-border);
      border-bottom: 2px solid;
    }

    .bulk-scan-form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $padding-medium;
      padding-bottom: 0;
      color: $theme-text-color;

      .bulk-scan-icon-wrapper {
        margin-right: $padding-normal;

        .svg-icon-wrapper:hover {
          cursor: default;

          path {
            stroke: var(--icon-color);
            cursor: default;
          }
        }
      }

      .card-text {
        font-weight: 500;
      }
    }

    .bulk-scan-form {
      padding: $padding-medium;

      .bulk-scan-settings {
        padding-bottom: $padding-medium;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        .drop-down-fields-wrapper {
          display: flex;
          flex-direction: column;
          margin-right: $padding-medium;

          .reset-button {
            color: #2168e4;
            font-size: $font-size-medium;
            font-style: normal;
            font-weight: 400;
            margin-top: 24px;
            cursor: pointer;
            /* 175% */
          }

          .form-label {
            display: flex;
            font-size: 14px;
            line-height: 21px;
            color: $theme-text-color;
          }

          .form-block {
            height: auto;
            padding: $padding-small $padding-small $padding-small 0;
          }

          .dropdown-multi-select-component {
            width: $dropdown-width;
            min-width: max-content;
            border-radius: 4px;

            .options-container {
              max-height: unset;

              .dropdown-item {
                color: $theme-text-color;
              }

              .dropdown-item:hover {
                background-color: $theme-dropdown-hover-bg-color;
              }
            }

            .dropdown-menu {
              height: 400px;
              overflow-y: scroll;
            }
          }

          button.dropdown-toggle {
            overflow: auto;
            width: $dropdown-width;
            border: $theme-border-2;
            color: $theme-text-color !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
          }

          .dropdown-menu {
            width: $dropdown-width;
            background-color: $theme-form-control-color;
            color: $theme-text-color;
          }

          .check-box-container {
            margin-bottom: 0;
          }
        }

        .wait-range {
          width: 150px;
          background: transparent;

          &::-webkit-slider-thumb {
            @include thumb;
            -webkit-appearance: none;
          }

          &::-moz-range-thumb {
            @include thumb;
            background-color: $theme-hollow-btn-border;
          }

          &::-moz-range-track {
            border-radius: 8px;
            height: 7px;
            border: 1px solid #bdc3c7;
            background: white;
          }
        }
      }

      .form-group {
        width: 100%;
        margin-left: 0;
        margin-bottom: $padding-medium;

        .label {
          font-size: $font-size-medium;
          color: $theme-text-color;
        }

        .limit {
          padding-left: $padding-medium;
          color: $theme-text-color;
          font-weight: normal;
          font-size: $font-size-normal;
        }

        .fa-info-circle {
          margin-left: $padding-small;
        }

        .row {
          margin-left: 0;
          margin-right: 0;

          .form-check {
            margin-right: $padding-medium;
          }
        }
      }

      .button-containers {
        display: flex;
        flex-direction: row;

        .btn-primary {
          width: 90px;
          font-weight: 600;
          font-size: $font-size-medium;
        }

        .clear-button {
          color: #2168e4;
          font-size: $font-size-normal;
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
        }

        .margin-start-10 {
          margin-inline-start: 10px;
        }
      }

      .bulk-scan-info-redirect-container {
        font-size: 14px;
        margin-top: 15px;

        .bulk-scan-status-button-container {
          .bulk-scan-rescan-btn {
            background-color: transparent;
            color: $theme-a-tag-color;
            margin-left: 20px;
          }
        }
        .bulk-scan-urls-list-header {
          font-weight: 600;
        }
        .bulk-scan-urls-list-container {
          list-style: none;
          padding: 0;
          margin-top: 5px;
        }
      }
    }

    .team-view {
      position: absolute;
      left: 10px;
      display: flex;
      flex-direction: row;
      top: 35px;
    }

    .bulk-scan-table-container {
      border: none !important;

      .table-component {
        .table-source-url-column {
          min-width: 0;
          max-width: 20vw;

          .spinner {
            margin-right: $padding-xxsmall;
            min-width: 1rem;
          }

          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $theme-hollow-btn-border !important;
          }
        }

        .table-ip-column {
          min-width: 0;
          max-width: 10rem;

          a,
          div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $theme-hollow-btn-border !important;
          }
        }

        @media only screen and (max-width: $screen-size-sm) {
          .table-source-url-column {
            max-width: 6rem;
          }
        }

        @media only screen and (min-width: $screen-size-sm) and (max-width: $screen-size-md) {
          .table-source-url-column {
            max-width: 15vw !important;
          }

          .hide-below-md,
          .web-only {
            display: none;
          }
        }

        // handle special case
        @media only screen and (min-width: $screen-size-md) and (max-width: 1140px) {
          .table-source-url-column {
            max-width: 25vw !important;
          }

          .hide-below-md,
          .web-only {
            display: none;
          }
        }

        .dashboard-tile-component {
          background-color: $theme-widget-bg-color;
          position: relative;

          .tile-loading-mask {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.5);
          }
        }

        .dashboard-tile-component.is-new-added {
          background-color: $theme-new-row-added-bg-color !important;
        }

        .dashboard-tile-component.is-checked {
          background-color: $theme-row-select-bg-color !important;
        }

        .custom-btn-wrapper {
          .btn {
            height: 40px;
            background-color: var(--theme-filter-button-bg);
            color: var(--theme-page-text);
            // border: 0;
            font-size: $font-size-medium;
            margin-left: $padding-small;
            padding: $padding-xsmall $padding-small;
            font-weight: 500;
            line-height: 19px;
          }
        }
      }
    }
  }
  .bulk-scan-text-area::placeholder {
    color: #888888 !important;
  }
}

.bulkscan-error-message-block {
  margin-bottom: 16px;
  font-size: $font-size-normal;

  span.bulkscan-error-message-text {
    font-size: small;
    color: $red;
  }
}
