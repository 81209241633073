@import 'src/index';

.pricing-wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center !important;
  align-items: center;
  margin: 0 !important;
  height: 100%;
  .plans-slider-wrap {
    height: auto !important;
  }
  .plans-slider-wrap {
    height: 50px;
    .price-wrap {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
    .plans-slider {
      width: 100%;
      background-color: $theme-plan-professional-text-color;
    }
    .plans-range {
      accent-color: $theme-plan-professional-text-color !important;
      width: 100%;
      position: relative;
      top: 24px;
    }
    .plan-slide-ridge-wrap {
      display: flex;
      justify-content: space-between;
    }
  }
  .plan-details {
    padding: 1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    color: $theme-text-color;
  }
  .subtext {
    color: $theme-pricing-subtext-light;
  }
}

.plans-range::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px transparent;
  position: relative;
  top: 1px;
}
