@import 'src/index';

.feedback-page {
  .feedback-page-content {
    margin: $padding-medium $padding-medium-web;
    padding: $padding-medium;

    > .col-lg-6 {
      padding-left: 0;
      padding-right: 0;
    }

    .form-container {
      padding: 0 $padding-medium-web;
    }

    @media only screen and (max-width: $screen-size-lg) {
      .form-container {
        margin-top: $padding-medium;
      }
    }
  }
  .feedback-title {
    text-align: center;
    padding-bottom: $padding-large;
  }

  .padding-bottom {
    padding-bottom: $padding-xlarge;
  }
}
