@import 'src/index';

.automation-landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  margin-top: 100px;
  color: $theme-text-color;

  .landing-page-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .landing-page-tips {
    width: 490px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
  }
}
