@import 'src/index';

.acquisitions-page {
  .table-page {
    border: $theme-border;
    margin: $padding-medium $padding-medium 0;
    width: calc(100% - 2 * #{$padding-medium}) !important;
    background-color: $theme-widget-bg-color;

    .table-component {
      padding-bottom: $padding-medium !important;
    }
    .no-bottom-padding {
      padding-bottom: 0 !important;
    }
  }

  .recommended-domains-section {
    padding: $padding-medium;
    margin: $padding-medium $padding-medium 0;
    width: calc(100% - 2 * #{$padding-medium}) !important;
    background-color: $theme-widget-bg-color;
    border: $theme-border;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: $theme-text-color;

    .title {
      font-weight: 600;
      font-size: $font-size-large;

      img {
        width: 14px;
        margin-left: $padding-normal;
      }
    }

    .description {
      padding-top: $padding-small;
      font-size: $font-size-normal;
      color: $theme-secondary-text-color;
    }

    .left {
      flex: 1;
      padding-right: $padding-xxlarge;
      display: flex;
      flex-direction: column;

      .spinner-container {
        flex: 1;
      }
      .sliders {
        display: flex;
        flex-direction: column;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .credit {
        font-size: $font-size-medium;
        padding-bottom: $padding-medium;
      }
      .form-label,
      .form-control {
        font-size: $font-size-medium;
        font-weight: 400;
        color: $theme-text-color;
      }
      .form-label {
        color: $theme-text-color;
      }
      .form-control {
        background-color: $theme-background-color;
        border: $theme-border;
      }
      .buttons {
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        .reset-filter-wrapper {
          margin-right: $padding-medium;
          align-self: center;
        }
        .btn-outline {
          color: $theme-hollow-btn-border;
          border: 1px solid $theme-hollow-btn-border;
          font-size: $font-size-medium;
          font-weight: 600;
        }
      }
    }
  }

  .add-credit-link {
    margin-left: $padding-normal;
    font-size: $font-size-medium;
    font-weight: 500;
    color: $theme-hollow-btn-border !important;
  }
}

.acquisitions-page.no-padding {
  .table-page {
    .table-component {
      padding-bottom: 0 !important;
    }
  }
}
