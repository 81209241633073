@import 'src/index';

.home-notify-modal {
  max-width: 800px;
  & ul {
    padding-top: 0.5rem;
  }
  & li {
    padding-left: 5px;
  }
  & li::marker {
    content: '\2713';
  }
}

.typosquat-container {
  padding: 5vw;
  .status-bar {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    h5 {
      margin: 0 auto;
    }
  }
  .monitored-domain-helper-text {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2rem;
  }

  .results {
    width: 100%;
    text-align: left;
  }
}
.progress-bar-wrap {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 0;

  .progress-bar-container {
    width: 100%;
    text-align: center;
  }

  .custom-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0 0.5rem;

    button {
      width: max-content;
    }
    .export-btn {
      background-color: $theme-dropdown-button-color;
      border-color: $theme-dropdown-button-color;
      color: black;
    }
  }
}

.home-page {
  .bg-light-gray {
    background-color: #f3f5f7;
  }
  .scanner-section {
    h1 {
      font-size: 2.1rem;
    }
    .scan-box-container {
      @media only screen and (min-width: $screen-size-lg) {
        padding-top: 3rem;
        padding-bottom: 10rem;
      }
    }
  }
  .toast-header {
    a {
      color: #fff;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .recent-scans-section {
    text-align: center;

    .recent-scans-description {
      color: $platinum;
      font-size: $font-size-xlarge;

      div:first-child {
        border-right: 2px solid $platinum;

        @media only screen and (max-width: $screen-size-md) {
          border-right: none;
        }
      }
    }
    .checkphish-plugin-text-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: $padding-small;
      .checkphish-plugin-text {
        font-size: $font-size-large;
      }
    }
    .checkphish-plugin-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: $padding-small;
      .checkphish-plugin-description {
        width: 50%;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding-left: $padding-medium;
      }
    }
    .checkphish-plugin-try-it-out-wrapper {
      width: 50%;
      justify-content: center;
      align-items: center;
      .try-it-out-wrapper {
        border-color: #4479f3;
        background-color: #4479f3;
      }
      .try-it-out-wrapper:hover {
        color: #4479f3 !important;
        border-color: #4479f3;
        background-color: white;
      }
    }
    .recent-scans-table {
      border: $border;

      .table-component {
        .loading-wrapper-component {
          min-height: 500px;
        }

        @media only screen and (min-width: $screen-size-lg) {
          width: 800px;
        }

        .table-source-url-column {
          text-align: left;
          word-break: break-word;
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;

          //@media only screen and (max-width: $screen-size-sm) {
          //  max-width: 9rem !important;
          //}
          //
          //@media only screen and (min-width: $screen-size-sm) {
          //  max-width: 18rem !important;
          //}
          //
          //@media only screen and (min-width: $screen-size-lg) {
          //  max-width: 30rem !important;
          //}
          //
          //@media only screen and (min-width: $screen-size-xlg) {
          //  max-width: 45rem !important;
          //}
        }
      }
    }
  }

  .deep-learning-section {
    text-align: center;

    .deep-learning-description {
      font-size: $font-size-large;
      color: $dark-gray-7;
      line-height: 150%;
    }

    .deep-learning-stats-component {
      flex: 1;
    }

    .deep-learning-stats-component + .deep-learning-stats-component {
      @media only screen and (min-width: $screen-size-md) {
        border-left: $border5;
      }
      @media only screen and (max-width: $screen-size-md) {
        border-top: $border5;
      }
    }
  }

  .bring-to-you-section {
    h2 {
      text-align: center;
    }
  }
}

.feature-item-component {
  .feature-item-title {
    color: $dark-gray-7;
    font-size: $font-size-xlarge;
    line-height: $font-size-xxxlarge;
    font-weight: 500;
  }

  .feature-item-description {
    color: $dark-gray-7;
    font-size: $font-size-large;
    line-height: 150%;
  }
}

.feature-item-component + .feature-item-component {
  @media only screen and (max-width: $screen-size-md) {
    padding-top: 3rem;
  }
}
