@import 'src/index';

.dropdown-multi-select-component.box-style {
  .dropdown-toggle,
  .dropdown-toggle:active,
  .dropdown-toggle:hover:not(:disabled) {
    border: $theme-border !important;
    background-color: $theme-widget-bg-color !important;
  }
}

.dropdown-multi-select-component.box-style {
  .dropdown,
  .dropdown-menu {
    background-color: $theme-form-control-color;
  }
}

.dropdown-multi-select-component {
  .dropdown-toggle,
  .dropdown-toggle:active,
  .dropdown-toggle:hover:not(:disabled) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent !important;
    color: $dark-gray-3 !important;
    font-weight: 400;
    font-size: $font-size-medium;
    border-width: 0 0 1px;
    border-bottom-color: $light-gray-4;
    box-shadow: none !important;
  }

  .limit-text {
    color: #a1a1a1;
    font-size: $font-size-medium;
    font-style: normal;
    font-weight: 400;
  }

  .options-container {
    max-height: 35vh;
    overflow-y: scroll;
    margin-bottom: $padding-small;

    .option-group-label {
      color: $theme-secondary-text-color-light;
    }

    .dropdown-item {
      height: 32px;
      font-size: $font-size-medium;
      color: $theme-text-color;
      padding: 0 $padding-medium-half;

      .check-box-container {
        height: 16px;
        margin-bottom: 0;

        &.disabled {
          .checkmark-style {
            background: url(../../assets/icons/DisabledCheckbox.svg) !important;
            background-size: cover !important;
          }
        }
      }

      .dropdown-item-label {
      }

      .dropdown-item-default {
        color: $theme-secondary-text-color-light;
      }
    }

    .dropdown-item:active,
    .dropdown-item:hover {
      background-color: $theme-dropdown-hover-bg-color;
    }
  }

  .dropdown-footer-bottoms {
    padding: $padding-normal $padding-medium;
    border-top: $theme-border;

    .select-buttons {
      color: $theme-hollow-btn-border;

      div:first-child {
        border-right: $theme-border;
      }
    }

    .update-button-container {
      padding: 0;
      width: max-content;
    }

    .update-button-container:active,
    .update-button-container:hover {
      background-color: $white;
    }
  }
}
