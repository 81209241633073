@import 'src/index';

.label-value-wrap.border-left {
  margin-left: 15px;
  border-left: 1px solid #dde7ee !important;
  padding-left: 15px;
  .dark & {
    border-left: 1px solid #4d535a !important;
  }
}
.label-value-wrap {
  font-size: 14px;
  display: flex;
  align-items: center;
  .label {
    color: #7e868e;
    margin-right: 5px;

    .dark & {
      color: #c6d3e0;
    }
  }

  .value {
  }

  &.label-text-color {
    .label {
      color: #7e868e;
      .dark & {
        color: #c6d3e0;
      }
    }
  }
}
