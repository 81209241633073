@import 'src/index';
@import 'src/Theme';

.takedown-time-analytics-no-data-container {
  .takedown-time-analytics-no-data-text {
    font-size: 12px;
    color: #555e68;
    color: $card-descriptipn-text-color;
  }
  .takedown-time-analytics-no-data-bar-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;

    .takedown-time-analytics-no-data-bar-row {
      display: flex;
      align-items: center;
      margin: 5px 0;
    }

    .takedown-time-analytics-no-data-bar-value {
      color: var(--theme-page-text);
    }

    .takedown-time-analytics-no-data-bar {
      flex-grow: 1;
      margin-left: 10px;
      border: 1px solid var(--abuseMailBox-takedownTimeAnalytics-bar);

      &.bar-0 {
        border: 1px solid var(--abuseMailBox-takedownTimeAnalytics-bar-0);
      }
    }
  }
}
