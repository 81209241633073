@import 'src/index';

.request-purchase-modal {
  .credits-info {
    margin: 0;

    .credit {
      border-left: 2px solid $theme-text-color;
    }
    .credit.border-blue {
      border-color: $theme-hollow-btn-border;
    }
  }
}
