@import 'src/index';

.abuse-mailBox-emailDetails-main-container {
  display: flex;
  height: auto;

  .emails-list-view-container {
    margin-top: 20px;
    background-color: $theme-widget-bg-color;
    width: 250px;
    margin-left: 20px;
    border: var(--theme-border);
    margin-block-end: 30px;

    .emails-list-view-count-conatiner {
      border-bottom: var(--theme-border);
    }
    .emails-list-view-count {
      padding: 20px;
      color: var(--theme-page-text);
    }

    .emails-list-view-panel {
      list-style-type: none;
      margin: 0;
      padding: 10px;
      overflow: hidden;

      .emails-list-view-panel-content {
        position: relative;
        height: 79vh;
        overflow-y: auto;
        scrollbar-color: $theme-column-title-color white;
      }
    }

    .emails-list-view-item {
      height: 132px;
      padding: 10px;
      display: grid;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 10px;
      border: 1px solid var(--gray-300, #c3cbcf);
      border-radius: 5px;
    }

    .emails-list-view-item.selected {
      border: 6px solid #9fc8fe;
    }
  }
}

.abuse-mailBox-threatType-details-container {
  .abuse-mailBox-emailDetails-main-container {
    .emails-list-view-container {
      margin-top: 0px;
      height: 85vh;
      overflow-y: scroll;
    }
  }
}
