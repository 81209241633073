.stepper-wrap {
  .stepper {
    width: 100%;
    #RFS-ConnectorContainer {
      top: calc((2em - 3px) / 2) !important;
    }
    .step {
      background-color: transparent !important;
      border-color: #4479f3;
      button {
        span {
          position: absolute;
          background-color: white !important;
        }
      }
    }
    .active img {
      animation: rotation 2s infinite linear;
    }
  }
  .txt-msg {
    font-size: 14px;
    text-align: center;
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
