@import 'src/index';

.global-search-sub-menu-tab-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: $theme-border;
  height: 40px;
  margin-bottom: $padding-medium-half;
  .global-search-sub-menu-tab-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    .tab-wrapper {
      width: auto;
      height: inherit;
      display: flex;
      align-items: center;
      margin-left: 32px;
      margin-right: 32px;
      .tab-label {
        color: $theme-secondary-text-color;
        &.active {
          color: $theme-hollow-btn-border;
        }
      }
      &.active {
        color: $theme-hollow-btn-border;
        border-bottom: 2px solid $theme-hollow-btn-border;
      }
    }
    .tab-wrapper:hover {
      cursor: pointer;
      color: $theme-active-hover-bg-color;
      border-bottom: 2px solid $theme-hollow-btn-border;
    }
  }
}
