@import 'src/index';

.hubspot-contact-form-wrapper {
  // min-height: calc(100vh - 96px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-form {
  margin-top: 1rem;
}

.hubspot-contact-form {
  min-width: 400px;
  width: 100%;
  padding: 25px;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  background-color: #eeeeee;
  color: black;
}

.contact-us-wrap {
  display: flex;
  align-items: flex-end;
  .pack-wrap {
    position: relative;
    .most-popular-tag {
      border: 1px solid 6fd89a;
      color: #025550 !important;
      width: fit-content;
      padding: 3px 5px;
      border-radius: 10px;
      color: black;
      background-color: #6fd89a;
      font-weight: bold;
      margin: 0 auto;
      position: absolute;
      top: -10px;
      width: 100px;
      text-align: center;
      left: 100px;
    }
    width: 320px;
    min-height: 600px !important;
    border: 1.5px solid $theme-upgrade-subtext-border;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 1px 1px 10px gray;
    min-height: 550px;
    .pack-container {
      margin: 1.3rem auto;
    }
    .header-wrap {
      text-align: center;
      color: $theme-text-color;
      .sub-head {
        color: $theme-upgrade-subtext-color;
      }
    }
    .contact-us-btn-wrap,
    .btn-wrap {
      text-align: center;
      button {
        width: 100%;
      }
      .transparent-btn {
        background-color: transparent;
        color: $theme-upgrade-button-border;
        border-color: $theme-upgrade-button-border;
        &:hover {
          background-color: $theme-a-tag-color;
          color: #ffffff !important;
          border-color: $theme-upgrade-button-bg;
        }
      }
    }
    .bold-text {
      color: #b3b3b3;
      font-weight: bold;
      text-align: center;
    }
    .feature-wrap {
      .features {
        .feature-head {
          font-weight: bold;
          font-size: 14px;
          margin: 5px 0;
        }
        .features-list {
          ul {
            padding: 0;
            li {
              align-items: flex-start;
              margin: 5px 0;
              list-style: none;
              display: flex;
              justify-content: flex-start;
              img {
                margin-right: 0.3rem;
              }
            }
          }
        }
      }
    }
  }
}

.starter-wrap {
  background-color: $theme-starter-pack-bg;
  border: 1.5px solid $theme-starter-contact-border !important;
}
.dark {
  .pack-wrap {
    box-shadow: none;
  }
}

.diabled-btn {
  background-color: $disabled-btn-bg !important;
  border-color: $disabled-btn-bg !important;
  color: #999999 !important;
}

@media only screen and (max-width: 1100px) {
  .pack-wrap {
    width: 25vw !important;
  }
}
