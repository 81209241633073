@import 'src/index';

.takedown-history-section {
  .takedown-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .takedown-label {
      color: $theme-secondary-text-color;
      margin-right: 4px;
      font-size: $font-size-medium;
    }
    .takedown-status {
      font-size: $font-size-medium;
      color: $dark-gray-3;
      font-weight: 400;
    }
  }
  .attached-file-viewer-container {
    width: 100%;
    .list-group {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px 0px;
      .list-group-item {
        margin-top: 0;
        width: 49%;
        .attached-file-name {
          max-width: 150px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
