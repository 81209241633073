@import 'src/index';
@import 'src/Theme';

.abuse-mailbox-dashboard-no-data-container {
  height: 140px;
  display: flex;
  background: var(--abuseMailBox-dashboard-noDataView-background);
  border: 1px solid var(--abuseMailBox-dashboard-noDataView-border);
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 30px;
  align-items: center;

  .abuse-mailbox-dashboard-no-data-text-container {
    padding-left: 30px;

    .abuse-mailbox-dashboard-no-data-main-text {
      font-size: 22px;
      font-weight: 600;
      color: var(--theme-page-text);
    }
    .abuse-mailbox-dashboard-no-data-sub-text {
      font-size: 16px;
      font-weight: 400;
      color: $card-descriptipn-text-color;
      .abuse-mailbox-dashboard-no-data-sub-text-example {
        font-weight: 600;
        font-size: 16px;
        color: var(--theme-page-text);
      }
    }
  }
}
