@import 'src/index';

.global-search-container {
  display: flex;
  align-items: center;
  justify-content: right;
  height: auto;
  margin-right: $padding-medium;
  transition: width 0.3s ease-in-out;
  .global-search-paper {
    border: $theme-border-3;
    display: flex;
    transition: all 0.3s ease-in-out;
  }
  .global-search-input {
    margin: 0;
  }
}

.global-search-no-data-container {
  display: flex;
  flex-direction: column;
  color: $theme-text-color;
  padding: $padding-normal;
  .global-search-no-data-title {
    display: flex;
    align-items: center;
    font-size: $font-size-large;
    font-weight: 500;
    margin-bottom: $padding-normal;
  }
  .global-search-no-data-description {
    display: flex;
    align-items: center;
    font-size: $font-size-medium;
    font-weight: 400;
  }
}

.MuiButtonBase-root.Advanced {
  border-top: var(--theme-border);
}
