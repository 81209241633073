@import 'src/index';

.brand-property-container {
  .table-container {
    .table-component {
      .table-source-url-column {
        min-width: 0;
        max-width: 20vw;
      }
    }
    .document-link {
      color: $theme-hollow-btn-border;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        opacity: 0.8;
      }
    }
  }
}

.delete-property-container {
  display: flex;
  flex-direction: column;
  .deleting-header-text {
    font-weight: 400;
    font-size: $font-size-normal;
    line-height: 21px;
    color: $theme-text-color;
    margin-bottom: $padding-normal;
    span {
      font-weight: 600;
    }
  }
  .delete-value-wrapper {
    display: flex;
    flex-direction: row;
    word-break: break-all;
    .deleting-item {
      font-weight: 600;
      font-size: $font-size-medium;
      line-height: 21px;
      color: $theme-hollow-btn-border;
      margin-bottom: $padding-normal;
    }
  }

  .confirmation-text {
    font-weight: 400;
    font-size: $font-size-normal;
    line-height: 21px;
    color: $theme-text-color;
    margin-bottom: $padding-normal;
  }
}
