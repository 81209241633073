@import 'src/index';

.admin-page {
  padding: $padding-medium;
  color: $theme-text-color;
  .top-container {
    align-items: center;
    margin-top: $padding-medium;
  }

  .section-container {
    width: 100%;
    border-bottom: $theme-border-2;
    padding-bottom: $padding-medium;
    margin-bottom: $padding-medium;
    margin-top: $padding-medium;
    margin-left: 0;
    margin-right: 0;

    .type-head-container {
      input::placeholder {
        color: $theme-text-color;
        opacity: 0.7;
      }
      .dropdown-menu {
        background-color: $theme-form-control-color;
        .dropdown-item {
          color: $theme-text-color;
          .rbt-highlight-text {
            background-color: $theme-hover-bg-color;
            color: $theme-text-color;
          }
        }
        .dropdown-item:hover {
          color: $theme-text-color;
          background-color: $theme-dropdown-hover-bg-color;
        }
      }
    }
  }

  .section-title {
    font-weight: 500;
    font-size: $font-size-large;
    line-height: $font-size-xlarge;
  }

  .section-row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .dropdown-component.box-style > div > button {
    border: $theme-border-2;
  }

  .px-start--0 {
    padding-left: 0;
  }

  .px-end--0 {
    padding-right: 0;
  }
}
