.overall-email-submission-container {
  .overall-email-submission-roi-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 24px;
    position: relative;
    z-index: 99;
    .overall-email-submission-roi-value {
      margin-right: 4px;
      border: 1px solid #aae2a1;
      border-radius: 2px;
      background-color: #e8fcdc;
      color: #35c759;
      font-size: 14px;
      font-weight: 500;
      padding: 0 4px;
    }
    .overall-email-submission-roi-tooltip-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.overall-email-submission-roi-popover {
  padding: 16px;
  .overall-email-submission-roi-popover-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .overall-email-submission-roi-popover-item-title {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .overall-email-submission-roi-popover-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    font-size: 12px;
    .overall-email-submission-roi-popover-item-label {
      min-width: 72px;
      color: var(--am-all-email-sub-subheader-text);
    }
    .overall-email-submission-roi-popover-item-input {
      .MuiInputBase-input {
        width: 60px;
        height: 20px;
        padding: 2px 8px;
      }
    }
  }
  .overall-email-submission-roi-popover-update-button-container {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
}
