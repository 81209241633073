@import 'src/index';
.search-term-page {
  .add-search-term-section {
    padding: $padding-medium $padding-medium 0;
    font-size: $font-size-medium;
    color: $theme-text-color;

    .add-search-term-form {
      background-color: $theme-widget-bg-color;
      width: 100%;
      border: $theme-border;
      margin-top: $padding-normal;
      padding: $padding-normal $padding-medium $padding-medium $padding-medium;
      font-size: $font-size-medium;
      font-weight: 400;
      color: $theme-modal-overlay-close-btn;
      .form-fields {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .form-block {
          padding: $padding-small $padding-small $padding-small 0;
        }

        .dropdown-multi-select-component {
          width: $dropdown-width;

          .options-container {
            max-height: unset;
            .dropdown-item {
              color: $theme-text-color;
            }
            .dropdown-item:hover {
              background-color: $theme-dropdown-hover-bg-color;
            }
          }
        }

        button.dropdown-toggle {
          width: $dropdown-width;
          border-bottom: $border;
          color: $theme-text-color !important;
          padding-left: 0;
          padding-right: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .dropdown-menu {
          width: $dropdown-width;
          background-color: $theme-form-control-color;
          color: $theme-text-color;
        }

        .check-box-container {
          margin-bottom: 0;
        }
      }

      .form-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn {
          margin-left: $padding-medium;
        }
      }
    }
  }

  .searches-table-container {
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    padding: $padding-medium;

    .table-component {
      .tool-bar-container {
        padding-left: 0;
        padding-right: 0;
      }

      .table-title {
        font-size: $font-size-large;
        font-weight: 600;
      }
    }
  }
}
.platform-column-logo {
  height: 24px;
  padding-top: $padding-xxxsmall;
  padding-bottom: $padding-xxxsmall;
  padding-right: $padding-xsmall;
}

.dashboard-tile-component .action-col-render-component {
  flex-wrap: wrap;
}
.action-col-render-component {
  .action-text-container {
    padding-left: $padding-xsmall;
  }
  .action-text-container + .action-text-container {
    margin-left: $padding-xsmall;
    border-left: $border2;
  }

  .action-text {
    color: $theme-hollow-btn-border;
    cursor: pointer;
    min-width: max-content;
  }
}

.logo-image-render-component {
  .col-value {
    padding: $padding-xxxsmall $padding-xsmall;
    display: flex;
  }
  .blue-background {
    background-color: $button-active;
  }
  .icon {
    padding-left: $padding-small;
  }
}
.tooltip div {
  background-color: $theme-form-control-color;
}
.logo-image-col-tooltip {
  background-color: $theme-form-control-color;
  box-shadow: $theme-dropdown-box-shadow;

  .tooltip-inner {
    padding: 0 $padding-normal;
    background-color: $theme-form-control-color;

    .attached-file-viewer-container {
      .list-group {
        background-color: $theme-form-control-color;
      }

      .list-group-item:first-child {
        margin-top: 0;
      }

      .list-group-item {
        border-width: 0;
        border-left-width: 0 !important;
        padding: 0;
        background-color: $theme-form-control-color;

        img {
          display: none;
        }
        .attached-file-name {
          color: $theme-hollow-btn-border;
          font-size: $font-size-medium;
        }
      }
    }
  }
}

.detection-action-modal {
  .url-list {
    max-height: 200px;
    overflow-y: scroll;
  }
  .upload-files-container {
    margin-top: $padding-normal;
  }
}
.description-danger {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--theme-warning-color);
}

.add-finding-for-takedown-modal,
.soc-add-finding-modal,
.soc-edit-finding-modal {
  form {
    .form-group {
      .text-input-component,
      .dropdown-multi-select-component,
      .dropdown-component {
        .btn {
          width: 100%;
        }
      }
    }
  }
}
.add-finding-for-takedown-modal,
.soc-add-finding-modal,
.soc-edit-finding-modal {
  form {
    .form-group {
      .text-input-component {
        background-color: $theme-widget-bg-color;
      }
    }
  }
}

.takedown-finding-modal,
.delete-finding-modal,
.approve-finding-modal {
  font-size: $font-size-medium;

  > div + div {
    padding-top: $padding-medium;
  }
  .high-lighted-text {
    color: $theme-hollow-btn-border;
    text-decoration: underline;
    overflow-wrap: break-word;
  }
  .high-lighted-text + .high-lighted-text {
    padding-top: $padding-small;
  }
}

.finding-header-button {
  padding-left: $padding-normal;
  color: $theme-hollow-btn-border;
  cursor: pointer;
  margin-left: $padding-xxlarge;
}

.finding-header-button + .finding-header-button {
  border-left: $theme-border;
  margin-left: $padding-normal;
}

.finding-insight-images-section {
  .tabs {
    padding: $padding-normal 0;
    .tab + .tab {
      margin-left: $padding-xlarge;
    }
    .tab {
      font-size: $font-size-large;
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }
    .tab-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .tab-selected {
      color: $theme-hollow-btn-border;
      font-weight: 600;
      border-bottom-color: $theme-hollow-btn-border;
    }
  }
}
