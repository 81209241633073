@import 'src/index';

//whats gone table
.active_typosquats,
.new_typosquat {
  background-color: $delta-new-typosquat;
}
.ns_not_found {
  background-color: $delta-ns-not-found;
}
.mx_not_found {
  background-color: $delta-mx-not-found;
}
.ip_not_found {
  background-color: $delta-a-record-not-found;
}
.no-change {
  background-color: black;
}

.mx_record_change {
  background-color: $delta-mx-found;
}
.new_suspicious,
.new_scam,
.new_phish {
  background-color: $delta-disposition;
}
.ns_changes {
  background-color: $delta-ns-found;
}
.ip_changes {
  background-color: $delta-record-found;
}
