@import 'src/index';

.social-media-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .intro-text-wrapper {
    max-width: 824px;
    display: flex;
    flex-direction: column;
    margin-top: 106px;
    .social-media-protection-header {
      font-weight: 600;
      font-size: $font-size-xxlarge;
      line-height: 32px;
      text-align: center;
      color: $theme-text-color;
      padding-bottom: $padding-small;
    }
    .social-media-protection-description {
      font-size: $font-size-medium;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $theme-text-color;
      padding-bottom: $padding-medium;
    }
  }
  .try-out-button-wrapper {
    padding-bottom: $padding-medium;
    display: flex;
    flex-direction: row;
    .trial-confirmation-text {
      font-size: $font-size-medium;
      line-height: 21px;
      text-align: center;
      color: $theme-text-color;
    }
  }
  .social-media-protection-image-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    .social-media-protection-image {
      display: flex;
      width: 824px;
    }
  }
}
