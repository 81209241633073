@import 'src/index';

.monitored-domains-container {
  display: flex;
  width: calc(100% - 2 * #{$padding-medium});
  min-height: max-content;
  background-color: $theme-widget-bg-color;
  border: $theme-widget-border-color;
  margin: 0px $padding-medium 0px $padding-medium;
  flex-direction: column;

  .monitored-domains-header {
    width: 100%;
    padding: $padding-medium $padding-medium 0px $padding-medium;

    .monitored-domains-title {
      width: 100%;
      height: 32px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1 auto;

      .title-container {
        height: 24px;
        display: flex;
        flex-direction: row;
        width: 50%;
        margin: auto 0;
        align-items: center;

        .title-wrapper {
          width: 100%;
          margin-left: 10px;
          font-weight: 500;
          font-size: $font-size-large;
          line-height: 19px;
          color: $theme-text-color;
        }

        .icon {
          .svg-icon-wrapper:hover {
            path {
              stroke: var(--icon-color);
            }
          }
        }
      }

      .filter-container {
        font-size: $font-size-medium;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $theme-text-color;

        .btn {
          background-color: $theme-filter-button-bg;
          color: $theme-text-color;
          border: 0;
          font-size: $font-size-medium;
          margin-left: 8px;
          padding: $padding-xsmall $padding-small;

          &:hover {
            background-color: $theme-hover-bg-color;
          }

          &.active {
            background-color: $theme-hover-bg-color;
          }
        }
      }
    }

    .monitored-domains-actions {
      display: flex;
      width: 100%;
      height: 22px;
      flex-direction: row;
      margin-top: 18px;

      .action-circle {
        width: 13px;
        height: 13px;
        background: $theme-monitored-header-bg;
        border-radius: 50%;
        margin: auto 0;
      }

      .action-label {
        margin-left: 8px;
        font-size: $font-size-normal;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $theme-secondary-text-color;
      }
    }
  }

  .monitored-domains-funnel-container {
    margin: 0 $padding-medium;
    overflow: scroll hidden;
  }

  .monitored-domains-funnel-wrapper {
    width: 100%;
    height: auto;
    padding: $padding-small 0 $padding-medium;
    display: grid;
    grid-template-columns: 1fr 7px 3fr 7px 1fr 7px 1fr;
    grid-column-gap: 1px;
    min-width: 1330px;

    .acquisition-wrapper {
      display: grid;
      position: relative;
      perspective: 100vw;
      height: inherit;

      .monitored-domains-label-wrapper {
        width: 100%;
        height: 32px;
        background: $theme-monitored-header-bg;
        margin: 0;
      }

      .monitored-pre-label-wrapper {
        width: 100%;
        height: 32px;
        background: $theme-monitored-header-bg;
        margin: 0 auto;
      }

      .monitored-takedown-label-wrapper {
        width: 100%;
        height: 32px;
        background: $theme-monitored-header-bg;
        margin: 0 auto;
      }

      .monitored-post-label-wrapper {
        width: 100%;
        height: 32px;
        background: $theme-monitored-header-bg;
      }

      .monitored-domains-label {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white;
        font-weight: 600;
        font-size: $font-size-medium;
        line-height: 21px;
        min-width: max-content;
      }

      .monitored-domains-label:hover {
        background: $theme-hover-bg-color;
        color: $theme-hover-text-color;
        cursor: pointer;

        .acq-chevron-right {
          visibility: unset;
          display: flex;
          width: 24px;
          height: auto;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .acq-chevron-right {
        display: flex;
        width: 24px;
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .acq-chevron-right.hidden {
        visibility: hidden;
      }

      .funnel-wrapper {
        width: 100%;
        height: 220px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 20px 0px;
        margin: auto 0;
        position: relative;

        .funnel-content {
          display: flex;
          width: 100%;
          height: inherit;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-shadow: 0px 1px 2px rgba(7, 7, 7, 0.25);
          color: $white;
          font-style: normal;
          font-weight: normal;

          .funnel-label {
            font-weight: 600;
            font-size: $font-size-normal;
            line-height: 16px;
            margin-bottom: 12px;
            text-transform: uppercase;
          }

          .funnel-value {
            width: 100%;
            font-weight: 600;
            font-size: $font-size-xxxlarge;
            line-height: 42px;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }

          .funnel-info {
            font-size: $font-size-normal;
            line-height: 16px;
            transform: rotateZ(1deg);
          }
        }
      }

      .hover-wrapper:hover {
        opacity: 0.8;
        cursor: pointer;
        font-weight: 900;

        .acq-chevron-right {
          visibility: unset;
          display: flex;
          width: 24px;
          height: auto;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .red-dashed-line {
      width: 1px;
      border-left: 1px dashed $red;
      margin: 0 auto;
      height: 100%;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        z-index: 1;
        left: -8px;
      }
    }

    .gray-vertical-dashed-line {
      width: 0;
      border-left: 1px dashed $dark-gray-3;
      margin: 0 auto;
    }

    .parked-domains-funnel {
      margin-left: 1px;
    }

    .bottom-labels-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      .labels {
        font-size: $font-size-normal;
        line-height: 16px;
        color: $theme-modal-overlay-close-btn;
        margin: 0px $padding-small;
      }

      .labels:hover {
        cursor: default;
      }

      .labels + img {
        margin-left: $padding-normal;
      }

      .recommended-label {
        width: max-content;
      }

      .svg-icon-wrapper:hover {
        cursor: default;

        path {
          stroke: var(--icon-color);
        }
      }
    }

    .gray-horizontal-dashed-line {
      flex: 1;
      height: 0;
      border-top: $theme-dashed-border;
      margin: 0px 10px;
    }
  }
}

.monitor-domains-icon-tooltip {
  background: $theme-tooltip-bg-color;
  color: $theme-text-color;

  .tooltip-inner {
    max-width: 375px;
    height: 80px;
    text-align: left;
    padding: $padding-small;
    background: $theme-tooltip-bg-color;
    color: $theme-text-color;
    font-size: $font-size-normal;
  }
}

.monitor-icon-tooltip {
  background: $theme-tooltip-bg-color;

  .tooltip-inner {
    max-width: 175px;
    height: 65px;
    text-align: left;
    padding: $padding-small;
    background: $theme-tooltip-bg-color;
    color: $theme-text-color;
    font-size: $font-size-normal;
  }
}

.monitor-icon-disposition {
  background: $theme-tooltip-bg-color;

  .tooltip-inner {
    width: auto;
    height: auto;
    text-align: center;
    padding: $padding-small;
    background: $theme-tooltip-bg-color;
    color: $theme-text-color;
    font-size: $font-size-normal;
  }
}

// hide scrollbar on large screen
@media (min-width: 1500px) {
  .acquisition-wrapper.large-screen-padding {
    padding-right: 25px;
  }

  .monitored-domains-funnel-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .monitored-domains-funnel-container {
    margin: 0 $padding-medium;
    overflow: scroll hidden;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    width: 100%;
  }
}

@media (max-width: 1500px) {
  .acquisition-wrapper {
    width: 100% !important;

    #funnel-container {
      width: 441px !important;
      transform: perspective(44.2px) rotateY(1deg) translate(4.6px) !important;
    }
  }

  .acquisition-wrapper:last-child {
    #funnel-container {
      transform: rotateY(0) !important;
    }
  }
}
