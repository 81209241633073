@import 'src/index';

.timeline-event-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  padding: 10px 6px;
  background-color: $theme-background-color;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(21, 21, 21, 0.08), 0px 2px 4px 0px rgba(21, 21, 21, 0.08);
  margin: 8px 0 12px 0;
  .timeline-event-label {
    font-size: $font-size-normal;
    font-weight: 500;
    color: $theme-text-color;
    text-align: left;
  }
  .timeline-event-time {
    font-size: 11px;
    font-weight: 500;
    color: $theme-secondary-text-color-light;
    text-align: left;
  }
}
.takedown-event {
  border-left-color: #20aa20;
  border-left-width: 3px;
  border-left-style: solid;
}
.hosting-provider-event {
  border-left-color: #ff6464;
  border-left-width: 3px;
  border-left-style: solid;
}
.registrar-event {
  border-left-color: #ffb904;
  border-left-width: 3px;
  border-left-style: solid;
}
.tld-event {
  border-left-color: #26c7c7;
  border-left-width: 3px;
  border-left-style: solid;
}
.registrant-event {
  border-left-color: #4791ff;
  border-left-width: 3px;
  border-left-style: solid;
}
.awating-document {
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: #e48af3;
}
