@import 'src/index';

.add-credit-modal {
  .add-credit-label {
    color: $theme-modal-overlay-close-btn;
  }
  .add-credit-description {
    font-size: $font-size-normal;
    line-height: 18px;
    color: $theme-text-color;
  }
  .dollar-sign-wrapper {
    border: none;
    border-radius: 0;
    color: $theme-text-color;
    background-color: $theme-widget-bg-color;
  }
}
