@import 'src/index';

.plan-chip {
  width: fit-content;
  width: fit-content;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 0.5rem;
  color: $theme-text-color;
  border: 1px solid $plan-chip-border;
  text-transform: capitalize;
}
