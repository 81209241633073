.abuse-mailBox-emailDetails-container {
  .email-category {
    height: 20px;
    margin-left: 8px;
  }
  .email-category-targeted-malicious {
    color: #ff5722;
    background: #fbe9e7;
  }
  .email-category-generic-malicious {
    color: #fa9926;
    background: #fff4e6;
  }
  .email-category-official-emails {
    color: #0a89ff;
    background: #f1fafd;
  }
  .email-category-other {
    color: #636b74;
    background: #f7f9fb;
  }
}
