// For global status colors, disposition status colors, etc.
// Import this file into the scss file where you want to use these colors.

$status-color-in-progress: #4ba6ee;
$status-color-taken-down: #5ab545;
$status-color-exhausted: #e47474;
$status-color-awaiting-documents: #ef6c00;
$status-color-not-applicable: #888888;
$status-color-request-takedown: #2168e4;
$status-color-active: #fc4949;
$status-color-cancelled: #888888;

.status_chip {
  height: 24px;
  padding: 7px;
  border-radius: 50px;
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;
}

.status_in_progress {
  border: 1px solid $status-color-in-progress;
  color: $status-color-in-progress;
}

.status_taken_down {
  border: 1px solid $status-color-taken-down;
  color: $status-color-taken-down;
}

.status_exhausted {
  border: 1px solid $status-color-exhausted;
  color: $status-color-exhausted;
}
.status_awaiting_documents {
  border: 1px solid $status-color-awaiting-documents;
  color: $status-color-awaiting-documents;
}
.status_not_applicable {
  border: 1px solid $status-color-not-applicable;
  color: $status-color-not-applicable;
}
.status_request_takedown {
  background: $status-color-request-takedown;
  color: white;
}

.status_active {
  border: 1px solid $status-color-active;
  color: $status-color-active;
}
.status_cancelled {
  border: 1px solid $status-color-cancelled;
  color: $status-color-cancelled;
}
