@import 'src/index';

.insight-spinner-container {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $theme-background-color;
  margin-top: $padding-medium;
  .spinner-border {
    width: 72px;
    height: 72px;
    color: $platinum;
  }
}

.map-container {
  width: 100%;
  min-height: 400px;
  position: relative;

  .geo-footer-hover {
    position: absolute;
    z-index: 500;
    height: $top-nav-bar-height;
    bottom: 0;
    width: 100%;
    background: $dark-gray-5;
    opacity: 0.75;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $padding-small $padding-medium;

    p {
      margin-bottom: 0;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-medium;
      line-height: $font-size-xlarge;
      color: $white;
    }
  }

  .location-not-found {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 501;

    div {
      font-size: $font-size-xlarge;
      font-weight: 500;
      color: $dark-gray-3;
    }
  }
}

.page-content {
  &.ip-insights-content,
  &.insights-container {
    padding-top: $padding-medium;
  }
}

.insights-container {
  .ip-url-value-wrapper {
    color: $theme-hollow-btn-border;
  }
  .card-container {
    padding-bottom: $padding-medium;
  }

  .card-between {
    margin-bottom: $padding-medium;
    .tags-column-container {
      padding: $padding-medium;
      background-color: $theme-widget-bg-color;
      .tags-column-wrapper {
        .pills-label-wrapper {
          flex-basis: auto;
        }
      }
    }
  }
  .card-next-to {
    margin-right: $padding-medium;
  }
  .self-card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .self-6 {
    padding: 0 $font-size-normal;
  }
  .dom-value {
    display: flex;
  }
  .img-fluid {
    img {
      padding: 0;
      object-fit: contain;
      background-color: $light-gray-2;
    }
  }
  .cert-list {
    font-weight: normal;
    font-size: $font-size-medium;
    margin-bottom: 0;
  }
  .abuse-reported {
    span {
      padding: 0;
    }
    .card-label {
      font-weight: 500;
      font-size: $font-size-medium;
      margin-bottom: 0;
    }

    .card-value {
      font-weight: normal;
      font-size: $font-size-medium;
      margin-bottom: 0;
    }

    .report-link {
      color: $theme-hollow-btn-border;
    }
    .report-link:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .country-icon {
    margin-right: 8px;
  }
  .abuse-text {
    margin-right: 8px;
  }
  .insight-threat-component {
    background: $theme-background-color;
    padding: 0 0 $padding-medium 0;
  }

  // fix height to 20px.
  .height-20 {
    height: 20px;
  }

  // fix height to 100%.
  .height-100p {
    height: 100% !important;
  }

  .images-slide-card .images-slide-component {
    padding-top: $padding-medium;
  }
}

.timeline-and-whois {
  width: 100%;
  justify-content: space-evenly;
}

@media only screen and (max-width: $screen-size-sm) {
  .timeline-and-whois {
    .whois-info-button {
      margin: 0;
    }
  }
}

.whois-info-button {
  display: flex;
  justify-content: center;
  border: $theme-border;
  box-sizing: border-box;
  font-size: $font-size-medium;
  font-weight: 500;
  margin: 0;
  height: 40px;
  align-items: center;
  color: $theme-text-color;
  background-color: $theme-dropdown-button-color;
}
.whois-info-button + .whois-info-button {
  margin-left: $padding-normal;
}
.whois-info-button.disabled {
  border: $theme-border;
  color: $dark-gray-2;
}
.whois-info-button:hover {
  background-color: $theme-button-hover-bg-color;
  color: $theme-text-color;
}

.threat-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .threat-component-item:last-child {
    border-right: none;
  }

  .threat-component-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-right: $theme-border-2;
    padding: 0 $padding-medium;
    margin-top: $padding-normal;

    .threat-component-label {
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-normal;
      line-height: $font-size-medium;
      margin-bottom: $padding-normal;
      color: $theme-secondary-text-color;
    }

    .threat-component-value {
      font-weight: 600;
      font-size: $font-size-xxlarge;
      line-height: $font-size-xxlarge;
      color: $theme-text-color;
    }
  }

  .threat-component:last-child {
    border-bottom: 0;
  }
}

@media only screen and (max-width: $screen-size-md) {
  .insights-container .insight-threat-component {
    padding-bottom: 0;
  }

  .threat-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .threat-component-item {
      flex: 1;
      border-top: $theme-border-2;
      border-right-width: 0;

      .item-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: $padding-normal 0;

        .threat-component-label {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-medium;
          line-height: $font-size-xlarge;
          margin-bottom: 0;
        }

        .threat-component-value {
          font-weight: 500;
          font-size: $font-size-xxlarge;
          line-height: $font-size-xxlarge;
          color: $dark-gray-3;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-size-md) {
  .card-between-mob {
    margin-bottom: $padding-medium;
  }
}

@media only screen and (max-width: $screen-size-md) {
  .whois-info-button {
    flex: 1;
    font-size: $font-size-medium;
  }
}

.insights-container {
  .table-long-text {
    max-width: 30vw;
    a {
      color: $theme-hollow-btn-border;
    }
  }

  @media only screen and (max-width: $screen-size-md) {
    .table-long-text {
      max-width: 80vw !important;
    }
  }

  @media only screen and (min-width: $screen-size-md) and (max-width: $screen-size-xlg) {
    .table-long-text {
      max-width: 18rem !important;
    }
  }
}

.page-content {
  padding: 0 $padding-medium-web;
  margin: 0;
  background-color: $theme-color;
}

.ip-insights-content {
  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .country-name {
    font-size: $font-size-medium;

    .flag-icon {
      margin-right: $padding-small;
    }
  }

  .images-slide-card .images-slide-component {
    padding-top: $padding-medium;
  }

  .table-long-text.long-text-ellipsis-1 {
    max-width: 30vw;
    @media only screen and (max-width: $screen-size-sm) {
      max-width: 90vw;
    }
  }
}

.risk-dom-container {
  padding-top: $padding-medium;
  padding-left: $padding-medium;
}

.dispute-dom-container {
  padding-top: $padding-medium;
  padding-left: $padding-medium;
  font-size: $font-size-medium;
  color: $theme-text-color;

  .dispute-icon-container.disabled {
    opacity: 0.5;
  }
  .dispute-icon-container {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $light-blue-0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $padding-normal;

    > img {
      margin-right: 0;
      cursor: default;
    }
  }

  .disposition-status {
    margin-right: $padding-normal;

    &.disabled {
      opacity: 0.5;
    }
  }

  .dispute-button {
    border-left: $theme-border;
    padding-left: $padding-normal;
    color: $theme-hollow-btn-border;
    cursor: pointer;

    &.disabled {
      color: $dark-gray-2;
      cursor: default;
    }
  }

  .dispute {
    position: relative;

    .dispute-disposition {
      padding-left: $padding-xsmall;
      font-weight: 500;
    }

    .dispute-submitted-text {
      padding: $padding-small $padding-normal;
      background-color: $theme-dropdown-button-color;
      font-size: $font-size-medium;
      cursor: pointer;
      color: $theme-text-color;

      img {
        margin-right: $padding-small;
        width: 14px;
      }

      .history-wrapper {
        display: flex;
        flex-direction: row;
        .icon {
          margin-right: $padding-xsmall;
        }
      }
      &.history {
        background-color: transparent;
        color: $dark-gray-3;
      }

      &.history:before {
        content: '';
        border-left: 1px solid $light-gray-3;
        display: block;
        position: absolute;
        height: 24px;
        left: -$padding-small;
      }

      &.open {
        // background-color: rgba(116, 197, 244, 0.3);
        background-color: $theme-dropdown-button-color;
        border: 0;
        padding: $padding-small $padding-normal;

        & + div {
          display: block;
        }
      }

      & + div {
        display: none;
        box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.24);
        min-width: 100%;
        padding: $padding-normal;
        position: absolute;
        z-index: 1000;
      }
    }
  }
}

@media only screen and (max-width: $screen-size-md) {
  .dispute-dom-container {
    flex-wrap: wrap;
    padding-top: $padding-normal;
    padding-left: $padding-medium;
  }
}

@media only screen and (max-width: $screen-size-sm) {
  .dispute-dom-container {
    padding-left: $padding-normal;
    .dispute-submitted-text {
      margin-top: $padding-normal;
      width: 100%;
    }
  }
}

.takedowns-requested-section {
  background-color: $white;

  .takedowns-requested-section-header {
    padding-right: $padding-normal;
    border-right: $border;
    margin-right: $padding-normal;
  }

  .takedowns-requested-number {
    color: $dark-gray-3;
  }

  .takedowns-requested-button {
    font-size: $font-size-medium;
    font-weight: normal;
  }

  .takedowns-requested-button.blue-link {
    cursor: pointer;
    color: $light-blue-5;
  }

  .section-group {
    padding: $padding-small 0;
    margin: 0 $padding-medium;
  }

  .section-group + .section-group {
    border-top: $border;
  }

  .takedown-by {
    font-size: $font-size-normal;
    font-weight: 500;
  }

  .takedowns-requested-item {
    .user {
      font-size: $font-size-medium;
      font-weight: 500;
    }

    .comment {
      font-size: $font-size-normal;
      font-weight: 400;
      color: $dark-gray-2;
    }

    .time {
      font-size: $font-size-medium;
      font-weight: normal;
    }
  }

  .view-all-group {
    min-height: 60px;

    .view-all {
      font-size: $font-size-medium;
      color: $light-blue-5;
    }
  }
}

.dom-tree-page-content {
  padding-top: $padding-medium;
  .card-body {
    padding-top: $padding-medium !important;
    margin-bottom: 0;

    .label-and-value-component {
      .download-button {
        color: $theme-hollow-btn-border;
      }
    }
    .dom-tree {
      background-color: $theme-widget-bg-color;
      margin-bottom: 0;
      code {
        color: $theme-text-color;
      }
    }
  }
}

.whois-page {
  .page-content {
    padding: $padding-medium $padding-medium-half;

    .card-container {
      padding-left: $padding-medium-half;
      padding-right: $padding-medium-half;
    }
  }
}

.network-wrapper {
  display: flex;
  align-items: flex-start;

  .more-info-icon {
    margin: 0 5px;
    height: 11px;
  }
}

.community-link-container {
  color: $theme-widget-legend-header-color;
  margin: 1rem 24px;
  a {
    color: $theme-hollow-btn-border;
    text-decoration: underline;
    margin-right: 2px;
  }
}

.redirected-chains-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $padding-small;
  .redirected-chains-label {
    font-size: $font-size-normal;
    font-weight: 500;
    margin-bottom: $padding-small;
  }
  .redirected-chains-list {
    width: 100%;
    .redirected-chains-item {
      font-size: $font-size-medium;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 96%;
    }
  }
}
