@import 'src/index';
.failed-upload-modal {
  .modal-content {
    width: fit-content;
    .modal-container {
      font-size: 1rem;
      padding: 0.5rem;
      .heading {
        font-weight: bold;
        margin: 0.5rem 0;
      }
      .content {
        display: flex;
        flex-flow: column nowrap;
        max-height: 400px;
        overflow-y: scroll;
        padding: 0;
        .url-data {
          margin: 0.3rem 0;
          list-style-position: inside;
        }
      }
    }
  }
}
