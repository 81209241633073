@import 'src/index';

.upgrade-modal {
  > .modal-dialog {
    @media only screen and (min-width: $screen-size-sm) and (max-width: $screen-size-lg) {
      max-width: 95% !important;
    }

    .modal-content {
      // background-color: $light-gray-1;
    }
  }
}
