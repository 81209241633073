@import 'src/index';

.widget-collapsed {
  transition: all 1s ease-in-out;
  // max-width: 45%;
  min-width: 400px;
}
.enitity-widget-wrap {
  .inner-wrap {
    overflow: hidden;
    height: 100%;
  }
}

.other-entity {
  .expand-tile-wrap {
    display: flex;
    position: absolute;
    right: 0;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0.3rem 0.5rem;
    height: 70%;
    align-items: flex-start;
  }
  .end-box-shadow {
    width: 150px;
    background: radial-gradient(ellipse at right, $theme-widget-background, transparent);
  }
  .inner-wrap {
    height: 100%;
    .widget-body {
      height: 100%;
      padding: 0 !important;
    }
  }
}
