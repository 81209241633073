@import 'src/index';

.detail-takedown-timeline-container {
  display: flex;
  width: 100%;
  overflow: auto;
  min-height: 300px;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 0;
  .detail-takedown-timeline-container-title {
    width: 100%;
    height: 64px;
    color: $theme-text-color;
    text-align: center;
    font-size: $font-size-large;
    font-weight: 500;
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .detail-takedown-timeline-wrapper {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    height: 100%;
    padding: 0px 12px;
    .detail-takedown-timeline-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding: 16px 10px;
      .detail-takedown-timeline-item-date {
        display: flex;
        width: 100%;
        height: 25px;
        font-size: $font-size-large;
        font-weight: 500;
        color: $theme-text-color;
      }
      .detail-takedown-timeline-item-events {
        width: 100%;
        overflow: auto;
        min-height: 250px;
        height: 250px;
        margin: 10px 0 0 4px;
        border-left: $theme-dashed-border;
      }
    }
  }
}
