@import 'src/index';

.donut-wrapper {
  display: flex;
  margin: 0;
  width: 550px;
  display: flex;
  justify-content: space-around;
  border-left: 1px solid #cccccc;
  padding-left: 2rem;
}

.widget-collapsed {
  .donut-wrapper {
    padding-left: 0 !important;
    min-width: fit-content !important;
    border-left-color: transparent;
    .donut {
      max-width: 200px;
      // border: 1px solid black;
      .heading {
        width: max-content !important;
        max-width: 300px;
      }
    }
  }
  .employee-donut {
    margin-left: 2rem;
  }
}
