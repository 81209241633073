@import 'src/index';
.pills-label-wrapper {
  flex-basis: 30%;
  padding: 0px $padding-normal;
  height: $padding-medium;
  border-radius: $padding-medium;
  color: $theme-text-color;
  font-weight: 500;
  font-size: $font-size-normal;
  line-height: 19px;
}
