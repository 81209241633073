@import 'src/index';

.ag-dateRangeFilter-component {
  background-color: $theme-form-control-color;

  .ag-dataRangeFilter-options {
    .ag-dataRangeFilter-option-items-container {
      padding-top: 10px;

      .ag-dataRangeFilter-option-item {
        height: 32px;
        justify-content: space-between;
        padding: 0 $padding-normal;
        cursor: pointer;
      }

      .ag-dataRangeFilter-option-item {
        &:hover {
          background-color: $theme-dropdown-hover-bg-color;
        }
      }

      .ag-dataRangeFilter-option-item-label {
        font-size: $font-size-medium;
        color: $theme-text-color;
        font-weight: normal;
        padding-right: 15px;
      }

      .ag-dataRangeFilter-option-item-value {
        font-size: $font-size-normal;
        color: $theme-text-color;
        font-weight: normal;
      }
    }

    .rdrCalendarWrapper {
      background-color: $theme-agGridDateRangeFilter-rdrCalendarWrapper-background-color;

      .rdrDateDisplayWrapper {
        display: none;
      }

      .rdrMonthAndYearWrapper {
        padding-top: 0;
        height: min-content;

        .rdrMonthPicker,
        .rdrYearPicker {
          select {
            color: $theme-agGridDateRangeFilter-rdrMonthAndYearWrapper-select-color;
            font-size: $font-size-normal;
          }
        }
      }

      .rdrNextPrevButton.rdrPprevButton {
        background: $theme-dropdown-button-color;

        i {
          border-color: transparent $theme-modal-overlay-close-btn transparent transparent;
        }
      }

      .rdrNextPrevButton.rdrNextButton {
        background: $theme-dropdown-button-color;

        i {
          border-color: transparent transparent transparent $theme-modal-overlay-close-btn;
        }
      }

      .rdrNextPrevButton:hover {
        background: $theme-monitored-header-bg;
      }

      .rdrMonthName {
        font-weight: 500;
        font-size: $font-size-large;
        color: $theme-text-color;
      }

      .rdrDay.rdrDayDisabled {
        background-color: transparent;
        opacity: 0.5;
        cursor: default;

        span {
          color: $theme-text-color;
          font-size: $font-size-large;
          font-weight: normal;
        }
      }

      .rdrDay.rdrDayPassive {
        opacity: 0;
      }

      .rdrDay {
        .rdrDayNumber {
          span {
            color: $theme-text-color;
            font-size: $font-size-large;
            font-weight: normal;
          }
        }

        .rdrStartEdge,
        .rdrEndEdge {
          background-color: $theme-a-tag-color;
        }

        .rdrStartEdge + .rdrDayNumber > span,
        .rdrEndEdge + .rdrDayNumber > span {
          color: $white;
        }
      }

      .rdrDays {
        .rdrInRange {
          color: $theme-agGridDateRangeFilter-rdrInRange-color !important;
        }
      }
    }

    .ag-dataRangeFilter-apply-button-container {
      .ag-dataRangeFilter-apply-button {
        float: right;
        padding: $padding-xsmall $padding-normal;
        margin: 0 $padding-normal $padding-normal;
        margin-top: 10px;
        font-weight: 600;
        font-size: $font-size-medium;
      }
    }
  }
}
