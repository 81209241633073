@import 'src/index';

$component-size: 12px;

.removable-item {
  background-color: $theme-active-filter-bg-color;
  padding: 0 $padding-xsmall;
  border-radius: 2.596px;
}

.removable-item + .removable-item {
  margin-left: $padding-small;
}
