@import 'src/index';

.text-input-component {
  height: 32px;
  border: $theme-border;
  outline: none;
  padding: 0 $padding-small;
  background-color: $theme-form-control-color;
}

.text-input-component:focus {
  border-color: $theme-hollow-btn-border;
  box-shadow: none;
  background-color: $theme-form-control-color;
}
