@import 'src/index';

.top-risk-container {
  display: flex;
  max-width: 100%;
  width: 49%;

  .risk-widget-wrap {
    display: flex;
    width: 100%;
    min-height: 130px;
    .delta-wrap {
      height: 100%;
      box-sizing: border-box;
      padding: $padding-small $padding-medium;
      border-right: 1px solid $card-wrapper-border;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      width: 40%;
      .heading-wrap {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        width: 100%;
        font-size: 14px;
        text-wrap: wrap;
      }
      .delta-data {
        display: flex;
        align-items: center;
        .total-count {
          font-size: 26px;
          font-weight: bold;
        }
        .delta {
          display: flex;
          margin: 10px;
          font-size: 12px;
          font-weight: bold;
          padding: 3px 5px;
          border-radius: 3px;
          span {
            margin: 0 2px;
          }
        }
        .delta.delta-green {
          color: #27a95b;
          align-items: baseline;
          background-color: $delta-green;
        }
        .delta.delta-red {
          color: $risk-priority1;
          align-items: flex-end;
          border: 1px solid $risk-priority1;
          background-color: $delta-red;
        }
      }
      .sub-heading {
        font-size: 12px;
        color: $card-subtext !important;
      }
    }
    .top-risk-data-wrap {
      padding: $padding-small $padding-medium;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      flex-grow: 1;
      .heading {
        color: $card-subheading;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 15px;
      }
      .risk-data {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 100%;
        flex-grow: 1;
        align-items: flex-start;
        .no-data {
          align-self: flex-end;
        }
        .risk-item {
          align-self: flex-end;
          border: 1px solid transparent;
          background-color: transparent;
          min-width: 100px;
          border-right: 1px solid #636b744d;
          margin-right: 1px;
          text-align: start;
        }
        .link {
          color: $theme-a-tag-color;
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}

.sub-text {
  color: $widget-card-description;
}

.item-center {
  align-items: center;
}

.padding-x-s-24 {
  padding-inline-start: 24px;
}
