@import 'src/index';

.dispute-status {
  white-space: nowrap;
  background: $theme-form-control-color;
  box-shadow: $theme-dropdown-box-shadow;
  color: $theme-text-color;
  .card-header {
    background: $theme-form-control-color;
    color: $theme-text-color;
    .dispute-header {
      font-weight: 400;
      padding: 0 0 $padding-small;
      background: $theme-form-control-color;
      color: $theme-text-color;
      .dispute-disposition {
        padding-left: $padding-xsmall;
        font-weight: 500;
      }
    }
  }

  .card-body {
    padding: 0;
    .dispute-reporter-name {
      font-weight: 500;
    }

    .dispute-comments {
      color: $theme-secondary-text-color;
      white-space: normal;
    }

    .card-text {
      padding-top: $padding-xxsmall;
      margin: 0;
      font-size: $font-size-normal;
      line-height: $font-size-large;
    }
  }

  .attached-file-viewer-container {
    .list-group {
      .list-group-item {
        .attached-file-name {
          max-width: 150px;
        }
      }
    }
  }
}
