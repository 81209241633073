@import 'src/index';

.global-takedown-filter-container {
  border-radius: 10px;
  .widget-details-container {
    max-height: 150px;
    border: $theme-border-2;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;

    .widget-details-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 22px;
      border-right: $theme-border-2;
      height: 147.5px;
      box-sizing: border-box;
      width: 100%;
      overflow-x: visible;
      max-height: 150px;
    }
    .widget-details-item.active {
      border-radius: 12.622px;
      max-height: unset;
      border: 2px solid var(--Light-Theme-Alternative-Dark-Web-400, #ff7d6b);
      background: $theme-widget-bg-color;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      height: 175px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
    }
    .widget-details-item.coming-soon {
      cursor: not-allowed;
      background-color: var(--theme-slider-bg-color);
      color: $card-cross-color;
    }
    .widget-details-item.coming-soon:first-child {
      border-radius: 5px 0 0 5px;
    }
    .widget-details-item.coming-soon:last-child {
      border-radius: 0 5px 5px 0px;
    }
    :last-child {
      border: none;
    }
  }
  .tile {
    display: flex;
    justify-content: left;
    .tile-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 0;
      margin: 0;
      height: fit-content;
      .icon {
        margin: 0 8px;
      }
      .title {
        margin: 0;
      }
      .svg-icon-wrapper:hover {
        cursor: default;
        rect {
          fill: var(--icon-color);
        }
        path {
          fill: var(--icon-color);
          cursor: default;
        }
      }
    }
    .tile-body {
      display: flex;
      .data-set {
        display: flex;
        flex-flow: column nowrap;
        height: 30%;
        padding: 0 3px;
        .head {
          font-size: $font-size-xxlarge;
          margin: 0;
          padding: 0;
        }
        .body {
          font-size: $font-size-medium;
          margin: 0;
          padding: 0;
          color: $theme-secondary-text-color-light;
        }
      }
    }
    .available-soon {
      color: $theme-secondary-text-color-light;
      font-size: $font-size-medium;
      margin: 0;
      padding: 10px;
    }
  }
}
