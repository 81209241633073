.bolster-logo-icon {
  height: 24px;
  margin: 0 auto;
}

.logo-icon {
  width: 31px;
  height: 21px;
}

.logo-icon-small {
  width: 31px;
  height: 16px;
}

.checkphish-by-bolster-logo-icon {
  height: 50px;
}
