@import 'src/index';

$alert-height: $padding-xlarge;

.global-alert-component {
  position: fixed;
  left: 72px;
  bottom: $padding-normal;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.global-alert-item {
  height: min-content;
  width: max-content;
  min-height: $alert-height;
  background-color: $green-success;
  color: $white;
  padding: $padding-normal;
  margin-top: $padding-small;
  box-shadow: 0 4px 8px rgba(41, 42, 51, 0.24);
  transition: bottom 0.5s ease-in-out;

  .alert-status-icon {
    padding-right: $padding-normal;
  }
  .global-alert-message {
    padding-right: $padding-small;
    font-size: $font-size-medium;
  }

  .global-alert-message-trim {
    max-width: 950px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .buttons-separator {
    padding: 0 $padding-small;
  }

  .close-text {
    color: $dark-gray-2;
    cursor: pointer;
  }

  img:last-child {
    padding-left: $padding-small;
    cursor: pointer;
  }
}

.global-alert-item.alert-danger {
  background-color: $red;
}

.global-alert-item.alert-information {
  background-color: #0d77bd;
}

.global-alert-item.alert-custom-action {
  background-color: $sun;
}

.global-alert-item.alert-required-action {
  background-color: $white;
  color: $dark-gray-5;
}
