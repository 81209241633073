@import 'src/index';

.donut,
.details {
  width: fit-content;
  display: flex;
  flex-flow: column nowrap;
}
.donut {
  min-width: 200px;
  max-width: 220px !important;
}
.donut {
  .heading {
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: start;
    padding: 0 1rem;
  }
}
.outer-circle {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-top: 0.2rem;
  padding: 3px;
  background-image: conic-gradient(from -25deg, #762623, #f8d355, #f09340, #762623);
}
.inner-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $theme-widget-background;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-container-circle {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: 0px 8px 16px $theme-widget-donut-box-shadow;
  background-color: $theme-darkweb-gb-color2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: translateX(-12%);
  .inner-circle {
    position: absolute;
    height: 50%;
    width: 50%;
    background-color: transparent;
  }
}
