@import '../../../index.scss';

.abuse-mailbox-dashboard-container {
  overflow-x: auto;
}
.abuse-mailbox-dashboard {
  min-width: 1230px;
  padding: 0 28px;
  color: var(--theme-page-text);
  .abuse-mailbox-dashboard-section-titles {
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    padding: $padding-medium;
    .page-title {
      padding-left: 0;
      padding-top: 0;
    }
  }
  .shared-titles-for-cards {
    font-size: 14px;
    font-weight: 500;
  }
  .info-icon {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }

  .legend {
    display: flex;
    align-items: center;
  }

  .legend-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
  }

  .legend-color {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
  .overall-email-report-overview-container {
    display: flex;
    gap: 24px;
    padding: 24px;
    width: 100%;
    height: 415px;
    border: $theme-border-2;

    background: var(--theme-page-background, #fff);

    .overall-email-submission-container {
      position: relative;
      width: 50%;

      .overall-email-submission-total-container {
        height: 332px;
        border-radius: var(--radius-xl, 16px);
        background: linear-gradient(
          360deg,
          rgba(211, 239, 255, 0.25) 0%,
          rgba(211, 239, 255, 0) 108.63%
        );
        .dark & {
          background: linear-gradient(0deg, rgba(13, 36, 60, 0) 0%, rgba(13, 36, 60, 0.6) 100%);
        }
        .overall-email-submission-total {
          margin-top: 16px;
          padding-left: 24px;
          padding-top: 16px;

          .overall-email-submission-total-value {
            color: #2368bc;
            .dark & {
              color: #599bff;
            }
            font-size: 60px;
            font-weight: 600;
            line-height: 1.3;
          }
          .overall-email-submission-total-description {
            font-size: 16px;
            font-weight: 400;
            .overall-email-submission-total-users {
              font-weight: 600;
            }
          }
        }
        .overall-email-submission-chart-container {
          position: absolute;
          top: 140px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .overall-email-submission-breakdown-container {
    min-width: 560px;
    width: 50%;
    height: 332px;
    flex-shrink: 0;
    border-radius: var(--radius-xl, 16px);

    .overall-email-submission-breakdown-chart-container {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      .overall-email-submission-breakdown-chart-unit {
        min-width: 40%;
        height: 120px;
        display: flex;
        align-items: center;
        .overall-email-submission-breakdown-chart {
          width: 100px;
          height: 100px;
          @media (min-width: 1680px) {
            margin-right: -24px;
          }
          @media (min-width: 1780px) {
            margin-right: -48px;
          }
          @media (min-width: 1880px) {
            margin-right: -72px;
          }
        }
        .overall-email-submission-breakdown-chart-label {
          font-size: 14px;
          font-weight: 500;
          width: 160px;
        }
        .overall-email-submission-breakdown-chart-value {
          font-size: 32px;
          font-weight: 600;
        }
      }
    }
  }

  .targeted-malicious-email-analytics {
    .targeted-malicious-email-analytics-section-container {
      display: flex;
      gap: 24px;
    }

    .targeted-malicious-email-analytics-section {
      flex: 1;
      background: var(--theme-page-background);
      padding: 24px;
      border: $theme-border-2;
    }

    .section-header {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-bottom: 24px;
    }

    .section-header-breakdown {
      margin-bottom: 24px;
      .phish-scam-header-buttons {
        margin-top: 12px;
        justify-content: flex-end;
      }
    }

    .email-color {
      background-color: #0a89ff;
    }

    .sms-color {
      background-color: #35c759;
    }

    .by-delivery-method-bar-container {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 16px;
    }

    .category-label {
      width: 160px;
      min-width: 160px;
    }

    .rectangle-bar {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .email-bar {
      min-width: 2px;
      background-color: #0a89ff;
      height: 18px;
      border-radius: 3px;
    }

    .email-bar-has-value {
      min-width: 3px;
    }

    .sms-bar {
      background-color: #35c759;
      height: 18px;
      border-radius: 3px;
    }

    .sms-bar-has-value {
      min-width: 3px;
    }

    .links-breakdown-container {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-bottom: 16px;
    }

    .category-bar {
      min-width: 2px;
      height: 18px;
      border-radius: 3px;
    }

    .category-bar-has-value {
      min-width: 3px;
    }
  }

  .phish-scam-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phish-scam-header-buttons {
    display: flex;
  }

  .phish-scam-buttons {
    background-color: #dde7ee;
    color: #555e68;
    border: none;
    border-radius: 24px;
    margin-left: 20px;
    padding: 2px 12px;
    .dark & {
      background-color: #32383e;
      color: #c6d3e0;
    }
  }

  .phish-scam-buttons.active {
    background-color: var(--theme-page-text);
    color: var(--theme-page-background);
    .dark & {
      background-color: #1d4785;
      color: #fff;
    }
  }
  .takedown-top5-urls-container {
    flex: 3;
  }

  .takedown-time-analytics {
    min-width: 800px;
    height: 400px;
    border: $theme-border-2;
    margin: 24px 0;
    padding: 28px;
    position: relative;
    background-color: var(--theme-page-background);

    .bar-container {
      height: 300px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .bar {
      width: 6px;
      border-radius: 2px;
      position: relative;
    }

    .bar.highlight {
      width: 14px;
    }

    .highlighted-index {
      position: absolute;
      top: -56px;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      white-space: nowrap;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--theme-page-text);
      color: var(--theme-page-background);
      border-radius: 6px;
      padding: 0 12px;
    }

    .average-median-time {
      font-size: 12px;
      font-weight: 400;
    }

    .average-time-color {
      background-color: #35c759;
      margin-right: 10px;
    }

    .median-time-color {
      background-color: #0a89ff;
      margin-right: 10px;
    }
  }

  .top-5-suspicious-urls {
    min-width: 800px;
    overflow-x: auto;
    border: $theme-border-2;
    margin: 24px 0;
    padding-top: 28px;
    background-color: var(--theme-page-background);
    .phish-scam-header {
      margin: 0 28px;
    }
    .top-5-suspicious-urls-table {
      margin: 0 8px;
      margin-top: 36px;
      .top-5-suspicious-urls-table-header {
        .top-5-suspicious-urls-table-header-cell {
          color: $abuseMailBox-dashboard-reported-malicious-threat-header-color;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }
      }

      .top-5-suspicious-urls-table-cell {
        max-width: 140px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .top-5-suspicious-urls-source-url {
          max-width: 100%;
          display: inline-block;
          color: #0a89ff;
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-decoration: none;
        }
      }
      .top-5-suspicious-status {
        max-width: 180px;
      }
      a {
        color: #0a89ff;
      }
    }
  }

  .most-reported-domains {
    flex: 1;
    background-color: var(--theme-page-background);
    margin: 24px 0;
    margin-left: 28px;
    padding: 28px 0;
    border: $theme-border-2;
    .shared-titles-for-cards {
      margin-left: 24px;
      margin-bottom: 16px;
    }
    .phish-scam-buttons-container {
      margin: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .phish-scam-buttons {
        margin: 0;
      }
    }
    .most-reported-domains-table-container {
      min-width: 296px;
      height: 700px;
      overflow-y: auto;
      margin: 24px;
      margin-bottom: 0;
      td {
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .social-media-intro-container {
    .social-media-protection-description {
      max-width: 650px;
    }
  }
}

.abuse-mailbox-no-permission-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: var(--theme-page-text);
  .abuse-mailbox-no-permission-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  .abuse-mailbox-no-permission-page-content-title {
    font-size: 24px;
    font-weight: 600;
  }
  .abuse-mailbox-no-permission-page-content-description {
    font-size: 16px;
    font-weight: 400;
  }
  .abuse-mailbox-no-permission-page-content-image {
    width: 800px;
  }
}
