.pic-files-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #d3d3d3;
  overflow: scroll;

  .logo-with-background {
    width: 100%;
    background: linear-gradient(180deg, #007ea7 0%, #48accd 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    img {
      max-height: 30px;
    }
  }

  .pic-item {
    padding: 10px;

    img {
      max-height: 50px;
      margin-bottom: 20px;
      object-fit: none;
    }
  }
}
