@import 'src/index';

.typosquat-table {
  width: 100%;
  border: 1px solid #e9ecf0;
}

.no-data-wrap {
  h6 {
    color: $theme-text-color;
  }
}
.typo-welcome-container {
  box-sizing: border-box;
  position: inherit;
  background: $theme-welcome-background;
  border: 1px solid #e9ecf0;
  width: 90%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 3rem;
  box-shadow: 0px 5px 5px rgba(32, 25, 22, 0.2);
  align-self: flex-end;
  margin: 0 auto;
  height: 100%;
  margin-top: 2%;
  border: $theme-welcome-background;

  .content-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;

    .heading-wrapper {
      width: 400px;
      padding: 1rem;
      align-self: flex-start;
      margin: 3rem;
    }

    .get-started-btn {
      width: 100px;
      color: whitesmoke;
      background-color: $theme-welcome-btn;
      border-radius: 20px;
      padding: 0.3rem;
      border: 1px solid #a8b3c1;
      margin: 0.5rem 0;
    }

    .main-head {
      font-family: 'Fakt';
      font-style: normal;
      color: $theme-text-color;
      margin: 0.5rem 0;
    }

    .sub-head {
      font-family: 'Fakt';
      font-style: normal;
      font-weight: 400;
      color: $dark-gray-3;
    }

    .img-wrapper {
      height: 450px;
      width: 800px;
      background: url('../../assets/dashboard_bg_img.png');
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
      position: relative;
      left: 2%;
    }
  }
}

.table-ip-column {
  display: flex;
  flex-flow: row wrap;
  width: auto;
  a {
    padding: 0 auto;
  }
}

.typo-wrapper {
  height: -webkit-fill-available;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  .processing-page {
    width: 100%;
    height: 100%;

    .processing-layover {
      width: 100%;
      height: 100%;
    }
    img {
      height: 100%;
      width: 90%;
      margin: 0 auto;
      display: flex;
      object-fit: fill;
    }
  }

  .typo-popup {
    background: #ffffff;
    border: 1px solid #e9ecf0;
    box-shadow: 0px 10px 30px rgba(32, 25, 22, 0.2);
    width: 80% !important;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    min-height: 35vh;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    right: 10%;
    z-index: 11;
    min-width: 400px;

    .close-btn {
      border: none;
      background: none;
      align-self: flex-end;
      font-weight: bold;
      position: absolute;
      top: 2rem;
      color: $theme-text-color;
    }
    .header {
      font-weight: bold;
    }
  }
}

.scan-ts {
  font-family: 'Fakt';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-top: 1rem;
  padding-left: 24px;
  letter-spacing: 0.003em;
  color: $dark-gray-3;
}

.top-disposition {
  max-height: 335px !important;
  .widgets-container-card-body {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    padding: 0 11px;
  }
  .widgets-container-card-body::-webkit-scrollbar {
    background: transparent !important;
    width: 6.109px !important;
  }
  .widgets-container-card-body::-webkit-scrollbar-thumb {
    background: var(--light-scheme-line-hr-hr-dark, #bbb) !important;
    border-radius: 29px !important;
  }
  .card-footer {
    height: 44px;
    flex-shrink: 0;
    .see-details-btn {
      padding: 15px 0;
    }
  }
}
.table-ip-column {
  display: flex;
  justify-content: space-between;
}

.no-finding-wrap {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  width: max-content;
  margin-top: 10vh;
  align-items: center;
  .main-head {
    color: #808080;
    font-size: 23px;
    margin: 0.5rem auto;
  }
  .sub-text {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    color: $theme-text-color;
  }
}

.ip-link {
  margin-right: 0.3rem;
}

.typosquat-scan-wrap {
  color: $theme-text-color;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 1rem;
  max-width: 60%;
  .typosquat-scan-sub-text {
    color: $typosquat-scan-sub-text;
    opacity: 0.8;
    font-size: 14px;
    text-align: center;
  }
  .search-bar-wrap {
    width: 100%;
    .err-text {
      input {
        font-size: 14px;
        color: $theme-warning-color;
      }
    }
    .success-text {
      color: #00b15c !important;
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .live-scan-suggestion {
      margin-top: 1rem;
      padding: 10px;
      color: $theme-livescan-color;
      background-color: $theme-livescan-bg;
      display: flex;
      align-items: center;
      margin-left: 120px;
      margin-right: 180px;
      font-size: 13px;
      border: 1px solid $theme-livescan-border;
      img {
        margin-right: 0.3rem;
        height: 20px;
      }
      .extracted-domain {
        color: $theme-livescan-highlight;
        font-weight: 500;
        padding: 0 3px;
      }
      .link-wrapper {
        a {
          color: $theme-livescan-highlight;
          font-weight: 500;
          text-decoration: underline;
          padding: 0 3px;
        }
      }
    }
  }
  .progress-wrap {
    width: 100%;
    color: $theme-text-color;
    text-align: center;
  }
}
.contact-us-link {
  color: $theme-contact-us-link !important;
  cursor: pointer;
  background-color: transparent;
  border: transparent;
  font-weight: bold;
  text-decoration: underline;
}
.edit-domain-btn {
  background: none !important;
  border: none !important;
  color: $theme-a-tag-color !important;
  align-self: flex-end;
  align-items: center;
  min-width: 120px;
}
.cancel-edit-typosquat {
  align-self: flex-end;
}
.analytics-dashboard-page-title {
  justify-content: flex-start !important;
  align-items: center !important;
  padding-top: 24px;
}

@media only screen and (max-width: $screen-size-md) {
  .warning-wrap {
    flex-flow: column nowrap;
    width: auto;

    button {
      margin: 1rem auto;
    }
  }
}
.typosquat-table {
  min-height: 71vh !important;
  padding: 0.5rem;
  color: $theme-text-color;
  .progress-page-wrap {
    display: flex;
    flex-flow: column nowrap;
    .block {
      background-color: $theme-progress-page-block;
      opacity: 0.8;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-name: block-flow;
      transition-duration: background-image 1s;
    }
    .table-toolbar {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      .toolbar-block {
        width: 250px;
        height: 30px;
        margin: 0.5rem;
      }
    }
    .progress-table-wrap {
      height: inherit;
      .progress-table {
        height: inherit;
        width: 100%;
        tr.typosquat-table-row {
          height: 45px;
          display: flex;
          justify-content: space-between;
          .check-box-data {
            width: 50px !important;
          }
          .table-data {
            margin: 0.5rem;
          }
          .table-data.checkbox {
            align-self: center;
          }
          .small {
            width: 5%;
          }
          .medium {
            width: 10%;
          }
          .round {
            border-radius: 20px;
          }
        }
      }
    }
  }
}

.monitiored-container-v2 {
  .top-breakdown-chart,
  .risk-level-breakdown,
  .top-riskiest {
    padding: 32px 20px;
  }
  .top-riskiest {
    .domain-risk-insight .header,
    .look-alike-domains .header {
      font-family: Fakt;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
    .domain-risk-insight .header {
      padding: 0 !important;
      position: relative;
      right: 0.3rem;
    }

    .critical-alerts-container {
      border-right: 1px solid #4d5563;
      padding: 0 50px 0 0;
      .critical-alerts {
        position: relative;
        height: 100%;
        border-radius: 12px;
        background: linear-gradient(295deg, #ff7629 11.27%, #fd4649 86.81%);
        padding: 12px 20px;
        color: $white;
        .critical-header {
          font-size: 20px;
          font-weight: 500;
        }
        .critical-count {
          text-align: center;
          font-size: 64px;
          font-weight: 500;
        }
        .critical-content-header {
          margin-bottom: 0;
          font-size: 15px;
          font-weight: 500;
        }
        .critical-content {
          margin-bottom: 0;
          font-size: 13px;
        }
        img {
          cursor: pointer;
        }
      }
    }

    .look-alike-domains {
      border-right: 1px solid #4d5563;
      .domain-generation-wrap {
        margin-top: 5%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;

        .domain-generation-stats {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          padding: 0.5rem auto;
          justify-content: center;
          padding-top: 0 !important;
          div {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
          }
          .upgrade-button-container {
            margin: 0.2rem auto;
            padding: 0.2rem;
            button {
              min-width: 50px;
            }
          }
        }
      }
      .tld-variant-wrap {
        margin-top: 10%;
      }
      .domain-generation-limit {
        padding: 0;
        font-family: Fakt;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        display: flex;
        justify-content: center;
      }
      .upgrade-container {
        padding-top: 28px;
        .scan-percentage {
          height: 2px;
        }
        .stats {
          padding-right: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          span {
            color: #808080;
          }
        }
        .upgrade-button-container {
          padding-left: 0;
          .upgrade-button {
            padding: 3px 8px;
            border-radius: 30px;
            font-size: 12px;
            background-color: $theme-typo-dashboard-upgrade-button-bg;
            &:hover {
              color: #ffffff !important;
            }
          }
        }
      }
      .tld-stats .title,
      .variants-container .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #808080;
        padding: 9px 0 0 0;
      }
      .tld-stats .content,
      .variants-container .content {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
      }
      .variants-container {
        .title {
          text-align: end;
        }
      }
    }
    .domain-risk-insight {
      padding-left: 50px;
      .insight-header {
        .title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: #808080;
        }
        .header-content {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          top: 4px;
          padding-left: 4px;
        }
        .header-text {
          color: $theme-text-color;
        }
        .mail-server-header {
          padding: 0px 4px;
          border-radius: 2px;
        }
      }
      .insight-title {
        margin-top: 1rem;
        margin-bottom: 0;
      }
      .insight-content {
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #808080;
      }
    }
  }
  .risk-level-breakdown {
    border-bottom: 1px solid #4d5563;
    border-top: 1px solid #4d5563;
    .content {
      color: #999;
      font-family: Fakt;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.042px;
      padding-right: 36%;
      display: flex;
      padding-top: 13px;
    }
  }
  .risk-score-legend {
    width: 265px;
    padding-left: 27px;
    margin-top: 40px;
    .no-padding {
      display: flex;
    }
    .sub-text {
      font-size: 14px;
      color: #999999;
      padding-inline-end: 7px;
    }
    .badge {
      padding-top: 0.3rem;
    }
  }
  .risk-level-bar {
    margin-top: 60px;
    height: 44px;
    border-radius: 30px;
    display: flex;
    overflow: hidden;
    .risk-level-bar-length {
      justify-content: center;
      display: flex;
      border-right: 1px solid white;
      cursor: pointer;
      &:last-child {
        border-right: none;
      }
      .legend-bubble {
        display: flex;
        position: absolute;
        margin-top: -50px;
        justify-content: center;
        .badge {
          height: 18px;
        }
        &.red::before,
        &.pink::before,
        &.orange::before,
        &.yellow::before,
        &.blue::before {
          content: '';
          position: relative;
          left: 11px;
          top: 18px;
          height: 32px;
        }
        &.red::before {
          border-left: 1px dotted #ff4f4e;
        }
        &.pink::before {
          border-left: 1px dotted #ff7d75;
        }
        &.orange::before {
          border-left: 1px dotted #fa9926;
        }
        &.yellow::before {
          border-left: 1px dotted #f9cd30;
        }
        &.blue::before {
          border-left: 1px dotted #b2d0fe;
        }
      }
    }
  }
}

.monitiored-container-v2.table-page {
  margin: 0;
  overflow-y: scroll;
  height: 500px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: var(--theme-delta-scroll-track-backgroun);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--theme-delta-scroll-thumb-background);
    border-radius: 40px;
  }
  .tool-bar-container {
    display: none;
  }
}

.export-wrapper {
  display: flex;
  button {
    margin: 0 1rem;
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .export-btn {
    border: 1px solid $typosquat-export-first-btn-border;
    &:hover {
      border: 1px solid $typosquat-export-first-btn-border-hover !important;
      color: $typosquat-export-first-btn-border-hover !important;
    }
  }
  .continue-btn {
    background-color: $typosquat-continue-btn-bg;
    &:hover {
      background-color: $typosquat-continue-btn-hover;
      color: white !important;
    }
  }
}

.no-data-page {
  img {
    width: 100%;
  }
}

@keyframes block-flow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.typosquat-table-row:nth-child(2n + 2) {
  background-color: $theme-progress-page-row;
}

.err-text {
  font-size: 14px;
  color: $theme-warning-color;
  input {
    border: 1px solid $theme-livescan-highlight !important;
  }
}

.warning-wrap {
  // border: 1px solid black;
  background-color: #ffd7d1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  color: #333333;
  min-height: 50px;
  margin: 1rem auto;
  padding: 1.5rem;
  p {
    font-size: small;
    font-weight: 500;
    height: 100%;
    margin: 0;
    padding: 0;
    height: auto;
    margin-right: 1rem;
  }
  button {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    height: fit-content;
    align-self: center;
    background: #fff9ef;
    border-color: transparent;
  }
}

.table-page.hide {
  display: none;
}

.no-padding {
  padding: 0 !important;
}
.upgrade-strip {
  padding-left: 36px;
  padding-top: 10px;
  margin-left: 24px;
  color: var(--theme-page-text);
  p {
    margin: 0;
  }
}
.whats-new-wrapper-empty {
  padding: 24px;
  img {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
  img,
  p,
  h4,
  button {
    margin: auto;
    display: block;
  }
  img,
  p,
  h4 {
    width: 288px;
  }
  p,
  h4 {
    text-align: center;
  }
  h4 {
    color: var(--text-primary, #171a1c);
    font-family: Fakt;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 166%;
    margin-bottom: 10px !important;
  }
  p {
    color: var(--text-secondary, #555e68);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 166%; /* 23.24px */
  }
  button {
    margin-top: 10px;
  }
}
.whats-new-wrapper {
  .upgrade-strip.next-scan-strip {
    color: var(--text-secondary, #555e68);
    font-family: Fakt;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 166%; /* 23.24px */
    margin: 0;
  }
  h6 {
    color: $theme-text-color;
  }

  &.mx-6 {
    margin-inline: 24px;
  }
}
.alert-container {
  padding: 24px;
  .alert {
    border-radius: 0;
    .darker {
      color: #e33231;
      font-family: Fakt;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
    .capitalise {
      text-transform: capitalize;
    }
  }
}
.delta-switch-container {
  top: -5px;
  .MuiSwitch-root {
    float: right;
  }
}
.delta-table {
  margin: 0 0 24px 0;
  padding: 0 24px;
  #ag-table-container {
    height: 500px;

    .ag-root-wrapper.ag-layout-normal {
      border-radius: unset;
    }
    &.border-rounded-sm {
      .ag-root-wrapper {
        border-radius: 12px !important;
      }
    }
  }
}

.delta-table-v2 {
  .ag-theme-quartz .ag-header,
  .ag-theme-quartz-dark .ag-header {
    span.ag-header-cell-text {
      font-weight: bold !important;
      color: $theme-ag-table-header-cp !important;
    }
  }
}

@media all and (max-width: 1350px) {
  .domain-generation-wrap {
    display: flex;
    flex-flow: column nowrap !important;
    align-items: flex-start;
    .domain-generation-limit {
      padding: 0;
      font-family: Fakt;
      font-size: 2.5rem !important;
      font-style: normal;
      font-weight: 500;
      font-size: 50px !important;
    }
  }
  .domain-generation-stats {
    padding: 0 !important;
  }
  .tld-variant-wrap {
    display: flex;
    flex-flow: column;
    .title {
      display: flex;
    }
  }
}

@media all and (max-width: 900px) {
  .stats-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
}

.apexcharts-canvas svg {
  width: 110% !important;
}

.typo_category_column {
  text-transform: capitalize;
}
.typosquat-search-btn-custom {
  border-radius: 0 5px 5px 0;
  width: 180px;
}

.search-bar-wrap {
  color: inherit;
  .warning-text {
    .search-text-field {
      color: #ff4f4e !important;
    }
  }
}
.typosquat-blurred-modal .monitiored-container-v2,
.typosquatting-reminder-modal .modal-dialog {
  margin-top: 20% !important;
}
.typosquat-blurred-modal.light {
  background: url('../../assets/Typosquat_dashboard_blurred_light.png');
}
.typosquat-blurred-modal.dark {
  background: url('../../assets/Typosquat_dashboard_blurred_dark.png');
}
.typosquat-blurred-modal {
  height: 100%;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  .capitalise {
    text-transform: capitalize;
    font-weight: 600;
  }
  .monitiored-container-v2 {
    width: 400px;
    padding: 45.75px 53.25px 45.75px 52.25px;
    margin: auto;
    .header {
      font-family: Fakt;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      margin: 0;
      margin-left: 5px;
      top: 2px;
      margin-bottom: 20px;
    }
    .btn {
      width: 100%;
    }
    img {
      width: 28.5px;
      height: 28.5px;
    }
    p {
      font-family: Fakt;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.028px;
    }
  }
}

.work-email-err-msg-logo {
  width: 47px;
  height: 44.707px;
  margin-right: 10px;
}

.typosquatting-reminder-modal {
  .modal-content {
    width: 74%;
    margin: auto;
    img {
      width: 25px;
      height: 25px;
      top: 5px;
      position: relative;
    }
    .modal-header {
      justify-content: center;
      padding-bottom: 0 !important;
      p {
        color: #c51a19;
        font-family: Fakt;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 37.9px;
        letter-spacing: 0.2px;
        text-transform: capitalize;
      }
    }
    .modal-body {
      text-align: center;
      font-family: Fakt;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 142.524%;
      letter-spacing: 0.048px;
      p {
        margin: 0 !important;
      }
    }
    .modal-footer {
      padding-bottom: 24px !important;
    }
  }
}

.remind-me-later-button {
  background: inherit;
  color: inherit;
  border: none;
}
.work-email-reminder-text {
  color: #694c4c;
}
.banner-wrap {
  display: flex;
  .tooltip-wrapper {
    flex-grow: 1;
    margin-left: 120px !important;
    margin-right: 180px !important;
    padding: 0.5rem;
    background-color: #e9f4ff;
    border: 2px solid #b9dcff;
    color: #0058f2;
    margin: 0.5rem auto;
    width: fit-content;
    display: flex;
    align-items: basel;
    img {
      padding-right: 0.5rem;
      display: block;
    }
  }
}

.progress-text {
  padding: 2rem 1rem;
  .status {
    color: $typosquat-scan-sub-text;
    opacity: 0.7;
  }
}

.risk-legend-high-text-color {
  color: $risk-legend-high-text-color;
}
.risk-legend-low-text-color {
  color: $risk-legend-low-text-color;
}

.top-risk-widget-wrap-cp {
  .overflow-visible {
    overflow: visible !important;
  }
}

.dashboard-wrapper {
  padding: $padding-medium;
  .risk-section-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .top-risk-wrap {
      flex-grow: 1;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}

.next-scan-description {
  color: $next-scan-text-color;

  .next-scan-date {
    color: $next-scan-date-color;
  }
}

.container-widget-got--row {
  display: flex;
  justify-content: space-between;
}

.mb-end-6 {
  margin-block-end: 24px;
}
