@import 'src/index';

#ag-table-container {
  .ag-grid-abuse-mailbox-table-tool-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20px;
    padding: 2rem 0;
    position: relative;
    .persistent-filters-container {
      left: -100px !important;
    }
  }

  width: 100%;
  // make filter dropdowns visible even table is empty
  .ag-body-viewport {
    height: auto;
  }
  .ag-cell-wrapper {
    min-height: 100%;
  }
  .ag-group-value {
    display: flex;
  }
  .ag-floating-filter-input {
    .ag-text-field-input[disabled] {
      background-color: var(--ag-background-color);
      pointer-events: none;
    }
    .ag-text-field-input-wrapper:hover {
      cursor: pointer;
    }
  }
  .ag-select-list {
    // anti pattern, to override ag-grid inline styles
    max-height: 380px !important;
    overflow: auto;
  }

  // Customize dark theme for ag-grid
  &.ag-theme-quartz-dark {
    --ag-background-color: var(--theme-widget-bg-color);
    .ag-header {
      background-color: #303236;
      .ag-floating-filter-button-button {
        background-color: #303236;
        &:hover {
          background-color: var(--ag-quartz-icon-hover-color);
        }
      }
    }
    .ag-center-cols-viewport,
    .ag-paging-panel,
    .ag-row-group {
      background-color: var(--theme-widget-bg-color);
    }
    &.group-view-table-container {
      .ag-center-cols-viewport,
      .ag-paging-panel,
      .ag-row-group {
        background-color: var(--theme-page-background);
      }
    }
    .ag-row-group::before {
      z-index: 2;
    }
    .ag-menu,
    .ag-tabs-header,
    .ag-tab,
    .ag-select-header,
    .ag-select-list,
    .ag-wrpper,
    .ag-text-field-input,
    .ag-checkbox-input-wrapper,
    .ag-number-field-input,
    .ag-picker-field-wrapper {
      background-color: var(--theme-widget-bg-color);
    }

    .ag-button {
      background-color: var(--theme-page-background);
    }
  }
  .ag-root-wrapper {
    .ag-popup {
      .ag-popup-child {
        z-index: 0;
      }
      .ag-tabs-body {
        .ag-menu-column-select-wrapper {
          .ag-column-select {
            .ag-column-select-header {
              .ag-column-select-header-checkbox {
                display: none;
              }
            }
          }
        }
      }
      .ag-filter {
        .ag-filter-condition {
          .ag-filter-condition-operator-and {
            pointer-events: none;
            opacity: 0.4;
          }
        }
      }
    }

    .ag-column-select {
      .ag-column-select-header {
        .ag-column-select-header-checkbox {
          display: none;
        }
      }
    }

    .ag-root-wrapper-body {
      .ag-side-bar-right-unselectable {
        opacity: 0.35;
        pointer-events: none;
      }

      .ag-side-bar-right {
        .ag-tool-panel-wrapper {
          .ag-column-panel {
            .ag-column-panel-column-select {
              .ag-column-select-header {
                .ag-column-select-header-checkbox {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .custom-row-background {
    background-color: var(--theme-ag-selected-row-color);
    // animation: 4s ease-out mymove;
  }
  .custom-row-background.disabled {
    pointer-events: none !important;
    .ag-group-expanded,
    .ag-group-contracted {
      pointer-events: none !important;
      .ag-icon {
        opacity: 0.3;
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }

  // TODO :  don't need for now, maybe in future
  @keyframes mymove {
    from {
      background-color: var(--theme-ag-selected-row-color);
    }
    to {
      background-color: transparent;
    }
  }
  .tvc-table-description-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px $padding-medium-half $padding-small 0px;
  }
}

.MuiPopper-root {
  .MuiTooltip-tooltip {
    &.table-source-url-tooltip {
      background: $theme-tooltip-bg-color;
      color: $theme-text-color;
      max-width: 40rem;
      background: $theme-tooltip-bg-color;
      color: $theme-text-color;
      font-size: 12px;
      padding: 10px 12px;
      font-family: Fakt;
      background-color: $theme-tooltip-bg-color;
      box-shadow: $box-shadow;
      word-break: break-all;
    }
  }
}

[data-testid='abuse_mailbox_all_url_analysis'] {
  .ag-paging-button[ref='btFirst'] {
    display: none;
  }

  .ag-paging-button[ref='btLast'] {
    display: none;
  }
}

.ag-grid-loading-am--all-url-analysis {
  .ag-paging-page-summary-panel {
    [ref='btPrevious'],
    [ref='btNext'] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
