.label-value-wrap.label-text {
  font-weight: 400;
  font-size: 12px;
  font-family: 'Fakt';

  .value {
    color: #171a1c;

    .dark & {
      color: #f0f4f8;
    }
  }
}

.abuse-mailbox-tab:first-child {
  margin-right: 10px;
}

.MuiBox-root {
  padding: 0;

  a {
    &.insights-link {
      color: #327ef0;
      text-decoration: none;
      font-family: 'Fakt';

      &:hover {
        color: #2360bc;
      }

      .dark & {
        color: #599bff;

        &:hover {
          color: #b2d0fe;
        }
      }

      &.link-font-size--14 {
        font-size: 14px;
      }
    }
    &.no-insights-link {
      color: #000;
      pointer-events: none;
    }
  }
}

.text-eclipse {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;

  &.text-eclipse-width--200 {
    width: 200px;
  }
}

.my--top-2 {
  margin-block-start: 2px;
}

.MuiSvgIcon-root {
  &.no-hover {
    pointer-events: none;
  }
}

.my-10 {
  margin-block: 60px;
}

.MuiDivider-root {
  .dark & {
    border-color: #4d535a;
  }

  &.divider--yaxis {
    height: 21px;
    align-self: center;
    border-color: #dde1e7;
    .dark & {
      border-color: #4d535a;
    }
  }

  &.divider--xaxis {
    border-color: #dde1e7;

    .dark & {
      border-color: #4d535a;
    }

    &.my--12-16 {
      margin-block: 12px 16px;
    }
  }
}

.MuiPaper-root {
  &.tile-border {
    border: 1px solid #dde7ee;
    .dark & {
      border: 1px solid #555e68;
      background-color: #1c1f22;
    }
  }
}

.MuiTypography-root {
  font-family: 'Fakt' !important;

  &.my--top-2 {
    margin-block-start: 2px;
  }
  &.card-title--header {
    font-size: 16px;
    font-weight: 600;
    color: #000000de;
    .dark & {
      color: #fff;
    }
  }
  &.meta-data--title {
    font-size: 12px;
    color: #8d939a;
    font-weight: 500;
  }
  &.meta-data--value {
    font-size: 14px;
    color: #000000de;

    .dark & {
      color: #fff;
    }
  }
  &.px--start {
    padding-inline-start: 4px;
  }
}
a.MuiLink-root {
  font-size: 14px;

  &.text-eclipse {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.text-link-color {
    color: #327ef0;
  }
  &.remove-underline {
    &:is(:hover, :active) {
      text-decoration: none;
      color: #2360bc;
      .dark & {
        color: #b2d0fe;
      }
    }
  }
  &.display-inline {
    max-width: fit-content;
  }
}

.all-url-analysis-tile-wrapper {
  .fill-space {
    min-height: 70vh;
  }
}

.all-url-analysis-tile-wrapper {
  .MuiGrid-container {
    width: auto !important; //added important as width is getting inline through material ui js
  }
}
