@import 'src/index';

.action-btn {
  border-radius: 5px;
  width: 100%;
  color: $action-btn-text;
  border: 1px solid $action-btn-border;
  &:hover,
  &:focus {
    color: $action-btn-disabled-background;
    background-color: $go-back-btn-background-color;
  }
}
.bold {
  font-weight: bold;
  color: $theme-text-color;
}
.disabled-btn {
  background-color: $action-btn-current-plan-disabled--background;
  color: $action-btn-current-plan-disabled--text-color;
  border: 1px solid $action-btn-disabled-border;
  &:hover,
  &:focus,
  &:hover,
  &:active {
    background-color: $action-btn-disabled-background;
    color: $action-btn-disabled-text;
    border: 1px solid $action-btn-disabled-border !important;
    outline: 1px solid $action-btn-disabled-border !important;
    cursor: default;
  }
}

.highlight-free {
}
.highlight-starter {
  background-color: #07a8a8;
  border: 1px solid #07a8a8;
  color: white;
}
.highlight-pro {
  background-color: #327ef0;
  border: 1px solid #327ef0;
  color: white;
}
.highlight-premium {
  background-color: #c05fc2;
  border: 1px solid #c05fc2;
  color: white;
}
.highlight-professional_api {
  background-color: #c05fc2;
  border: 1px solid #c05fc2;
  color: white;
}
