@import 'src/index';

.page-wrap {
  height: 100%;
  .sub-heading {
    width: 100%;
    display: block;
    padding: 10px 24px;
    font-weight: 400;
    color: $theme-subtext-color;
    opacity: 90%;
  }
  .logo-upload-wrap {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $theme-text-color;
    .title {
      font-weight: 450;
      font-size: 16px;
      margin-bottom: 5px;
      color: $theme-sub-item-text-color;
    }
    label {
      padding: 0 !important;
      margin-bottom: 5px !important;
    }
    .logo-upload-container {
      min-height: 400px !important;
      width: 500px !important;
      padding: 1rem;
      .upload-via-file {
        display: flex;
        flex-flow: column nowrap;
        .logo-upload {
          border: 1px dashed $theme-logo-detection-border !important;
          height: 200px !important;
          width: 100% !important;
          display: flex;
          justify-content: center;
          align-items: center;
          .upload-files-container {
            margin-bottom: 0 !important;
          }
          .form-group {
            display: flex !important;
            flex-flow: column !important;
            margin: 0 auto;
            input {
              display: flex;
              justify-content: center;
              width: fit-content !important;
            }
            .help-text {
              display: flex;
              flex-flow: column nowrap;
              padding: 5px auto !important;
              margin-top: 1rem;
              h6 {
                color: $theme-sub-item-text-color;
                opacity: 0.9;
                font-size: 14px;
              }
              div {
                margin: 0 !important;
                line-height: 0.5;
              }
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }

      .division-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        .left-line {
          flex-grow: 1;
          border-top: 1px solid $theme-logo-detection-border !important;
        }
        span {
          padding: 0 1rem;
          color: $theme-logo-detection-border !important;
        }
        .right-line {
          flex-grow: 1;
          border-top: 1px solid $theme-logo-detection-border !important;
        }
      }
      .upload-via-url {
        .logo-url {
          background-color: $theme-logo-input-bg;
          color: $theme-logo-link-color !important;
          &:active {
            border-color: $theme-logo-link-color;
          }
        }
        .logo-url::placeholder {
          color: $theme-logo-detection-border !important;
        }
        .logo-url {
          &:active {
            border-color: transparent !important;
          }
        }
      }
      .btn-wrap {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
