@import 'src/index';

.expandable-item-wrap {
  margin: 5px 0;
  .expandable-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border-radius: 5px;
    width: 100%;
    padding: 0 5px;
    button {
      border: 0px solid transparent;
      background-color: transparent;
    }
    .lable-wrap {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        margin-right: 5px;
      }
      .nav-link {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .subitem-wrap {
    display: flex;
    flex-flow: column nowrap;
    .nav-item-wrap {
    }
  }
  .subitem-wrap.expanded-line {
    border-left: 2px solid $theme-active-nav-border-color;
    margin-left: 17px;
  }
  .subitem-wrap.no-padding {
    padding-left: 0 !important;
  }
}

.nav-link {
  color: $theme-text-color;
  opacity: 0.9;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 0 !important;
  a {
    text-decoration: none;
  }
}

.nav-item {
  margin: 3px;
  border-radius: 8px;
  padding: 0 !important;
  width: fit-content;
  &:hover {
    background-color: $theme-active-nav-bg;
  }
  a {
    text-decoration: none;
    color: $theme-text-color !important;
  }
  .is-new-tag {
    font-size: 12px;
    position: relative;
    bottom: 7px;
    left: 5px;
    color: #00a980;
    font-weight: bold;
  }
}

.nav-item.active {
  background-color: $theme-active-nav-bg;
  color: $theme-active-nav-bg;
  display: flex;
  align-items: center;
  opacity: 1 !important;
}

.disabled {
  a {
    color: gray !important;
  }
}

.tags-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .is-new-tag {
    bottom: 0px;
    left: 5px;
  }
  .lock-img {
  }
}

.tags-wrap.position-relative {
  position: relative;
  bottom: 7px;
}

.locked-item {
  justify-content: flex-start !important;
  width: 100% !important;

  &:hover,
  &:active {
    background-color: transparent !important;
    cursor: none !important;
  }
}

.expandable-item-wrap.no-spacing {
  margin-top: 0 !important;
}
