@import url('https://fonts.googleapis.com/css?family=Fira+Sans&display=swap');
@import 'src/index';

.dropdown-component.box-style {
  > div > button {
    border: $theme-widget-border-color;
    border-radius: 5px;
  }
}

.dropdown-component.font-weight-normal .btn {
  font-weight: normal;
}
.bg-transparent.dropdown-component {
  .btn {
    background-color: transparent;
  }
}

.dropdown-component {
  .btn {
    color: $theme-text-color;
    font-weight: 500;
    font-size: $font-size-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $theme-dropdown-button-color;
  }
  .btn:hover {
    color: $theme-text-color;
  }

  .btn-primary.btn {
    color: $theme-text-color;
    font-weight: 600;
    background-color: $theme-dropdown-button-color;
  }

  .bg--transparent .btn-primary.btn {
    background-color: transparent;
  }

  .dropdown {
    .dropdown-toggle:disabled {
      cursor: default;
    }
  }

  .dropdown-menu {
    width: 100%;
    min-width: min-content;
    background-color: $theme-form-control-color;
    max-height: 350px;
    overflow: auto;
    .dropdown-item,
    #dropdown-basic-button {
      font-size: $font-size-medium;
      color: $theme-text-color;
    }

    .dropdown-item > .add-domain-text {
      text-align: center;
      color: #2168e4;
      font-weight: 500;
    }

    .dropdown-item.disabled {
      color: var(--theme-dropdown-text-disabled-color);
    }

    .dropdown-item:hover {
      background-color: $theme-dropdown-hover-bg-color;
    }
    .loading-skeleton {
      cursor: not-allowed;
      pointer-events: none;
      animation: loading-skeleton 1s infinite alternate;

      background-color: $theme-widget-bg-color-2;
      margin: 2px auto;
      width: 90%;
      img {
        filter: grayscale(100) contrast(0%) brightness(1.8);
      }
      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      .btn,
      label,
      .form-control {
        @extend %loading-skeleton;
      }
    }
  }

  .dropdown-toggle::after {
    margin-left: $padding-small;
  }

  .remove-outline {
    &.px--end-14 {
      > .MuiSelect-select.MuiOutlinedInput-input {
        padding-right: 14px;
        padding-block: 0;
        cursor: pointer;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

.dropdown-component.dropdown-hide-arrow {
  .dropdown > button::after {
    display: none;
  }

  .MuiSelect-icon {
    display: none;
  }
}

.MuiList-root.MuiMenu-list {
  width: 100% !important;
  max-width: none;
}

%loading-skeleton {
  height: 25px;
  color: transparent;
  appearance: none;
  -webkit-appearance: none;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
