@import 'src/index';

.advanced-search-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 15px;

  .advanced-search-header {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .advanced-search-categories-options-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;

    .advanced-search-category {
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }

    .advanced-search-category-required {
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 5px;
      color: #00000099;
      letter-spacing: 0.5px;
      .dark & {
        color: #ffffff61;
      }
    }

    .advanced-search-options-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 5px;
    }

    .advanced-search-options {
      border: none;
      border-radius: 24px;
      background-color: #00000014;
      color: #000000de;
      padding: 2px 12px;
      height: 30px;
      width: auto;
      font-size: 13px;
      .dark & {
        background-color: #4d5052;
        color: #c6d3e0;
      }
    }

    .advanced-search-options.selected {
      background-color: var(--theme-page-text);
      color: var(--theme-page-background);
      .dark & {
        background-color: #1d4785;
        color: #fff;
      }
    }

    .advanced-search-options.disabled {
      background-color: #d6d6d6;
      color: #9e9e9e;
      cursor: not-allowed;
      .dark & {
        background-color: #414344;
        color: #85888c;
      }
    }
  }

  .advanced-search-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
  }
}
