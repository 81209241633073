@import 'src/index';

.label-component {
  display: flex;
  align-items: flex-start;

  img {
    padding-top: $padding-xxsmall;
    padding-right: $padding-small;
  }
}
