@import 'src/index';
.save-current-filters {
  display: flex;
  padding-right: $padding-normal;
  .save-current-filter-btn {
    border: 1px solid $theme-hollow-btn-border;
    color: $theme-hollow-btn-border;
    border-radius: 5px;
  }
  .save-current-filter-btn:hover {
    background-color: $theme-hollow-btn-border;
    color: $white;
  }
}
.saved-filters-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 366px !important;
  background-color: $theme-background-color;
  top: 40px !important;
  left: 10px !important;
  z-index: 10;
  box-shadow: 0px 0px 7px rgba(5, 3, 16, 0.33);
  padding: $padding-small $padding-xsmall 0px $padding-xsmall;
  height: 184px !important;
  padding: $padding-normal;
  transform: translate3d(-50x, -40px, 0px) !important;

  .save-filter-header {
    font-weight: 500;
    font-size: $font-size-large;
    line-height: 19px;
    padding-bottom: $padding-normal;
    background-color: $theme-background-color;
    .saved-filter-name {
      color: $theme-secondary-text-color-light;
      max-width: 170px;
      margin-right: $padding-small;
    }
  }
  .saved-filter-name-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 35px;
    margin: 8px 0px 25px 0px;
    .saved-filter-name {
      margin-left: $padding-normal;
    }
    .text-destination {
      color: $theme-secondary-text-color-light;
      width: 45px;
    }
    .text-destination-input {
      input {
        opacity: 0.7;
        background-color: $theme-filter-button-bg;
        margin-left: $padding-small;
        margin-right: $padding-small;
        padding: 0 $padding-small;
        flex: 1;
        border: $theme-border;
        border-radius: 0;
        color: $theme-text-color;
        height: 35px;
        width: 160px;
      }
    }
    .text-saved-input {
      width: 100%;
      input {
        background-color: $theme-filter-button-bg;
        margin-left: $padding-small;
        margin-right: $padding-small;
        padding: 0 $padding-small;
        flex: 1;
        border: $theme-border;
        border-radius: 0;
        color: $theme-text-color;
        height: 35px;
        width: 98%;
      }
    }
  }
  .save-filters-btn-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .submit-button,
    .cancel-button {
      margin-right: 12px;
    }

    .button-type--split {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .save-for-button {
    .btn-primary {
      background-color: $theme-hollow-btn-border;
      border: none;
      color: #fff;
    }
    .dropdown-toggle-split {
      background-color: $theme-hollow-btn-border;
      margin: unset;
      border-left: 1px solid #fff !important;
      border: none;
      padding-right: 20px;
    }
    .btn-primary,
    .dropdown-toggle:hover {
      margin: unset;
      border: 1px solid $theme-hollow-btn-border;
    }
    .dropdown-menu {
      transform: translate3d(0px, 35px, 0px) !important;
    }
  }
}
