@import 'src/index';

.loading-wrapper-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  flex-flow: column nowrap;
  .loader-text {
    margin: 2rem auto;
  }
  .spinner-border {
    width: 72px;
    height: 72px;
    color: $platinum;
  }
  .small-spinner {
    width: 2rem;
    height: 2rem;
  }
}
