@import 'src/index';

.widget-collapsed {
  animation: collapse-tile 1s ease 1;
  max-width: 49.5%;
  min-width: 600px !important;
  flex-grow: 1;
  overflow-x: clip;
}
.widget-expanded {
  animation: expand-tile 1s ease 1;
}
.enitity-widget-wrap {
  padding: 0 !important;
  overflow-y: clip;
  height: 400px !important;
  background-color: $theme-widget-background !important;
  margin: 1rem 0 !important;
  .inner-wrap {
    overflow: hidden;
    height: 100%;
  }
  .subtext {
    color: rgba(102, 102, 102, 0.7);
  }
  .widget-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $theme-darkweb-border-storke;
    background-color: $theme-darkweb-gb-color2;
    padding: 0.5rem;
    .header-info {
      display: flex;
      align-items: center;
      .icon-wrap {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin: 5px;
      }
      .entity-title {
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0 5px;
      }
      .found-summary-subtext {
        font-size: 14px;
        color: $theme-darkweb-subtext;
      }
      .subtext {
        color: $theme-darkweb-subtext;
      }
    }
    .see-findings-wrap {
      display: flex;
      align-items: center;
      color: $theme-hollow-btn-border;
      font-weight: 500;
      font-size: 14px;
      background-color: transparent;
      border: 0px solid transparent;
    }
  }
  .widget-body {
    min-height: 300px;
    height: 100%;
    padding: 1rem 2rem;
    .expand-collapse-icon {
      &:hover {
        cursor: pointer;
      }
    }
    .expand-tile-wrap {
      display: flex;
      position: absolute;
      right: 0;
      flex-flow: row nowrap;
      justify-content: flex-end;
      padding: 0.3rem 0.5rem;
      height: 70%;
      align-items: flex-start;
    }
    .end-box-shadow {
      width: 150px;
      background: radial-gradient(ellipse at right, $theme-widget-background, transparent);
    }
    .dropdown-wrap {
      display: flex;
      justify-content: space-between;
    }
    .widget-body-data-wrap {
      justify-content: flex-start;
      display: flex;
      padding: 1rem 0;
      .box-shadow {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 5%;
        background: linear-gradient(90deg, transparent, $theme-darkweb-gb-color2 80%);
        box-shadow: 0 0 10px $theme-background-color;
        border: 0px solid transparent;
      }
    }
  }
}

.inner-body-padding {
  padding-top: 1rem !important;
}

@keyframes expand-tile {
  0% {
    max-width: 45%;
  }
  100% {
    max-width: 100%;
  }
}

@keyframes collapse-tile {
  0% {
    max-width: 100%;
  }
  100% {
    max-width: 45%;
  }
}
