@import 'src/index';
.page-wrap {
  .mock-table-wrapper {
    height: 100%;
    height: -webkit-fill-available !important;
    box-sizing: border-box;
    margin: 24px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .blurr-img {
    img {
      filter: blur(2px);
    }
  }
}

@media screen and (max-width: 1100px) {
  .locked-page-wrap {
    box-sizing: border-box;
    height: 100% !important;
    box-sizing: border-box;
    .lock-banner-wrap {
      .banner {
        padding: 1rem 0 !important;
        height: 100%;
        .content-wrap {
          height: 100% !important;
        }
        .heading {
          padding: 1rem 0 !important;
          font-size: 1rem !important;
        }
      }
    }
    .mock-table-wrapper {
      box-sizing: border-box;
      height: 100% !important;
      img {
        height: 100% !important;
        object-fit: cover !important;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .locked-page-wrap {
    box-sizing: border-box;
    height: 100% !important;
    box-sizing: border-box;
    .lock-banner-wrap {
      .banner {
        flex-flow: column nowrap;
        padding: 1rem 0 !important;
        height: 100%;
        .content-wrap {
          height: 100% !important;
        }
        .heading {
          padding: 1rem 0 !important;
          font-size: 1rem !important;
        }
      }
    }
  }
}
