@import 'src/index';

.width-0 {
  width: 0px !important;
  max-width: 0px !important;
}

.border-0 {
  border: none !important;
}
.border-right-only {
  border-right: $theme-border;
}
.small-chip {
  font-size: 12px;
  font-weight: 500;
  border-width: 1px !important;
  border-radius: 5px;
  margin-bottom: 2px !important;
}
.user-name-checkphish {
  color: $theme-text-color;
}
.open-drawer-button-wrapper {
  width: auto;
  height: auto;
  padding: 25px 15px 0px 20px;
  display: block;
  // -webkit-animation: fadeOut 1s;
  // animation: fadeOut 1s;
}
.open-drawer-button-wrapper:hover {
  cursor: pointer;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    // width: 100%;
    // height: 100%;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    // width: 100%;
    // height: 100%;
  }
}
.directory-container {
  display: flex;
  flex-direction: column;
  .navHeader {
    font-weight: 500;
    font-size: $font-size-large;
    line-height: 24px;
    color: $theme-text-color;
  }
}
.side-bar-container {
  display: flex;
  flex-direction: column;
  background-color: $theme-background-color;
  border-right: $theme-border;
  .feedback-text {
    color: $theme-text-color;
    margin: 10px auto;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
  .auto-height-overflow {
    height: 100% !important;
    overflow-y: auto !important;
  }
  .side-bar-web-2nd {
    align-items: flex-start;
    width: 210px;
    max-width: 220px;
    padding: $padding-medium 0;
    background-color: $theme-background-color;
    transition: width 0.5s ease, max-width 0.5s ease;
    overflow: hidden;
    height: 100vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .second-level-header {
      overflow: hidden;
      width: 100%;
      padding: 0 $padding-normal $padding-normal $padding-normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: $font-size-xlarge;
      line-height: $font-size-xlarge;
      color: #b8b8b8;
      .brand-name {
        font-weight: 500;
        font-size: $font-size-medium;
        line-height: 17px;
      }
      .right-arrow-icon {
        display: none;
      }
      img {
        cursor: pointer;
      }
    }

    .second-level-body {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      font-size: $font-size-medium;
      width: 100%;
      .dashboard-label-wrapper {
        font-size: 13px;
        line-height: 21px;
        color: $theme-text-color;
        padding: $padding-small $padding-normal $padding-small $padding-normal;
      }
      .dashboard-label-wrapper:hover {
        font-weight: 600;
        background-color: $theme-hover-bg-color;
        color: $theme-hover-text-color;
        cursor: pointer;
      }
      .dashboard-label-wrapper.selected {
        font-weight: 600;
        background-color: $theme-hover-bg-color;
        color: $theme-hover-text-color;
        cursor: pointer;

        .dark & {
        }
      }
      .nav-sub-item-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        color: $dark-gray-5;
        line-height: 21px;
        .nav-sub-item-label {
          font-size: 15px;
          line-height: 20px;
          color: #b8b8b8;
          padding: $padding-normal $padding-normal $padding-small $padding-normal;
          .big-arrow-left {
            display: none;
          }
        }
        .nav-sub-item-mobile {
          font-size: 15px;
          line-height: 20px;
          color: #b8b8b8;
          padding: $padding-small $padding-normal;
          .big-arrow-left {
            display: none;
          }
        }
        .drawer-sub-item {
          font-size: 13px;
          line-height: 21px;
          color: $theme-text-color;
          padding: $padding-small $padding-normal $padding-small $padding-normal;
        }
        .drawer-sub-item:hover {
          background-color: $theme-hover-bg-color;
          font-weight: 600;
          cursor: pointer;
          color: $theme-hover-text-color;
        }
        .drawer-sub-item.selected {
          background-color: $theme-hover-bg-color;
          font-weight: 600;
          cursor: pointer;
          color: $theme-hover-text-color;
        }
        .disabled-sub-item {
          color: $theme-disabled-drawer-item;
          pointer-events: none;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
@media only screen and (max-width: 850px) {
  .side-bar-container {
    .side-bar-web-2nd {
      .second-level-header {
        .right-arrow-icon {
          display: block;
        }
      }
      .second-level-body {
        .nav-sub-item-container {
          display: flex;
          .nav-sub-item-mobile {
            color: $theme-text-color;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;

            .big-arrow-left {
              display: flex;
              width: 15px;
              height: 15px;
              padding: 19px 15px 0px auto;
            }
          }
          .nav-sub-item-mobile:hover {
            background-color: $theme-hover-bg-color;
          }
          .nav-sub-item-mobile.selected {
            background-color: $theme-hover-bg-color;
            font-weight: 600;
          }
        }
        .expandable-item-component.nav-sub-item-container {
          .nav-sub-item-mobile.selected {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.expandable-item-component {
  .chevron-icon {
    transform: rotate(-90deg);
    transition: transform 200ms ease-in;
  }
  .nav-sub-item-mobile.selected {
    font-weight: 600;
  }
  .expandable-item {
    cursor: pointer;
  }
  .nav-sub-items-container {
    max-height: 0;
    overflow: hidden;

    .drawer-sub-item {
      padding: $padding-small $padding-normal $padding-small calc(#{$padding-normal} * 2) !important;
      display: flex;
      align-items: center;
    }
  }

  &.expanded {
    .chevron-icon {
      transform: rotate(0deg);
    }
    .nav-sub-items-container {
      max-height: max-content;
    }
  }
}
.text-wrap-top-nav {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  height: 100%;
  padding: 0.5rem 0;

  .is-new-banner {
    font-size: 10px;
    background-color: $new-label-bg-color;
    color: #ffffff;
    width: max-content;
    padding: 1px 0.5rem;
    border-radius: 3px;
  }
  .external-link-tab {
    text-decoration: none;
    color: $theme-text-color !important;
  }
}
.checkphish-top-nav {
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center !important;
  .text-wrap-top-nav {
    .lock-icon {
      width: 12px;
      position: relative;
      bottom: 2px;
      right: 1px;
      margin-right: 5px;
    }
  }
}
