.progress-bar-container {
  text-align: center;
  padding: 0.5rem;
  .result-text {
    margin: 0.3rem auto;
  }
  .bar {
    background-color: #d9d9d9;
    border-radius: 40px;
    margin: 0.5rem auto;
  }
  .progress {
    background: linear-gradient(89.87deg, #4adeff 0.11%, #5b62ff 99.89%);
  }
}
