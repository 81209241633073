@import 'src/index';

.edit-custom-tag-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .edit-title {
    font-size: $font-size-normal;
    margin: $padding-medium-half 0px;
    font-weight: 600;
  }
  .custom-tag-input {
    width: 100%;
    height: 50px;
    padding: $padding-xsmall;
    border: $theme-border;
    border-radius: $padding-xsmall;
    font-size: $font-size-medium;
    color: $theme-text-color;
    &::placeholder {
      color: $theme-secondary-text-color;
    }
  }
}
