@import 'src/index';

.release-page {
  max-width: 1000px;
  border-top: $border2;
  color: $theme-text-color;

  .bg-yellow {
    background-color: $yellow;
  }

  ol {
    li {
      padding-top: 1.5rem;
      font-size: $font-size-large;
      font-weight: normal;
    }
  }

  .image-note {
    font-size: $font-size-medium;
    color: $dark-gray-2;
  }
}

.release-page:first-child {
  border-top-width: 0;
}
