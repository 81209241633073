@import 'src/index';

$range-bg-color: #e9ecf0;
$track-color: transparent !default;
$thumb-color: #407cea !default;

$thumb-radius: 9px !default;
$thumb-height: 18px !default;
$thumb-width: 18px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
$thumb-border-width: 2px !default;
$thumb-border-color: #fff !default;

$track-width: 100% !default;
$track-height: 7px !default;
$track-shadow-size: 0 !default;
$track-shadow-blur: 0 !default;
$track-shadow-color: transparent !default;
$track-border-width: 0 !default;
$track-border-color: transparent !default;

$track-radius: 0 !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

$pro-bg-color: #00c1b0;

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
    0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
}

@mixin thumb {
  // @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: pointer;
  height: $thumb-height;
  width: $thumb-width;
  box-shadow: 0 0 0 4px $thumb-color;
  filter: drop-shadow(0 0 2px #999);
}

[type='range'] {
  // -webkit-appearance: none;
  background: transparent;
  margin: $thumb-height / 2 0;
  width: $track-width;
  z-index: 1;
  position: relative;
  background: $range-bg-color;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      // background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      // background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
  }

  &::-moz-range-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}

.products-page,
.pricing-wrap {
  .plans-slider-wrap {
    width: 86%;
    position: relative;
    margin-bottom: 1rem;
  }
  .plans-h1 {
    padding-top: 4rem;
  }
  .plans-desc {
    font-size: 24px;
    font-weight: 400;
    padding-top: 1rem;
    padding-bottom: 2rem;
    @media only screen and (min-width: $screen-size-md) {
      padding-bottom: 16rem;
    }
  }
  .plans-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 25px;
    margin-top: 7px;
    & input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .plans-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8bb0ef;
    transition: 0.4s;
    border-radius: 34px;
    &:before {
      border-radius: 50%;
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 1px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
    }
  }
  input:checked + .plans-slider {
    background-color: $thumb-color;
  }

  input:focus + .plans-slider {
    box-shadow: 0 0 1px $thumb-color;
  }

  input:checked + .plans-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .plans-cards {
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-gap: 1rem;
    justify-content: center;
    margin: 0 $padding-medium-half $padding-medium $padding-medium-half;
    @media only screen and (min-width: $screen-size-md) {
      flex-direction: row;
      display: flex;
      text-align: center;
      grid-gap: 1rem;
      justify-content: center;
      margin: 0 $padding-medium-half $padding-medium $padding-medium-half;
    }
  }
  .plans-card {
    border: 1px solid $range-bg-color;
    padding: 0.5rem;
    // background-color: #fff;
    flex-basis: 394px;
    margin: 2rem 1rem 0;
    @media only screen and (min-width: $screen-size-md) {
      margin: 0;
      margin-top: -12rem;
    }
  }
  .plans-h3 {
    border-bottom: 1px solid $range-bg-color;
    margin: 2rem 1.5rem 0;
    padding-bottom: 2rem;
    font-size: 1.75rem;
  }
  .plans-summary-btn {
    background-color: #fff;
    color: #407cea;
    width: 88%;
    height: 50px;
    border: 1px solid #e8e8e8;
    font-weight: 500;
  }
  @media only screen and (max-width: 850px) {
    .plans-summary-btn {
      width: 78% !important;
      height: 50px;
      padding: 5px;
      font-size: 12px;
    }
  }
  .plans-btn {
    background-color: #407cea;
    color: #fff;
    width: 88%;
    font-weight: 500;
    padding: 0.65rem;
    &.mod-pro {
      background-color: $pro-bg-color;
      color: #000;
    }
    &.mod-ent {
      background-color: #000;
    }
  }
  @media only screen and (max-width: 850px) {
    .plans-btn {
      width: 78% !important;
      height: 50px;
      padding: 5px;
      font-size: 12px;
    }
  }
  .plans-switch-wrap {
    display: flex;
    font-size: 14px;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .plans-info {
    display: flex;
    flex-direction: column;
    height: 16rem;
    align-items: center;
    justify-content: center;
    line-height: 2.5rem;
    font-size: 1.25rem;

    h4 {
      margin: 0.75rem;
    }
  }
  .plans-title {
    padding: 1.5rem 0 1rem;
    margin: 0 2rem;
    font-size: 20px;
    border-bottom: 1px solid $range-bg-color;
  }
  .plans-list {
    margin-top: 0.5rem;
    list-style-position: outside;
    & li {
      text-align: left;
      list-style: inside;
      list-style-type: none;
      position: relative;
      padding-left: 23px;
    }
    & img {
      vertical-align: top;
    }
  }
  .plans-check {
    position: absolute;
    left: -5px;
  }

  .plan-slide-ridge {
    height: 26px;
    width: 3px;
    background-color: $range-bg-color;
    position: absolute;
    z-index: 0;
    top: 11px;
    &.mod-1 {
      left: 0;
    }
    &.mod-2 {
      left: 25%;
    }
    &.mod-3 {
      left: 50%;
    }
    &.mod-4 {
      left: 73%;
    }
    &.mod-5 {
      left: 100%;
    }
  }

  .products-page-plans-section {
    max-width: 1000px;
    margin: auto;

    @media only screen and (max-width: $screen-size-md) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-bottom: 0 !important;
    }
  }
}
.pro-plan-modal .plans-card {
  @media only screen and (min-width: $screen-size-md) {
    margin-top: 0;
  }
}
