@import 'src/index';
@import '../Style/monitoredDomains.scss';
@import '../../../components/Dashboard/dashboard.scss';

.dashboard-page-v2 {
  padding: $padding-medium;
  width: -webkit-fill-available;

  .dashboard-widget {
    .label-and-value-component {
      margin: 8px 0;

      .card-label {
        color: $dark-gray-3;
      }

      .card-value {
        font-size: $font-size-xxxlarge;
        font-weight: 600;
      }
    }
  }

  .dashboard-monitored-acquisitions {
    display: flex;
    padding-left: 15px;
    position: relative;

    .arc-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .dashboard-chart-title {
        font-size: 20px;
      }

      .small-text {
        font-family: Fakt;
        font-size: 14px;
        font-weight: 400;
        color: #7b7b7b;
      }

      .arc-image-container {
        padding-top: 40px;
        position: relative;

        .arc-text-container {
          display: flex;
          flex-direction: row;
          color: #adb3c2;
          position: absolute;
          left: 65px;
          bottom: 25px;

          .arc-number-text-big {
            font-size: xx-large;
            color: $theme-text-color;
            padding-right: 5px;
          }

          .arc-number-text-small {
            font-size: x-small;
            color: $theme-text-color;
            width: 0;
            word-wrap: normal;
            padding-top: 10px;
            padding-left: 5px;
          }
        }

        .unregistered-text {
          color: $unregistered-text;
          font-size: 14.508px;
          position: absolute;
          bottom: 0;
          left: 65px;
        }
      }
    }

    .progress-bar-container {
      .dashboard-chart-title {
        font-size: 20px;
        margin-bottom: 50px;
      }

      .bar-container {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        align-items: center;

        .p-font {
          color: #333;
          font-size: 14.508px;
        }

        .money {
          color: #747474;
          font-family: Fakt;
          font-size: 16px;
          margin-left: 10px;
        }
      }

      display: flex;
      flex-direction: column;
      flex: 1.5;
    }
  }
}

.monitored-domains-container-v2 {
  @extend .monitored-domains-container;
  border: unset !important;
  width: 100% !important;
  margin: 0 !important;

  .monitored-domains-header-v2 {
    @extend .monitored-domains-header;
    padding-left: 0 !important;
    // width: 1200px;

    .monitored-domains-title-v2 {
      @extend .monitored-domains-title;

      .title-container-v2 {
        @extend .title-container;
      }
    }

    .btn.round-filter {
      border-radius: 42.881px;
      background: #e9ecf0;
      color: var(--light-scheme-text-text-default, #333);
      font-size: 11px !important;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }

    .monitored-domains-actions-v2 {
      @extend .monitored-domains-actions;

      .legend-icon {
        margin-right: 20px;
      }

      justify-content: flex-end;
    }

    .description {
      color: var(--light-scheme-text-text-disable, #7b7b7b);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.042px;
    }
  }

  .monitored-domains-funnel-container {
    margin: 0 !important;
    width: auto;

    .monitored-domains-funnel-wrapper-v2 {
      @extend .monitored-domains-funnel-wrapper;
      display: flex;
      min-width: unset;

      .acquisition-wrapper-v2 {
        @extend .acquisition-wrapper;
        border-radius: 13.429px;
        height: 200px;
        margin-right: 5px;

        .monitored-pre-label-wrapper {
          background: unset;
          padding-right: 5px;
          height: 75px;

          .hover-wrapper:hover {
            font-weight: 500;
            cursor: pointer;
          }
        }

        .monitored-takedown-label-wrapper {
          background: unset;
          padding-right: 5px;
        }

        .monitored-post-label-wrapper {
          background: unset;
        }

        .text-bubble-container {
          left: 25px;
          top: 50px;
          position: absolute;
        }

        .funnel-wrapper {
          height: unset !important;
          padding-top: 40px;
          justify-content: unset;

          .funnel-content {
            justify-content: end;
            align-items: unset;
            padding-bottom: 15px;
          }

          .funnel-value {
            padding-left: 25px;
            justify-content: unset !important;
          }

          .funnel-label {
            color: #fff;
            font-size: $font-size-normal;
            font-weight: 600;
            text-transform: uppercase;
            opacity: 0.7599999904632568;
          }
        }

        .funnel-wrapper.non-clickable {
          position: absolute;
          top: 73px;
        }

        #funnel-container {
          height: unset !important;
          transform: unset !important;
          background: unset !important;
        }

        div.monitored-domains-label-v2 {
          display: flex;
          flex-direction: row;
          margin-top: 22px;
          color: #fff;
          font-size: $font-size-large;
          font-weight: 500;
          letter-spacing: 0.2px;
          text-transform: capitalize;
          padding-left: 27px;

          .acq-chevron-right {
            position: absolute;
            right: 27px;
          }
        }
      }

      .disabled-wrapper {
        width: 100% !important;
      }

      .hover-wrapper:hover {
        opacity: 1 !important;
      }
    }
  }

  @media only screen and (min-width: 1500px) {
    .monitored-domains-funnel-wrapper-v2 {
      justify-content: center;
    }

    .monitored-domains-header-v2 {
      padding: 24px 0px 24px 24px;
      width: 1200px;
    }
  }
}

.button-small {
  width: 120px;
}

@media only screen and (min-width: 1500px) {
  .monitored-domains-container-v2 {
    align-items: center;
  }
}

.title-wrapper-v2 {
  @extend .title-wrapper;
  width: unset !important;
  margin-right: 10px;
  font-size: 20px !important;
  margin-left: unset !important;
}

.bar-container {
  .money-bars {
    border-radius: 17px;
    height: 16.325px;
    margin-left: 10px;
    max-width: 60%;
  }
}

.see-details-btn {
  display: flex;
  flex-direction: row;

  .see-details-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    padding-right: 5px;
    color: $unregistered-text !important;

    &:hover {
      cursor: pointer;
    }
  }

  position: absolute;
  bottom: 0;
  right: 30px;
}

.widget-item-component-v2 {
  @extend .widget-item-component;

  .widget-item-inner {
    min-height: unset;
  }
}

.funnel-label-v2 {
  @extend .funnel-label;
  display: flex;
  flex-direction: row;
  opacity: 1 !important;

  .label-text {
    opacity: 0.7599999904632568;
  }
}
