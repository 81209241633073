@import 'src/index';

.entity-dropdown-wrap {
  display: flex;
  border: 1px solid $theme-darkweb-border-storke;
  background-color: $theme-darkweb-gb-color2;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  min-width: 300px;
  .dropdown-header {
    color: $theme-text-color;
    width: 30%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .entity-dropdown-btn {
    background-color: transparent !important;
    color: $theme-hollow-btn-border;
    border: 0px solid transparent;
    border-left: 1px solid $theme-darkweb-border-storke;
    height: 80%;
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-between;
    text-align: left;
    &:focus,
    &:active,
    &:hover {
      color: $theme-hollow-btn-border !important;
      background-color: transparent !important;
      border-left: 1px solid #e1e1e1 !important;
    }
  }
  .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
  .dropdown-options {
    width: 200px;
    background-color: $theme-darkweb-gb-color2;
    color: $theme-text-color;
    padding: $padding-small;
  }
}
