@import 'src/index';

.pricing-page-wrap {
  .center-container {
    height: 500px;
  }
  .header-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    margin-top: 1rem auto;
    color: $theme-text-color;
    .main-heading {
      margin: 1rem auto;
    }
    .sub-heading {
      color: $theme-pricing-subtext;
      font-weight: 400;
      font-size: 18px;
      max-width: 650px;
      .contact-us-link {
        color: #327ef0 !important;
        font-weight: normal;
      }
    }
  }
  .pricing-plans-wrap {
    border-bottom: 1px solid #dde7ee;
  }
  .pricing-plans-wrap,
  .plan-block {
    .plan-price-info-wrap {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      .domain-select-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      }
    }
  }
  .pricing-plans-wrap,
  .add-on-card {
    display: flex;
    flex-flow: row nowrap;
    .pricing-plan {
      min-width: 350px;
      padding: 1rem;
      border-right: 1px solid #dde7ee;

      .description {
        margin: 0.5rem auto;
        color: $theme-pricing-subtext;
        font-size: 14px;
      }
      .feature-header {
        color: $theme-pricing-subtext-light;
        font-size: 14px;
        padding: 10px 0;
      }
      .feature-wrap {
        margin: 1rem auto;
        display: flex;
        flex-flow: column nowrap;
        .feature {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin: 3px auto;
          font-size: 14px;
          width: 100%;
          img {
            margin: 3px;
            margin-right: 10px;
            align-self: flex-start;
          }
        }
      }

      &.professional-api--min-width {
        min-width: 217px;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  .add-on-card {
    padding: 1rem 0;
    .add-on-widget-wrap {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      .pricing-plan {
        border-right: 0px solid transparent;
      }
      .pricing-wrap {
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        margin: 0 3rem;

        .plans-slider {
          background-color: $theme-plan-professional-text-color;
        }
        .plans-btn {
          background-color: $theme-plan-professional-text-color;
          color: white;
          border-radius: 5px;
        }
        .plans-info {
          width: 100%;
          line-height: auto;
          .plans-slider-wrap {
            .plans-range {
              accent-color: $theme-plan-professional-text-color !important;
              width: 101%;
            }
            .plans-range::-webkit-slider-thumb {
              box-shadow: 0px 0px 0px transparent;
              width: 20px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
      .img-wrap {
        box-sizing: border-box;
        img {
          height: 400px;
        }
      }
    }
  }
  h4 {
    color: $theme-text-color;
  }
  .comparision-table-wrap {
    min-height: 30rem;
    margin-bottom: 50px;
    .comparision-header-wrap {
      display: flex;
      flex-flow: row nowrap;
      height: auto;
      padding: 1rem auto;
      .plan-block {
        flex-grow: 1;
        width: auto;
        border: 1px solid #dde7ee;
        border-left: transparent;
        padding: 1rem;
        width: 25%;
      }
      .plan-block:first-child {
      }
      .plan-block:last-child {
        border-right: transparent;
      }
    }
    .table-container {
      max-height: 800px;
      .table-header {
      }
      .table {
        .table-header {
          .table-row:first-child {
            .table-cell {
              background-color: $action-btn-active-background;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }
        .table-header-light {
          border: 0px solid transparent !important;
          .table-row {
            border: 0px solid transparent !important;
            .table-cell {
              color: $theme-pricing-subtext-light;
              font-weight: 600 !important;
              background-color: $theme-background-color !important;
            }
          }
        }
        .table-row {
          .table-cell:first-child {
            width: 25%;
          }
          .table-cell {
            width: 20%;
            color: $theme-text-color;
            border: 1px solid $cell-border;

            &.header-row {
              background-color: $action-btn-active-background !important;
              font-weight: 600;
              color: #ffffff;
            }

            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }
    .body-table {
      .table-row {
      }
      .table-cell {
        text-align: center;
        vertical-align: middle;
      }

      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
  .checkout-wrap {
    padding: 3rem;
    .profession-api-widget-wrap.highlight {
      border: 2px solid $theme-plan-professional-text-color;
    }
    .profession-api-widget-wrap {
      border: 2px solid #636b744d;
      padding: 3rem 1rem;
      min-height: 150px;
      .widget-header {
        display: flex;
        justify-content: space-between;
        .MuiSwitch-switchBase + .MuiSwitch-track {
          background-color: #555e68;
        }
        .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
          background-color: $theme-plan-professional-text-color;
        }
        .MuiSwitch-switchBase.Mui-checked {
          color: white;
        }
      }
      h3 {
        color: white !important;
      }
      .checkout-slider {
        // border: 1px solid red;
        margin: 10px auto !important;
        max-width: 400px;
      }
      .live-scan-pricing-cards-wrap {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .plan-card {
          display: flex;
          flex-flow: column nowrap;
          padding: 0.5rem;
          height: 120px;
          flex-grow: 1;
          background-color: #eff2f7;
          cursor: pointer;
          margin: 5px;
          .pricing {
            display: flex;
            flex-flow: column nowrap;
            margin-bottom: 10px;
          }
        }
        .current-plan {
          background-color: $theme-plan-professional-bg-color;
          border: 1px solid $theme-plan-professional-text-color;
        }
      }
    }
    .checkout-summary-wrap {
      padding: 1rem;
      margin: 1rem 0;
      display: flex;
      flex-flow: column nowrap;
      color: $theme-text-color;
      .summary-item {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        margin: 0.3rem;
        .pricing-data {
          font-weight: bold;
        }
        .saving-highlight {
          color: #27a95b;
        }
      }
      .total {
        font-size: 22px;
        .subtotal-wrap {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-end;
          .sub-heading {
            font-size: 16px;
            color: $theme-pricing-subtext;
          }
        }
      }

      .horizontal-line {
        border-top: 3px solid #636b744d;
        margin: 1rem 0;
      }
    }
  }
}

.free {
  color: $theme-plan-free-text-color !important;
  background-color: $theme-plan-free-bg-color;
  border-color: $theme-plan-free-border-colors !important;
}
.starter {
  color: $theme-plan-starter-text-color !important;
  border-color: $theme-plan-starter-text-color !important;
  background-color: $theme-plan-starter-bg-color;
  padding-inline: 5px !important;
}
.pro {
  color: $theme-plan-pro-text-color !important;
  border-color: $theme-plan-pro-text-color !important;
  background-color: $theme-plan-pro-bg-color;
}
.premium {
  color: $theme-plan-premium-text-color !important;
  border-color: $theme-plan-premium-text-color !important;
  background-color: $theme-plan-premium-bg-color;
}

.professional_api {
  color: $theme-plan-professional-text-color !important;
  border-color: $theme-plan-professional-text-color !important;
  background-color: $theme-plan-professional-bg-color;
  text-transform: none;
  padding-inline: 5px !important;
}

.size-small {
  max-width: 150px;
}
.black-and-white {
  &:hover,
  &:active,
  &:focus {
    background-color: #000000 !important;
    border: 1px solid $action-btn-border;
  }
}
.black-and-white.disabled-btn {
  &:hover,
  &:active,
  &:focus {
    background-color: $action-btn-disabled-background !important;
  }
}
.bold-text {
  font-weight: 500;
}

.compare-plan-block,
.toggle-switch-wrap {
  .toggle-switch-full-width {
    margin: 0 auto;
    width: 250px;
  }
  .toggle-switch {
    background-color: $toggle-btn-bg;
    border-radius: 5px;
    border: 0px solid $toggle-btn-bg;
    padding: 2px;
    .toggle-switch-item {
      height: 30px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $theme-text-color;
      font-size: 12px;
      font-weight: 500;
      margin: 2px;
      .additional-text {
        background: #ecf5ff;
        border: 1px solid #bed3f4;
        border-radius: 4px;
        padding: 4px 8px;
        margin: 0 5px;
        color: #4479f3;
        box-shadow: 0.59px 0.59px 8px 0px #bed3f4;
      }
    }
    .toggle-switch-item.selected {
      border-radius: 5px;
      background-color: $toggle-btn-active;
      color: #000000;
      box-shadow: 0.59px 0.59px 8px 0px $toggle-btn-bg;
    }
  }
}
.toggle-switch-wrap {
  display: flex;
  justify-content: center;
}

.checkout-form {
  display: flex;
  flex-direction: column;
  .form-outline-header {
    padding: 0 1rem;
    display: flex;
    color: $theme-text-color;
    justify-content: space-between;
    align-items: center;
    span {
      width: 105px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $theme-pricing-subtext;
    }
  }
  form {
    display: flex;
    flex-flow: row wrap;
    border: 1px solid #636b744d;
    border-radius: 10px;
    padding: 1rem 0;
    justify-content: space-around;

    .stripe-payment-form {
      width: 600px;
      padding: 1rem;
      .form-group {
        display: flex;
        justify-content: space-between;
        flex-flow: row nowrap;
        label {
          font-size: 14px;
          font-weight: normal;
          color: gray;
          flex-grow: 1;
          margin: 10px;
          .form-control {
            background-color: $theme-background-color;
            margin: 5px;
            box-shadow: 0 0 5px 1px rgba(128, 128, 128, 0.2);
            border-radius: 5px;
            color: $theme-text-color !important;
            border: 0px solid transparent;
            align-items: center;
            color: $theme-pricing-subtext;
            font-weight: 300;
            height: 40px;
            padding-top: 10px;
            font-size: 16px;
            &::placeholder {
              font-size: 16px;
              color: $theme-pricing-subtext-light;
            }
            &:focus {
              // box-shadow: 0 0 5px 1px #bbe9ff;
            }
          }
        }
        .error {
          position: relative;
          top: 5px;
          color: #df1b41;
        }
      }
    }

    .form-control-invalid {
      border: 2.5px solid #df1b41 !important;
    }
    .form-control-complete {
      color: #36ba98 !important;
      border: 2.5px solid #36ba98 !important;
    }
  }
}

.new-pricing-modal-toggle {
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.plan-card {
  width: 488px;
  height: 570px;
  border-radius: 12px !important;
  position: relative;
  background-color: $card-background-color !important;
  color: #fff;
}

span.close-html-entity {
  position: absolute;
  top: 10px;
  right: 14px;
  height: 23px;
  cursor: pointer;
  color: $card-cross-color;
}

.plan-card--content {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.plan-card--title {
  color: $card-text-color;
}

.plan-card--current-plan--title {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-weight: 400;
  line-height: 21px;
  color: #929aa2;
  text-transform: uppercase;
}

.card-content--wrapper--title {
  padding-block-start: 44px !important;
  padding-inline: 62px !important;
}

.divider {
  border: 1px solid rgba(99, 107, 116, 0.3);
}

.card-content-wrapper--description {
  padding-inline: 62px !important;
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .btn {
      padding: 2px 12px;
      height: 42px;
      border-radius: 6px;
      box-shadow: 0 2px 4px #15151514;

      &.plain-btn {
        // background-color: #fff !important;
        background-color: $go-back-btn-background-color;
        color: $go-back-btn-color !important;
        border: 1px solid $go-back-btn-border-color;
      }
      &.cta-btn {
        background-color: $cta-btn-background-color !important;
        color: $cta-btn-text-color !important;
        border: 1px solid #15151514;
      }

      &:is(.plain-btn, .cta-btn):hover {
        cursor: pointer;
      }
    }
  }
}

.plan-card--pack {
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: #555e68;
  font-size: 14px;

  .pack-type--starter {
    background-color: $theme-plan-starter-bg-color;
    color: $theme-plan-starter-text-color;
    border: 1px solid #93e7e2;
    max-width: fit-content;
    border-radius: 6px;
    padding: 3px 10px;
  }

  .pack-type--pricing {
    color: $theme-text-color;
  }
}

.plan-card-description-container {
  display: flex;
  flex-direction: column;
  color: $card-descriptipn-text-color;

  :is(.plan--availability, .plan--service-term) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .plan--service-term {
    a {
      text-decoration: underline;
      color: #555e68;
    }
  }
}

.z-index-1 {
  z-index: 500000000 !important;
}

.select-domain-input-box {
  color: $mui-input-root-color !important;
  border: 1px solid $mui-select-box-border-color !important;
}

.lock-icon-image {
  filter: $theme-light-dark-lock-color;
}

.MuiMenu-list:has([data-testid='domain-select-value']) {
  background-color: $mui-ul-background-color;
  color: $mui-li-item-color;
}

input[type='range']:focus {
  outline: none;
}
