@import 'src/index';

$checkbox-size: 16px;

.check-box-container.disabled {
  .checkmark {
    background-color: var(--theme-checkbox-disabled-color) !important;
    cursor: default;
  }
  background-color: transparent !important;
}
.check-box-container {
  display: block;
  position: relative;
  padding-right: $padding-normal;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .label {
    padding-left: $padding-medium;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $theme-hollow-btn-border;
    border: none;
    border-radius: 1px;
  }

  input:checked ~ .minus-checkmark-style {
    background-color: $theme-modal-overlay-close-btn;
    border: none;
    border-radius: 1px;
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $checkbox-size;
    width: $checkbox-size;
    background-color: var(--theme-checkbox-color);
    border: 1px solid $theme-secondary-text-color;
    border-radius: 1px;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    height: 9px;
    border: solid white;
  }

  .checkmark-style:after {
    top: 3px;
    width: 5px;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(38deg);
    -webkit-transform: rotate(38deg);
    -ms-transform: rotate(38deg);
  }

  .minus-checkmark-style:after {
    background-color: $theme-modal-overlay-close-btn;
    top: 2px;
    width: 6px;
    border-width: 0 1.5px 0 0;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
}
