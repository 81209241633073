@import 'src/index';

.attached-file-viewer-modal {
  z-index: 1071;
  top: 60px;
  height: calc(100% - 60px);
}

.attached-file-viewer-container {
  .list-group-horizontal {
    flex-wrap: wrap;
    border-radius: 0;
    width: 100%;

    .list-group-item {
      width: calc(50% - 1 * #{$padding-xsmall});

      .attached-file-name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .list-group-item:first-child {
      margin-top: $padding-small;
    }

    .list-group-item:nth-child(odd) {
      margin-right: $padding-xsmall;
    }

    .list-group-item:nth-child(even) {
      margin-left: $padding-xsmall;
    }
  }

  .list-group {
    .list-group-item {
      margin-top: $padding-small;
      border-top-width: 1px;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      background-color: $theme-widget-bg-color;
      .attached-file-name {
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }

  .list-group-item:first-child {
    margin-top: $padding-normal;
  }

  .list-group-item {
    cursor: default;
    margin-top: $padding-normal;
    border-radius: 0 !important;
    border-left-width: 1px !important;
  }
}

.attached-file-viewer-container.sm {
  .list-group {
    .list-group-item {
      max-width: 150px;
      min-width: 100px;
      padding: $padding-xxsmall;
      color: $blue2;
      font-weight: 600;
      border-radius: 4px !important;

      .file-img {
        display: none;
      }
    }
    .list-group-item {
      margin-right: $padding-small;
    }
    .list-group-item {
      margin-left: 0;
    }
  }
}

.image-slider {
  position: fixed;
  top: $top-nav-bar-height;
  left: 0;
  width: 100vw;
  height: calc(100vh - #{$top-nav-bar-height});
  background-color: $dark-gray-5;
  z-index: 9999;
  color: $white;

  .image-slider-header {
    padding: $padding-medium $padding-normal;
    border-bottom: $border;

    .file-name {
      font-weight: 500;
      font-size: $font-size-large;
      padding-right: $padding-normal;
      border-right: $border;
    }

    .created-ts {
      font-size: $font-size-normal;
      padding-left: $padding-normal;
    }

    img {
      margin-left: $padding-normal;
      cursor: pointer;
    }
  }

  .image-slider-body {
    flex: 1;

    img {
      padding: 0 $padding-medium;
    }

    .attached-file-viewer-carousel {
      .carousel-item {
        object-fit: cover;
        height: calc(100vh - 150px - 3 * #{$padding-medium});
        width: 100%;

        .img-slide {
          max-width: calc(100vw - 6 * #{$padding-medium});
          object-fit: contain;
          padding: $padding-medium 0;
        }
      }
    }
  }

  $image-picker-height: 74px;

  .image-slider-footer {
    margin: 0 $padding-medium $padding-medium $padding-medium;
    overflow: scroll;
    max-width: calc(100vw - 2 * #{$padding-medium});
    width: min-content;
    height: $image-picker-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .image-thumbnail.selected {
      opacity: 1;
    }

    .image-thumbnail {
      width: 132px !important;
      height: $image-picker-height;
      background-color: $dark-gray-3;
      opacity: 0.3;
      transition: opacity 0.3s;

      img {
        width: 132px !important;
        height: $image-picker-height;
        object-fit: contain;
        cursor: pointer;
      }
      div {
        .static-text-placeholder {
          height: 2px;
          display: block;
          background: lightgray;
          margin: 3px 1px 0px 2px;
        }
        .fix-loader {
          @extend .static-text-placeholder;
          margin: 4px 1px 0px 2px;
          width: 98%;
        }
        .fix-loader-65 {
          @extend .static-text-placeholder;
          width: 65%;
        }
        .fix-loader-half {
          @extend .static-text-placeholder;
          width: 45%;
        }
        .fix-loader-big {
          @extend .static-text-placeholder;
          width: 95%;
          height: 15px;
        }
      }
    }

    .image-thumbnail + .image-thumbnail {
      margin-left: $padding-medium;
    }
  }
}
