@import 'src/index';

.password-eye-component {
  margin-top: $padding-normal;
  position: relative;

  .password-label {
    font-weight: 500;
    font-size: $font-size-normal;
    margin-bottom: $padding-small;
    color: $theme-secondary-text-color;
  }

  input {
    display: block;
    max-width: 100%;
    width: 100%;
    padding-top: $padding-small;
  }

  .password-trigger {
    position: absolute;
    top: 52%;
    right: $padding-small;
    color: #666;
    user-select: none;
  }

  input.form-control.error {
    border-color: $red;
  }

  input.form-control.padding-x--end {
    padding-inline-end: 2.25rem;
  }
}
