.outer-layer {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(0, 175, 170, 0.0001) 0deg,
    #5178eb 359.96deg,
    rgba(0, 175, 170, 0.0001) 360deg
  );
  animation: rotation 1s linear infinite;
  .inner-layer {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: white;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
