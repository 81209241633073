@import 'src/index';

.widget-header-wrapper {
  display: flex;
  flex-direction: column;

  .header-name {
    font-weight: 500;
    font-size: $font-size-large;
    color: $theme-text-color;
    gap: $padding-small;
  }

  .header-description {
    padding-left: $padding-medium;
    font-size: $font-size-medium;
    letter-spacing: -0.011em;
    color: $theme-secondary-text-color;
  }
}
