@import 'src/index';

.table thead tr th:first-child,
.table tbody tr td:first-child {
  padding-left: $padding-medium;
}

.table {
  a {
    color: $theme-hollow-btn-border;
  }
}

.table thead tr th:last-child,
.table tbody tr td:last-child {
  padding-right: $padding-medium;
}

.tool-bar-container-padding {
  .tool-bar-container {
    padding-left: $padding-medium;
    padding-right: $padding-medium;
    background-color: $theme-widget-bg-color !important;
  }
}

.table-component {
  padding-top: 0;
  div.is-disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  @media screen and (max-width: 1130px) {
    .justify-content-end {
      justify-content: normal !important;
    }
  }
  .tool-bar-container.flex-down {
    flex-direction: column;
    align-items: normal;
  }
  .tool-bar-container {
    @media screen and (max-width: 1130px) {
      flex-direction: column;
      align-items: flex-end;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    width: 100%;
    background-color: $theme-color;
    color: $theme-text-color;
    padding-top: $padding-medium;
    padding-bottom: $padding-normal;

    .table-title {
      font-weight: 600;
      max-width: max-content;
      font-size: $font-size-xlarge;
      color: $theme-text-color;
      align-self: flex-start;

      .page-title {
        padding-top: 0;
        padding-left: 0;
      }

      .page-parent-label {
        font-weight: 500;
        font-size: $font-size-xlarge;
      }
    }
    .sub-title {
      display: flex;
      font-size: $font-size-normal;
      width: 95px;
      align-items: center;
      padding-left: $padding-xsmall;
    }

    .tools-container.expanded {
      overflow: unset;
    }

    .no-max-width {
      max-width: 100% !important;
    }
    .tools-container {
      align-items: center;
      flex: 1;
      max-width: 85%;
      .tool-buttons-wrapper,
      .tools-button-wrapper {
        margin: auto $padding-medium-half;
      }
      .tool-buttons-wrapper.disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
        svg:hover {
          cursor: not-allowed !important;
          path {
            fill: var(--icon-color);
          }
        }

        & > .delete-icon {
          pointer-events: none;
        }
      }
      .table-filter-controller {
        background-color: transparent;
        color: $dark-gray-5;
        width: max-content;
        position: relative;
        font-size: $font-size-medium;
        font-weight: normal;

        .dropdown-toggle,
        .input {
          font-weight: normal;
          color: $theme-text-color;
          background-color: $theme-filter-button-bg;
        }

        .added-filters-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          padding: $padding-small;
          background-color: $theme-color;
          .save-filter-button {
            margin-right: $padding-normal;
            color: $theme-a-tag-color;
            background-color: transparent;
            border-color: $theme-a-tag-color;
          }

          .added-filter-item-component {
            height: min-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: $padding-xxsmall $padding-xxxsmall $padding-xxsmall $padding-small;
            margin-right: $padding-normal;
            margin-top: $padding-xxxsmall;
            margin-bottom: $padding-xxxsmall;
            background: $theme-active-filter-bg-color;
            color: $theme-text-color;
            border-radius: 3px;
            max-width: 300px;
          }
          .saved-filter-item-component {
            height: min-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: $padding-xxsmall $padding-xxxsmall $padding-xxsmall $padding-small;
            margin-right: $padding-normal;
            margin-top: $padding-xxxsmall;
            margin-bottom: $padding-xxxsmall;
            background: #ceeedd;
            border-radius: 3px;
            max-width: 300px;
          }
        }

        .text {
          line-height: 30px;
        }

        .dropdown-menu {
          button:hover {
            background-color: $light-blue-1;
          }
        }

        input {
          margin-left: $padding-small;
          margin-right: $padding-small;
          padding: 0 $padding-small;
          background-color: $theme-filter-button-bg;
        }

        > .filters-editor-container {
          padding: $padding-normal;
          width: max-content;
          position: absolute;
          top: -26px;
          right: 100%;
          box-shadow: $theme-dropdown-box-shadow;
          z-index: 2;
          background-color: $theme-background-color;
          color: $theme-text-color;

          .filters-editor-header {
            font-weight: 500;
            font-size: $font-size-large;
            line-height: 19px;
            padding-bottom: $padding-normal;
            background-color: $theme-background-color;
            color: $theme-text-color;

            img {
              cursor: pointer;
            }
            .saved-filter-name {
              max-width: 170px;
              margin-right: $padding-small;
            }
            .filter-helper-icon-wrapper {
              height: 16px;
              object-fit: contain;
              margin-left: $padding-small;
              display: flex;
              justify-content: center;
            }
            .edit-filters-button {
              color: $theme-hollow-btn-border;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px; /* 150% */
            }
            .edit-filters-button:hover {
              color: $theme-hollow-btn-border;
              cursor: pointer;
              text-decoration: underline;
            }
          }

          button.dropdown-toggle {
            margin: 0 $padding-small;
            border-bottom: $border;
          }

          .filter-content {
            padding-bottom: $padding-normal;

            .filter-value-error {
              border: 1px solid $red;
            }
            input {
              background-color: $theme-filter-button-bg;
              margin-left: $padding-small;
              margin-right: $padding-small;
              padding: 0 $padding-small;
              flex: 1;
              border: $theme-border;
              border-radius: 0;
              color: $theme-text-color;
            }
            .dropdown-multi-select-component {
              .dropdown-toggle,
              .dropdown-toggle:active,
              .dropdown-toggle:hover:not(:disabled) {
                margin: 0;
                width: auto;
                min-width: 230px !important;
                border: $theme-border !important;
                background-color: $theme-filter-button-bg !important;
                color: $theme-text-color !important;
              }
            }

            .dropdown-multi-select-component {
              .dropdown,
              .dropdown-menu {
                background-color: $theme-form-control-color;
                height: auto;
              }
              .options-container {
                max-height: none !important;
              }
            }
            .cursor-pointer {
              cursor: pointer;
              &-disabled {
                opacity: 0.5;
                cursor: not-allowed !important;
              }
            }
          }
          .add-filter-button {
            width: max-content;
            color: $theme-hollow-btn-border;
            font-size: $font-size-medium;
            cursor: pointer;
            display: flex;
            align-items: center;

            .add-filter-wrapper {
              margin-right: $padding-small;
            }
            > img {
              margin-right: $padding-small;
            }

            &:hover .MuiSvgIcon-root.add-plus-icon {
              fill: #327ef0;

              .dark & {
                fill: #599bff;
              }
            }
          }

          .filters-editor-buttons {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-top: $padding-normal;
            color: $dark-gray-3;

            position: relative;

            .save-filters-btn-wrapper {
              display: flex;
              justify-content: end;
              width: 60%;

              .submit-button,
              .cancel-button {
                margin-right: 12px;
              }
            }
          }
          .saved-filter-name-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 35px;
            margin: 22px 0px 0px 0px;
            .saved-filter-name {
              margin-left: $padding-normal;
            }
            .text-destination {
              width: 45px;
            }
            .text-destination-input {
              input {
                opacity: 0.7;
                background-color: $theme-filter-button-bg;
                margin-left: $padding-small;
                margin-right: $padding-small;
                padding: 0 $padding-small;
                flex: 1;
                border: $theme-border;
                border-radius: 0;
                color: $theme-text-color;
                height: 35px;
                width: 160px;
              }
            }
            .text-saved-input {
              width: 100%;
              input {
                background-color: $theme-filter-button-bg;
                margin-left: $padding-small;
                margin-right: $padding-small;
                padding: 0 $padding-small;
                flex: 1;
                border: $theme-border;
                border-radius: 0;
                color: $theme-text-color;
                height: 35px;
                width: 98%;
              }
            }
          }
          .save-for-button {
            .btn-primary {
              background-color: $theme-hollow-btn-border;
              border: none;
              color: #fff;
            }
            .dropdown-toggle-split {
              background-color: $theme-hollow-btn-border;
              margin: unset;
              border-left: 1px solid #fff !important;
              border: none;
              padding-right: 20px;
            }
            .btn-primary,
            .dropdown-toggle:hover {
              color: #fff !important;
              margin: unset;
            }
            .dropdown-menu {
              transform: translate3d(0px, 35px, 0px) !important;
            }
          }
        }
      }

      .table-filter-controller.expanded {
        background-color: white;
      }

      .table-columns-controller {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: $theme-modal-overlay-bg-color;

        .columns-editor-container {
          background-color: $theme-widget-bg-color;
          color: $theme-text-color;
          font-size: $font-size-medium;
          font-weight: normal;
          box-shadow: $box-shadow;
          width: max-content;
          float: right;
          margin-top: $padding-medium;
          margin-right: $padding-medium;
          padding: $padding-medium;
          .cursor-pointer {
            position: absolute;
            top: 50px;
            right: 48px;
          }
          .group-findings-container {
            border-bottom: 0.5px solid $theme-column-border-bottom-color;
            .custom-control-label::before {
              // background of switch when off
              background-color: $theme-group-findings-button-background-off;
              border: none;
            }
            .custom-control-label::after {
              //circle color when off
              background-color: $theme-group-findings-button-circle-off;
            }
            .custom-control-input:checked ~ .custom-control-label::before {
              background-color: $theme-group-findings-button-background-on;
              border: none;
            }
            .custom-control-input:checked ~ .custom-control-label::after {
              background-color: $theme-group-findings-button-circle-on;
            }
          }
          .columns-editor-header {
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: $font-size-large;
            padding-bottom: $padding-normal;
          }
          .columns-title-color {
            padding-top: 15px;
            color: $theme-column-title-color;
          }

          .columns-editor-description {
            font-size: $font-size-medium;
            padding-bottom: $padding-normal;
          }

          .columns-editor {
            display: flex;

            .column-container {
              flex: 1;
              min-width: 260px;
              display: flex;
              flex-direction: column;

              .column-title {
                padding-bottom: $padding-normal;
              }

              .column-content {
                flex: 1;
              }
            }
            .column-container:first-child {
              border-right: $theme-border;
              padding-right: $padding-medium;
            }
            .column-container:nth-child(2) {
              padding-left: $padding-medium;
            }

            .column-item-container.opacity-05 {
              span,
              img {
                opacity: 0.5;
              }
            }

            .column-item-container {
              width: 100%;
              margin-bottom: $padding-small;
              padding: $padding-xsmall $padding-normal;
              border: $theme-border;
              border-radius: 3px;
              background-color: $theme-background-color;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          .columns-editor-buttons {
            padding-top: $padding-normal;

            button {
              float: right;
            }
            button + button {
              margin-right: $padding-medium;
            }
          }
        }
      }

      .export-wrapper {
        height: 24px;
        .export-label {
          font-size: $font-size-medium;
          font-weight: 500;
          color: $theme-secondary-text-color;
          margin-right: $padding-normal;
          cursor: pointer;
          &:hover {
            color: var(--icon-blue-shade-color);
          }
        }
        .export-icon:hover + .export-label {
          color: var(--icon-blue-shade-color);
        }

        &:has(.export-label:hover) > .MuiSvgIcon-root.export-icon {
          fill: #327ef0;
        }

        .dark &:has(.export-label:hover) > .MuiSvgIcon-root.export-icon {
          fill: #599bff;
        }
      }

      > .dropdown-component {
        margin-left: $padding-normal;

        > .dropdown-menu {
          z-index: 1;
        }

        .dropdown-menu-shown {
          left: unset;
          right: 0;
        }
      }
      > img {
        margin-left: $padding-normal;
        cursor: pointer;
      }

      > .dropdown-date-range-picker-component {
        margin-left: $padding-normal;

        .date-range-dropdown {
          height: 35px;
          &:hover {
            color: $theme-widget-legend-header-color;
          }
        }
      }
      .MuiSwitch-root {
        padding: 4px;
        .MuiSwitch-colorPrimary.Mui-checked {
          color: $theme-switch-thumb-active-color;
        }
        .MuiSwitch-colorPrimary {
          color: $theme-switch-thumb-inActive-color;
        }
        .MuiSwitch-track {
          border-radius: 40px;
          background-color: $theme-switch-track-backgroundColor;
        }
      }
    }
  }

  .table-loading-spinner {
    min-height: 722px;
  }

  .bs-table-container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    height: 100%;
    .ag-root-wrapper-body.ag-layout-normal {
      height: 85vh;
      // min-height: 180px;
    }
    .ag-header-cell-resize {
      z-index: auto;
    }
    .ag-overlay-no-rows-wrapper {
      display: none;
    }
  }
  .ag-root-wrapper {
    min-height: 200px;
  }

  table {
    background-color: $theme-widget-bg-color;
    thead {
      tr {
        background-color: $theme-widget-bg-color;
        color: $theme-widget-legend-header-color;
        cursor: pointer;
        th {
          div {
            height: 20px;
            display: flex;
            align-items: center;

            span {
              padding-right: $padding-small;
              font-weight: 600;
              color: $theme-widget-legend-header-color;
              font-size: $font-size-normal;
              word-break: normal;
            }
          }

          .check-box-container {
            height: 10px;
          }
        }

        th.no-pointer {
          cursor: default;
        }
      }
    }
    tbody {
      font-size: $font-size-medium;
      border: none;
      .row-wrapper:hover {
        cursor: pointer;
        background: rgba(219, 231, 251, 0.15) !important;
      }
      .row-wrapper {
        td {
          .table-source-url-column {
            max-width: 15rem;
          }
        }
      }
      .expandable {
        td {
          background: $theme-grouped-expanded-bg-color;
          .table-source-url-column {
            max-width: 15rem;
          }
        }
        .hide-below-md {
          .takedown-request-col {
            display: flex;
            align-items: center;
            .svg-icon-wrapper {
              margin-left: $padding-xsmall;
              circle {
                fill: $theme-circle-badge-bg-color;
              }
              path {
                stroke: $theme-modal-overlay-close-btn;
              }
            }
            .svg-icon-wrapper:hover {
              cursor: pointer;
              circle {
                fill: $theme-user-icon-hover-bg-color;
              }
              path {
                stroke: $theme-modal-overlay-close-btn;
              }
            }
          }
        }
      }
      @media only screen and (min-width: $screen-size-sm) and (max-width: 1200px) {
        .row-wrapper {
          td {
            .grouped-header-wrapper {
              max-width: 10rem !important;
            }
            .table-source-url-column {
              max-width: 10rem !important;
              .tooltip-inner {
                background: $theme-tooltip-bg-color;
                color: $theme-text-color;
              }
            }
          }
        }
        .expandable {
          td {
            .table-source-url-column {
              max-width: 10rem !important;
              .tooltip-inner {
                background: $theme-tooltip-bg-color;
                color: $theme-text-color;
              }
            }
          }
        }
      }
      .expandable > td:first-child {
        border-left: 1.5px solid $theme-hollow-btn-border;
        padding-left: $padding-medium;
      }

      .expandable > td:nth-child(2) {
        padding-left: $padding-xlarge;
      }
      .show {
        display: table-row !important;
      }

      tr {
        width: 100%;
        border-bottom: $theme-border-2;
        background-color: $theme-widget-bg-color;

        td {
          font-weight: normal;
          font-size: $font-size-medium;
          color: $theme-table-body-text-color;
          .chevron-icon {
            transform: rotate(0deg);
            -webkit-transition: transform 0.4s; /* Safari */
            transition: transform 0.4s;
          }
          .chevron-up {
            transform: rotate(-90deg);
            -webkit-transition: transform 0.4s; /* Safari */
            transition: transform 0.4s;
          }
          > img.icon {
            margin: 0 0 3px $padding-small;
            cursor: pointer;
          }
          .column-image {
            img {
              width: auto;
              object-fit: contain;
              max-height: 30px;
            }
            .add-white-background {
              background: white;
            }
          }
          .column-extension {
            width: 150px;
          }
          .grouped-header-wrapper {
            display: flex;
            max-width: 15rem;
            white-space: nowrap;
            .source-url-column {
              min-width: 0;
              display: flex;
              max-width: 10rem;
              margin: 0 12px;
              a,
              div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $theme-text-color;
              }
              .tooltip-inner {
                background: $theme-tooltip-bg-color;
                color: $theme-text-color;
              }
            }
            .counts-wrapper {
              height: 24px;
              background: $theme-grouped-badge;
              border-radius: 24px;
              padding: 3px 10px;
              color: $theme-grouped-badge-text-color;
              font-weight: 500;
              font-size: 13px;
              line-height: 19px;
              display: flex;
              span:hover {
                cursor: pointer;
              }
              .dropdown-component.bg-transparent.grouped-view-dots {
                .btn:focus {
                  box-shadow: none;
                }
              }

              .dropdown-menu.show {
                width: unset;
              }
            }
          }
        }
        .hide-below-md {
          .takedown-request-col {
            display: flex;
            align-items: center;
            .svg-icon-wrapper {
              margin-left: $padding-xsmall;
              circle {
                fill: $theme-circle-badge-bg-color;
              }
              path {
                stroke: $theme-modal-overlay-close-btn;
              }
            }
            .svg-icon-wrapper:hover {
              cursor: pointer;
              circle {
                fill: $theme-user-icon-hover-bg-color;
              }
              path {
                stroke: $theme-modal-overlay-close-btn;
              }
            }
          }
        }

        img.icon {
          margin: 0 0 3px $padding-small;
          cursor: pointer;
        }
      }

      // tr:nth-child(odd) {
      //   background-color: $theme-widget-bg-color-2;
      // }

      .action {
        cursor: pointer;
      }

      .action.save-item,
      .action,
      .edit-item {
        color: $theme-hollow-btn-border;
      }

      .action-column {
        width: 40px;
        .dropdown-component {
          .dropdown {
            .btn {
              padding: 0;
              display: inline-block;
            }
            .dropdown-menu {
              width: unset;
            }
            .dot-dot-dot-wrapper {
              svg {
                path {
                  stroke: var(--icon-black-white-color);
                }
              }
            }
          }
        }
      }
      .action-column {
        .note-column {
          color: $theme-hollow-btn-border;
        }
        .note-column:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      .action-column.always-enable {
        pointer-events: all;
        opacity: 1;
      }

      .dropdown-component {
        .dropdown-menu {
          position: fixed !important;
        }
      }

      .delete-item {
        padding-right: $padding-normal;
        margin-right: $padding-normal;
        border-right: $border;
        color: var(--theme-risk-level-high-color);
      }

      .cancel-item {
        color: $dark-gray-3;
      }

      td.editing-row:first-child {
        padding-left: 10px;
      }

      .editing-row {
        padding-top: 7px;
        padding-bottom: 5px;
        padding-left: 0;

        .form-control {
          height: 32px;
          margin-top: 0;
          margin-bottom: 0;
        }
        .dropdown-menu {
          width: unset;
        }
      }

      tr:last-child {
        border-bottom: $theme-table-bottom-border;
      }

      tr.row-is-checked {
        background-color: $theme-row-select-bg-color !important;
      }
      tr.row-is-new-added {
        background-color: $theme-new-row-added-bg-color !important;
      }
      tr.row-is-disabled {
        pointer-events: none;

        > td {
          opacity: 0.5;
        }
      }
      .grouped-bottom-wrapper {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 1rem;
        .count-wrapper {
          display: flex;
          flex-direction: row;
          margin-left: 14px;
          min-width: 110px !important;
          div {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            color: $theme-grouped-badge-text-color;
            padding-right: 6px;
          }
          span {
            font-weight: 600;
            font-size: 13px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-decoration-line: underline;
            color: $theme-hollow-btn-border;
          }
          span:hover {
            cursor: pointer;
          }
        }
      }
    }
    // tbody:nth-child(odd) {
    //   tr {
    //     background-color: $theme-widget-bg-color-2 !important;
    //   }
    // }
  }
  // table > tbody:nth-child(odd) {
  //   background-color: $theme-widget-bg-color-2 !important;
  // }

  @media only screen and (max-width: $screen-size-lg) {
    .table-filter-controller,
    .table-columns-setting,
    .table-columns-controller {
      display: none !important;
    }
  }
  .page-number-view {
    position: absolute;
    left: 20px;
  }

  .table-pagination-controller {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    padding: 0 $padding-medium;
    color: $theme-text-color;
    background-color: $theme-widget-bg-color;
    position: relative;
    span {
      font-weight: normal;
      font-size: $font-size-medium;
    }

    input {
      max-width: 40px;
      width: min-content;
      padding: 0 $padding-xsmall;
      text-align: center;
      margin-left: $padding-normal;
      margin-right: $padding-normal;
      font-size: $font-size-medium;
      color: $theme-text-color;
      border: $theme-border;
      background-color: $theme-widget-bg-color;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    .table-pagination-button-wrapper {
      display: flex;
      flex-direction: row;
      div {
        margin-left: $padding-normal;
      }
    }
  }

  .tiles-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: $theme-border;
    background-color: $theme-color;

    .table-tile-component {
      position: relative;

      .check-box-container {
        position: absolute;
        top: $padding-medium;
        right: $padding-medium;
      }
    }
    .table-tile-component.is-checked {
      background-color: $theme-row-select-bg-color !important;
    }
    .table-tile-component.is-new-added {
      background-color: $theme-new-row-added-bg-color !important;
    }
  }

  .add-item-container {
    height: 40px;
    color: $theme-hollow-btn-border;
    cursor: pointer;
    width: max-content;
    margin-left: $padding-small;
    span {
      font-weight: 600;
      padding-left: $padding-small;
      font-size: $font-size-medium;
    }
  }
}

.table-page {
  .table-container.card {
    border: none !important;
    box-shadow: none !important;
    background-color: $theme-color !important;
  }
}

.MuiSvgIcon-root.grid-view-icon {
  fill: transparent !important;
  stroke: #27292d99;
  .dark &.grid-view-icon {
    stroke: #f1f5f880 !important;
  }
  &:is(:hover, :active) {
    fill: transparent !important;
    path {
      stroke: #327ef0 !important;
    }

    .dark & path {
      stroke: #599bff !important;
    }
  }
}

.MuiOutlinedInput-root.mx--8 {
  margin-inline: 8px;
}
