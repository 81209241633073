@import 'src/index';

$radio-button-size: 21px;

.radio-buttons-component {
  .radio-buttons-label {
    font-weight: 500;
    font-size: $font-size-medium;
    margin-bottom: $padding-normal;
  }

  .col {
    padding-left: 0;
  }
}

.radio-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $padding-normal;

  img {
    padding: 0px $padding-small 0px $padding-normal;
  }
}
