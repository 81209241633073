@import 'src/index';

.progress-container {
  display: flex;
  flex-direction: column;
  border-radius: 7.486px;
  width: 137px;
  min-width: 137px;
  height: 393px;
  position: relative;
  justify-content: end;
  background: $theme-source-feed-bar-bg-color;
  .progress-icon-container {
    position: absolute;
    top: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .source-progress-bar {
    border-radius: 7.486px;
    width: 100%;
    max-height: 85%;
    align-items: end;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: height 0.4s ease-out;
    .source-description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: inherit;
      justify-content: flex-end;
      position: absolute;
      bottom: 0px;
      padding: 0px 0px $padding-normal $padding-small;
      color: $white;
      .source-label {
        font-size: $font-size-normal;
        font-weight: 500;
        white-space: break-spaces;
        text-align: left;
      }
      .source-count {
        font-size: $font-size-medium;
        font-weight: 600;
        line-height: 20.799px;
        padding-bottom: $padding-small;
      }
      &-dark-font-color {
        @extend .source-description;
        color: #5946d0;
      }
    }
  }
  &.darken {
    filter: brightness(60%);
    animation: brightnessAnimation 0.5s ease-out;
  }
}
.progress-container:hover {
  cursor: pointer;
}
.progress-container-disabled {
  background: #c0c3c7;
}

@keyframes fillProgress {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes brightnessAnimation {
  0% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(0.6);
  }
}
