@import 'src/index';

.team-member-page {
  .team-member-table-container {
    border: none !important;
    box-shadow: none !important;

    .table-component {
      padding: $padding-small 0;
    }
  }
}

.MuiButton-root.delete-button-contained {
  background-color: #f44336;
  color: #fff;

  &:is(:hover, :active) {
    background-color: #e53935 !important;
    color: #fff;
  }
  &:disabled {
    color: #f44336;
  }
}

.MuiButton-root.delete-button-outlined {
  height: 30px;
  width: 70px;
}

.MuiButton-root.delete-button-text {
  color: #f44336;

  &:is(:hover, :active) {
    background-color: #d32f2f4d;
  }
  &:disabled {
    color: #f44336;
  }
}
