@import 'src/index';

.mr-20 {
  margin-right: 20px;
}

.tool-buttons-wrapper {
  margin-inline: 12px;
}
.persistent-filters-wrapper {
  position: relative;
  .persistent-filters-btn {
    border: $theme-border !important;
  }
  .empty-persistent-filters-text {
    color: $theme-secondary-text-color-light;
    font-size: $font-size-medium;
    font-weight: 400;
    margin: 0px 0px 0px 24px;
    height: auto;
  }
}
.persistent-filters-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 366px !important;
  height: 298px !important;
  background-color: $theme-background-color;
  top: 40px !important;
  left: 10px !important;
  z-index: 10;
  box-shadow: 0px 0px 7px rgba(5, 3, 16, 0.33);
  padding: $padding-small $padding-xsmall 0px $padding-xsmall;
  transform: translate3d(-200px, -45px, 0px) !important;

  .persistent-filters-wrapper {
    height: 250px;
    overflow: auto;
    .eye-icon-wrapper {
      margin-right: 10px;
    }
    .filters-wrapper {
      width: 100%;

      .persistent-filter-header {
        font-size: $font-size-medium;
        font-weight: 400;
        padding: 10px 0px 0px 24px;
        height: auto;
        color: $theme-secondary-text-color-light;
      }
      .persistent-filter {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        align-items: center;
        .check-box-container {
          margin-bottom: $padding-normal;
          margin-left: 2px;
          .checkbox {
            margin-top: $padding-xxsmall;
          }
        }

        .persistent-filter-name {
          color: $theme-text-color;
          font-size: $font-size-medium;
          line-height: 1.43;
          letter-spacing: 0.25px;
          font-weight: 400;
          margin: 4px 0px 4px 8px;
        }
      }
      .persistent-filter:hover {
        background-color: $theme-hover-bg-color;
        cursor: pointer;
        border-radius: 4px;
      }
      .active {
        background-color: $theme-hover-bg-color;
        cursor: pointer;
        border-radius: 4px;
      }
    }
  }

  .persistent-filter:last-child {
    margin-bottom: 8px;
  }
  .ellipsis-wrapper {
    display: flex;
    width: 100%;
    height: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .persistent-filters-title {
      font-size: $font-size-large;
      font-weight: 600;
      padding: 0px 0px 8px 24px;
      height: auto;
      color: $theme-secondary-text-color;
    }
  }
  .delete-view-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    margin-right: 8px;
    border-top: $theme-border-2;
    height: 50px;
    .delete-view-btn {
      color: $theme-hollow-btn-border;
      margin: 0px 12px;
      padding-top: 10px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .cancel-delete-view {
      color: $theme-secondary-text-color-light;
      margin: 0px 12px;
      padding-top: 10px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
