@import 'src/index';

//override page-title css
.time-line-page-title {
  background-color: $theme-background-color;
  padding-top: $padding-medium;
  padding-bottom: $padding-medium;
  border-bottom: none !important;
  color: $theme-text-color;
  @media only screen and (max-width: $screen-size-md) {
    .page-title-left {
      .title-info {
        padding-bottom: 0;
        border-bottom-width: 0;
        width: max-content;
      }
    }
  }

  .page-title-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $theme-background-color;

    .title-info {
      padding-top: 0;
    }
  }

  .page-title-right {
    background-color: $theme-background-color;
    padding-top: 0;
    padding-left: $padding-medium;
    justify-content: flex-start;
  }

  @media only screen and (max-width: $screen-size-md) {
    .page-title-right {
      margin-top: $padding-medium;
      padding-top: $padding-medium;
      border-top: $border;
      padding-bottom: 0;
    }
  }
}

.time-line-days {
  font-size: $font-size-medium;
  padding-top: 0;
  padding-left: $padding-medium;
}

@media only screen and (max-width: $screen-size-sm) {
  .time-line-days {
    margin-top: $padding-xxsmall;
  }
}

.legends-container {
  .legend-container + .legend-container {
    padding-left: $padding-medium;
  }

  .legend-container {
    font-size: $font-size-normal;

    .legend-icon {
      margin-right: $padding-small;
    }
  }
}

.time-line-page-content {
  background-color: $theme-background-color;
  padding-top: 0;

  @media only screen and (max-width: $screen-size-md) {
    .time-line-list {
      border-top: none !important;
      padding-top: 0 !important;
    }
  }

  .url-insight-link {
    margin-bottom: $padding-medium;
    color: $theme-hollow-btn-border !important;
  }

  .time-line-list {
    border-top: $theme-border;
    padding: $padding-medium-web 0;
    background-color: $theme-background-color;
    height: 100%;
    width: 100%;

    color: $theme-text-color;
    .time-line-item {
      display: flex;
      flex-direction: row;

      .time-and-status.mobile {
        display: none;
        padding-bottom: $padding-medium;
        padding-top: 0;
      }

      .time-and-status {
        min-width: 194px;
        display: flex;
        flex-direction: column;
        font-size: $font-size-normal;
        padding-top: $padding-small;
        padding-right: $padding-medium;

        .time {
          font-size: $font-size-medium;
        }

        .status {
          padding-top: $padding-medium;
          color: $red;

          img {
            margin-right: $padding-small;
          }
        }
      }

      .time-indicator {
        min-width: $padding-normal;

        .line {
          width: 2px;
          border-left: $theme-border;
          border-right: $theme-border;
        }

        .line-dot {
          height: 0;
        }

        .line-top {
          height: $padding-small;
        }

        .line-bottom {
          flex: 1;
        }

        .dot {
          width: $padding-normal;
          height: $padding-normal;
          border-radius: 50%;
          border: $padding-xxxsmall solid $white;
        }
      }

      .image-container {
        flex: 1;
        padding: $padding-medium;
        padding-top: $padding-small;

        .timeline-image {
          max-width: 400px;
          margin-bottom: $padding-xsmall;

          img {
            object-fit: contain;
          }
        }
      }
    }

    @media only screen and (max-width: $screen-size-sm) {
      a {
        padding: 0 $padding-medium;
      }

      .time-line-item {
        padding: 0 $padding-medium;

        .time-and-status.web {
          display: none !important;
        }

        .time-and-status.mobile {
          display: block !important;
        }
      }
    }
  }
}

.timeline-container {
  .card-body {
    display: flex;
    justify-content: space-around;
    padding: 50px !important;
  }
  .timeline {
    border-left: 1px solid #dee1e6;
    letter-spacing: 0.2px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 50px;
    list-style: none;
    text-align: left;
    max-width: 50%;
    .icon-wrapper {
      .chevron-icon {
        transform: rotate(0deg);
        -webkit-transition: transform 0.4s; /* Safari */
        transition: transform 0.4s;
      }
      .chevron-up {
        transform: rotate(-90deg);
        -webkit-transition: transform 0.4s; /* Safari */
        transition: transform 0.4s;
      }
    }
    .title {
      font-size: $font-size-medium;
    }
    .by-who {
      font-size: $font-size-normal;
      color: $theme-secondary-text-color;
    }
    .expandable-row-wrapper:hover {
      cursor: pointer;
    }
    .pill-container {
      flex-wrap: wrap;
      .pills-label-wrapper {
        font-size: 10px;
        flex-basis: unset;
        margin: 5px 5px 5px 0;
      }
    }
    .disposition-changes {
      span {
        margin: $padding-xsmall $padding-normal;
      }
    }
  }

  .timeline h1 {
    font-weight: 300;
    font-size: 1.4em;
  }

  .timeline h2,
  .timeline h3 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .timeline .event {
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
  }

  .timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }

  .timeline .event:before,
  .timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
  }

  .timeline .event:before {
    left: -225px;
    content: attr(data-date);
    text-align: left;
    font-weight: 100;
    font-size: $font-size-normal;
    width: 136px;
    min-width: 136px;
    white-space: pre-wrap;
  }

  .timeline .first-event.event:after {
    box-shadow: 0 0 0 3px #2168e4;
    left: -52.8px;
    background: #dee1e6;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    content: '';
    top: 5px;
  }
  .timeline .event:after {
    // box-shadow: 0 0 0 3px #727cf5;
    left: -53.8px;
    background: #dee1e6;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    content: '';
    top: 5px;
  }

  .rtl .timeline {
    border-left: 0;
    text-align: right;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: 3px solid #727cf5;
  }

  .rtl .timeline .event::before {
    left: 0;
    right: -170px;
  }

  .rtl .timeline .event::after {
    left: 0;
    right: -55.8px;
  }
}

.timeline-popover {
  &.popover {
    .popover-body {
      background-color: $theme-form-control-color;
      .list-group {
        max-height: 200px;
        overflow-y: auto;
        .list-group-item {
          .attached-file-name {
            color: $theme-text-color;
          }
        }
      }
    }
  }

  .arrow::after {
    border-right-color: $theme-form-control-color !important;
  }
}

.timeline-popover-content {
  .attached-file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
