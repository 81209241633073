@import 'src/index';

$component-height: 20px;
$thumb-size: 34px;

.slider-component.theme-color-priority-1 {
  --theme-color: var(--theme-priority-1-bg-color);
}
.slider-component.theme-color-priority-2 {
  --theme-color: var(--theme-priority-2-bg-color);
}
.slider-component.theme-color-priority-3 {
  --theme-color: var(--theme-priority-3-bg-color);
}

.slider-component {
  margin: $padding-small 0;
  height: 40px;
  --theme-color: var(--theme-slider-color);
  display: flex;
  align-items: center;

  .switch.disabled {
    .switch-text {
      color: var(--theme-slider-text-disabled-color);
    }
  }

  .switch {
    margin-right: $padding-small;

    .switch-text {
      font-weight: 600;
      font-size: $font-size-normal;
      line-height: 20px;
      color: $white;
      padding-left: $padding-small;
    }

    .react-switch-bg {
      background-color: var(--theme-color) !important;
    }
  }

  .slider-container {
    transform: translateX(calc(#{$thumb-size}/ -2));
    .slider {
      width: 100%;

      .slider-track:first-child {
        border-top-right-radius: calc(#{$component-height}/ 2);
        border-bottom-right-radius: calc(#{$component-height}/ 2);
      }
      .slider-track:nth-last-child(2) {
        border-radius: calc(#{$component-height}/ 2);
      }
      .slider-track {
        height: $component-height;
        background-color: $dark-gray-1;
      }
      .slider-track-0 {
        background: linear-gradient(
          to right,
          var(--theme-slider-total-price-bg-color) calc(#{$thumb-size}/ 2),
          var(--theme-color) calc(#{$thumb-size}/ 2)
        );
      }
      .slider-track-1 {
        background: linear-gradient(
          to right,
          var(--theme-color) 10px,
          var(--theme-slider-bg-color) 10px
        );
        background-color: var(--theme-slider-bg-color);
      }
      .slider-thumb {
        background-color: $white;
      }
    }
  }
  .slider-container.min-width {
    min-width: 60px;
  }
}

.total-price.disabled {
  color: var(--theme-slider-text-disabled-color);
}
.total-price {
  background-color: var(--theme-slider-total-price-bg-color);
  box-shadow: 0px 1px 3px rgba(148, 148, 148, 0.25);
  color: var(--theme-slider-text-color);
  font-size: $font-size-medium;
  height: $component-height;
  min-width: 100px;
  text-align: center;
}

.domain-thumb.disabled {
  cursor: default;
}
.domain-thumb {
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;

  .number-of-domains {
    background-color: $white;
    height: $thumb-size;
    width: $thumb-size;
    filter: drop-shadow(0px 1px 3px rgba(148, 148, 148, 0.25));
    border-radius: 50%;
    border: 1px solid transparent;
    font-size: $font-size-normal;
    font-weight: 600;
    z-index: 1;

    &:hover {
      border-color: var(--theme-color);
    }
  }
}
