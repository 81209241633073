@import 'src/index';

.reports-page-header-tools {
  button + button {
    margin-left: $padding-normal;
  }
}

.drop-down-container {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  align-items: center;
  min-width: 50%;
  justify-content: space-evenly;

  .drop-down-fields-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: $padding-medium;

    .form-label {
      display: flex;
      font-size: $font-size-medium;
      line-height: 21px;
      color: $theme-text-color;
    }

    .form-block {
      height: auto;
      padding: $padding-small $padding-small $padding-small 0;

      .dropdown-menu.show {
        background-color: $theme-widget-bg-color;
      }

      .date-range-dropdown {
        &:hover {
          color: $theme-widget-legend-header-color;
        }
      }

      .dropdown-multi-select-component {
        .dropdown-toggle {
          color: $theme-table-body-text-color !important;

          &:hover {
            color: $theme-widget-legend-header-color !important;
          }
        }
      }
    }
  }

  .generate-button {
    max-height: 35px;
    padding-top: $padding-small;
    padding-right: $padding-medium;
  }

  .reset-button {
    font-size: $font-size-medium;
    color: #2168e4;
    opacity: 0.8;
    padding-top: $padding-normal;
    padding-right: $padding-small;

    &:hover {
      cursor: pointer;
    }
  }

  .my-16 {
    margin-block-start: 16px;
  }

  .loading-wrapper-component {
    .spinner-border {
      width: 25px;
      height: 25px;
      color: $platinum;
    }
  }
}

.email-button {
  margin-left: 10px !important;

  .loading-wrapper-component .spinner-border {
    height: 25px;
    width: 25px;
  }
}

.reports-page {
  background-color: $theme-color !important;

  .email-download-container {
    float: right;
    color: $theme-hollow-btn-border;
    font-size: $font-size-medium;

    span {
      cursor: pointer;
    }

    .error-link {
      color: $theme-warning-color;
    }

    .email {
      border-right: $border;
      padding-right: $padding-normal;
    }

    .pdf-download {
      padding-left: $padding-normal;
    }
  }

  .reports-table-container {
    border: none !important;
    box-shadow: none !important;

    .table-component {
      padding: 0 $padding-medium;

      .tool-bar-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.modal-content {
  .report-names {
    padding-bottom: $padding-normal;
    margin-bottom: $padding-normal;
    border-bottom: $border !important;
  }
}

.drop-down-padding {
  margin-bottom: 20px;
  padding-top: 12px;
}

.form-label-padding-bottom {
  padding-bottom: 10px;
}
