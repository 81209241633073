@import 'src/index';

.flyout-menus {
  position: absolute;
  top: $top-nav-bar-height;
  left: 0;
  width: 100%;
  background: $theme-color;
  border: $theme-border;
  box-shadow: 0px 2px 2px var(--theme-slider-bg-color);
  padding: $padding-medium 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .flyout-menu-item {
    width: 280px;
    color: $theme-text-color;

    .flyout-menu-item-header {
      font-weight: 600;
      font-size: $font-size-medium;
      padding-bottom: $padding-small;
      border-bottom: 1px solid var(--icon-black-white-color);
      margin: 0 $padding-medium;
      display: flex;
      align-items: center;

      svg {
        margin-right: $padding-small;
      }
    }

    .flyout-menu-item-body {
      font-weight: 400;
      .dashboard-label-wrapper,
      .disabled-label {
        padding: $padding-small $padding-medium;
      }
      .dashboard-label-wrapper {
        cursor: pointer;
      }
      .dashboard-label-wrapper:hover {
        background-color: $theme-hover-bg-color;
      }
      .disabled-label {
        cursor: not-allowed;
      }
    }
  }
}
