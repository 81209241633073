@import 'src/index';

.checkphish-ribbon.blue-wrap {
  background-color: #2661c6;
  color: white;
  .ribbon-wrap {
    display: flex;
    align-items: center;
    .checkphish-ribbon-button {
      background-color: #ffffff;
      color: #2661c6;
      margin: 0 10px;
      font-weight: bold;
    }
  }

  .close-btn {
    color: white;
  }
}
.checkphish-ribbon {
  position: sticky;
  top: 0px;
  background-color: #ffe484;
  width: 100%;
  min-height: 50px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .ribbon-wrap {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
  }

  .checkphish-ribbon-info {
    padding: 0.3rem;
  }
  .close-btn {
    align-self: flex-start;
    color: #64717e;
    margin: 0 0.1rem;
    background: transparent;
    border: 0px solid transparent;
    font-size: 25px;
    width: 3%;
  }

  &-info {
    margin-top: 15px;
    padding: 0px 15px;
    cursor: default;
  }

  &-button {
    background: #fff9ef;
    border: 1px solid #fff9ef;
    outline: none;
  }

  &-close {
    color: #555555;
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 30px;
    transition: 0.5s;

    &:hover {
      color: #000000;
    }
  }
}

.hb-contact-form {
  display: block;
  margin: auto;
  width: 60%;
}

.top-navigation-bar-container {
  display: flex;
  width: 100%;
  height: $top-nav-bar-height;
  background: $theme-background-color;
  border: $theme-border;
  position: absolute;
  z-index: 1500;
  .top-navigation-wrapper {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px $padding-medium-half;
    .hamburger-icon-wrapper {
      display: none;
      align-items: center;
      .hamburger-icon {
        width: 25px;
        height: 25px;
      }
    }
    .left-navigation-wrapper {
      display: flex;
      width: 100%;
      .nav-header-container {
        display: flex;
        font-size: $font-size-medium;
        // width: 85%;
      }
      .company-logo-container {
        display: flex;
        width: 169px;
        border-radius: 50%;
        background-color: $theme-background-color;
        align-items: center;
        height: 54px;
        .company-logo-wrapper {
          width: auto;
          display: flex;
          height: auto;
        }
        .company-logo-wrapper:hover {
          cursor: pointer;
        }
        .company-title-container {
          padding-left: 10px;
          text-transform: uppercase;
          color: $theme-text-color;
        }
      }
    }
    .help-button-container {
      width: 15%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      margin-right: 15px;
      align-items: center;
      .help-button-text {
        text-transform: uppercase;
        color: var(--theme-page-text);
        margin: 0 5px 0 0 !important;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 13px;
      }
      button {
        margin: 0 0.5rem;
      }
      .help-button-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        .help-button-shadow {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          &:hover {
            background-color: $theme-hover-button-color;
          }
          .svg-icon-wrapper:hover {
            path {
              fill: $theme-active-hover-button-color;
            }
          }
        }
      }
    }
    .help-drop-down-modal {
      position: absolute;
      top: 56px;
      right: 0;
      margin-right: 85px;
      background-color: $theme-background-color;
      height: 160px;
      width: 186px;
      .get-help-title {
        font-size: $font-size-large;
        display: flex;
        color: $theme-text-color;
        padding: 17px;
      }
      .links-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .support-styling {
          color: #b8b8b8;
          padding-left: 17px;
        }
        .clickable-links {
          width: 100%;
          height: 36px;
          text-indent: 17px;
          font-size: $font-size-medium;
          display: flex;
          align-items: center;
          color: $theme-text-color;
          &:hover {
            background-color: $theme-links-hover;
            text-decoration: none;
          }
        }
      }
    }
    .navitem-wrapper {
      height: inherit;
      .first-level-nav-item {
        margin: 0px 10px;
        color: $theme-text-color;
        text-decoration: none;
        height: 54px;
        display: flex;
        align-items: center;
      }
      .first-level-nav-item:hover {
        cursor: pointer;
      }
      .first-level-nav-item.selected {
        color: $theme-hollow-btn-border;
        border-bottom: 2px solid;
        .lock-icon-wrapper {
          svg {
            path {
              fill: var(--icon-active-color);
            }
          }
        }
      }
      .first-level-nav-item.flyout-nav-item {
        .chevron-icon {
          margin-left: $padding-small;
          transform: rotate(-90deg);
          transition: transform 200ms ease-in-out;
        }
      }
      .first-level-nav-item.selected.flyout-nav-item {
        .chevron-icon {
          path {
            fill: $theme-hollow-btn-border;
          }
        }
      }
    }
    .navitem-wrapper.active {
      .first-level-nav-item.flyout-nav-item {
        .chevron-icon {
          transform: rotate(0deg);
        }
      }
    }
    .lock-icon-wrapper {
      display: block;
      margin: 0px $padding-xsmall $padding-xxxsmall 0px;
    }
    .profile-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: $top-nav-bar-height;
      align-items: center;
      .user-profile-avatar {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
        background-color: $theme-text-color;
        color: $theme-background-color;
      }
      .chevron-down-wrapper {
        padding: 0px 10px;
        transition: transform 0.5s ease-out;
      }
      .notification-wrapper {
        top: -11px;
        padding-left: 2px;
        position: relative;
      }
    }
    .profile-wrapper:hover {
      cursor: pointer;
      .chevron-down-wrapper {
        transform: translateY(6px);
      }
    }
    .profile-wrapper.selected {
      .user-profile-avatar {
        border: 1px solid $dark-ocean;
        background-color: $dark-gray-5;
        color: $white;
      }
      .chevron-down-wrapper {
        transform: translateY(6px);
      }
    }
    .profile-wrapper:active {
      .user-profile-avatar {
        border: 1px solid $dark-ocean;
        background-color: $dark-gray-5;
        color: $white;
      }
    }
  }
}
.disabled-label {
  padding: $padding-small $padding-normal;
  color: #747474;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.disabled-label:hover {
  cursor: not-allowed;
}

@media only screen and (max-width: 850px) {
  .top-navigation-bar-container {
    .top-navigation-wrapper {
      display: flex;

      .hamburger-icon-wrapper {
        display: flex;
        align-items: center;
        .hamburger-icon {
          width: 25px;
          height: 25px;
        }
      }
      .left-navigation-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        .nav-header-container {
          display: none;
        }
      }
    }
  }
  .ribbon-wrap {
    flex-direction: column !important;
    padding-bottom: 0.5rem;
    .checkphish-ribbon-info {
      margin: 0.5rem auto;
      padding: 0;
    }
    .close-btn {
      width: 5% !important;
    }
  }
}

.modal-wrap {
  .modal-dialog {
    display: flex;
    justify-content: center;
  }
}

.explore-demo-banner {
  background-color: $theme-explore-demo-bg;
  border-color: $theme-explore-demo-border;
  color: $theme-explore-demo-text-color;
  height: 45px;
  font-size: 14px;
  .explore-demo-text {
    .bold-text {
      font-weight: bold;
    }
  }
  .explore-demo-btn {
    background-color: $theme-explore-demo-btn-bg;
    color: $theme-explore-demo-btn-color;
    border-color: $theme-explore-demo-btn-bg;
    margin: 0 1rem;
    font-weight: bold;
    font-size: 14px;
    border-radius: 2px;
  }
  .close-btn {
    color: $theme-explore-demo-text-color;
  }
}

.explore-demo-wrap {
  margin: 0 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  h3 {
    margin-bottom: 1rem;
    font-size: 20px;
  }
  p {
    margin-bottom: 1rem;
    font-size: 14px;
    color: $theme-explore-subtext-color;
  }
  button {
    width: 120px;
    margin-bottom: 3rem;
    background-color: $theme-contact-us-button-bg;
    border-radius: 2px;
  }
}

.domain-dropdown {
  position: relative;
  top: 12px;
  margin-right: 16px;
}
.monitor-more {
  color: #cccc;
}

.explore-demo-wrap-paid {
  .modal-dialog {
    top: 25%;
    .modal-content {
      width: 550px;
      border-radius: 5px;
      overflow: hidden;
      .modal-header {
        position: relative;
        z-index: 9;
        height: 0;
        right: 15px;
        button {
          position: relative;
          top: 2px;
          left: 4px;
          margin-bottom: 10px;
        }
      }
      .background-wrapper {
        display: flex;
        justify-content: center;
        background-image: url('../../assets/icons/TyposquatFullScanBackground.svg');
        background-size: cover;
      }
      .button-wrapper {
        display: flex;
        justify-content: center;
        padding-bottom: 32px !important;
        background: #ffff;
        .compare-all-plans {
          background-color: transparent !important;
          border-color: #2168e4 !important;
          color: #2168e4 !important;
          margin-right: 10%;
        }
        button {
          width: 200px !important;
          height: 35px;
        }
      }
      .text-wrapper {
        padding: 16px;
        background: #ffff;
        color: black;
        text-align: center;
      }
    }
  }
}
.explore-demo-wrap-paid.small-popup {
  .modal-content {
    width: 500px;
    .background-wrapper {
      img {
        object-fit: cover;
      }
    }
  }
}
