@import 'src/index';

.timeline-and-whois {
  .btn.btn-outline-secondary {
    background-color: $theme-takedown-button-background !important;
    color: $theme-takedown-button-color !important;
  }
  .btn-outline-secondary :hover {
    background-color: $theme-takedown-button-background !important;
    color: $theme-takedown-button-color !important;
  }
}

.primary-border {
  border: 1px solid $theme-takedown-button-background;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $theme-takedown-button-background !important;
  }
}

.transparent-btn {
  background-color: transparent;
  color: $theme-takedown-button-background;
  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    border: 1px solid $theme-takedown-button-background !important;
    color: $theme-takedown-button-background !important;
  }
}
.blue-content-tooltip {
  margin-top: 0.5rem;
  div {
    color: $theme-tooltip-bg-color-seconday !important;
    text-align: start;
  }
}
