@import 'src/index';

.row-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: $theme-border;
  background-color: $theme-background-color;
  margin: 0 0 $padding-medium-half 0;
  padding: $padding-normal 0px;

  .card-category-name {
    padding-left: $padding-medium;
    font-weight: 600;
    font-size: $font-size-large;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    text-align: left;
    color: $theme-hollow-btn-border;
  }
  .card-category-name:hover {
    cursor: pointer;
  }
  .label-value-wrapper-risk {
    width: 15%;
  }
  .label-value-wrapper-title {
    width: 40%;
    display: flex;
    .new-findings-text {
      padding-left: $padding-small;
      font-weight: 600;
      font-size: $font-size-medium;
      line-height: 21px;
      color: #fa6246;
      text-transform: uppercase;
      padding-right: $padding-large;
    }
  }
  .label-value-wrapper-threat {
    width: 15%;
    a {
      color: $theme-hollow-btn-border;
    }
  }
}
.high-risk {
  border-left: 7px solid var(--theme-risk-level-high-color);
}
.medium-risk {
  border-left: 7px solid var(--theme-risk-level-medium-color);
}
.low-risk {
  border-left: 7px solid var(--theme-risk-level-low-color);
}
