@import 'src/index';

.steps-component {
  padding: $padding-medium 0;

  .step-container {
    flex: 1;
    outline: none;
    background-color: transparent;
    border: none;
    color: $dark-gray-3;

    .step-number {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $theme-circle-badge-bg-color;
      color: $theme-widget-sub-label-color;
    }

    .step-label {
      padding-left: $padding-small;
      color: $theme-widget-sub-label-color;
    }
  }

  .step-container.passed-step {
    .check-circle-wrapper {
      svg {
        path {
          stroke: var(--icon-blue-shade-color);
        }
      }
    }
    .MuiSvgIcon-root {
      &.check-circle-color {
        fill: #327ef0 !important;

        &:is(:hover, :active) {
          fill: #2360bc !important;
        }

        .dark &.check-circle-color {
          fill: #599bff !important;

          &:is(:hover, :active) {
            fill: #b2d0fe !important;
          }
        }
      }
    }
    color: $theme-hollow-btn-border;
    .step-label {
      color: $theme-hollow-btn-border;
      font-weight: 600;
    }
  }

  .step-container.current-step {
    .step-number {
      border: 2px solid $theme-hollow-btn-border;
      background-color: transparent;
      color: $theme-hollow-btn-border;
    }

    .step-label {
      color: $theme-hollow-btn-border;
      font-weight: 600;
    }
  }
}
