@import 'src/index';

.feedback-popup {
  .feedback-btn-wrap {
    .feedback-toggle-btn {
      margin-right: 10px;
      margin-bottom: 1rem;
    }
  }
  .feeback-reason-label {
    font-size: 14px;
    margin-bottom: 20px;
    display: block;
  }
  .form-check {
    font-size: 14px;
    margin: 10px auto;
  }
  .error {
    color: #ea868f;
    padding: 3px 0;
  }
  .error-border {
    border-color: #ea868f;
  }
}
