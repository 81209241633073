@import 'src/index';

.communication-settings-container {
  padding-top: $padding-medium;

  .text-container {
    padding-left: $padding-medium;
    padding-bottom: $padding-medium;

    .subheader-text {
      font-size: 14px;
      color: $theme-text-color;
      padding-top: 30px;
    }

    .last-timestamp {
      font-size: 12px;
      color: #999999;
    }
  }

  .delivery-channel-container {
    padding-bottom: $padding-normal;
    margin-left: 25px;

    .check-box-container {
      color: $theme-text-color;
      margin-top: $padding-normal;

      .checkmark.checkmark-style {
        margin-top: 4px;
      }
    }
  }

  .module-container {
    padding-bottom: $padding-normal;
    margin-left: 25px;

    .module-options-container {
      display: flex;
      flex-direction: row;

      .check-box-container {
        color: $theme-text-color;
        margin-top: $padding-normal;

        .checkmark.checkmark-style {
          margin-top: 4px;
        }
      }
    }
  }

  .frequency-container {
    margin-left: 25px;

    .radio-buttons-component {
      padding-left: 12px;

      .radio-button-container {
        color: $theme-text-color;
        padding-right: $padding-normal;

        img {
          padding-right: 5px;
        }
      }
    }
  }

  .divisor-text {
    color: $theme-text-color;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    border-bottom: 1px solid #333333;
    width: 500px;
    text-align: left;
    padding-bottom: $padding-normal;
  }
  .connectors-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: $padding-normal;
  }
}
