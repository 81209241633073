@import 'src/index';

.org-switcher-container {
  display: flex;
  align-items: center;
  margin-right: 12px;
  color: white;
}
.sub-menu-item-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
