@import 'src/index';

.playbook-name-input {
  font-weight: 500;
  font-size: $font-size-xxlarge !important;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.playbook-name-input:focus {
  padding-left: $padding-small;
  padding-right: $padding-small;
  border: $border-blue2;
}

.playbook-description-input:focus {
  border: $border-blue2;
}

.choose-template-modal-header {
  font-size: $font-size-xxlarge;
  font-weight: 500;
}

.playbook-modal-body {
  .playbook-internal-wrapper {
    padding: $padding-xxsmall;
    .check-box-container {
      margin-top: $padding-medium-half;
      margin-right: $padding-xsmall;
    }
  }

  .choose-playbook-template-tile {
    height: min-content;
    width: 100%;

    .tile-status {
      width: 8px;
      min-height: 100%;
      background: $theme-hollow-btn-border;
    }

    .tile-info {
      border: $theme-border-2;
      height: 100%;
      padding: $padding-normal;

      .name {
        font-size: $font-size-xlarge;
        font-weight: 500;
      }

      .description {
        padding-top: $padding-small;
        font-size: $font-size-medium;
        font-weight: 400;
        color: $theme-secondary-text-color;
      }
    }
  }

  .choose-playbook-template-tile + .choose-playbook-template-tile {
    margin-top: $padding-normal;
  }

  .config-content-container {
    border-top: $border2;
    padding-top: $padding-medium;

    .dropdown-toggle {
      height: 40px;
      font-weight: normal;
    }

    .sort-by-dropdown {
      .dropdown-menu {
        max-height: 300px;
        overflow-y: scroll;
      }
    }

    .output-list-type.selected-output-list {
      border: 1px solid $theme-hollow-btn-border;
    }

    .output-list-type {
      border: $border2;
      padding: $padding-small $padding-normal;
      margin-bottom: $padding-normal;
    }

    .dropdown-date-range-picker-component {
      .dropdown-menu {
        left: 0;
      }
    }

    .table-filter-controller {
      width: 100%;

      .filters-editor-container {
        width: 100%;

        .add-filter-button {
          float: left;
          color: $theme-hollow-btn-border;

          .add-filter-wrapper {
            margin-right: $padding-small;
          }
          img {
            padding-right: $padding-small;
          }

          &:hover .MuiSvgIcon-root.add-plus-icon {
            fill: #327ef0;

            .dark & {
              fill: #599bff;
            }
          }
        }

        .filter-content {
          margin-bottom: $padding-small;

          .dropdown-component {
            .dropdown-toggle {
              width: 100%;
              border-bottom: $border;
              font-weight: normal;
              color: $theme-text-color;
              background-color: $theme-filter-button-bg;
            }

            .dropdown-toggle:focus {
              box-shadow: none;
            }
          }

          input {
            background-color: $theme-filter-button-bg;
            margin-left: $padding-small;
            margin-right: $padding-small;
            padding: 0 $padding-small;
            flex: 1;
            border: $theme-border;
            border-radius: 0;
            color: $theme-text-color;
          }
          .dropdown-multi-select-component {
            .dropdown-toggle,
            .dropdown-toggle:active,
            .dropdown-toggle:hover:not(:disabled) {
              margin: 0;
              width: auto;
              min-width: 195px !important;
              border: $theme-border !important;
              background-color: $theme-filter-button-bg !important;
              color: $theme-text-color !important;
            }
          }
          .recent-changes-dropdown {
            width: 130px !important;

            .dropdown-toggle,
            .dropdown-toggle:active,
            .dropdown-toggle:hover:not(:disabled) {
              margin: 0;
              max-width: 150px !important;
              overflow: auto;
              min-width: 130px !important;
              border: $theme-border !important;
              background-color: $theme-filter-button-bg !important;
              color: $theme-text-color !important;
            }
          }

          .dropdown-multi-select-component {
            .dropdown,
            .dropdown-menu {
              background-color: $theme-form-control-color;
              height: auto;
            }
            .options-container {
              max-height: none !important;
            }
          }
        }
      }

      .add-filter-button {
        width: max-content;
        display: flex;
        align-items: center;
        margin-top: $padding-normal;
        cursor: pointer;
        float: right;
      }
    }
  }
}

.connectors-page,
.playbooks-page {
  .connectors-table-container,
  .playbooks-table-container {
    border: none !important;
    box-shadow: none !important;

    .table-component {
      .tool-bar-container {
        .table-title {
          font-size: 24px;
          font-weight: 500;
        }
      }

      .tiles-container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .playbooks-get-started {
      background-color: $theme-color;
      padding: 80px 30px;

      .description {
        width: 100%;
        max-width: 500px;
        padding: $padding-small;
        font-weight: 400;
        font-size: $font-size-medium;
        color: $theme-secondary-text-color;
      }

      .get-started-tile {
        background: $theme-widget-bg-color;
        margin: $padding-medium $padding-medium-half;
        border: $theme-border-2;
        padding: $padding-medium;
        width: 320px;

        .text {
          padding-top: $padding-medium;
          font-weight: 600;
          font-size: $font-size-medium;
          color: $theme-text-color;
        }
      }
    }
  }
}

.playbook-tile-component:nth-child(odd) {
  padding-right: $padding-medium-half;
}

.playbook-tile-component:nth-child(even) {
  padding-left: $padding-medium-half;
}

.playbook-tile-component {
  padding-bottom: $padding-medium;
  min-width: 50%;
  max-width: 50%;
  .tile-status {
    width: 16px;
    height: 100%;
    background: $theme-hollow-btn-border;
  }

  .tile-status.active {
    background: linear-gradient($light-blue-5, $light-blue-4);
  }

  .tile-info {
    padding: $padding-medium;
    border: $theme-border-2;
    border-left-width: 0;

    .tile-info-top {
      padding-bottom: $padding-medium;
      border-bottom: $theme-border-2;
    }

    .tile-info-bottom {
      padding-top: $padding-medium;

      .label-and-value-component + .label-and-value-component {
        padding-left: $padding-medium;
        margin-left: $padding-medium;
        border-left: $theme-border-2;
      }

      .run-now-button {
        flex: 1;
        text-align: end;
        color: $theme-hollow-btn-border;
        font-size: $font-size-medium;
        font-weight: 600;
      }
    }
  }
}

.parameters-text-area {
  .connector-parameters-textarea-box {
    border: $border;

    span#json-input-area {
      color: $dark-gray-3 !important;
    }
  }
}
