@import 'src/index';

.label-and-value-component.no-margin-bottom {
  margin-bottom: 0;
}

.label-and-value-component {
  margin-bottom: $padding-medium;

  .direction-row.card-label {
    padding-right: $padding-small;
    min-width: max-content;
    &.card-text {
      font-size: 14px;
      color: $abuseMailBox-allEMailSubmission-subheader-text;
      font-weight: normal;
    }
  }

  .direction-column.card-label {
    padding-bottom: $padding-small;
  }

  .card-label {
    font-weight: 500;
    font-size: $font-size-normal;
    color: $theme-secondary-text-color;
  }
  .attachement-text-clip {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copy-button,
  .redirect-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding-left: $padding-small;
  }

  .card-value {
    font-weight: normal;
    font-size: $font-size-medium;
    word-break: break-all;
    color: $theme-text-color;
  }

  p {
    margin-bottom: 0;
  }
}
