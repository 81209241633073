@import 'src/index';

$component-size: 12px;

.status-component {
  height: $font-size-xlarge;
  display: flex;
  align-items: center;

  .status-dot {
    min-width: $component-size;
    min-height: $component-size;
    border-radius: 50%;

    + * {
      margin-left: $padding-small;
    }
  }

  .active {
    background-color: $orange;
  }

  .down {
    background-color: $light-blue-3;
  }

  .pre-threat {
    background-color: $platinum;
  }

  .green {
    background-color: $green;
  }
}

.status-tooltip {
  .tooltip-inner {
    text-transform: capitalize;
  }
}
