@import 'src/index';
.lock-banner-wrap {
  margin: 1rem 24px;
  .banner {
    display: flex;
    margin: 1rem auto;
    padding: 1.5rem;
    color: $theme-text-color;
    align-items: center;
    background-color: $theme-widget-bg-color;
    border: 1px solid #ebf5ff59;
    box-shadow: 0px 0px 60px 0px #18181a4d;
    max-height: 150px;

    .lock-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
    }
    .content-wrap {
      padding: 0 1rem;
      font-size: 12px !important;
      font-weight: 300 !important;
      flex-grow: 1;
      height: auto;
      .heading {
        font-size: 20px;
        font-weight: bold;
        padding: 0.5rem 0;
      }
      .sub-heading {
        padding: 0 !important;
        padding-bottom: 1rem;
        color: $theme-subtext-color-typosquat-popup;
        .highlight-text {
          font-size: 16px;
          color: $theme-text-color;
          padding: 0 3px;
        }
        .normal-text {
          font-weight: 500 !important;
          font-size: 14px;
        }
      }
    }
    .upgrade-wrap {
      .upgrade-btn {
        width: 150px;
        margin: 0 1rem;
      }
    }
  }
}
