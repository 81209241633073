.new-connector-modal {
  .header-tips {
    font-size: 12px;
    font-weight: 600;
  }
  .add {
    color: var(--Light-Theme-Primary-600, #0d5bf8);
    font-size: 15px;
    font-weight: 500;
  }
  .add-container {
    width: 60px;
    margin-left: -4px;
    margin-top: 4px;
  }

  .cancel-button {
    margin-right: 16px;
    .dark & {
      color: #f5f5f5;
    }
  }
}

.connectors-table {
  height: calc(100vh - 204px);
  margin-top: 48px;
  position: relative;
}

.connector-modal-footer-buttons {
  min-width: 200px;
}

#delete-connector-modal {
  .modal-title {
    font-weight: 600;
    font-size: 14px;
  }
}
.delete-connector-modal-body {
  font-weight: 500;
  font-size: 12px;
  .light & {
    color: #333;
  }
  .dark & {
    color: #a9b2bf;
  }
  &-title {
    margin-top: -12px;
    margin-bottom: 16px;
  }
}
