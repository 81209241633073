@import 'src/index';

.auth-container {
  height: 100vh;
  width: 100vw;
}

.auth-title,
.back-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8vh;
  width: 100%;
  padding-bottom: $padding-large;
}
.back-btn {
  padding-bottom: 20px;
  button {
    color: $theme-text-color;
  }
  button:hover {
    color: $theme-hover-text-color !important;
  }
}

.sign-up-container {
  width: 100%;
  h2 {
    padding-top: $padding-large;
  }
}

.sign-up-via-brand-page {
  background-color: $theme-widget-bg-color;
}

.auth-description {
  max-width: 600px;
  padding-bottom: $padding-large;
}

.login-page {
  min-height: 100vh;
  padding: 0 8vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 10rem;
  .login-page-content {
    margin-top: 3rem;
    .login-glitch {
      max-height: 580px;
    }
  }
  .error {
    color: #ff4b55;
    margin: 1rem;
  }
}

@media only screen and (max-width: 567px) {
  .login-page {
    padding: 0px 2vh;
  }
}

.info-block {
  font-size: $font-size-normal;
  padding-top: $padding-small;
}

.back-icon {
  border: 1px solid black;
}

.checkphish-logo-login {
  .checkphish-logo {
    width: 207.466px;
    height: 42px !important;
    flex-shrink: 0;
  }
}

.checkphish-logo {
  height: 30px;
  display: flex;
  width: fit-content;
  margin: 0 auto;
}

.typo-error {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  color: #ff4b55;
  width: 60%;
  margin-bottom: 0;
  text-align: center;
}

.auth-page-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: auto;
  padding: $padding-large;
  // border: $theme-border;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: $theme-background-color;
  color: $theme-text-color;

  .email-component,
  .sign-up-form-component {
    margin-top: $padding-medium;
    margin-bottom: $padding-medium;
    position: relative;

    .email-inp::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $dark-gray-1;
    }

    .email-label,
    .label {
      font-weight: 500;
      font-size: $font-size-normal;
      color: $theme-secondary-text-color;
    }
  }

  .password-eye-component {
    margin-bottom: 0;
    .password-label {
      color: $theme-secondary-text-color;
    }
  }

  .nav-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: $padding-medium;

    span {
      padding-left: $padding-normal;
      font-size: $font-size-xxlarge;
      line-height: $padding-medium;
      font-weight: 500;
    }
  }

  .form-control:focus {
    border-color: $blue;
    box-shadow: none;
  }
  .form-control:invalid {
    box-shadow: none;
  }

  h3 {
    text-align: left;
    margin: 0;
    font-size: $font-size-xxlarge;
    line-height: $padding-medium;
    font-weight: 500;
  }

  .login-error.hidden {
    display: none;
  }

  .login-error {
    margin-top: $padding-medium;
    padding: $padding-small $padding-normal;
    border: 1px solid $red;
    border-radius: 3px;

    .help-block {
      height: auto;
      padding-top: 0;
    }
  }

  .help-block.hidden {
    opacity: 0;
  }

  .help-block {
    color: $red;
    font-size: $font-size-normal;
    height: 24px;
    padding-top: $padding-small;
  }

  .nav-text {
    font-size: $font-size-normal;
    padding-top: $padding-small;
  }

  .confirm-nav-text {
    font-size: $font-size-medium;
    padding-top: $padding-normal;
    padding-bottom: $padding-small;
  }

  .bottom-component {
    width: 100%;
    margin-top: $padding-medium !important;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div {
      padding-left: 0;
      padding-right: 0;
    }

    .forget-password-text {
      font-weight: normal;
      font-size: $font-size-medium;
    }
    .form-group {
      margin-bottom: 0;
      button:focus {
        color: $theme-text-color;
        background-color: $theme-hover-bg-color;
      }
    }
    .submit-button {
      background: #2168e4;
      color: white;
    }
  }

  .helper-bottom {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: $padding-medium;
  }

  .resetpass-component {
    margin-top: $padding-medium;
  }

  .top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: $padding-medium;

    .user-big-avatar {
      border-radius: 50%;
      height: 32px;
      width: 32px;
      line-height: 32px;
      text-align: center;
      background-color: $primary;
      margin-right: $padding-normal;
      color: $white;
      font-size: $font-size-medium;
    }

    .user-full-name {
      font-weight: 500;
      font-size: $font-size-medium;
    }
  }

  .info-text {
    font-size: $font-size-medium;
    font-weight: normal;
  }
}

.form-separator-container {
  padding: $padding-medium 0;
  color: $theme-secondary-text-color;

  .form-separator {
    height: 1px;
    flex: 1;
    border-bottom: $theme-border-2;
  }
}

.sso-login-button {
  height: 32px;
  border: 1px solid $theme-hollow-btn-border;
  color: $theme-secondary-text-color;
  font-weight: 600;
  font-size: $font-size-medium;
  cursor: pointer;
  border-radius: 4px;
  .svg-icon-wrapper {
    margin-right: $padding-normal;
  }
}

.sso-login-button:hover {
  color: $theme-hover-text-color;
  .svg-icon-wrapper {
    path {
      stroke: $theme-hover-text-color;
    }
  }
}

.login-page-content {
  .feature-list {
    // border: 1px solid black;
    background-image: url('../../assets/auth-background.jpg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 680px;
    color: white;

    .feature-item-text-container {
      max-width: 300px;
      height: auto;
      margin: 0 auto;

      .feature-item-benefit-heading {
        margin-top: 2rem;
      }

      .feature-items-wrap {
        margin-top: 50%;

        .feature-item {
          line-height: 150%;
          padding-bottom: $padding-small;
        }
      }
    }
  }

  .sign-up-component-custom-css {
    margin: unset;
    height: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: $screen-size-sm) {
      width: 100vw;
    }

    @media only screen and (min-width: $screen-size-sm) {
      width: 600px;
    }

    .check-box-component-container {
      .checkbox-label {
        font-size: $font-size-normal;
        font-weight: bold;
      }
    }

    .sign-up-email-button {
      margin: 0;
      margin-top: 0;
      height: 50px;
      background-color: white;
      color: $ocean;
      border: 0;
      box-shadow: $box-shadow;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.bg-ocean-light {
  background-color: $ocean-light;
}
.sign-up-brands-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10rem;

  .title-text {
    max-width: 700px;
    color: $theme-text-color;
    padding: 1rem;
  }

  .form-container {
    margin: auto;
    padding: 0 8vw;
  }
}

.about-bolster-section {
  background-color: #dcc4be;

  .bolster-image-top {
    max-height: 50px;
  }

  .section-image {
    min-width: 1px;
    max-width: 500px;
  }

  .about-bolster {
    .bolster-image {
      width: min-content;
    }

    .title {
      font-size: $font-size-xxxlarge;
      font-weight: 500;
    }

    .description {
      font-size: $font-size-xlarge;
    }
  }
}

.user-verify-page {
  .sign-up-button {
    border-radius: 20px;

    a {
      color: $white;
    }
  }
}

.separator-line {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-top: $padding-normal;
  text-align: center;
  color: $dark-gray-2;
}

.separator-line::before,
.separator-line::after {
  position: absolute;
  width: 45%;
  height: 1px;
  top: 24px;
  border-top: 1px dashed $light-gray-4;
  content: '';
}

.separator-line::before {
  left: 0;
}

.separator-line::after {
  right: 0;
}

.link-ocean-theme,
.link-ocean-theme:hover {
  color: $ocean;
}
.show-login {
  margin-top: -$padding-medium;
  transition: all 0.5s ease-in-out;
  height: 90px;
  pointer-events: cursor;
  opacity: 1;
}
.hide-login {
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}

.sign-up-v2 {
  .work-email-err-msg-logo {
    height: 23px;
    width: 23px;
    margin-right: 10px;
  }
  .form-control:disabled {
    background-color: #e9ecef !important;
    cursor: not-allowed;
  }
  background: var(--Neutral-50, #f6f9fc);
  height: 100%;

  ::placeholder {
    color: #0a2540 !important;
    opacity: 0.55 !important;
  }
  .sign-up-brands-container {
    padding-bottom: 0;
    height: 100%;
    flex-direction: unset;
    .sign-up-container {
      @media only screen and (min-width: $screen-size-sm) {
        margin: 0 188px;
        border-left: 1px dotted rgba(226, 233, 242, 0.9);
        border-right: 1px dotted rgba(226, 233, 242, 0.9);
        .screen-width-info {
          display: none !important;
        }
      }
      @media only screen and (max-width: $screen-size-sm) {
        margin: 0 24px;
        .screen-width-info {
          border-radius: 2px;
          border: 1px solid #ffe681;
          background: #fff9db;
          padding: 14px 7px;
          p {
            color: #573b00;
            font-size: 12px;
          }
        }
      }
      padding: 0;
      z-index: 1;
      .back-btn {
        padding-top: 0;
      }
      .checkphish-logo-login {
        @media only screen and (min-width: $screen-size-sm) {
          margin-bottom: 6.5rem;
        }
        @media only screen and (max-width: $screen-size-sm) {
          margin-bottom: 1rem;
        }
        margin-top: 3rem;
        img {
          width: 10rem;
          height: 2rem;
          flex-shrink: 0;
        }
      }
      .form-container {
        max-width: 500px;
        padding: 0;
        .asterisk {
          color: #ff5757;
        }
        .error-border {
          border: 1px solid #ff2020 !important;
        }
        .title-text-err {
          color: #ff5757;
        }
        .email-container {
          padding-top: 14px;
        }
        .text-container {
          z-index: 2;
          position: relative;
          .title-text {
            padding: 0;
            margin: 0;
          }
          .second-text {
            color: rgba(10, 37, 64, 0.6);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.2px;
          }
          h2.second-text {
            @media only screen and (max-width: $screen-size-sm) {
              font-size: 14px;
            }
          }
          h2.title-text {
            @media only screen and (max-width: $screen-size-sm) {
              font-size: 24px;
            }
          }
          @media only screen and (min-width: $screen-size-sm) {
            margin-bottom: 45px;
          }
          @media only screen and (max-width: $screen-size-sm) {
            margin-bottom: 10px;
          }
        }
        .form-label {
          color: var(--Neutral-500, #51637a);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0.2px;
        }
        .form-control {
          font-size: 1rem;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 8px;
          height: 54px;
          position: relative;
          z-index: 2;
        }
        .continue-button {
          width: 100%;
          border-radius: 8px;
          height: 54px;
          position: relative;
          z-index: 2;
          &:hover {
            background: #1744ad;
            color: #fff !important;
          }
        }
        .continue-button.work-email-disable {
          background: #e6e6e6;
          color: #b1b1b1;
          border: none;
          &:hover {
            background: #e6e6e6;
            color: #b1b1b1 !important;
          }
        }
        .continue-button.btn-outline-secondary {
          border: 1px solid #4479f3;
          color: #4479f3;
          &:hover {
            background-color: #4479f3;
            color: #fff;
          }
        }
        .provider-button {
          img {
            background-color: #fff;
            padding: 13px;
            margin: 0;
            border-radius: 8px 0 0 8px;
            width: 52px;
            height: 52px;
          }
          .text {
            width: 90%;
            position: relative;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            top: 14px;
            text-transform: capitalize;
          }
          &:hover {
            color: white;
            background-color: var(--theme-a-tag-color);
            border-color: var(--theme-a-tag-color);
            font-weight: 600;
            font-size: 14px;
          }
        }
        .remind-me-later {
          margin-top: 10px;
          float: right;
          cursor: pointer;
          color: rgba(81, 101, 120, 0.75);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          letter-spacing: 0.4px;
        }
        @media only screen and (min-width: $screen-size-sm) {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 71.5%;
            border-left: 1px dashed rgba(226, 233, 242, 0.9);
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 28.5%;
            border-left: 1px dashed rgba(226, 233, 242, 0.9);
          }
        }

        form {
          .enter-container {
            flex-direction: row-reverse;
            .form-label {
              color: #2c58be;
              font-weight: 400;
              span {
                font-weight: 600;
                img {
                  width: 11px;
                  height: 14.5px;
                  top: -2px;
                  position: relative;
                }
              }
            }
          }
          #emailHelpBlock {
            color: #ff2323 !important;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px; /* 158.333% */
            letter-spacing: 0.2px;
          }
        }

        .divider {
          padding: 18px 0;
          display: flex;
          align-items: center;
          color: rgba(67, 81, 99, 0.7);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
          letter-spacing: 0.2px;
        }

        .divider::before,
        .divider::after {
          flex: 1;
          content: '';
          padding: 1px;
          background: rgba(171, 191, 217, 0.6);
          margin: 5px;
        }

        .sso-container {
          cursor: pointer;
          padding: 12px 18px;
          position: relative;
          z-index: 2;
          width: 100%;
          height: 54px;
          border-radius: 8px;
          background: #fff;
          border: 1px solid rgba(171, 191, 217, 0.4);
          justify-content: center;
          p {
            color: rgba(67, 81, 99, 0.9);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
          }
          .logo {
            width: 25px;
            height: 25px;
            margin-right: 12px;
          }
        }
        .microsoft-container {
          margin: 14px 0;
        }
        .already-have-account {
          position: relative;
          z-index: 2;
          color: var(--Neutral-400, #7b8da5);
          font-size: 14px;
          font-style: normal;
          line-height: 22px; /* 157.143% */
          letter-spacing: 0.2px;
          a {
            margin-left: 5px;
          }
        }
        .work-email-err-msg {
          border-radius: 4px;
          background: #fee;
          padding: 14px 12px;
          margin-bottom: 20px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          p {
            margin: 0;
            color: #ff2323;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.2px;
          }
        }
      }
      .form-container.sent-email-verification {
        p {
          color: #0a2540;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 166.667% */
          letter-spacing: 0.2px;
          .email {
            color: #4479f3;
          }
        }
      }
      .password-eye-component {
        .password-trigger {
          z-index: 2;
        }
      }
      @media only screen and (min-width: $screen-size-sm) {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          border-left: 1px dashed rgba(226, 233, 242, 0.9);
        }
      }
    }
  }
}
