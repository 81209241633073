@import 'src/index';

.monitiored-container-v2 {
  border: $theme-widget-border-color;
  margin: $padding-medium;
  padding: $padding-medium;
  background-color: $theme-widget-bg-color;
  color: $theme-text-color;
  width: -webkit-fill-available;

  .tabs-wrapper {
    display: flex;
    flex-direction: row;

    .tabs {
      font-size: $font-size-xlarge;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .disabled-tabs:hover {
      color: $theme-column-title-color !important;
      pointer-events: none !important;
    }

    .tabs:hover {
      cursor: pointer;
    }

    .tabs.active {
      cursor: pointer;
      color: $theme-text-color;
      text-decoration: underline;
      text-underline-offset: 8px;
    }

    .tab-line {
      height: inherit;
      border: $theme-widget-border-color;
      margin: 0px $padding-normal;
    }

    .tabs-dropdown-container {
      margin-left: $padding-normal;

      .tabs-dropdown {
        transition: transform 0.5s ease-out;
      }
    }

    .tabs-dropdown-container:hover {
      .tabs-dropdown {
        cursor: pointer;
        transform: translateY(6px);
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .filter-container {
      display: none !important;
    }

    .filter-container-mobile {
      display: block;

      .dropdown-toggle {
        border-bottom-color: $theme-widget-bg-color;
        color: $theme-text-color !important;
      }

      .dropdown-menu.show {
        background-color: $theme-widget-bg-color;
      }

      .options-container {
        overflow-y: unset !important;
      }

      .w-100 {
        width: 200px !important;
        overflow-x: auto;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .filter-container-mobile {
      display: none;
    }

    .filter-container {
      display: block;
    }
  }
}
.monitiored-container-v2.new-container {
  padding: $padding-small !important;
  border: 1px solid $card-wrapper-border !important;
  background-color: $card-background-color !important;
  color: $theme-text-color !important;
  border-radius: 12px !important;
}

.sub-tabs-wrapper {
  position: absolute !important;
  top: 40px !important;
  right: -35px !important;
  background-color: $theme-background-color;
  padding: 12px;
  color: $theme-text-color;
  width: 330px;
  box-shadow: 5px 6px 7px rgba(5, 3, 16, 0.33);
  border: $theme-border;
  z-index: 1;

  .list-wrapper {
    .label-wrapper {
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: $padding-small;
      margin: $padding-small $padding-xsmall 0px 0px;
    }

    .label-wrapper:hover {
      cursor: pointer;
      background-color: $theme-dropdown-hover-bg-color;
    }
  }
}

.MuiTabs-flexContainer {
  .MuiTab-root.tabs {
    text-transform: none;
    &:not(:last-child) {
      margin-inline-end: 12px;
    }
  }
}
