@import 'src/index';

.upload-files-button {
  padding: $padding-small $padding-normal;
  border: $border2;
  border-radius: 4px;
  cursor: pointer;
  display: flex;

  span {
    color: $theme-hollow-btn-border;
    font-weight: 600;
    font-size: $font-size-medium;
    padding-left: $padding-normal;
  }
}

.upload-files-container {
  margin-bottom: $padding-normal;

  .label {
    font-weight: 600;
    font-size: $font-size-normal;
    color: $theme-text-color;
    padding-bottom: $padding-small;
  }

  .attach-file-text {
    padding-left: $padding-normal;
    font-family: Fakt;
  }

  .drag-and-drop-area {
    padding: $padding-xxsmall $padding-normal $padding-xxsmall $padding-xxsmall;
    border-radius: 4px;
    border: 1px dashed #c9c9c9;
    width: max-content;
    .drop-files-text {
      padding-left: $padding-small;
      font-weight: 500;
    }
  }
  .drag-and-drop-area.is-dragging {
    border-width: 2px;
    border-color: $light-blue-4;
    * {
      pointer-events: none;
    }
  }
  .help-text {
    font-weight: 400;
  }
}

.upload-file-button {
  & > .MuiSvgIcon-root.upload-file-icon {
    fill: #327ef0;
  }
  &:hover > .MuiSvgIcon-root.upload-file-icon {
    fill: #2360bc !important;

    .dark & {
      fill: #b2d0fe !important;
    }
  }
  .dark & .MuiSvgIcon-root.upload-file-icon {
    fill: #599bff;
  }
}
