@import 'src/index';

.insights-pagination-controls {
  display: flex;
  justify-content: space-around;
  .insights-page-wrapper {
    display: flex;
    align-items: center;
    .svg-icon-wrapper {
      margin-right: 8px;
    }
    .insights-page-label {
      color: $theme-secondary-text-color;
      margin-right: 8px;
    }
  }
}
