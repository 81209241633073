@import 'src/index';
.alert-session-wrapper {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: $padding-normal $padding-medium;
        background-color: $theme-user-session-alert-color;
        color: $white;
      }
      .modal-footer {
        .button-container {
          .btn-primary {
            background-color: $theme-user-session-alert-color;
            border-color: $theme-user-session-alert-color;
            &:hover {
              background-color: $theme-user-session-alert-color;
              border-color: $theme-user-session-alert-color;
              color: white;
            }
          }
          .btn-outline-secondary {
            color: $theme-user-session-alert-color;
            border-color: $theme-user-session-alert-color;
            &:hover {
              background-color: white;
              color: $theme-user-session-alert-color;
              border-color: $theme-user-session-alert-color;
            }
          }
          .MuiButton-root {
            &.MuiButton-contained {
              background-color: #f44336;
              color: #fff;

              &:is(:hover, :active) {
                background-color: #e53935 !important;
                color: #fff;
              }
            }
            &.MuiButton-outlined {
              border-color: #d32f2f80 !important;
              color: #f44336;

              &:is(:hover, :active) {
                background-color: #d32f2f0a;
              }
            }
          }
        }
      }
    }
  }
}
.warning-session-wrapper {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: $padding-normal $padding-medium;
        background-color: $theme-user-session-warning-color;
        color: $white;
      }
      .modal-footer {
        .button-container {
          .btn-primary {
            background-color: $theme-user-session-warning-color;
            border-color: $theme-user-session-warning-color;
            &:hover {
              background-color: $theme-user-session-warning-color;
              border-color: $theme-user-session-warning-color;
            }
          }
          .btn-outline-secondary {
            color: $theme-user-session-warning-color;
            border-color: $theme-user-session-warning-color;
            &:hover {
              color: $theme-user-session-warning-color;
              border-color: $theme-user-session-warning-color;
            }
          }
        }
      }
    }
  }
}
.session-timeout-container {
  margin-top: $padding-medium;
  .session-title {
    font-size: $font-size-xlarge;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: $padding-medium-half;
  }
  .session-description {
    display: flex;
    flex-direction: row;
    .description-wrapper {
      margin-left: $padding-xsmall;
      font-size: $font-size-normal;
      font-weight: 400;
      line-height: $padding-normal;
      letter-spacing: 0.004em;
      text-align: left;
      .timer {
        font-size: $font-size-medium;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0.004em;
        text-align: left;
      }
    }
  }
}
