@import 'src/index';

$component-size: 12px;

.risk-component {
  height: 100%;
  display: flex;
  align-items: center;

  .risk-value {
    border-radius: $padding-medium-half;
    height: $padding-medium;
    line-height: $padding-medium;
    padding: 0 $padding-medium-half;
    font-weight: 600;
    font-size: $font-size-normal;
    color: $theme-text-color !important;
  }

  .severity-critical {
    background-color: $theme-risk-bg-color-critical;
    color: #fff !important;
  }

  .severity-high {
    background-color: $theme-risk-bg-color-high;
    color: #fff !important;
  }

  .severity-moderate {
    background-color: $theme-risk-bg-color-moderate;
  }

  .severity-low {
    background-color: $theme-risk-bg-color-low;
  }

  .severity-ver-low {
    background-color: $theme-risk-bg-color-very-low;
  }
}

.risk-tooltip {
  .tooltip-inner {
    .high-light {
      font-weight: 600;
    }
  }
}
