@import 'src/index';

.page-title-component.padding-top {
  padding-top: $padding-medium;
}

.page-title-component.background-white {
  background-color: $theme-color;
}

.page-title-component {
  width: 100%;
  min-height: max-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $theme-color;

  .page-title-left {
    display: flex;
    align-items: center;

    .title-info {
      padding-top: $padding-medium;
      padding-left: $padding-medium;
    }

    img {
      margin-right: $padding-normal;
    }
  }

  > div.page-title {
    padding-top: $padding-medium;
    padding-left: $padding-medium;
  }

  &.px--start-0.py--top-0 > .page-title {
    padding: 0;
  }

  div.page-title {
    font-weight: 500;
    font-size: $font-size-xlarge;
    max-width: 20rem;
    color: $theme-text-color;

    .page-parent-label {
      font-weight: 500;
      font-size: $font-size-xlarge;
    }
  }

  .page-title-right {
    padding-top: $padding-medium;
    padding-right: $padding-medium;
    padding-left: $padding-medium;
    .tools-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .page-title-spinner {
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}

@media only screen and (max-width: $screen-size-sm) {
  .page-title-component {
    justify-content: center;
    padding-bottom: $padding-normal;

    > div.page-title {
      display: none;
    }
  }

  .page-title-component.title-always-shown {
    justify-content: flex-start;
    border-bottom: $border;

    > div.page-title {
      display: block;
      font-size: $font-size-xlarge;
    }
  }
}

@media only screen and (max-width: $screen-size-md) {
  .page-title-insights-component {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-bottom: $border;

    .page-title-left {
      align-self: flex-start;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title-info {
        background-color: $white;
        width: 100%;
        padding-top: $padding-normal;
        padding-bottom: $padding-normal;
        padding-right: $padding-medium;
        border-bottom: $border;

        .page-title {
          flex: 1;
          max-width: 100vw;
        }
      }
    }

    .page-title-right {
      padding: $padding-normal $padding-medium;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: $screen-size-sm) {
  .page-title-insights-component {
    border-bottom: none !important;

    .page-title-left {
      .title-info {
        padding-left: $padding-normal;
        padding-right: $padding-normal;

        .page-title {
          font-size: $font-size-xlarge;
        }
      }
    }

    .page-title-right {
      padding: $padding-normal $padding-normal;
    }
  }
}

.MuiSvgIcon-root.home-icon-hover--none {
  pointer-events: none;
}
