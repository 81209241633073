@import 'src/index';

.domain-monitoring-wrapper {
  max-width: 100px !important;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px;
}
.disposition-status-component {
  color: $theme-text-color;
  font-weight: normal;
  font-size: $font-size-medium;
  text-transform: capitalize;
}

.disposition-clean {
  color: var(--theme-disposition-clean);
}

.disposition-suspicious {
  color: var(--theme-disposition-suspicious);
}

.disposition-malicious {
  color: var(--theme-disposition-malicious);
}

.disposition-malware {
  color: var(--theme-disposition-malware);
}

.disposition-phish {
  color: var(--theme-disposition-phish);
}

.disposition-likely-phish {
  color: var(--theme-disposition-likely-phish);
}

.disposition-spam {
  color: var(--theme-disposition-spam);
}

.pill {
  color: $white;
  width: auto;
  padding: 0 15px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
}

.disposition-clean.pill {
  background-color: $theme-disposition-clean;
}

.disposition-phish.pill {
  background-color: $theme-disposition-phish;
}

.disposition-likely-phish.pill {
  background-color: $theme-disposition-likely-phish;
}

.disposition-suspicious.pill {
  background-color: $theme-disposition-suspicious;
}

.disposition-malicious.pill {
  background-color: var(--theme-disposition-malicious);
}

.disposition-malware.pill {
  background-color: var(--theme-disposition-malware);
}

.disposition-spam.pill {
  background-color: var(--theme-disposition-spam);
}

.disposition-others.pill {
  background-color: $theme-disposition-others;
}
.sfb-blocked.pill {
  background-color: $green;
}

.disposition-clean.newPillBgColor.pill {
  background-color: $disposition-clean-pill-bg-color;
}

.disposition-phish.newPillBgColor.pill {
  background-color: $disposition-phish-pill-bg-color;
}

.disposition-scam.newPillBgColor.pill {
  background-color: $disposition-scam-pill-bg-color;
}
