@import 'src/index';

.loader-wrap {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  .rbt-loader {
    height: 30px;
    width: 30px;
    color: var(--theme-page-text);
  }
}

.notification-wrap {
  padding: $padding-medium;
  color: var(--theme-page-text);
  .sub-header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: $padding-medium;
  }
  .notification-checkbox-wrap {
    .form-check-label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0.3rem;
    }
    .save-btn {
      min-width: 100px;
      margin-top: 1rem;
    }
  }
}
