@import 'src/index';

.source-feeds-container {
  display: flex;
  flex-direction: row;
  margin: $padding-medium 0px;
  gap: $padding-normal;
  overflow-x: auto;
  width: 100%;
}

@media (min-width: 1500px) {
  .source-feeds-container {
    justify-content: center;
  }
}
