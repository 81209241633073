@import 'src/index';

.findings-title {
  text-transform: capitalize;
}
.dashboard-table-container {
  border-radius: 10px;
  width: 100%;
  display: flex;
  .widget-details-container {
    width: 100%;
    color: $theme-text-color;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    overflow: auto;

    .widget-details-item {
      width: 100%;
      cursor: pointer;
      padding: $padding-medium-half 20px;
      border-top: $theme-border-2;
      border-bottom: $theme-border-2;
      height: 100% !important;
      min-width: 225px;
      overflow-x: visible;
      max-height: 150px !important;
      display: flex;
      align-items: center;
    }

    .widget-details-item:first-child {
      border-left: $theme-border-2;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    .widget-details-item:last-child {
      border-right: $theme-border-2;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .hide-border {
      border-right: none;
    }
    .active {
      border-radius: 12px !important;
      max-height: unset !important;
      border: 2px solid var(--Light-Theme-Alternative-Dark-Web-400, #ff7d6b) !important;
      background: $theme-widget-bg-color;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      height: 175px !important;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
    }

    .divider-line {
      background-color: $theme-light-bg-color;
      display: flex;
      width: 1px;
      height: 60%;
      align-items: center;
    }
    .darkWeb-findings-widget-icon {
      width: 32px;
      height: 32px;
    }
  }
  .hide-left-border {
    border-left: none !important;
  }
  .hide-right-border {
    border-right: none !important;
  }
  .darkweb-column-group {
    background: $theme-darkweb-finding-group-header-bg-color;
  }
  .tile {
    display: flex;
    justify-content: left;
    .tile-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 0;
      margin: 0;
      height: fit-content;
      .icon {
        margin: 0 8px;
      }
      .title {
        margin: 0;
      }
      .svg-icon-wrapper:hover {
        cursor: default;
        rect {
          fill: var(--icon-color);
        }
        path {
          fill: var(--icon-color);
          cursor: default;
        }
      }
    }
    .tile-body {
      display: flex;
      .data-set {
        display: flex;
        flex-flow: column nowrap;
        height: 30%;
        padding: 0 3px;
        .head {
          font-size: $font-size-xxlarge;
          margin: 0;
          padding: 0;
        }
        .body {
          font-size: $font-size-medium;
          margin: 0;
          padding: 0;
          color: $theme-secondary-text-color-light;
        }
      }
    }
  }
}

.export-label {
  font-family: Fakt;
  font-size: $font-size-medium;
  font-weight: 500;
  color: $theme-secondary-text-color;
  margin-right: $padding-normal;
  cursor: pointer;
  &:hover {
    color: var(--icon-blue-shade-color);
  }
}

.export-csv-button {
  margin-inline: 12px;
  &:hover > .export-label {
    color: var(--icon-blue-shade-color);
  }

  &:hover > .MuiSvgIcon-root.export-icon {
    fill: #327ef0 !important;
  }
  .dark &:hover > .MuiSvgIcon-root.export-icon {
    fill: #599bff !important;
  }
}

.darkweb-v2-dashboard-page {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.darkweb-v2-dashboard-resize {
  width: calc(100% - 300px);
  max-width: calc(100% - 300px);
}
