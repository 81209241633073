@import 'src/index';

.top-5-suspicious-urls-no-data-container {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 20px;

  .top-5-suspicious-urls-no-data-text-container {
    .top-5-suspicious-urls-no-data-main-text {
      font-size: 18px;
      font-weight: 600;
    }
    .top-5-suspicious-urls-no-data-sub-text {
      font-family: Fakt;
      font-size: 14px;
      font-weight: 400;
      color: $card-descriptipn-text-color;
    }
  }
}
