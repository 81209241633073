@import 'src/index';

.takedown-timeline-container {
  display: flex;
  width: 100%;
  overflow: auto;
  min-height: 300px;
  .takedown-timeline-container-no-data {
    display: flex;
    justify-content: left;
    width: 100%;
    height: inherit;
    font-size: $font-size-medium;
    color: $theme-secondary-text-color;
    padding: $padding-medium;
  }
}
