@import 'src/index';

.dropdown-timezone-picker-component {
  .dropdown-component {
    .dropdown-menu {
      max-height: 200px;
      overflow-y: scroll;
    }
  }
}
