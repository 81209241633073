@import 'src/index';
@import 'src/components/Common/Tags/Tags.scss';

.delete-custom-tag-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .delete-custom-tag-description {
    font-size: $font-size-normal;
    margin-bottom: $padding-normal;
    span {
      font-weight: bold;
    }
  }
  .selected-tag-wrapper {
    display: flex;
    margin-bottom: $padding-normal;
  }
}
