@import 'src/index';

$component-size: 12px;

.sso-sign-in-button-component {
  cursor: pointer;
  box-shadow: $box-shadow;
  color: $ocean;
  display: flex;
  align-items: center;
  background-color: $theme-widget-bg-color;

  img {
    width: 50px;
    padding: 11px;
    border-right: 1px solid $light-gray-3;
    background-color: $white;
  }

  span {
    flex: 1;
    text-align: center;
  }

  & + & {
    margin-top: $padding-normal;
  }
}
