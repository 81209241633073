@import 'src/index';

.no-data-wrapper {
  padding: 3rem;
  width: 55%;
  margin: 0 auto;
  text-align: center;
  display: flex !important;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  color: $theme-text-color;
}
