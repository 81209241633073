@import 'src/index';

$component-size: 12px;

.finding-status-component {
  .dot {
    min-width: $component-size;
    min-height: $component-size;
    border-radius: 50%;
    + * {
      margin-left: $padding-small;
    }
  }
  [data-status='LIVE'] {
    background-color: $status-live;
  }
  [data-status='PAUSED'] {
    background-color: $status-paused;
  }
  [data-status='IN_PROGRESS'] {
    background-color: $status-in-progress;
  }
  [data-status='DOWN'] {
    background-color: $status-down;
  }
  [data-status='IGNORED'] {
    background-color: $status-ignored;
  }
}

.upload-detection-modal-body {
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 10px;
  }
}
