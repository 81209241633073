@import 'src/index';

.dropdown-date-range-picker-component {
  .date-range-dropdown {
    height: 40px;
    background-color: $theme-dropdown-button-color;
    border: $theme-widget-border-color;
    color: $theme-text-color;
    display: flex;
    align-items: center;

    img {
      padding-right: $padding-small;
    }
    &:hover {
      color: $theme-widget-legend-header-color;
    }
  }

  .date-range-dropdown::after {
    margin-left: $padding-small;
  }

  > button.blue-box {
    border-color: $light-blue-2;
  }

  .dropdown-menu.drop-down-left.calendar-shown {
    left: -422px;
    background-color: $theme-dropdown-button-color;

    .submit-button-container {
      .submit-button {
        float: right;
        padding: $padding-xsmall $padding-normal;
        margin: 0 $padding-normal $padding-normal;
        font-weight: 600;
        font-size: $font-size-medium;
      }
    }
  }

  .dropdown-menu.drop-down-left {
    left: -22px;
    background-color: $theme-form-control-color;

    .option-items-container {
      .option-item:hover {
        background-color: $theme-dropdown-hover-bg-color;
      }

      .option-item {
        height: 32px;
        width: 290px;
        justify-content: space-between;
        padding: 0 $padding-normal;
        cursor: pointer;

        .option-item-label {
          font-size: $font-size-medium;
          color: $theme-text-color;
          font-weight: normal;
        }

        .option-item-value {
          font-size: $font-size-normal;
          color: $theme-text-color;
          font-weight: normal;
        }
      }
    }

    .rdrCalendarWrapper {
      background-color: $theme-dropdown-button-color;
    }
    .rdrDateDisplayWrapper {
      display: none;
    }

    .rdrMonthAndYearWrapper {
      padding-top: 0;
      height: min-content;
      .rdrMonthPicker,
      .rdrYearPicker {
        select {
          color: $theme-text-color;
          background-image: url(../../assets/DropDownArrow.svg);
          background-repeat: no-repeat;
        }
      }
      .rdrNextPrevButton.rdrPprevButton {
        background: $theme-dropdown-button-color;
        i {
          border-color: transparent $theme-modal-overlay-close-btn transparent transparent;
        }
      }
      .rdrNextPrevButton.rdrNextButton {
        background: $theme-dropdown-button-color;
        i {
          border-color: transparent transparent transparent $theme-modal-overlay-close-btn;
        }
      }
      .rdrNextPrevButton:hover {
        background: $theme-monitored-header-bg;
      }
    }

    .rdrMonthName {
      font-weight: 500;
      font-size: $font-size-large;
      color: $theme-text-color;
    }

    .rdrDay.rdrDayDisabled {
      background-color: transparent;
      opacity: 0.5;
      cursor: default;

      span {
        color: $theme-text-color;
        font-size: $font-size-large;
        font-weight: normal;
      }
    }

    .rdrDay.rdrDayPassive {
      opacity: 0;
    }

    .rdrDay {
      .rdrDayNumber {
        span {
          color: $theme-text-color;
          font-size: $font-size-large;
          font-weight: normal;
        }
      }

      .rdrStartEdge,
      .rdrEndEdge {
        background-color: $theme-a-tag-color;
      }

      .rdrStartEdge + .rdrDayNumber > span,
      .rdrEndEdge + .rdrDayNumber > span {
        color: $white;
      }
    }
  }

  @media only screen and (min-width: $screen-size-sm) and (max-width: $screen-size-md) {
    .dropdown-menu.drop-down-left.calendar-shown {
      left: -6rem;

      .rdrMonths.rdrMonthsHorizontal {
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: $screen-size-sm) {
    .dropdown-menu.drop-down-left.calendar-shown {
      left: -3rem;

      .rdrMonths.rdrMonthsHorizontal {
        flex-direction: column;
      }
    }
  }

  .dropdown-menu-shown {
    display: block !important;
    margin-block-start: 0.5rem;
    padding-block-start: 0px;
  }

  .MuiSvgIcon-root {
    &[data-testid='DateRangeRoundedIcon'] {
      pointer-events: none;
    }
  }
}

.MuiPaper-root {
  &.MuiPaper-elevation.MuiPopover-paper {
    margin-block-start: 0.5rem;
    .MuiList-root {
      width: 290px;
      .option-items-container {
        .MuiMenuItem-root {
          justify-content: space-between;
          .option-item {
            height: 32px;
            width: 290px;
            justify-content: space-between;
            padding: 0 $padding-normal;
            cursor: pointer;
          }
          .option-item-label {
            font-size: $font-size-medium;
            color: $theme-text-color;
            font-weight: normal;
          }

          .option-item-value {
            font-size: $font-size-normal;
            color: $theme-text-color;
            font-weight: normal;
          }
        }
      }
    }
  }
}
