@import 'src/index';

.bottom-items {
  display: flex;
  width: 190px;
  margin: 1rem auto;
  justify-content: center;
  background: linear-gradient(300deg, #40afff 8.94%, #5954fe 92.98%),
    linear-gradient(0deg, #ffffff, #ffffff);
  border-radius: 12px;
  align-items: center;
  color: $theme-text-color;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  .upgrade-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 1rem;
    width: 99%;
    height: 99%;
    border-radius: 10px;
    background-color: $theme-upgrade-popup-bg;
    .main-head {
      color: $theme-text-color;
      width: 100%;
      margin-bottom: 0.5rem;
      font-weight: 300;
      opacity: 80%;
      .sub-head {
        font-size: 12px;
        opacity: 80%;
      }
    }
    .content {
      font-size: 14px;
      font-weight: 500;
    }
    .upgrade-btn {
      width: 120px;
      margin-top: 1rem;
      border-radius: 30px;
      background: $theme-upgrade-button-bg;
      color: $theme-upgrade-button-color;
      border: transparent;
      &:hover {
        color: black !important;
      }
    }
  }
}

.tooltip-wrapper {
  // padding: 0.5rem;
  background-color: #e9f4ff;
  border: 2px solid #b9dcff;
  color: #0058f2;
  margin-right: 180px;
  margin-left: 120px;
  display: flex;
  align-items: first baseline;
  img {
    display: block;
  }
}
