@import 'src/index';

.look-alike-wrap {
  margin: 5px 0;
  margin-right: 5px;
  .container {
    width: 280px;
    display: flex;
    flex-flow: column nowrap;
    padding: 25px;
    justify-content: space-between;
    .header {
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 10px;
      img {
        padding: 0 10px;
      }
    }
    .content {
      .active-domain-stat {
        font-size: 56px;
        font-weight: bold;
        padding: 10px 0;
      }
      .active-domain-stat.center {
        text-align: center;
      }
      .bar-wrap {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 50px;
        .data-wrap {
          .data {
            font-weight: bold;
            font-size: 14px;
            span {
              color: $theme-look-alike-subtext;
            }
          }
          .scan-percentage {
            margin-top: 5px;
            height: 6px;
            border-radius: 10px;
            width: 120%;
          }
        }
        .upgrade-button-container {
          margin-left: 20px;
          .upgrade-button {
            width: 100px;
            height: 28px;
            display: flex;
            font-weight: 500;
            font-size: 14px;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
          }
        }
      }
    }
    .bottom-wrap {
      display: flex;
      justify-content: space-between;
      .data-wrap {
        height: 30px;
        .label {
          color: $theme-look-alike-label;
          margin-right: 5px;
          font-size: 12.5px;
          letter-spacing: 1px;
        }
        .data {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}
