@import 'src/index';

@mixin tags-apply-btn {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  .tags-loading-wrapper {
    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(0, 175, 170, 0.0001) 0deg,
      #ffffff 359.96deg,
      rgba(0, 175, 170, 0.0001) 360deg
    );
    .tags-loading {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: transparent;
    }
  }
}
.tags-column-container {
  width: 100%;
  padding: 10px 0px;
  .tags-column-wrapper {
    &:hover {
      cursor: pointer;
    }
    min-width: 290px;
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    gap: 6px;
    align-items: center;
    .add-tag-button {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      height: $padding-medium;
      border-radius: $padding-medium;
      padding: $padding-xxxsmall $padding-small;
      background: $theme-add-tag-bg-color;
      color: $theme-modal-overlay-close-btn;
      font-weight: 500;
      font-size: $font-size-normal;
      line-height: 19px;
      .svg-icon-wrapper:hover {
        path {
          fill: var(--icon-color);
        }
      }
      .MuiSvgIcon-root.add-plus-tag-icon {
        fill: #64717e;

        .dark & {
          fill: #a8b3c1;
        }
      }

      &:hover .MuiSvgIcon-root.add-plus-tag-icon,
      .MuiSvgIcon-root.add-plus-tag-icon:hover {
        fill: #2a3a4a !important;
      }
      .dark &:hover .MuiSvgIcon-root.add-plus-tag-icon,
      .dark & .MuiSvgIcon-root.add-plus-tag-icon:hover {
        fill: #c6d5ea !important;
      }
    }
    .custom-tag-button {
      @extend .add-tag-button;
      padding: $padding-xxsmall $padding-small;
      background: none;
      .svg-icon-wrapper {
        margin-right: $padding-xsmall;
        path {
          stroke: var(--icon-color);
        }
      }
      .svg-icon-wrapper:hover {
        path {
          stroke: var(--icon-color);
        }
      }
    }
    .edit-tag-button {
      @extend .add-tag-button;
      width: 36px;

      .MuiSvgIcon-root.pencil-edit-icon {
        fill: #64717e;

        .dark & {
          fill: #a8b3c1;
        }
      }

      &:hover .MuiSvgIcon-root.pencil-edit-icon,
      .MuiSvgIcon-root.pencil-edit-icon:hover {
        fill: #2a3a4a !important;
      }
      .dark &:hover .MuiSvgIcon-root.pencil-edit-icon,
      .dark & .MuiSvgIcon-root.pencil-edit-icon:hover {
        fill: #c6d5ea !important;
      }
    }
  }
}

.tags-container {
  position: absolute;
  background-color: $theme-background-color;
  padding: 12px;
  color: $theme-text-color;
  width: 330px;
  box-shadow: 5px 6px 7px rgba(5, 3, 16, 0.33);
  border: $theme-border;
  z-index: 1;
  height: auto;
  max-height: 480px;

  .custom-tags-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: $padding-medium-half;
    .custom-tag-input {
      width: 100%;
      height: 50px;
      padding: $padding-xsmall;
      border: $theme-border;
      border-radius: $padding-xsmall;
      font-size: $font-size-medium;
      color: $theme-text-color;
      &::placeholder {
        color: $theme-secondary-text-color;
      }
    }
    .color-palette-container {
      width: 100%;
      margin: $padding-normal 0px;
      .custom-tag-title {
        font-size: $font-size-normal;
        padding-bottom: $padding-xsmall;
        span {
          color: $theme-text-color;
          font-weight: bold;
        }
      }
    }
    .cancel-btn-wrapper {
      margin-right: $padding-medium-half;
    }
    .tags-apply-btn {
      @include tags-apply-btn;
    }
  }
  .tags-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding-top: 12px;
    .cancel-btn-wrapper {
      margin-right: $padding-medium-half;
    }
    .tags-apply-btn {
      @include tags-apply-btn;
    }
  }
  .tags-label-wrapper {
    width: 100%;
    height: 350px;
    overflow: auto;
    .label-wrapper {
      padding-left: $padding-small;
      margin: $padding-small $padding-xsmall 0px 0px;
    }
  }
}

.tag-author-name-tooltip {
  background: $theme-tooltip-bg-color;
  color: $theme-text-color;
  .tooltip-inner {
    max-width: 375px;
    height: 56px;
    background: $theme-tooltip-bg-color;
    font-size: $font-size-normal;
    text-align: left !important;
    .author-wrapper {
      background: inherit;
      .tag-author-info {
        background: inherit;
        font-weight: 400;
        font-size: $padding-medium-half;
        line-height: 16px;
        color: $theme-text-color;
        span {
          font-weight: 600;
        }
      }
      .tag-author-date {
        background: inherit;
        margin-top: $padding-small;
        font-weight: 400;
        font-size: $padding-medium-half;
        line-height: 16px;
        color: $theme-secondary-text-color;
      }
    }
  }
}

.d-flex.col-gap-12 {
  column-gap: 12px;
}

.MuiSvgIcon-root.red-icon-color--action-needed {
  fill: #ff3535;
  pointer-events: none;
  .dark & {
    fill: #e34444;
  }
}
