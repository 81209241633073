@import 'src/index';

.toggle-switch {
  display: inline-flex;
  width: auto;
  flex-direction: row;
  cursor: pointer;
  border: 1px $light-blue-0 solid;

  > * {
    flex: 1 1 auto;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    padding: 0 5px;
    background-color: $light-blue-0;
    color: $light-blue-5;
  }

  .selected {
    background-color: transparent;
    color: $white;
  }
}
