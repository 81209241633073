@import 'src/index';

.filter-faq-page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;

  .filter-faq-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    overflow: scroll;
    scroll-behavior: smooth;

    .filter-faq-content {
      width: 100%;
      height: min-content;
      color: $theme-text-color;

      .level {
        border-left: 1px dashed $dark-gray-2;
        padding: $padding-normal $padding-medium;
      }

      > .level {
        border-left: none;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: $padding-medium;
      }

      .filter-item {
        font-size: $padding-normal;
        font-weight: 500;
        padding: $padding-small $padding-normal;
        background-color: $theme-takedown-button-background;
        width: max-content;
        color: $theme-takedown-button-color;
      }

      .filter-explanation {
        font-size: $padding-normal;
        font-weight: normal;
      }
    }
  }

  .example-screenshot {
    font-size: $font-size-medium;

    img {
      padding: $padding-medium;
      max-height: 300px;
    }

    img.filter-image {
      max-height: 250px;
    }
  }

  .more-examples {
    padding-left: $padding-medium;
    color: $theme-text-color;

    .more-examples-title {
      font-weight: 600;
      font-size: $font-size-xxlarge;
      padding-bottom: $padding-medium;
    }

    .example {
      .example-condition {
        font-size: $font-size-large;
        font-weight: 400;
      }

      .example-screenshot {
        border-left: 1px dashed $dark-gray-2;
        padding: $padding-normal $padding-medium 0 $padding-medium;
        margin-bottom: $padding-medium;
      }
    }
  }

  .filter-faq-page-menu {
    position: fixed;
    right: $padding-normal;
    max-width: 25vw;
    min-width: max-content;
    height: min-content;
    display: flex;
    flex-direction: column;
    padding-right: 10vw;

    a {
      padding-left: $padding-medium;
      border-left: 1px dashed $dark-gray-2;
      padding-bottom: $padding-xsmall;
    }

    #more-examples-link {
      padding-top: $padding-normal;
    }
  }
}
