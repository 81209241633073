@import 'src/index';

.web-header-container {
  min-height: min-content;
  position: relative;
  background-color: white !important;

  &.headerWrapper {
    max-width: 1600px;
    left: 50%;
    transform: translate(-50%, 0);
    align-items: flex-end !important;
    // z-index: 100;
  }

  .tabs-container {
    align-items: flex-end !important;
    .nav-item {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      .new-label-text {
        display: flex;
        font-size: 12px;
        background-color: #4479f3;
        color: white;
        min-width: 40px;
        width: fit-content;
        border-radius: 10px;
        justify-content: center;
      }
      .nav-link {
        padding: 0.1rem 1rem !important;
      }
    }
  }
  .dropdown.nav-item {
    > a {
      color: $dark-gray-7;
      margin-top: $padding-small;
    }

    .dropdown-menu {
      background-color: $light-gray-2;

      .dropdown-item:active {
        background-color: $light-gray-2;
      }

      .sub-tab-container {
        width: max-content;
      }
    }
  }

  .web-header-button {
    font-size: $font-size-medium;
  }

  .login-button-box {
    background-color: transparent;
    border: transparent;
    .login-button {
      color: $dark-gray-7;
      font-size: 1rem;
      font-weight: normal;
      display: block;
      height: 16px;
    }
  }

  .login-button-box:hover {
    background-color: $white;

    .login-button {
      color: $dark-gray-7;
    }
  }

  .sign-up-button-box {
    border-color: $ocean;
    background-color: $ocean;
    margin-top: 8px;

    .sign-up-button {
      color: $white;
    }
  }

  .sign-up-button-box:hover {
    background-color: $white;

    .sign-up-button {
      color: $ocean;
    }
  }
}
