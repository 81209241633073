@import 'src/index';

$widget-item-inner-height: 380px;
$dashboard-chart-title-height: 34px;
$widget-title-and-content-height: $widget-item-inner-height - $padding-medium - $padding-medium -
  2px;
$widget-content-height: $widget-item-inner-height - $padding-medium - $padding-medium -
  $dashboard-chart-title-height - 2px;
//2 px for the border

.card + .card {
  margin-top: $padding-medium;
}

/*react grid layout css*/
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: $red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.widgets-picker-modal {
  .modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-modal-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .dashboard-modal-header-main {
      font-size: $font-size-xxlarge;
      padding-right: $padding-normal;
    }

    .dashboard-modal-header-selection-count {
      font-weight: 500;
      font-size: $font-size-large;
    }
  }

  .dashboard-modal-header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    button + button {
      margin-left: $padding-medium;
    }
  }

  .widgets-picker-modal-body {
    max-height: 88vh;
    overflow: scroll;
    padding-left: $padding-medium-half !important;
    padding-right: $padding-medium-half !important;
    padding-bottom: 0 !important;

    .widgets-picker-contents {
      display: flex;
      flex-wrap: wrap;

      .widget-picker-candidate {
        flex: 0 50%;
        padding-left: $padding-medium-half;
        padding-right: $padding-medium-half;
        padding-bottom: $padding-medium;

        .widget-picker-candidate-inner {
          width: 100%;
          border: $border;

          img {
            max-width: 100%;
            object-fit: contain;
          }

          .candidate-info {
            border-top: $border;
            padding: $padding-medium;

            .candidate-title {
              font-weight: 500;
              font-size: $font-size-large;
            }

            .candidate-description {
              font-weight: normal;
              font-size: $font-size-medium;
            }

            button {
              width: 100%;
              margin-top: $padding-medium;
            }

            button.widget-add {
              color: $light-blue-5;
              border-color: $light-blue-5;
            }

            button.widget-add:hover {
              background-color: $light-blue-5;
              color: $white;
            }

            button.widget-selected {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              background-color: $blue_green;
              border-color: $blue_green;

              img {
                margin-right: $padding-small;
              }
            }

            button.widget-selected:hover {
              background-color: $blue_green;
            }
          }
        }
      }
    }
  }
}

.widget-section-container {
  padding: $padding-medium;
  padding-top: $padding-large;
}

.widget-item-component {
  height: 100%;
}

.widget-item-inner {
  position: relative;
  height: 100%;
  padding: $padding-medium;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $theme-widget-bg-color;

  .scan-result-item-title {
    font-size: 24px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
  }

  .scan-result-item-time-range {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
  }

  .scan-result-item-value {
    color: #0277bd;
  }
}

.widget-item-inner.add-new-widget-component {
  border: $border-dashed;
  background-color: $light-gray-1;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.scan-bar-container.scan-bar-xl {
  padding: $padding-medium $padding-medium 0 $padding-medium;

  .card {
    padding: $padding-medium;
  }
}

.dashboard-page-title {
  width: 100%;
  padding: $padding-medium-web $padding-medium-web 0 $padding-medium-web;
  align-items: center;
  flex-flow: nowrap;

  .page-title {
    flex: 1;
    font-weight: 500;
    font-size: $font-size-xxlarge;
    color: $dark-gray-5;
  }

  .scan-bar-container {
    z-index: 1;
    width: calc(390px + #{$padding-medium-web} * 2);
    flex: 1;
    padding: 0 $padding-medium-web;
    transition: all 0.5s ease;

    .scan-bar-inner {
      margin: 0 auto;
    }

    .card {
      width: 390px !important;
      margin: 0 auto;
      transition: all 0.5s ease;
    }
  }

  .title-right-buttons {
    flex: 1;
    justify-content: flex-end;

    .dropdown-date-range-picker-component {
      padding-left: $padding-medium-web;
    }
  }

  @media only screen and (max-width: $screen-size-sm) {
    .title-right-buttons {
      padding-top: $padding-normal;
      padding-bottom: $padding-normal;
    }

    .dropdown-date-range-picker-component {
      // padding-top: $padding-normal;
    }
  }

  .dashboard-action-dropdown {
    padding-top: $padding-medium;
    padding-right: $padding-medium;

    .dropdown {
      border: $border2;
      background-color: $white;
      height: 40px;

      button {
        padding: $padding-small;
      }
    }
  }
}

.dashboard-page-title.scanbar-is-focus {
  .scan-bar-container {
    padding: 0;

    .card {
      padding: $padding-medium;
      width: 100% !important;
    }
  }
}

.dashboard-page-editing-header {
  height: 80px;
  min-height: 80px;
  width: 100%;
  background-color: $dark-gray-3;
  padding: 0 $padding-medium;
  justify-content: space-between;

  input {
    background-color: $dark-gray-3;
    color: $white;
    padding-left: $padding-medium-half;
    border: 1px solid $white;
    width: 480px;
    height: 40px;
    font-size: $font-size-xxlarge;
    font-weight: 500;
    box-shadow: none;
  }

  input:focus {
    outline-color: $light-blue-3;
    border-color: $light-blue-3;
  }

  .dashboard-page-editing-header-buttons {
    .add-section-button {
      color: $white;
      padding-right: $padding-normal;
      cursor: pointer;

      img {
        padding-right: $padding-small;
      }
    }

    .btn-outline-light {
      color: $white;
    }

    button {
      font-weight: 500;
      height: 40px;
      font-size: $font-size-medium;
      color: $dark-gray-5;
    }

    button + button {
      margin-left: $padding-normal;
    }
  }
}

.hide-button-container.table-hide-button {
  position: inherit;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.hide-button-container {
  position: absolute;
  top: $padding-xxsmall;
  right: $padding-normal;

  .hide-widget-button {
    cursor: pointer;
  }
}

.replace-button-container {
  right: $padding-small;
  background-color: $white;
  padding-bottom: $padding-xxsmall;
  padding-left: $padding-small;
}

.allscans-page,
.brand-insight-page {
  .zero-section-help-text {
    background-color: $blue_green2;
    padding: 0 $padding-normal;
    margin-bottom: $padding-medium;
    width: 100%;
    height: 48px;
    line-height: 48px;
    color: $white;
    font-size: $font-size-medium;
  }

  .add-section-component {
    height: 350px;
  }

  .card.widgets-container {
    background-color: transparent;
    border: none !important;
    height: 280px;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: $screen-size-md) {
      flex-wrap: wrap;
    }

    .widget-item-component {
      flex: 1;
    }

    .widget-item-component + .widget-item-component {
      @media only screen and (max-width: $screen-size-md) {
        margin-top: $padding-medium;
      }
      @media only screen and (min-width: $screen-size-md + 1) {
        margin-left: $padding-medium;
      }
    }

    .widget-item-component {
      @media only screen and (max-width: $screen-size-md) {
        min-width: calc(100vw - 48px);
      }
    }
  }

  .nav-link {
    color: var(--theme-page-text);
  }

  .nav-link.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  .nav-link.active {
    color: var(--theme-hollow-button-border);
    border-bottom: 2px solid;
  }

  .allscans-table-container {
    border: none !important;
    .allscans-table-component {
      top: -20px;
      position: relative;
      .tool-bar-container {
        margin-top: -2px;
      }
    }
    .toggle-switch {
      top: 23px;
      height: 22px;
      position: relative;
      z-index: 1;
      width: 250px;
      border: 1px #666666 solid;
      > * {
        background-color: white;
        color: #666666;
        border-left: 1px #666666 solid;
        padding: 4px;
      }
      .selected {
        background-color: #666666;
        color: white;
      }
    }
  }

  .table-component {
    .allscans-table-loading-wrapper.is-loading {
      min-height: 80vh;
    }
    a {
      color: var(--theme-hollow-button-border) !important;
    }

    .table-source-url-column {
      min-width: 0;
      max-width: 10rem;

      a,
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    @media only screen and (max-width: $screen-size-sm) {
      .table-source-url-column {
        max-width: 9rem !important;
      }
    }

    // handle special case
    @media only screen and (min-width: $screen-size-sm) and (max-width: 1200px) {
      .table-source-url-column {
        max-width: 5rem !important;
      }
      .hide-below-lg,
      .web-only {
        display: none !important;
      }
    }

    .table-source-url-tooltip {
      .tooltip-inner {
        max-width: 40rem;
      }
    }

    .tools-container > .dropdown-component .dropdown-menu {
      left: -1.8rem !important;
    }

    @media only screen and (max-width: $screen-size-sm) {
      .tools-container > .dropdown-component .dropdown-menu {
        left: 0 !important;
      }
    }
  }
}

@media only screen and (min-width: $screen-size-xlg) {
  .dashboard-tile-component {
    width: calc(50% - #{$padding-medium-web} / 2) !important;

    .major-content {
      border-bottom: $border;
    }
  }
}

.dashboard-tile-component {
  margin-bottom: $padding-medium;
  padding: $padding-medium;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: $screen-size-lg) {
    .major-content {
      padding-bottom: 0 !important;

      img {
        padding-bottom: $padding-medium;
      }
    }
  }

  .major-content {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-bottom: $padding-medium;

    .col-sm-6,
    > div {
      padding-left: 0;
      padding-right: 0;
    }

    .major-info-content {
      justify-content: space-between;
      height: 100%;
    }
  }

  .secondary-content {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: $padding-medium;
  }
}

.monitoring-container-cp {
  border: none !important;
}

.breach-container {
  .dashboard-breach-widget {
    padding: 0;
    .alert-container {
      margin: 0;
      border-radius: 0;
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
        span {
          color: #1987af;
        }
      }
    }
  }
}

.dashboard-monitoring-widget-cp {
  > :not(:first-child) {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .col {
    padding-top: $padding-medium;
  }
}

.dashboard-monitoring-widget {
  background: linear-gradient(180deg, $light-blue-5 0%, $light-blue-4 100%);
  min-height: 10vh !important;

  :first-child {
    background-color: transparent !important;
  }

  &-control {
    justify-content: flex-end;
    padding: 0 $padding-small $padding-xxsmall;
    align-items: center;
    min-height: $padding-medium;
  }

  &-data {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    // padding-top: $padding-medium;

    .monitoring-widget-title {
      font-size: 14px;
      flex: 1;
      padding: 0 $padding-medium;
    }

    .monitoring-widget-2nd-column {
      height: $font-size-xxxlarge;
      padding: $padding-xxxsmall $padding-small;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.2);
      font-weight: 500;
      text-transform: capitalize;
      display: flex;
      align-items: center;
    }

    .monitoring-widget-item-cp {
      padding: 0;

      .monitor-widget-item-wrapper {
        border-right: 1px solid rgba(255, 255, 255, 0.5);

        .monitoring-widget-item-inner {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          min-height: 60px;
          padding: 0 $padding-medium;
          width: 100%;
          color: $white;

          .monitoring-widget-item-label {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-normal;
            line-height: $font-size-large;
          }

          .monitoring-widget-item-value {
            font-weight: 600;
            line-height: $font-size-xxxlarge;
            font-size: $font-size-xxlarge;
          }
        }
      }
    }

    .monitoring-widget-item-cp:last-child {
      .monitoring-widget-item-inner {
        border-right: 0;
      }
    }
  }

  .dashboard-monitoring-widget-cp .monitoring-widget-item-cp:first-child {
    background: transparent !important;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .hide {
    animation-name: fade-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .show {
    animation-name: fade-in;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
}

.card.no-border-or-background {
  border-width: 0 !important;
  background-color: $light-gray-1 !important;
}

.dashboard-chart-title {
  font-size: $font-size-large;
  font-weight: 500;
  height: $dashboard-chart-title-height;
  width: max-content;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dashboard-widget {
  display: flex;
  flex-direction: column;
  height: $widget-title-and-content-height;

  .apexcharts-canvas {
    margin-top: $padding-xsmall;

    .apexcharts-zoom-icon.apexcharts-selected {
      display: none;
    }

    .apexcharts-reset-icon {
      opacity: 0;
      cursor: default;
    }
  }

  .apexcharts-legend-series {
    display: flex !important;
    flex-direction: row;
    align-items: center;
  }

  .apexcharts-tooltip.apexcharts-active {
    border-radius: 0;
    box-shadow: 0 4px 8px rgba(51, 51, 51, 0.24);

    .apexcharts-tooltip-title {
      padding: 0;
      border-bottom: 0;
      font-weight: normal;
      font-size: $font-size-medium !important;
    }

    .apexcharts-tooltip-series-group {
      align-items: center !important;
      padding: 0;

      .apexcharts-tooltip-y-group {
        display: flex;
        align-items: center;
      }
    }
  }

  .apexcharts-xaxistooltip {
    background-color: $white;
    border: none;
  }

  .apexcharts-xaxistooltip::before {
    display: none;
  }

  .donut-chart-container {
    width: 100%;
    max-width: 600px;
    align-self: center;
  }
}

.dashboard-hosting-widget,
.dashboard-ip-widget {
  .hosting-widget-tooltip-container,
  .ip-widget-tooltip-container {
    .apexcharts-tooltip-title {
      font-size: $font-size-medium !important;
    }

    .live,
    .down {
      font-size: $font-size-normal !important;
      padding: $padding-xsmall 0;

      b {
        padding-left: $padding-xsmall;
      }
    }

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: $padding-small;
    }
  }

  .apexcharts-bar-series.apexcharts-plot-series {
    .apexcharts-series {
      path:hover {
        opacity: 0.9;
      }
    }
  }
}

.dashboard-countries-widget {
  .dashboard-map-container {
    position: relative;
    height: $widget-content-height;

    .dashboard-map {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;

      .leaflet-popup-close-button {
        display: none;
      }

      .leaflet-popup-content-wrapper {
        border-radius: 0;
      }

      .leaflet-popup-tip-container {
        display: none;
      }
    }
  }

  .height-0 {
    height: 0 !important;
  }
}

.dashboard-tld-widget {
  .apexcharts-legend.apexcharts-align-center.position-right {
    top: 0 !important;
    display: flex;
    justify-content: space-evenly;
  }

  .apexcharts-tooltip.apexcharts-active {
    background-color: transparent;
    padding: 0;
    border: $border;

    .apexcharts-tooltip-text {
      background-color: $white;
      color: $dark-gray-3;
      // padding: $padding-small;
    }
  }
}

.no-data-placeholder-container {
  flex: 1;
  width: 100%;
  height: $widget-content-height;
  min-height: $widget-content-height;
  padding: 0 $padding-xxlarge;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: $font-size-xxlarge;
    font-weight: 500;
    padding: $padding-normal;
  }

  .text {
    font-size: $font-size-medium;
    font-weight: normal;
    text-align: center;
  }
}

@media only screen and (max-width: $screen-size-xlg) {
  .no-data-placeholder-container {
    padding: $padding-small 0;
  }
}

@media only screen and (max-width: 800px) {
  .toggle-switch {
    top: 5px !important;
  }
}

.dashboard-add-section-tile {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $padding-medium;

  .dashboard-add-section-tile-image {
    width: 385px;
    height: 100px;
  }

  .dashboard-add-section-tile-image.border {
    border: $border;
  }

  .dashboard-add-section-info {
    flex: 1;
    padding: 0 $padding-medium;

    .dashboard-add-section-title {
      padding-bottom: $padding-small;
      font-size: $font-size-large;
      font-weight: 500;
    }

    .dashboard-add-section-description {
      font-size: $font-size-medium;
      font-weight: normal;
    }
  }
}

.scanned-locations-tooltip {
  max-width: none;
  white-space: nowrap;
  padding: 1px 1px;
}

.scanned-locations-tooltip .tooltip-inner {
  max-width: none;
}
