@import 'src/index';
.integrations-tab {
  .integrations-header {
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    margin-left: 24px;
  }
  .integration-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
    margin-left: 24px;
    cursor: pointer;

    .integration-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 256px;
      width: 320px;
      background-color: #333;
      transition: box-shadow 0.3s ease;
      border: 1px solid #e9ecf0;

      .dark & {
        background-color: #333;
        border: 1px solid #464d52;
      }

      .integration-name-hidden-on-hover,
      .integration-button-visible-on-hover {
        transition: opacity 0.3s ease;
        position: absolute;
        bottom: 32px;
      }

      .integration-button-visible-on-hover {
        opacity: 0;
      }

      &:hover {
        .integration-name-hidden-on-hover {
          opacity: 0;
        }
        .integration-button-visible-on-hover {
          opacity: 1;
        }
      }
    }

    .integration-item.integration-enabled {
      background-color: white;
    }

    .integration-item.integration-enabled:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border: 1px solid #d6d9dd;
      .dark & {
        border: 1px solid var(--Light-Theme-Neutral-Blue-Black-500, #767c83);
      }
    }

    .integration-item.integration-disabled {
      background-color: rgba(24, 29, 31, 0.25);
    }

    .integration-coming-soon {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 96px;
      background-color: white;
      border-radius: 12px;
      padding: 2px 8px;
      font-size: 12px;
      opacity: 1;
    }

    .integration-image-container {
      position: relative;
      height: 128px;
      width: 192px;
      margin-bottom: 32px;
    }

    .integration-image-container img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .dark & {
    color: #f5f5f5;

    .integration-item {
      background-color: #333;
    }

    .integration-item.integration-enabled {
      background-color: #444;
    }

    .integration-item.integration-enabled:hover {
      box-shadow: 0 4px 10px rgba(255, 255, 255, 0.1);
    }

    .integration-item.integration-disabled {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .integration-coming-soon {
      background-color: #555;
    }
  }

  .back-logo {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 24px;
    margin-right: 12px;
  }

  .selected-integration-title {
    color: var(--theme-page-text, rgba(0, 0, 0, 0.87));
    font-size: 24px;
    font-weight: 500;
    .dark & {
      color: #f5f5f5;
    }
  }

  .new-connector-with-knowledge-base {
    padding: 24px;
    padding-top: 0;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .new-connector-flat-container {
    flex: 1;
    background-color: var(--theme-page-background);
    border: 1px solid #e9ecf0;
    overflow-y: auto;
    height: 85vh;

    .dark & {
      border: 1px solid #464d52;
    }

    .modal-footer {
      border: none;
    }

    .modal-header {
      border: none;
    }

    .add {
      color: var(--Light-Theme-Primary-600, #0d5bf8);
      font-size: 15px;
      font-weight: 500;
    }
    .add-container {
      width: 60px;
      margin-left: -4px;
      margin-top: 4px;
    }

    .connector-hints {
      font-weight: 600;
      margin-top: -16px;
      margin-bottom: 20px;
      font-size: 14px;
    }

    .form-control::placeholder {
      color: var(--text-secondary, rgba(0, 0, 0, 0.6));
      font-size: 14px;
      .dark & {
        color: var(--text-secondary, rgba(255, 255, 255, 0.6));
      }
    }
    .cancel-button {
      margin-right: 16px;
      .dark & {
        color: #f5f5f5;
      }
    }
  }

  .mr-16 {
    margin-inline-end: 16px;
  }

  #knowledge-base-content {
    flex: 1;
    height: 85vh;
    padding: 16px;
    background-color: var(--theme-page-background);
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #e9ecf0;

    .dark & {
      border: 1px solid #464d52;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.checkphish-integrations-tab {
  padding: 0 24px;
  .integrations-header {
    margin: 16px 0 !important;
  }
  .integration-item {
    height: 300px !important;
  }
  .integration-item.integration-disabled-checkphish {
    background-color: $disabled-btn-bg !important;
    border: 1px solid $disabled-btn-bg !important;
    padding: 10px !important;
  }
  .highlight-text {
    font-weight: bold !important;
    color: $disabled-card-highlight-text !important;
  }
  .enable-text {
    text-align: center;
    margin-top: 10px;
    .link-text {
      font-weight: bold;
      color: #2168e4;
      cursor: pointer;
    }
  }
  .configure-text {
    color: #635bff;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    img {
      margin-left: 5px;
    }
  }
}
