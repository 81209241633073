@import 'src/index';

.app-wrapper {
  height: 100vh;
  background-color: $theme-color;
}

.content-wrapper {
  width: 100%;
  display: flex;
  padding-top: $top-nav-bar-height;
  height: 100vh !important;
  background-color: $theme-color;
  position: relative;
  z-index: 1;
  .app-content {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $theme-color;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
