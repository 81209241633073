@import 'src/index';

.matrix-dataset-wrap {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 2rem;
  min-width: 20% !important;
  margin: 0;

  .heading {
    font-weight: bold;
    text-align: left;
    margin-bottom: 1rem;
    height: 20%;
  }
  .dataset-body {
    .matrix-data-set {
      margin-bottom: 10px;
      padding-left: 0.5rem;
      .label {
        color: $theme-darkweb-subtext;
        display: flex;
      }
      .value {
        font-weight: bold;
      }
    }
  }
}

.widget-collapsed {
  .matrix-dataset-wrap {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    padding: 0 1rem !important;
    min-width: 40% !important;
    .heading {
      white-space: nowrap;
      overflow-x: clip;
    }
  }
}

.risk-tooltips {
  .tooltip-inner {
    max-width: 320px;
    .tooltip-message {
      display: flex;
      width: auto;
      padding: 2px;
      word-break: break-word;
      white-space: pre-line;
    }
  }
}
