.transparent-bg-tooltip {
  background-color: transparent !important;
  display: flex;
  width: auto;
  padding: 2px;
  word-break: break-word;
  white-space: pre-line;
  div {
    min-width: fit-content !important;
  }
  .text-left {
    width: 300px !important;
    width: auto;
    padding: 2px;
    word-break: break-word;
    white-space: pre-line;
  }
}
