@import 'src/index';

.add-brand-property-logo-form {
  .field-label-wrapper {
    display: flex;
    flex-direction: row;
  }

  .asterisk {
    color: #ba0517;
  }

  .field-value {
    margin-left: $padding-medium-half;
  }
}

.examples-container {
  display: flex;
  flex-direction: row;
}

.code-block {
  &.large-height {
    height: 65px;
  }

  background-color: $theme-code-block-color;
  color: $theme-text-color;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 35px;
  margin-right: 10px;
}

.PhoneInput {
  font-size: medium;
  .PhoneInputCountryIcon {
    width: 30px;
    height: 20px;
  }
}

.modal-sm {
  max-width: 358px;
}
