@import 'src/index';

.mui-card-wrapper {
  background-color: $theme-widget-bg-color !important;
  border-radius: 0 !important;

  .mui-card-header {
    .MuiCardHeader-title {
      font-size: 16px;
      font-weight: 500;
      font-family: 'Fakt';
    }
  }

  .mui-card-content {
    font-size: 16px;
    font-weight: 500;
  }
}
