@import 'src/index';

.detection-action-banner {
  background-color: $theme-color;
  padding-top: $padding-medium;
  .detection-action-banner-inner {
    width: 100%;
    padding: $padding-normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      color: $name-text-color;
      font-weight: 600;
      font-size: $font-size-medium;
      text-transform: uppercase;
    }

    .buttons {
      .edit-button-container {
        background-color: $white;
        display: inline-block;
        margin-right: $padding-normal;
      }
    }
    .buttons {
      .outline-secondary-button-container {
        display: inline-block;
        margin-right: $padding-normal;
        border-radius: 4px;

        &:last-child {
          margin-right: 0;
        }
        .btn.btn-outline-secondary {
          border-color: $theme-hollow-btn-border !important;
          color: $theme-hollow-btn-border !important;
        }
      }
    }
  }
}
