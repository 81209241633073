@import 'src/index';

.abuse-mailBox-emailDetails-container {
  flex: 3;
  padding-top: 20px;
}

.emailDetails-analysis-container {
  .status_chip {
    margin-left: 4px;
  }

  .email-details-header-section {
    background: $abuseMailBox-bg-color;
    padding: 24px;
    position: relative;
    .email-details-header-container {
      display: flex;
      line-height: normal;
      max-width: 800px;

      .email-details-header {
        margin-right: 15px;
        font-size: 16px;
        font-weight: 500;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }

    .email-details-subheader-container {
      margin-top: 10px;
      display: flex;
      .subheader-item {
      }
      .email-details-submission-date {
        padding-left: 0 !important;
      }
      .email-details-lastscan-date {
      }
    }
    .email-details-section-bar {
      display: flex;
      margin-top: 10px;
      width: max-content;
      .section-item {
        font-size: 14px !important;
        margin: 0 2px;
        padding: 0 10px;
        border-radius: 2px;
        color: $abuseMailBox-allEMailSubmission-subheader-text;
        font-weight: 500;
        &:hover {
          background: rgba(0, 0, 0, 0.07);
          .dark &:not(.active-section-item) & {
            color: $abuseMailBox-allEMailSubmission-subheader-text;
            background: #7e868e;
          }
        }
        .section-item-icon {
          fill: #555e68 !important;
          .dark & {
            fill: #c6d3e0 !important;
          }
        }
      }
      .active-section-item {
        color: #171a1c;
        background: rgba(0, 0, 0, 0.07);
        border-radius: 3px;
        .section-item-icon {
          fill: #171a1c !important;
        }

        .dark & {
          color: #fff;
          background: #555e6880;

          .section-item-icon {
            fill: #fff !important;
          }
        }
      }
      .filled-circle {
        margin-inline: 8px;
        &:last-child {
          display: none;
        }
      }
    }
  }

  .MuiCardHeader-content {
    flex: 0;
  }
  .mui-card-content {
    height: 50vh;
    overflow-y: auto;
  }
}

.email-details-screenShot-container {
  .card-between {
    border: none !important;
    .justify-content-between {
      display: none !important;
    }
  }

  .annotation-component {
    img {
      height: 50vh;
    }
  }
}

.abuse-mailBox-threatType-details-container {
  .abuse-mailBox-threatType-insights {
    padding: 20px 25px 20px 20px;
  }
  .abuse-mailBox-insights-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;

    .abuse-mailBox-insights-header-dipsosition {
      border-right: var(--theme-border);

      .disposition-status-component {
        margin-right: 10px;
      }
    }
  }
  .abuse-mailBox-emailDetails-main-container {
    .abuse-mailBox-emailDetails-container {
      padding: 0px;
    }
  }
}

.page-title-component {
  .dispute-dom-container {
    .abuse-mailBox-report-count-container {
      margin-left: 10px;
    }
  }
}

.abuse-mailBox-report-count-container {
  border-left: var(--theme-border);

  .abuse-mailBox-report-count {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    color: var(--theme-page-text);
  }
}

.emailDetails-insights-container {
  padding-top: 0 !important;
}

.email-details-header--tooltip {
  .MuiTooltip-tooltip {
    max-width: 800px;
    max-height: 100px;
    overflow: scroll;
  }
}
