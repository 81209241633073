@import 'src/index';

.finding-table-filter-by-category {
  padding: $padding-medium 0;
  gap: $padding-xxsmall;
  background-color: var(--theme-color);
  overflow-x: scroll;

  [data-risk='high'] {
    --background-image: url(../../../assets/bg-high-risk.png);
  }
  [data-risk='medium'] {
    --background-image: url(../../../assets/bg-medium-risk.png);
  }
  [data-risk='low'] {
    --background-image: url(../../../assets/bg-low-risk.png);
  }

  --hover-state-brightness: 0;
  --status-brightness: 0;
  .category-filter {
    height: 150px;
    min-width: 140px;
    background-image: var(--background-image);
    padding: $padding-normal $padding-normal $padding-xxsmall $padding-normal;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 600;
    cursor: pointer;
    color: #ffffff;
    transition: filter 250ms ease;
    filter: brightness(calc(1 + var(--hover-state-brightness) + var(--status-brightness)));

    .category {
      font-size: $font-size-large;
      text-shadow: 2px 1px 0px rgba(0, 0, 0, 0.51);
      word-break: break-word;
    }
    .total {
      font-size: $font-size-xxlarge;
      text-shadow: 0px 1.06547px 0px #070707;
    }
    svg:hover {
      filter: brightness(1.3);
    }

    &:hover {
      --hover-state-brightness: 0.1;
      &[data-risk='medium'] {
        --hover-state-brightness: 0.2;
      }
      &[data-risk='high'] {
        --hover-state-brightness: 0.3;
      }
    }

    &.darken {
      --status-brightness: -0.5;
    }
  }
}
